// Customizable Area Start
import React, { useContext, useEffect, useState } from "react";
import "./CardItem.web.css";
import { loader, playOutline, loopIc, defaultProfile } from "../../../dashboard/src/assets";
const img = {
  width: "148px",
  height: "148px",
  borderRadius: "12px",
};
import millify from "millify";
import { AudioContextApi } from "../../../dashboard/src/AudioContext/AudioContext.web";


const CardItem = ({ image, name, artist, play, refresh, item, index, mainList, }: any) => {
  const { handlePlayList } = useContext(AudioContextApi)


  const [audioType, setAudioType] = useState<any>('')
  useEffect(() => {
    if (window.location.href.includes('beat')) {
      setAudioType('Beats')
    } else if (window.location.href.includes('hook')) {
      setAudioType('Hooks')
    } else if (window.location.href.includes('verse')) {
      setAudioType('Verses')
    } else if (window.location.href.includes('song')) {
      setAudioType('Songs')
    } else if (window.location.href.includes('playlist')) {
      setAudioType('Playlists')
    }


  }, [])

  let srcImg;

if (image !== null) {
  srcImg = image;
} else if (image == null) {
  srcImg = defaultProfile;
} else {
  srcImg = loader;
}
  return (


    <div className="video-card-center">
      <div className="music-Box video-card" onClick={() => {
        handlePlayList(item, index, mainList, audioType)
      }}>
        <img
          src={srcImg}
          alt=""
          //  onClick={props.handlePlayList}
          style={{ cursor: "pointer" }}
        />


        <div className="song-info" style={{ marginLeft: "2px" }}>
          <h2>{name || "Unknown"}</h2>
          <p>{artist ? artist : "unknown"}</p>
        </div>
        <div style={{ display: "flex" }} className="text_blue" >
          <p
            className="mr_05 mb_0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={playOutline} style={{ height: "13.5px", width: "12.5px", cursor: "pointer" }} alt=""
            />
            <span className=" mb_0 plays-carasouel" >
              {millify(play) || "0"}
            </span>
          </p>
          <p className="mr_05 mb_0" style={{ display: "flex", alignItems: "center" }}>
            <img
              src={loopIc}
              className="mr_05"
              style={{ height: "12.5px", width: "12.5px", marginLeft: "7px" }}
              alt=""
            />
            <span className=" mb_0 plays-carasouel">
              {refresh || "0"}
            </span>
          </p>
        </div>
      </div>
    </div>




  );
};
export default CardItem;
// Customizable Area End