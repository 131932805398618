// Customizable Area Start
import React from "react";
import DealTermController, {
    Props,
} from "./DealTermController.web";
import "./DealTerm.web.css";
import AsideLeft from "../../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import Loader from "../Loader.web";
import { Layout } from "antd";
import { Grid, Hidden, Box, Typography } from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import RightPanel from '../Common/RightPanel.web';
import { DealTermRightBg } from "../assets";
import HomeHeader from "../../../../../components/src/CustomChart/PopUps/HomeHeader.web";
// Customizable Area End

// Customizable Area Start
export const TermCard = (props: { term: any, selected: boolean, onTermClick: any }) => {
    const { term, selected } = props;
    const handleClick = () => {
        props.onTermClick(term);
    }
    const dType: string = term.type;
    return (
        <Box className={`TermCard ${selected && 'SelectedTerm'}`} onClick={handleClick}>
            <Box className="TermDurationBox" >
                <Typography className='CommonText TermDuration'>{term.duration}</Typography>
            </Box>
            <Box className="TermMonthBox">
                <Typography className='CommonText TermDurationType'>{dType.toUpperCase()}</Typography>
                <Typography className='CommonText TermText'>{'Term'}</Typography>
            </Box>
        </Box>
    )
}
// Customizable Area End
export class DealTerm extends DealTermController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading, dealTerms, selectedTerm } = this.state;
        return (
            <>
                <Layout className="MainLayoutContainer">
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        className="MainContent"
                    >
                        <HomeHeader />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="AudioSplitContent">
                                <Grid container>
                                    <div className='MainContentDiv'>
                                        <Grid item xs={12} sm={12} md={6} lg={7} xl={8} className='ColumnCenter'>
                                            <div className='ColumnCenter AICenter'
                                            >

                                                <Hidden only={['lg', 'xl']}>
                                                    <div className="ColumnCenter AICenter MT32">
                                                        <div className='CommonText InfoTextSm'>Select The Term You </div>
                                                        <p className='CommonText InfoTextSm'>Would like to Own Split For</p>
                                                    </div>
                                                </Hidden>
                                                {/* Deal Terms START*/}
                                                <Grid container>
                                                    {
                                                        dealTerms.map((term: any, index: number) => {
                                                            const isOdd: boolean = this.isOdd(index + 1);
                                                            return (
                                                                <Grid key={term.id} item xs={6} className={`TermGrid ${isOdd ? 'TermOddGrid' : 'TermEvenGrid'}`}>
                                                                    <TermCard data-test-id="dealtermid" term={term} onTermClick={this.selectTerm} selected={selectedTerm.id === term.id} />
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                                {/* Deal Terms END */}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                                            <RightPanel
                                                line1={`Select Term You Would`}
                                                line2={`Like To Own Split For`}
                                                backgroundImage={DealTermRightBg}
                                                onNextClick={this.onNextClick}
                                                onBackClick={this.onBackClick}
                                            />
                                        </Grid>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </Content >
                </Layout >
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default DealTerm;
// Customizable Area End