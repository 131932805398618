import React from 'react'
import { Typography } from 'antd';
import { commentBlue, DummyProfileImage, fullscreen, plays, reshared } from '../../../../dashboard/src/assets';
import millify from 'millify';
import { useHistory } from 'react-router-dom';

const PlaylistTrackMobile = ({ song,index}: any) => {
    const history = useHistory();

    return (
        <div className="mobile-playicons" >

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', padding: '2px' }}>
            <Typography style={{ color: 'white', marginRight: '10px', fontSize: 'small' }}>{index < 9 ? '0' : ''}{index + 1}</Typography>
            <img src={song?.attributes.art_work || DummyProfileImage} style={{ height: '40px', width: '40px', borderRadius: '5px' }} alt="" />
            <div style={{ display: "flex", flexDirection: "column", color: 'white', margin: '0 10px' }}>
              <Typography style={{ fontWeight: 'bold', fontSize: 'small', color: '#efefef' }}>{song?.attributes.title}</Typography>
              <Typography style={{ color: "rgb(143, 144, 146)", fontSize: 'small' }}>{song?.attributes.artist_name}</Typography>
            </div>
          </div>

          <div >
            <img src={fullscreen} style={{ height: "28px", width: "30px", marginTop: "-4px", marginLeft: '10px' }} onClick={() => { history.push(`/home/expand/song/songId=${song.id}`) }} />
          </div>
        </div>

        <div className="mobile-view-div">
          <div style={{ display: 'flex', marginTop: '5px' }}>
            <img src={plays} style={{ height: "28px", width: "28px", marginTop: "-4px" }} />
            <p className="ml_02" style={{
              textAlign: "start",
              color: "#fff", fontSize: "13px"
            }}>
              {song?.attributes?.play_count ? millify(song?.attributes?.play_count) : "0"}
            </p>
          </div>
          <div style={{ display: 'flex', marginTop: '5px' }}>
            <img src={commentBlue} style={{ height: "24px", width: "24px", marginLeft: '4px' }} />
            <p className="ml_05" style={{
              textAlign: "start",
              color: "#fff", fontSize: "13px"
            }}>
              {song?.attributes?.comments_count ? millify(song?.attributes?.comments_count) : "0"}
            </p>
          </div>
          <div style={{ display: 'flex', marginTop: '5px' }}>
            <img src={reshared} style={{ height: "28px", width: "30px", marginTop: "-4px" }} />
            <p className="ml_02" style={{
              color: "#fff", fontSize: "13px"
            }}>
              {song?.attributes?.repost_count ? millify(song?.attributes?.repost_count) : "0"}

            </p>
          </div>

        </div>
      </div>
    )
}

export default PlaylistTrackMobile