// Customizable Area Start
import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const CustomSplitPercentageInput = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange,  ...other } = props;
        const maxValue:number = Number(localStorage.getItem('maxPercentage'));
        return (
            <NumericFormat
                data-testid="NumericFormatInput"
                {...other}
                isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue === undefined || Number(floatValue)<= maxValue;
                }}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                valueIsNumericString
                suffix='%'
            />
        );
    },
);

export default CustomSplitPercentageInput;
// Customizable Area End