// Customizable Area Start
import React, { useContext, useState } from 'react';
import {
  addToPlaylist,
  dealTrack,
  defaultProfile,
  pauseA,
  playButton,
  repostIcon,
  shareTrack,

} from '../../../../blocks/dashboard/src/assets';
import './index.css';
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import axios from 'axios';
const urlConfig = require("../../../../framework/src/config");
const baseUrl = urlConfig.baseURL;
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import {  Form, Select } from "antd";
import { openNotification } from '../../Notification.web';
import { useHistory } from 'react-router-dom';
import { Tooltip } from "@material-ui/core";
import RepostPopup from "components/src/CustomChart/PopUps/RepostPopup.web";

import { makeStyles } from "@material-ui/core/styles";
 import AddToQueuePopup from 'blocks/dashboard/src/CustomComponent/AddToQueue/AddToQueuePopup.web';
 import SharePlaylistPopup from 'blocks/dashboard/src/CustomComponent/SharePlaylistPopup/SharePlaylistPopup.web';
import PlayListCreateAndAddplayListModel from '../../../../blocks/Playlist/src/PlayListCard/PlayListCreateAndAddplayListModel.web'

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "13px",
    fontFamily: "Inter",
  },
}));

function MuiTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}




export const CarasouelProfile = ({ mainList, songIndex,
  name, trackdata, handleChartTrackRepost, artWork, trackId, setrepostCount, repostCount,
  artistImage }: any) => {

  const { handlePlayList, handleAudioVideo, setHandleAudioVideo } = useContext(AudioContextApi);

  let selectedSongStr: any = localStorage.getItem("selectedSong")
  let selectedSong = JSON.parse(selectedSongStr);
  const [openRepostPopup, setOpenRepostPopup] = useState<boolean>(false);
  const [ScanQropenPopUp, setScanQrOpenPopUp] = useState<boolean>(false);
  const [textFieldValue, setTextFieldValue] = useState<any>('');
  const token: any = localStorage.getItem("token");
  const [openPlaylistModal, setOpenPlaylistModal] = useState<any>(false);
  const [openAlertLogin, setopenAlertLogin] = useState(false);
  const [dialogTextAlertLogin, setDialogTextAlertLogin] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [userplaylist, setUserplaylist] = useState<any>("")
  const [emptyPlaylist, setEmptyPlaylist] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogText, setDialogText] = useState<any>("");
  const [showModalAddNewPlaylist, setshowModalAddNewPlaylist] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const { Option } = Select;
  const [imageUrl, setimageUrl] = useState<any>('');
  const [fileName, setfileName] = useState<any>('');
  const [playlistId, setPlaylistId] = useState<any>("");
  const [playlistName, setplaylistName] = useState<any>('');
  const [musicButton, setMusicButton] = useState<boolean>(false)

  const history = useHistory();









  const handleRepostClose = () => {
    setOpenRepostPopup(false);
  }


  const handlerFetchRepost = async () => {
    await axios
      .get(
        `${baseUrl}/bx_block_audiomusic/expan_track?id=${trackId}`,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",

            token: token,
          },
        }
      ).then((res) => {

        setrepostCount(res.data.data.attributes.repost_count);
      }).catch((error) => {
        console.log(error)
      })
  }


  const respostSubmit = async () => {
    handleChartTrackRepost(trackdata?.id,textFieldValue);
    setLoading(true);
    await handlerFetchRepost();
    setLoading(false);
    handleRepostClose();
  }

  const handleCaptionChange = (e: any) => {
    setTextFieldValue(e.target.value);
  }

  const getUserPlaylist = async () => {
    setLoading(true);
    await axios
      .get(`${baseUrl}/bx_block_playlist/play_lists`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",


          token: token,
        },
      })
      .then((response: any) => {
        setLoading(false);
        setUserplaylist(response.data.data);

      })
      .catch((err: any) => {
        setLoading(false);
        err?.response.status === 422 && setEmptyPlaylist(true);
      });
  };

  const handleAddToPlaylist = () => {

    if (token) {

      setOpenPlaylistModal(true);
    } else {
      setopenAlertLogin(true);
      setDialogTextAlertLogin(token ? "1" : "2");
    }
  }

  const closeModal = () => {
    setshowModalAddNewPlaylist(false);
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAlertLogin = () => {
    setopenAlertLogin(false);
  };

  const handleClosePlaylistModal = () => {
    form.resetFields();
    form1.resetFields();
    setimageUrl('');
    setOpenPlaylistModal(false);
  }

  const handleAddTrackToPlaylist = async (values: any) => {
    setLoading(true);
    const data = {
      add_track_id: trackdata.id,
      play_list_id: playlistId

    };
    await axios
      .post(`${baseUrl}/bx_block_playlist/add_track_to_playlist?`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((res) => {
        openNotification("This song is successfully added in playlist", "Success")
        setOpenPlaylistModal(false);
        form.resetFields();
        form1.resetFields();
        setimageUrl('')

      })
      .catch((err: any) => {
        if (err?.response?.status === 409) {

          openNotification(err.response.data.data[0].message, "Error");
        } else if (err?.response?.status === 400) {
          openNotification(err?.response?.data?.errors[0]?.token, "Error");
        }

      }).finally(() => {
        setOpenPlaylistModal(false);
        setLoading(false);
        form.resetFields();
        form1.resetFields();
        setimageUrl('')
      })
    setimageUrl('')
  };

  function onChange(value: any) {
    setPlaylistId(value);
  }

  function onSearch(val: any) {
  }

  const UploadPlayLists = async (fileName: any) => {
    setLoading(true)
    const token: any = localStorage.getItem("token")
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",

      token: token,
    }
    if (fileName && playlistName) {
      let formData = new FormData();
      for (const key of fileName) {
        //formdata object
        formData.append('name', playlistName);
        formData.append('cover_image', key);
        formData.append('status', musicButton ? 'public_playlist' : 'private_playlist');
        formData.append('play_list_type', 'audio');
      }

      //@ts-ignore
      document.getElementById('createPlayListBtn')?.setAttribute('disabled', true)
      document.getElementById('createPlayListBtn')?.setAttribute('style', 'background :#1890ff; color:#fff; width:100%;')
      await axios.post(`${baseUrl}/bx_block_playlist/play_lists`, formData, { headers })
        .then(async (response: any) => {
          openNotification('New PlayList Added Successfully', "Success")
          const data = {
            add_track_id: trackdata.id,
            play_list_id: response.data.data.id

          };
          await axios
            .post(`${baseUrl}/bx_block_playlist/add_track_to_playlist?`, data, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",

                token: token,
              },
            })
            .then((res) => {
              openNotification("This song is successfully added in playlist", "Success")
              setOpenPlaylistModal(false);
              form1.resetFields();

            })
            .catch((err) => {

              openNotification(err?.response?.data?.errors[0]?.account, "Error");
            }).finally(() => {
              setOpenPlaylistModal(false);
              form.resetFields();
              form1.resetFields();
              setimageUrl('');
            })
        }).catch((err: any) => {
          err?.response?.data?.data.length !== 0 && err?.response?.data?.data[0].message && openNotification(err?.response?.data?.data[0].message, "Error");
          err?.response?.status === 422 && openNotification(err?.response?.data?.errors[0]?.name, "Error");
          err?.response?.status === 400 && openNotification(err?.response?.data?.errors[0]?.token, "Error");
        }).finally(() => {
          setLoading(false);
          form.resetFields();
          form1.resetFields();
          setimageUrl('');
        })
    }
  }

  const fileUploadHandler = async (e: React.FormEvent<EventTarget>) => {

    let target = e.target as HTMLInputElement;
    let files = target.files as any;

    if (files.length !== 0) {

      let url = URL.createObjectURL(files[0]);
      setimageUrl(url);
      for (const file of files) {
        setfileName(files);
        console.log(files);
      }
    }
  };

  const userType = localStorage.getItem('user_type')
  const subscriptionPlan = localStorage.getItem("subscription_plans")

  const onShare = () => {
    const token: any = localStorage.getItem("token")
    if (token) {
      if (userType == "listener" && subscriptionPlan == "NXZ") {
        setopenAlertLogin(true);
        setDialogTextAlertLogin("3");
      } else {
        setScanQrOpenPopUp(true);
      }
    } else {
      setopenAlertLogin(true);
      setDialogTextAlertLogin("2");
    }

  }

  const onProfile = async (data: any, songIndex: any, mainList: any, name: any) => {
    const token: any = localStorage.getItem("token");
    const playlist = window.location.href.includes('playlists');

    if (token) {
      if (playlist) {
        const artistId = data?.attributes?.artist_id;
        const user_id: any = localStorage.getItem("user_id");
        localStorage.setItem("temp_id", user_id);
        localStorage.removeItem("user_id");
        localStorage.setItem("user_id", artistId);
        localStorage.setItem("creatives_screen", "true");
        history.push(`/creatives/beats?creative_id=${artistId}`);
      } else history.push(`/creatives/beats?creative_id=${trackdata?.attributes?.account_id}`);
    } else {
      setopenAlertLogin(true);
      setDialogTextAlertLogin(token ? "1" : "2");
    }
  }


  return (
    <div>
      <div style={{ position: 'relative' }}>
        {/* <img src={bgexpand} alt="" style={{}} className="two" /> */}
        {/* <div
            style={{
              backgroundImage: `url(${artWork || artistImage || defaultProfile})`,
            }}
            className='blur_bg_profile_img'
          /> */}
        <img src={artWork || artistImage || defaultProfile} alt="bg images" className='blur_bg_profile_img' />
        <div className='profile_bg_text'>
          {/* <img src={bgexpand} className="track-image-dialog" /> */}

          <div onClick={() => {

            handlePlayList(trackdata, 0, mainList, name, songIndex)
          }}>
            {/* <div
              style={{ backgroundImage: `url(${trackdata?.attributes?.art_work || trackdata?.attributes?.avatar || defaultProfile})` }}
              className='track-image-dialog'

            /> */}
            <img src={artWork || artistImage || defaultProfile} alt="bg images"
              className='track-image-dialog'
              onClick={() =>
                setHandleAudioVideo(handleAudioVideo == 1 ? 2 : 1)
              }
            />
            <img src={selectedSong && selectedSong === trackdata.id && handleAudioVideo === 2 ? pauseA : playButton} className='playbtn_image'
              onClick={() =>
                setHandleAudioVideo(handleAudioVideo == 1 ? 2 : 1)
              } />
          </div>

          <span className='track_title_name' onClick={() => { onProfile(trackdata, songIndex, mainList, name) }}>{trackdata?.attributes?.title || trackdata?.attributes?.name || trackdata?.attributes?.track_title}</span>

          <div className='track_username_info' onClick={() => { onProfile(trackdata, songIndex, mainList, name) }}>
            <span className='track_username_font'>{trackdata?.attributes?.artist_name || 'Unknown'}</span>
            {/* <img
              src={validIcon}
              alt='checkmark'
              style={{ width: '12px', height: '12px' }}
            /> */}
          </div>

          <div className='display_play_icon'>
            <MuiTooltip title="Repost" placement="bottom">
              <img
                src={repostIcon}
                alt='repostIcon'
                style={{
                  width: '40px',
                  height: '40px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOpenRepostPopup(!openRepostPopup)
                  // setOpenPopUp(true)
                }}
              />
            </MuiTooltip>
            {openRepostPopup && <RepostPopup open={openRepostPopup}
              onClose={handleRepostClose}
              profileImage={trackdata?.attributes?.art_work || trackdata?.attributes?.cover_image || defaultProfile}
              artistName={trackdata?.attributes?.artist_name}
              songName={trackdata?.attributes?.title}
              repost_count={respostSubmit}
              _handleTextFieldChange={handleCaptionChange}
            />}
            <MuiTooltip title="Playlist" placement="bottom">
              <img
                src={addToPlaylist}
                alt='addToPlaylist'
                style={{
                  width: '40px',
                  height: '40px',
                  cursor: 'pointer',
                }}
                onClick={handleAddToPlaylist}
              />
            </MuiTooltip>
            <MuiTooltip title="Share" placement="bottom" >
              <img
                src={shareTrack}
                alt='shareTrack'
                style={{
                  width: '40px',
                  height: '40px',
                  cursor: 'pointer',
                }}
                onClick={onShare}
              />
            </MuiTooltip>

            {/* <ScanQrDialog
              openPopup={ScanQropenPopUp}
              handleOpenPopup={() => setScanQrOpenPopUp(false)}
              shareItem={window.location.href.includes('playlists') ? "Playlist" : "Track"}
              TrackId={trackdata && trackdata.id}
              playData={trackdata}
              handleChartTrackRepost={handleChartTrackRepost}
            /> */}

           <SharePlaylistPopup
             id="Track"
             open={ScanQropenPopUp}
             handleClose={() => setScanQrOpenPopUp(false)}
             track={trackdata}
            />


            <MuiTooltip title="Deal" placement="bottom" >
              <img
                src={dealTrack}
                alt='dealTrack'
                style={{
                  width: '40px',
                  height: '40px',
                  cursor: 'not-allowed',
                }}
              />
            </MuiTooltip>
          </div>
        </div>
      </div>
      {
        showModalAddNewPlaylist && (
          <PlayListCreateAndAddplayListModel closeModal={closeModal} showModalAddNewPlaylist={showModalAddNewPlaylist} addTrackToPlaylist={'addTrackToPlaylist'} />
        )
      }
      {open && <LoginAlert open={open} handleClose={handleClose} dialogText={dialogText} />}
      {openAlertLogin && <LoginAlert open={openAlertLogin} handleClose={handleCloseAlertLogin} dialogText={dialogTextAlertLogin} />}
      {
       openPlaylistModal &&
        <AddToQueuePopup id="Queue" open={openPlaylistModal} handleClose={() => {setOpenPlaylistModal(false)}} playlistType = "audio" trackData={trackdata}/>
      }
    </div>
  );
};
// Customizable Area End
