// Customizable Area Start
import React, { useEffect, useRef, useState } from 'react'
import { Grid, Paper, Avatar } from '@material-ui/core';
import { defaultProfile,nxzloader } from '../../../../blocks/dashboard/src/assets';
import { usePubNub } from 'pubnub-react';
import "../../../../blocks/Sms/src/DirectMessage.css"
import axios from 'axios';
import { Row } from 'antd';
import { useHistory } from 'react-router-dom'
import MessageCard from '../../../../blocks/Sms/src/MessageCard.web';
import ViewNewMessengerProfile from '../../../../blocks/Sms/src/ViewNewMessengerProfile.web';
import { getChannelName, readMessage, sendMessage } from '../../../../blocks/Sms/src/ChatUtils';
import {IMessage,ISender} from '../../../../blocks/Sms/src/CustomMessageInterface'
const urlConfig = require("../../../../framework/src/config");
const baseUrl = urlConfig.baseURL;

export const ChartsMessages = ({ trackId, scrollToBottomParent }: any) => {
  
  const history = useHistory();

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [getUserDetails, setGetUserDetails] = useState<any>(null)
  const [avtarUrl, setAvtarUrl] = useState('');
  const [input, setInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const pubnub = usePubNub();
  const currentUserId = Number(localStorage.getItem("loggedIn_userId"))
  const userId = trackId?.attributes?.account_id|| trackId?.attributes?.artist_id||trackId?.attributes?.id
  const ChannelName = getChannelName(String(currentUserId), userId);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const scrollableDiv = useRef<HTMLDivElement>(null);



  useEffect(() => {
    if(userId){
      _getMessages();
      _getUserData();
      getUserAvtar();
      readMessage(userId)
    }
    }, [userId])


  useEffect(() => {
    if (pubnub) {
      pubnub.setUUID(currentUserId + "");
      const listener = {
        message: (envelope: any) => {
          setMessages(msgs => [
            ...msgs,
            {
              id: messages.length + 1,
              isRecived: currentUserId != envelope.publisher,
              sender: envelope.message.content.sender as ISender,
              text: envelope.message.content.text + "",
              timetoken: envelope.timetoken
            } as unknown as IMessage
          ]);
          if (currentUserId != envelope.publisher) {
            readMessage(userId)
          }
        },
      };


      pubnub.addListener(listener);
      pubnub.subscribe({ channels: [ChannelName] });


      return () => {
        pubnub.removeListener(listener);
        pubnub.unsubscribeAll();
      };
    }
  }, [pubnub]);
  const token: any = localStorage.getItem("token")


  const convertChatResponseToPubNub = (messages: any[]): IMessage[] => {
    return messages?.map((message) => {
      const {
        id,
        attributes: {
          profile_image: avtar,
          artist_name: name,
          message: text,
          // read_status: isRead,
          message_time: timestamp,
          sender_id: senderId,
          // receiver_id: receiverId,
          receiver
        }
      } = message;


      const sender = {
        id: senderId,
        name,
        avtar
      };


      return {
        id,
        isRecived: receiver,
        sender,
        text,
        timetoken: new Date(timestamp).getTime() * 10000
      };
    }) as any;
    // }
  }






  const _getMessages = async () => {
    // setIsLoading(true)
    axios.get(
      `${baseUrl}/bx_block_messages/messages/user_chat_screen?sender_id=` + userId,
      {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      }
    )
      .then((responseJson) => {
        // setIsLoading(false)
        if (responseJson?.data?.data) {


          setMessages(convertChatResponseToPubNub(responseJson.data.data))
        } else {
          setMessages([])
        }


      })
      .finally(() => {
        setIsLoading(false)
      });
  };


  const getUserAvtar = async () => {
    const profilePic:any = localStorage.getItem("profile_img")
    if (profilePic != "null") {
      setAvtarUrl(profilePic);
    }else{
      setAvtarUrl(defaultProfile);
     }
  };




  const _getUserData = async () => {
    axios.get(
      `${baseUrl}/bx_block_profile/user_profile?id=` + userId,
      {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      }
    )
      .then((responseJson) => {
        if (responseJson.data) {
          setGetUserDetails(responseJson.data.data)
        }
      });
  };








  const onSend = (msgTxt: string) => {
    if (msgTxt == "") {
      return
    }


    const message = {
      content: {
        text: msgTxt.trim(),
        sender: {
          id: currentUserId,
          name: "",
          avtar: avtarUrl,
        }
      },
      type: '',
      id: crypto.getRandomValues(new Uint8Array(16))
      
    };
    sendMessage(userId, msgTxt)
    pubnub.publish({ channel: ChannelName, message }, function (status: any, response: any) {
    });
    setTimeout(()=>{

      _getMessages()
    },1500)
    handleScroll()
    // scrollToBottomParent()

  }



  const handleScroll = () => {
   
    const element = document.getElementById(`messages-bottom-scroll-id`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', inline: "nearest"});
    }
  };


  const handleSearchInput = () => {
    setInput('')
  }
  const renderMessageContent = () => {
    if (isLoading) {
      return (
        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
          <img src={nxzloader} style={{ width: "10%" }} />
        </Row>
      );
    }

    if (userId === currentUserId) {
      return (
        <div style={{ marginTop: "7%" }}>
          <p className='showPrivateMessageList'>You can't message on your own content! Message</p>
        </div>
      );
    }

    if (messages.length > 0) {
      return (
        <>
          <div ref={scrollableDiv} style={{ justifyContent: "flex-start",height: '100%',overflowY: 'auto' }} className="chatgridHeadPrivateDM">
            {messages.map((chatData) => (
               <div id={`messages_id_${chatData.id}`}>
                <MessageCard chatData={chatData} isChartMessage={false} particularUserDataRes={{}}/>
              </div>
            ))}
            <div ref={messagesEndRef}></div>
          </div>
          <div style={{ float: "left", clear: "both" }} ref={messagesEndRef} />
        </>
      );
    }

    return <ViewNewMessengerProfile userData={getUserDetails} isExpand={true} />;
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={4}
        spacing={2}
        className='track_charts' style={{display: 'flex', height: '100%', flexGrow: 1, overflow: 'hidden', position: 'relative'}}>
        <Paper className={`expand_comment_container messages_margin_top`} style={{overflowY: 'auto'}}>
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            className='comment_title'>
            <Grid item xs={8}>
              <h1 className='comment_font'>Messages</h1>
            </Grid>
            <Grid
              container
              item
              xs={4}
              justifyContent='flex-end'
              style={{ paddingRight: '20px',cursor:"pointer" }}
              onClick={()=>history.push("/comments")}
              >
              <h1 className='sellAll_font'>See All</h1>
            </Grid>
          </Grid>
          <div className="expand_message_body containerChatPrivateDM" style={{justifyContent:messages.length > 0 ? "flex-start":"center"}}>
          {isLoading?
           <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
           <img src={nxzloader} style={{ width: "2%" }} />
         </Row>
         :
          
          renderMessageContent()
        }
            
          </div>
          {userId != currentUserId &&
          <div className="chatsearchBarDM" style={{position: 'absolute', bottom: '6px', width: '95%', background: 'black'}}>
            <div id="scroll-msg-more-new">
              <div style={{ border: "2px solid #ffffff", borderRadius: "40px", display: "flex", width: "100%", padding: "8px" }}>
                <Grid item xs={2}>
                  <Avatar
                    alt='avatar'
                    src={avtarUrl}
                    style={{ width: '42px', height: '42px' }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <input
                    type='text'
                    placeholder='Send private message'
                    className='comment_send_text'


                    onChange={(e) => {
                      setInput(e.target.value);
                    }}
                    value={input}
                    onKeyPress={(event: any) => {
                      if (event.key === 'Enter') {
                        onSend(input)
                        setInput('')
                      }
                    }}
                    style={{ lineBreak: "anywhere" }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={2}
                  justifyContent='flex-end'
                  onClick={() => {


                    onSend(input); handleSearchInput()
                  }}




                  alignItems='center'>
                  <span className='comment_send_btn'
                    style={{ cursor: input != "" ? "pointer" : "not-allowed" }}
                  >
                    Send
                  </span>
                </Grid>
              </div>
            </div>
          </div>
}
        </Paper>


      </Grid>
    </>
  );
};
// Customizable Area End