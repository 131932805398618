// Customizable Area Start
import React from 'react';
import { Grid } from '@material-ui/core';
import {ArrowUp, PlayIcon, expandMore, messageIcon, playlistProfile, repeatIcon} from 'blocks/dashboard/src/assets'
export const LabelTopAudioPlaylistMobile = (props: any) =>{
    const {isMusicButton} = props;
    return(
        <>
        <Grid style={{display: 'flex', flexDirection: 'column', backgroundColor: '#141414', padding: '5px 10px', borderRadius: '10px'}}>
        <Grid style={{display: 'flex', justifyContent: 'space-between', padding: '15px 0px'}}>
            <Grid style={{display: 'flex'}} >
                <Grid style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{color: 'white', fontSize: '16px'}}>1</div>
                <img src={ArrowUp}/>
                </Grid>
                <Grid style={{margin: '0px 10px', borderRadius: '10px'}}>
                    <img style={{height: '48px', width: isMusicButton ? '80px' : '48px', borderRadius: '10px'}} src={playlistProfile}/>
                </Grid>
                <Grid style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{color: 'white', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold'}}>Rolex</div>
                    <div style={{color: 'white', fontFamily: 'Karla', fontSize: '14px', fontWeight: 'bold'}}>47 Tracks</div>
                </Grid>
            </Grid>
            <Grid style={{display: 'flex'}}>
                <Grid>
                <img style={{height: '28px', width: '28px', margin: '0px 15px'}} src = {expandMore } />
                </Grid>
            </Grid>
        </Grid>
        <Grid style={{display: 'flex', backgroundColor: 'black', padding: "2px", borderRadius: '10px', marginBottom: '10px', justifyContent: 'space-evenly'}}>
                <Grid className="" style={{ color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={PlayIcon} height="24px" width="24px" />
                    <span className="play-songs-times">{'22k'}</span>
                </Grid>
                <Grid  className="" style={{ color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={repeatIcon} height="24px" width="24px" />
                    <span className="play-songs-times">{ '22k'}</span>

                </Grid>
                <Grid  className="" style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={messageIcon} height="24px" width="24px" />
                    <span className="play-songs-times" style={{ marginLeft: "4px" }}>{'22k'}</span>
                </Grid>

                <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <span style={{color: 'blue'}}>24h%</span>
                    <span>-0%</span>
                    </Grid>
                <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                  <span style={{color: 'blue'}}>7d%</span>
                    <span>+0%</span>
                </Grid>
            </Grid>
            </Grid>
        </>
    )
}

// Customizable Area End