// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { deleteChat } from "../ChatUtils";
export const configJSON = require("../config");

const tokenStr: any = localStorage.getItem("token");

export interface Props {
    navigation: any;
    id: string;
    otherUserID: any;
    particularUserDataRes: any;
    updateParticulartUserData: any;
    selectedParticularList: any;
    setOtherUserId:any;
    setSelectedDMIndex?:any;
    setParticularUser?:any;
    newUserAddedState?:any;
}
interface S {
    showDropdown: boolean;
    isOpen: boolean;
    anchorEl: any
    is_following: boolean;
    readStatus:any;
}
interface SS {
    id: any;
}

export default class DirectMessagesController extends BlockComponent<
    Props,
    S,
    SS
> {

    followUser: string = ""
    unFollowUser: string = ""
    markNew: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            showDropdown: true,
            isOpen: false,
            anchorEl: null,
            is_following: this.props.particularUserDataRes?.attributes?.is_following,
            readStatus: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    handleDropDownFalse = () => {
        this.setState({
            showDropdown: false
        })
    }

    handleDeleteModalOpen=()=>{
        this.setState({
            isOpen: true
        })
    }
    handleDMdelete = () => {
        const attributes = this.props?.selectedParticularList[0]?.attributes
        const currentUserID = localStorage.getItem("loggedIn_userId")
        if (currentUserID == attributes?.receiver_id) {
            deleteChat(attributes.sender_id)
            this.props.setSelectedDMIndex(null)
        } else {
            deleteChat(attributes.receiver_id)
            this.props.setSelectedDMIndex(null)

        }
        this.props.setOtherUserId("")
        this.props.setParticularUser({})
    }

    handlePlay = (e: any) => {
        // props.playMedia({}, props.mediaType)
    }

    handleDropDownTrue = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
        this.setState({
            showDropdown: true,
            anchorEl: event?.currentTarget,
        })
    }

    getReadStatus = (data:any) => {
        const currentUserID = localStorage.getItem("loggedIn_userId")
        const dataReadStatus = data[0]?.attributes?.read_status||false
        const dataSenderId = data[0]?.attributes?.sender_id||null
        const dataUnReadStatus = data[0]?.attributes?.unread_status||false

        const status1 = (!dataReadStatus && (dataSenderId != currentUserID)) || dataUnReadStatus
        return status1
      }

    handleDMClose = () => {
        this.setState({
            isOpen: false
        })
    }

    async componentDidMount() {
        super.componentDidMount();

        if(Object.keys(this.props.selectedParticularList).length>0){
        const readstatus =await this.getReadStatus(this.props.selectedParticularList )
        this.setState({ readStatus:readstatus });
        }
    }

    async componentDidUpdate(prevProps:any) {
        if (this.props.selectedParticularList !== prevProps.selectedParticularList) {
            const readstatus =await this.getReadStatus(this.props.selectedParticularList)
            this.setState({ readStatus:readstatus });
          // alternative: store keyValue as state to trigger re-render
        }
      }
 
    doFollow = () => {
        const header = { token: localStorage.getItem('token') }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.followUser = requestMessage.messageId
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/follow_user?user_id=${this.props.otherUserID}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    doUnFollow = () => {
        const header = { token: localStorage.getItem('token') }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.unFollowUser = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/unfollow_user?user_id=${this.props.otherUserID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    isMarkNew = () => {
        const header = { token: localStorage.getItem('token') }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.markNew = requestMessage.messageId
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_messages/messages/unread_message?receiver_id=${this.props.otherUserID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PUT'
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallID = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallID == this.followUser) {
                this.makeNewResponse(responseJson, errorReponse);
                this.props.updateParticulartUserData()
            }

            if (apiRequestCallID == this.unFollowUser) {
                this.makeNewResponse(responseJson, errorReponse);
                this.props.updateParticulartUserData()

            }

            if (apiRequestCallID == this.markNew) {
                this.makeNewResponse(responseJson, errorReponse);
            }
        }
    }
    makeNewResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson) {
            console.log(responseJson.message)
        }
        else {
            console.log(errorReponse)
        }
    }

}
// Customizable Area End