// Customizable Area Start
import React from "react";
import OfferSentController, {
    Props,
} from "./OfferSentController.web";
import "./OfferSent.web.css";
import AsideLeft from "../../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "../../../../../components/src/CustomChart/PopUps/HomeHeader.web";
import Loader from "../Loader.web";
import { Layout } from "antd";
import { Grid, Hidden, Box, Typography, Avatar } from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import RightPanel from '../Common/RightPanel.web';
import { OfferSentRightBg } from "../assets";
import { userLogo } from "../../assets";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class OfferSent extends OfferSentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading } = this.state;
        return (
            <>
                <Layout className="MainLayoutContainer">
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        className="MainContent"
                    >
                        <HomeHeader />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="OSMainPanel">
                                <Grid container>
                                    <div className='MainContentDiv'>
                                        <Grid item xs={12} sm={12} md={6} lg={7} xl={8} className='ColumnCenter'>
                                            <div className='ColumnCenter AICenter'
                                            >

                                                <Hidden only={['lg', 'xl']}>
                                                    <div className="ColumnCenter AICenter MT32">
                                                        <div className='CommonText InfoTextSm'>We’ll Notify You If Your</div>
                                                        <p className='CommonText InfoTextSm'>Offer Is Accepted</p>
                                                    </div>
                                                </Hidden>
                                                {/* Offer Sent START*/}
                                                <Box
                                                    className="OSBox"
                                                >
                                                    <Box>
                                                        <Avatar src={userLogo} className="Avatar"/>
                                                    </Box>
                                                    <Box className="OSOBox">
                                                        <Typography className="OSOText">OFFER</Typography>
                                                    </Box>
                                                    <Box className="OSSBox">
                                                        <Typography className="OSSText">SENT</Typography>
                                                    </Box>
                                                    
                                                </Box>
                                                {/* Offer Sent END */}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                                            <RightPanel
                                                line1={`We’ll Notify You If Your`}
                                                line2={`Offer Is Accepted`}
                                                nextButtonName="My Deals"
                                                backButtonName="Home"
                                                backgroundImage={OfferSentRightBg}
                                                onNextClick={this.gotoMyDeals}
                                                onBackClick={this.gotoHome}
                                            />
                                        </Grid>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </Content >
                </Layout >
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default OfferSent;
// Customizable Area End