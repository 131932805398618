// Customizable Area Start
import React from "react"

import axios from "axios";
import { createBrowserHistory } from "history";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
export const configJSON = require("../../../../framework/src/config");

const history = createBrowserHistory()
export interface Props {
  props?: any;
  navigation?: any;


}
interface S {
    token: any;
    creativeById: any;
    gridSizes: any;
    beatList: any;
    labeltabName: any;
    get_All_Activity_data: any;
    get_notification: any;
    labelValue: any;
    filterValue: any;
    musicButton: any;
    playlistVideo: any;
    getCreativeTabsLoader: any;
    apiToken: any;
    activityList: any;
    getTop100: any
}
interface SS {
  id: any;
}

export default class LabelController extends BlockComponent<Props, S, SS> {
    getactivityListId: string = "";
    constructor(props: Props) {
        super(props);
        
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage)
        ];
    
        this.state = {
            token: '',
            creativeById: {
                attributes: {
                    cover_image: "https://minio.b66173.dev.eastus.az.svc.builder.cafe/sbucket/chddrz5mutcsx14wdcm9kjxwsvad",
                    creative_type: [{name: ''}],
                    user_name: "soranga",
                    city: "ahmedabad",
                    country: "Alland Islands",
                    creative_type_name: "Producer"
                },
                id: "2184",
                type: "account"
            },
            gridSizes: {
              xsSize: 12,
              mdSize: 8,
              lgSize: 9,
              smSize: 6,
              xlSize: 4,
              xxlSize: 3,
            },
            beatList: [],
            labeltabName: [
              {
                tabName: "Creatives",
                value: 0
              },
              {
                tabName: "Activity",
                value: 1
              },
              {
                tabName: "Playlists",
                value: 2
              },
      
            ],
            get_All_Activity_data: [],
            get_notification: {
              follow_notification_count: 29,
              follow_notifications: {data: {
                "id": "620",
                "type": "notification",
                "attributes": {
                    "user_name": "finaltest1",
                    "avatar": "https://minio.b66173.dev.eastus.az.svc.builder.cafe/sbucket/urijmtn0alrl8if1ltucij446e7y"
                }
            }},
              repost_notification_count: 7,
              repost_notifications: {data: {
                "id": "618",
                "type": "notification",
                "attributes": {
                    "user_name": "nehatest",
                    "avatar": "https://minio.b66173.dev.eastus.az.svc.builder.cafe/sbucket/iytcjdntvxvo6zw03w4z45mywrs6"
                }
            }}
            },
            labelValue: 0,
            filterValue: "Filter",
            musicButton: false,
            playlistVideo: [],
            getCreativeTabsLoader: false,
            apiToken: localStorage.getItem("token"),
            activityList: [],
            getTop100: []
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
      super.componentDidMount();
      this.getAllActivity();
    }

    checkListenerCurrentTab = (event: React.ChangeEvent<{}>, newValue: number) => {
      this.setState({ labelValue: newValue });
    }

    handleFilter = async (value: any) => {
    }

    filterActivityData = (data: any) => {
  
      if (data.attributes?.reposts?.data) {
        this.setState({ get_All_Activity_data: [...data.attributes?.reposts?.data] })
      }
  
      if (data.attributes?.followings?.data) {
        this.setState({ get_All_Activity_data: [...this.state.get_All_Activity_data, ...data.attributes?.followings?.data] })
      }
      if (data.attributes?.uploads?.data) {
        let tempArray = Object.assign([], data.attributes?.uploads?.data);
        const key = 'id';
        const arrayUniqueByKey = [...new Map(tempArray.map((item: any) =>
          [item[key], item])).values()];
        this.setState({ get_All_Activity_data: [...this.state.get_All_Activity_data, ...arrayUniqueByKey] })
      }
      //this.setState({ tabLoading: false })
  
  
      this.state.get_All_Activity_data.sort((a: any, b: any) => new Date(b?.attributes?.created_at).getTime() - new Date(a?.attributes?.created_at).getTime());
      this.setState({ getCreativeTabsLoader: false })
  
    }

   

    getAllActivityData = () =>{
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': this.state.apiToken
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getactivityListId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.baseUrl + `/bx_block_activityfeed/activity_tab?user_id=2184`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     }



    getAllActivity = async () => {
      this.setState({ getCreativeTabsLoader: true })
      axios.get(`${configJSON.baseUrl}/bx_block_activityfeed/activity_tab?user_id=2184`, {
        headers: {
          "Content-Type": configJSON.dashboarContentType,
          'token': this.state.apiToken
        }
      })
        .then((response: any) => {
          response?.data?.attributes ?
  
            this.setState({ activityList: response?.data?.attributes })
            :
            this.setState({
              get_All_Activity_data: [],
              activityList: [],
              //beatErrorResponse: response.data.message,
              //tabLoading: false
            })
          response?.data?.attributes && this.filterActivityData(response?.data)
  
        }).catch((err: any) => {
          this.setState({
            get_All_Activity_data: [],
            activityList: [],
            //beatErrorResponse: err.message,
            //getCreativeTabsLoader: false
          })
  
  
        }).finally(() => {
          this.setState({ getCreativeTabsLoader: false })
  
        })
    }

    getActivityListApiSuccessCall = (response: any) =>{
      if(response?.data?.attributes){
        this.setState({ activityList: response?.data?.attributes })
      }
      else{
        this.setState({
          get_All_Activity_data: [],
          activityList: [],
          //beatErrorResponse: response.data.message,
          //tabLoading: false
        })
      }
       response?.data?.attributes && this.filterActivityData(response?.data)
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
          return;
        }
    
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
        if (apiRequestCallId === this.getactivityListId) {
          this.getActivityListApiSuccessCall(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        } 
        
    
      }
}
// Customizable Area End