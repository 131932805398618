// Customizable Area Start
import React, { useEffect, useState, useContext } from "react";
import {
  Col,
  Row,
  Form,
  Select,
} from "antd";
import {
  playButton,
  nxzloader,
  pauseA
} from "../../../../dashboard/src/assets";
import "./searchPlaylist.css";
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import '../../../../../components/src/CustomChart/Carousel/index.css'
import "../../../../user-profile-basic/src/Common/TopSongs.web.css";
import { Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import RepostPopup from "components/src/CustomChart/PopUps/RepostPopup.web";
import { AnyNsRecord } from "dns";
import axios from "axios";
import { openNotification } from "../../../../../components/src/Notification.web";
import PlaylistSongsList from "./PlaylistSongsList.web";
import PlaylistNameComponent from "./PlaylistNameComponent.web";
const urlConfig = require("./../../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

import { AudioContextApi } from "../../../../dashboard/src/AudioContext/AudioContext.web";
import AddToQueuePopup from "../../../../dashboard/src/CustomComponent/AddToQueue/AddToQueuePopup.web";
import SharePlaylistPopup from "../../../../dashboard/src/CustomComponent/SharePlaylistPopup/SharePlaylistPopup.web";
import PlayListCreateAndAddplayListModel from "../../../../Playlist/src/PlayListCard/PlayListCreateAndAddplayListModel.web";


const SearchPlaylistWeb = (props: any) => {
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openAlertLogin, setopenAlertLogin] = useState(false)
  const [dialogTextAlertLogin, setDialogTextAlertLogin] = useState<any>(false)
  const { allPlayLists, loading } = props
  console.log(allPlayLists, 'from playlists');
  const history = useHistory();

  //repost
  const [openRepostPopup, setOpenRepostPopup] = useState<boolean>(false)
  const [openPopUp, setOpenPopUp] = useState<boolean>(false)
  const [textFieldValue, setTextFieldValue] = useState<any>('')
  const [repostId, setRepostId] = useState<any>('')

  // add to playlist
  const [openPlaylistModal, setOpenPlaylistModal] = useState<any>(false)
  const [loadingPlayist, setLoadingPlaylist] = useState(false)
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [imageUrl, setimageUrl] = useState<any>('')
  const [playlistId, setPlaylistId] = useState<any>("")
  const [userplaylist, setUserplaylist] = useState<any>("")
  const [emptyPlaylist, setEmptyPlaylist] = useState(false)
  const [playlistName, setplaylistName] = useState<any>('')
  const [fileName, setfileName] = useState<any>('')
  const [addPlaylistId, setAddPlaylistId] = useState<any>("")
  const [open, setOpen] = useState(false);
  const [musicButton, setMusicButton] = useState<boolean>(false)




  const { Option } = Select;
  // add to playlist

  const [showModalAddNewPlaylist, setshowModalAddNewPlaylist] = useState<boolean>(false)
  const [dialogText] = useState<any>("")
  const [selectedPlaylistId, setSelectedPlaylistId] = useState("")
  const [sharePlaylistId, setSharePlaylistId] = useState("")

  const [selectedPlaylistSong, setSelectedPlaylistSong] = useState<any>("")
  const [followingUsers, setFollowingUsers] = useState<any>([])


  const { name, songIndex } = props
  const { handlePlayList, handleAudioVideo, setHandleAudioVideo, setPlaylistForQueue, isPlaylist } = useContext(AudioContextApi)

  let selectedSong = localStorage.getItem("selectedSong")
  console.log(selectedSong, 'selected from playlist');

  const closeModal = () => {
    setshowModalAddNewPlaylist(false)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const token: any = localStorage.getItem("token")
  const userType = localStorage.getItem('user_type')
  const subscriptionPlan = localStorage.getItem("subscription_plans")
  const onShare = (id: any) => {
    setSharePlaylistId(id)
    const token: any = localStorage.getItem("token")
    if (token) {
      if (userType == "listener" && subscriptionPlan == "NXZ") {
        setopenAlertLogin(true)
        setDialogTextAlertLogin("3")
      } else {
        setOpenPopup(true)
      }
    } else {
      setopenAlertLogin(true)
      setDialogTextAlertLogin("2")
    }

  }

  const handleCloseAlertLogin = () => {
    setopenAlertLogin(false);
  };



  const handleChartTrackRepost = async (caption: AnyNsRecord) => {
    console.log(repostId, 'repost id')
    await axios
      .post(
        `${baseUrl}/bx_block_playlist/repost_playlist?id=${repostId.id}&caption=${caption}`,
        null,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            token: token,
          },
        }
      )
      .then((res: any) => {

        openNotification("Successfully Reposted", "Success")
      })
      .catch((err) => {
        openNotification("Some error occurred", "Error")

      })
      .finally(() => {
      });
  };

  const respostSubmit = () => {
    handleChartTrackRepost(textFieldValue)
    handleRepostClose()
  }

  const handleCaptionChange = (e: any) => {
    setTextFieldValue(e.target.value)
  }


  const handleRepostClose = () => {
    setOpenRepostPopup(false)
  }

  //add to playlist start


  const handleClosePlaylistModal = () => {
    form.resetFields();
    form1.resetFields();
    setimageUrl('')
    setOpenPlaylistModal(false)
  }

  console.log(loadingPlayist,"seatchPlaylist")
  const getUserPlaylist = async () => {
    setLoadingPlaylist(true);
    await axios
      //.get(`${baseUrl}/bx_block_playlist/play_lists`, {
      .get(`${baseUrl}/bx_block_playlist/all_playlists`, {

        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          token: token,
        },
      })
      .then((response: any) => {
        setLoadingPlaylist(false);

        setUserplaylist(response?.data?.data);

      })
      .catch((err: any) => {
        setLoadingPlaylist(false);
        if (err?.response.status === 422) {
          setEmptyPlaylist(true)
        }


      });
  };

  const handleAddToPlaylist = (id: any) => {

    if (token) {
      console.log(id, 'adding playlist Id')
      setAddPlaylistId(id)
      setOpenPlaylistModal(true);
      getUserPlaylist()
    } else {
      setopenAlertLogin(true);
      setDialogTextAlertLogin(token ? "1" : "2")
    }
  }

 

  //add to playlist end

  const playlistSelected = (playlist: any) => {

    if (handleAudioVideo == 1) {
      setSelectedPlaylistId(playlist?.id)
      console.log(playlist?.attributes?.playlist_items?.data, 'playlist play selected');
      setSelectedPlaylistSong(playlist?.attributes?.playlist_items?.data[0])
      handlePlayList(playlist?.attributes?.playlist_items?.data[0], songIndex, playlist?.attributes?.playlist_items?.data, name)
      setPlaylistForQueue(playlist)
    }
  }

 

const playlistSongSelected = (playlist: any,song: any) =>{
  setSelectedPlaylistId(playlist?.id)
  setSelectedPlaylistSong(song);
  handlePlayList(song, songIndex, playlist?.attributes?.playlist_items?.data, name) 
  setPlaylistForQueue(playlist)
  console.log(song, 'selected playlist song')
}
const redirectProfile = (id: any) => {
    if (userType == "creative") {
      history.push(`/creatives/beats?creative_id=${id}`)
    } else if (userType == "listener") {
      history.push(`/listeners/activity?listener_id=${id}`)
    } else {
      history.push(`/originals/beats?originals_id=${id}`)
    }

  }

  const followUser = async (id: any) => {
    await axios.get(`${baseUrl}/account_block/follow_user?user_id=${id}`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        token: token
      }
    })
      .then((response: any) => {
        openNotification('Following successful', 'Success')
        setFollowingUsers(followingUsers.concat(id))
      }).catch((err: any) => {
        console.log(err.response, "eeeeeeeeeeeeeeeee");

      });
  }

  const unFollowUser = async (id: any) => {
    await axios.get(`${baseUrl}/account_block/unfollow_user?user_id=${id}`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        token: token
      }
    })
      .then((response: any) => {
        openNotification('unfollow successfull', 'Success')
        setFollowingUsers(followingUsers.filter((filterId: any) => filterId !== id))
      }).catch((err: any) => {
        console.log(err.response, "eeeeeeeeeeeeeeeee");

      });
  }

  useEffect(() => {
    allPlayLists.map((playlist: any) => {
      if (playlist?.attributes?.follow_status) {
        //setFollowingUsers(followingUsers.concat(playlist?.attributes?.artist_id)) 
        followingUsers.push(playlist?.attributes?.artist_id)
      }
    })
  }, [allPlayLists])

  return (
    <>
    {loading ?
       <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
        <img src={nxzloader} style={{ width: "5%" }} />
       </Row>

  : <div className={"playlist-scroll-wih-audio"} style={{ paddingRight: '15px' }}>{allPlayLists.length !== 0 ? allPlayLists.map((play: any, index: number) => {
    return play?.attributes.playlist_items.data.length !== 0 && <Grid container spacing={1} style={{ marginTop: '10px', backgroundColor: "#0a0a0a", border: "1.5px solid rgb(50, 49, 49)", padding: '10px', borderRadius: '20px', marginLeft: '2px', marginBottom: '2px' }}>
      <Grid item xl={4} lg={5} sm={12} md={5} >
        <Grid container >
          {/* <div style={{ display: 'flex', width: '100%',flexWrap:'wrap' }}> */}

          {/* <Grid item xs={12} sm={3} md={3}>           */}
          <div className='cover-img mobile-playlist-cover medium-screen-width-for-image' test-id= "playlist_selected_test_id" style={{ position: "relative" }} onClick={() => playlistSelected(play)}>


            <img className="playlist-image" style={{
              width: "100%",
              objectFit:"cover",
              borderRadius: '15px',

            }}
              src={play?.attributes?.cover_image} alt=""

            />
            <img
               id="setting_handle_audio_video_id"
              //id={props.cardId}
              style={{
                marginRight: "5px",
                position: "absolute",
                top: "35%",
                left: "35%",
                height: "35px",
                cursor: "pointer"

              }}
              // onClick={(e) => mediaClick(e, props.cardId)}
              onClick={() => { setHandleAudioVideo(handleAudioVideo == 1 ? 2 : 1) }}
              src={play?.id && play?.id === selectedPlaylistId && handleAudioVideo === 2 ? pauseA : playButton} />



          </div>
          {/* </Grid> */}

          {/* <Grid item xs={12} sm={5} md={9} > */}
          <div className="medium-screen-width-for-names">
            <div className="justify_space ml_1">
              <div className="width_100">

                <div>
                  <Typography variant="h2" id="redirecting_to_another_page_from_name_id" style={{ color: "#3959f9", marginBottom: "5px", fontSize: 'large', fontWeight: 'bolder' }} onClick={() => history.push(`/home/playlist/public-view/${play?.id}`)}>
                    {/* <Box sx={{width:{lg:100}}}> */}
                    {play?.attributes.name}!!!
                    {/* </Box> */}
                  </Typography>
                  <Typography variant="body2" style={{ color: "#8f9092" }}>{play?.attributes.playlist_items.data.length} Tracks</Typography>
                  <PlaylistNameComponent play={play} redirectProfile={redirectProfile} followingUsers={followingUsers} unFollowUser={unFollowUser} followUser={followUser} setOpenRepostPopup={setOpenRepostPopup} openRepostPopup={openRepostPopup} setOpenPopUp={setOpenPopUp} setRepostId={setRepostId} onShare={onShare} handleAddToPlaylist={handleAddToPlaylist} />

                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid className="main-scroll" item xl={8} lg={7} sm={12} md={7} style={{ maxHeight: '255px', overflow: 'auto', overflowX: 'hidden', backgroundColor: 'rgb(16 15 15)', width: '100%', borderRadius: '20px' }}>
        <PlaylistSongsList all_playlist_songs={play?.attributes.playlist_items.data} play={play} selectedPlaylistId={selectedPlaylistId} selectedPlaylistSong={selectedPlaylistSong} handleAudioVideo={handleAudioVideo} setHandleAudioVideo={setHandleAudioVideo} playlistSongSelected={playlistSongSelected} />
      </Grid>
    </Grid>
    
  }) :
    (<div style={{ textAlign: 'center', color: 'grey', fontSize: '20px', fontWeight: 'bold', marginTop: '200px' }}>NO PLAYLIST FOUND!</div>)}</div>

}
{
  showModalAddNewPlaylist && (
    <PlayListCreateAndAddplayListModel closeModal={closeModal} showModalAddNewPlaylist={showModalAddNewPlaylist} addTrackToPlaylist={'addTrackToPlaylist'} />
  )
}

{open && <LoginAlert open={open} handleClose={handleClose} dialogText={dialogText} />}

{openAlertLogin && <LoginAlert open={openAlertLogin} handleClose={handleCloseAlertLogin} dialogText={dialogTextAlertLogin} />}

{/* <ScanQrDialog
  openPopup={openPopup}
  handleOpenPopup={() => setOpenPopup(false)}

  shareItem="Playlist"
  TrackId={sharePlaylistId}
/> */}

<SharePlaylistPopup
        id= "Playlist"
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        playlist = {sharePlaylistId}
        isPlaylistRepost = {true}
      />
{openRepostPopup &&
  <>
    <RepostPopup open={openPopUp}
      onClose={handleRepostClose}
      profileImage={repostId?.attributes?.cover_image || repostId?.attributes?.art_work}
      artistName={repostId?.attributes?.artist_name}
      songName={repostId?.attributes?.name}
      repost_count={respostSubmit}
      _handleTextFieldChange={handleCaptionChange}
    />
  </>
}

{
    openPlaylistModal &&
    <AddToQueuePopup id="search" open={openPlaylistModal} handleClose={handleClosePlaylistModal} playlistType="audio" trackData={addPlaylistId}/>
}

</>
  );
};

export default SearchPlaylistWeb;
// Customizable Area End
