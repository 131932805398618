// Customizable Area Start
import React from 'react';
import {loopIc, playNow,defaultProfile } from 'blocks/dashboard/src/assets';
import 'blocks/user-profile-basic/src/Common/CardItem.web.css'

const VideoCard = (props: any) => {
    return (
        <div className="video-card-center">
            <div className="music-Box video-card">
                <img src={props.img ? props.img : defaultProfile} alt=""
                    onClick={props.handlePlayList} id="imgId"
                    style={{ cursor: "pointer" }} />

                <div className="song-info" style={{ marginLeft: "2px" }}>
                <h2>{props.songName.substring(0, 14) || "Unknown"}</h2>
                    <p>{props.author ? props.author : "unknown"}</p>
                </div>
                <div style={{ display: "flex" }}
                    className='text_blue'
                // className="song-icon"
                >
                    <div className='mr_05 mb_0' style={{ display: "flex", marginLeft: "-5px" }}>
                        <img src={playNow} style={{ height: "10px", width: "25px", cursor: "pointer", }} alt=""
                            onClick={props.handlePlayList}

                        />
                        <p className=' mb_0 songs-plays' >{props?.playCount || "0"}</p></div>
                        {props.type !="playlist" &&
                        
                    <div className='mr_05 mb_0' style={{ display: "flex" }}>
                        <img src={loopIc} className="mr_05" style={{ height: "14px", width: "14px", marginTop: "-2px", marginLeft: "7px" }} alt="" />
                        <p className=' mb_0 songs-plays' style={{ marginTop: "-5px" }}>{props?.repost_count ? props?.repost_count : "0"}</p>
                        </div>
                        }
                        </div>
            </div>
        </div>
    );
};

export default VideoCard;
// Customizable Area End
