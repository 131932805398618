// Customizable Area Start
import React,{useEffect, useState} from 'react'
import { Grid, Button, TextField } from "@material-ui/core";
import '../../../user-profile-basic/src/Creatives/AddTrack.web.css'
import axios from 'axios';
import { Form as FormAntd } from "antd";


import './index.web.css'
import { TrackDetailsCover} from '../assets';
import { makeStyles } from '@material-ui/core/styles';
import { Formik,FormikErrors} from "formik";
import { openNotification } from "../../../../components/src/Notification.web";
import ReleaseDatePopup from './ReleaseDatePopup.web';
import ReleaseTimePopup from './ReleaseTimePopup.web';

const urlConfig = require("./../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;


const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        borderRadius: '10px',
        border: '2px solid'
        
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
      },
      "& .MuiOutlinedInput-input": {
        color: "white"
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiInputLabel-outlined": {
        color: "white",
        fontSize: '16px',
        fontWeight: 'bold'
      },
      "&:hover .MuiInputLabel-outlined": {
        color: "white"
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "white"
      }
    }
  });

function TrackDetails({setActiveStep, setBackStep}: any) {

  const [uploadType, setUploadType] = useState<any>([]);
  const tag =["CHH","Christian","Classical","Country","EDM","Jazz","Latin","Pop","Rap","Rnb","Rock","Trap","Soul"]

  const [privateActive, setPrivateActive] = useState(false);
  const [publicActive, setPublicActive] = useState(false);
  const [openDatePopUp, setOpenDatePopUp] = useState(false);
  const [openTimePopUp, setOpenTimePopUp] = useState(false);
  const [fullDate, setFullDate] = useState('');


  const token: any = localStorage.getItem("token");

    const classes = useStyles();
    
    const [form] = FormAntd.useForm();
    const onFinish = (values: any) =>{
      console.log(values, 'values of form')
    }

    const getUploadType = async (token: any) => {
      await axios
        .get(`${baseUrl}/bx_block_categories/upload_types?`, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            
            token: token,
          },
        })
        .then((response: any) => {
          setUploadType(response.data.data);
        })
        .catch((err: any) => {
  
          openNotification(err.response.data.errors[0].token, "Error");
        }).finally(() => {
  
        })
    };


  const isFullDate = (date: any) => {
    if (date) {
      setOpenDatePopUp(false);
      setFullDate(date);


      console.log(date, "got full date?")
    }
  }

    const handleDatePopUp = () => {
      console.log('on close click');
      setOpenDatePopUp(false);
    }
  
    const handleDate = () => {
      setOpenDatePopUp(true);
    }

    const handleTime = () => {
      if (fullDate) {
        setOpenTimePopUp(true);
      } else {
        openNotification("Please select date first", "Error")
      }
    }

    const handleTimePopUp = () => {
      console.log('on close click');
      setOpenTimePopUp(false);
    }
  
  const isFullTime = (time: any) => {
    if (time) {
      setOpenTimePopUp(false);


      console.log(time, "got full time?")
    }
  }


    const setPrivate = (e: any, setFieldValue: any) => {
       setFieldValue('uploadStatus', e.target.value)
       setPrivateActive(true);
       setPublicActive(false);
    }

    const setPublic = (e: any, setFieldValue: any) => {
      setFieldValue('uploadStatus', e.target.value)
      setPublicActive(true);
      setPrivateActive(false);
   }

    function arrayRemove(arr: any, value: any) {

      return arr.filter(function (ele: any) {
        return ele != value;
      });
    }


    useEffect(() =>{
      
      getUploadType(token);
    }, [])

    

  return (


<>

<Grid container style={{overflowX: 'hidden',overflowY: 'auto'}}>
          
    <div className='main-div'>
      {/* <Grid item xs={12} md={12} lg={7} > */}
        <Formik
          
          // onSubmit={(values: any) => console.log(values, "values getting")}
          //initialValues = {INITIAL_VALUES}
          initialValues={{
            
            
            uploadType: '',
            tags: [],
            // playlists: [],
          // play_list_id: '',
            title: '',
            genre: [],
            uploadStatus: '',
            releaseDate: '',
            releaseTime: ''
            // userName: currentUserName
          }}

          validate={values => {
            let errors: FormikErrors<any> = {};
            if (!values.title) {
              errors.title = 'Title is Required';
            } else if(!values.uploadType){
              errors.uploadType = 'Upload Type is Required';
            }
            else if(values.tags.length == 0){
              errors.tags = 'Genre is Required';
            }
            else if(!values.releaseDate){
              errors.releaseDate = 'Release Date is Required';
            }
            else if(!values.releaseTime){
              errors.releaseTime = 'Release Date is Required';
            }
            else if(!values.uploadStatus){
              errors.uploadStatus = 'Upload Status is Required';
            }

            return errors;
          }}

          onSubmit={(values: any) => onFinish(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            handleBlur,
            setFieldValue,
            initialValues,
            getFieldProps,
          }) => (
            
              
              <form onSubmit={handleSubmit} style={{width: '100%'}}>
                <div style={{display: 'flex'}} className = "track-details-div">
                  <Grid container item xs={12} md={12} lg = {7} style={{padding: '50px'}}>
                       <Grid className='track-details-margin'>   
                            <Grid item xs={12} style={{justifyContent: 'center',alignItems: 'center', width: '100%', marginBottom: '20px'}}>
                              <TextField
                                name="title"
                                data-testid="title_change_in_trackdetails_form"
                                type='title'
                                fullWidth
                                focused
                                className={classes && classes.root}
                                defaultValue=""
                                variant="outlined"
                                label="Title"
                                onChange={(e: any) => {
                                  setFieldValue('title', e.target.value) }}   
                                />
                                <div style={{color: 'red'}}>{errors?.title && errors.title}</div>
                            </Grid>

                            <Grid item container xs={12} style={{marginBottom: '20px'}}>

                                <Grid  xs={12} style={{color: 'white', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '20px'}}>
                                  Upload Type
                                </Grid>

                                <Grid  xs={12} className="drop_btn"  >
                                  {
                                    uploadType?.length !== 0 &&
                                    uploadType.map((uploadTypes: any, index: any) => {
                                      return (
                                        <button name="upload_type" type="button" className='button-upload-types'
                                          style={{ background: getFieldProps('uploadType').value == uploadTypes.attributes.id ? "#3959F9" : 'rgb(48, 48, 48)' }}
                                          onClick={() => {
                                            
                                            setFieldValue('uploadType', uploadTypes?.attributes?.id)
                                            
                                          }}
                                          key={index} id={uploadTypes?.attributes?.id}>{uploadTypes?.attributes?.name}</button>
                                      )
                                    })}

                                  <div style={{color: 'red'}}>{errors?.uploadType && errors.uploadType}</div>
                                </Grid>
                            
                            </Grid>    

                            <Grid item container xs={12} style={{marginBottom: '20px'}}>

                                <Grid item xs={12} style={{color: 'white', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '20px'}}>
                                  Select Genre
                                </Grid>

                                <Grid item xs={12} className="drop_btn">
                              {
                                tag?.length !== 0 &&
                                tag.map((tags: any, index: any) => {
                                  return (
                                    <button test-id="tags_name_trackdetails" type="button" className='button-upload-types'
                                      style={{ background: getFieldProps('tags').value.includes(tags) ? "#3959F9" : 'rgb(48, 48, 48)' }}
                                      onClick={() => {
                                        let data: any = form.getFieldValue('tags')

                                        if (data == undefined) {
                                          data = [tags]
                                        }
                                        // else if (data.length == 0) {
                                        //   data[0] = tags?.attributes?.id
                                        // }
                                        else {
                                          if (data.includes(tags?.attributes?.id)) {
                                            const filterdArray: any = arrayRemove(data, tags?.attributes?.id)
                                            data = filterdArray
                                          } else {
                                            data.push(tags)
                                          }
                                        }
                                        setFieldValue('tags', data)
                                        form.setFieldsValue({ tags: data })
                                      }}
                                      key={index} id={tags?.attributes?.id}>{tags}</button>
                                  )
                                })}

                                <div style={{color: 'red'}}>{errors?.tags && errors.tags}</div>

                                </Grid>
                            
                            </Grid>    


                          
                          <Grid container item xs={12} sm={12} spacing={1} style={{marginTop: '15px'}}>
                            <Grid item className='Mui-focused' xs={12} sm={6} style={{justifyContent: 'center',alignItems: 'center', width: '100%'}}>
                              <ReleaseDatePopup openDatePopUpFromChild={openDatePopUp} handleDatePopUpFromChild={handleDatePopUp} getFullDateFromChild={isFullDate} setFieldValue={setFieldValue} values={values}>

                              </ReleaseDatePopup>
                              <div className='release-date-margin'>
                              <TextField
                                name="handleDate_trackdetails_test"
                                type='releaseDate'
                                fullWidth
                                className={classes && classes.root}
                                focused
                                //defaultValue="Select Date"
                                placeholder="Select Date"
                                variant="outlined"
                                value={values.releaseDate}
                                label="Release Date"
                                onClick={handleDate}
                                // onChange={(e: any) => {
                                //   setFieldValue('releaseDate', e.target.value) }}   
                                />
                                </div>
                                <div style={{color: 'red'}}>{errors?.releaseDate && errors.releaseDate}</div>
                            </Grid>

                            <Grid item xs={12} sm={6}  className='Mui-focused' style={{justifyContent: 'center',alignItems: 'center', width: '100%'}}>
                             
                            <ReleaseTimePopup openTimePopUpFromChild={openTimePopUp} handleTimePopUpFromChild={handleTimePopUp} getFullTimeFromChild={isFullTime} setFieldValue={setFieldValue} values={values} date={fullDate}>

                            </ReleaseTimePopup>
                             
                              <TextField
                                name="handleTime_trackdetails_test"
                                type='title'
                                fullWidth
                                focused
                                className={classes && classes.root}
                                //defaultValue="Select Time"
                                value={values.releaseTime}
                                placeholder='Select Time'
                                variant="outlined"
                                label="Release Time"
                                onClick={handleTime}
                                // onChange={(e: any) => {
                                //   setFieldValue('releaseTime', e.target.value) }}   
                                 />
                              <div style={{color: 'red'}}>{errors?.releaseTime && errors.releaseTime}</div>

                            </Grid>
                         </Grid>

                         <Grid item container xs={12} style={{marginTop: '20px'}}>
                            <Grid item xs={12} style={{color: 'white', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '16px', marginBottom: '10px'}}>
                                  Upload Status
                            </Grid>

                            {/* style={{backgroundColor: !amActive ? 'rgb(67, 32, 124)' : 'transparent'}} */}

                            <Grid item xs={12} sm={4} direction="row" justifyContent='center' alignItems='center'>
                              <div className='public-private-buttons'>
                                <button test-id="setting_private_trackdetails" type='button' className = "button1_private" style={{backgroundColor: privateActive ? 'blue' : 'transparent'}} value='private'  onClick={(e: any) => {
                                 setPrivate(e,setFieldValue)}}>Private</button>
                                <button test-id="setting_public_trackdetails" type='button' className= "button2_public" style={{backgroundColor: publicActive ? 'blue' : 'transparent'}} value='public'  onClick={(e: any) => {
                                  setPublic(e,setFieldValue)}}>Public</button>
                              </div>
                              <div style={{color: 'red'}}>{errors?.uploadStatus && errors.uploadStatus}</div>
                            </Grid>

                         </Grid>

                         </Grid>
                  </Grid>

                  <Grid container item xs={12} md={12} lg = {5} style={{justifyContent: 'center'}} className="hide-side-image-in-responsive">
                    <Grid item className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)',position: 'relative', overflow: 'hidden' }}>
                      <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414', width: '100%', height: '100%'}} src={TrackDetailsCover} />
                      <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                       <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                      <div className='name-font-weight' style={{color: 'white' ,fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>Drag And Drop</div>
                      <p className='name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}> Or Click To Upload </p>


                      <Button type='submit' test-id = "track_details_form_submit_test_id" className=' buttons-font-weight' style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '10px'}} onClick={setActiveStep}>Next</Button>
                      <Button type='button'  className=' buttons-font-weight' style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px',boxShadow: '-50px 26px 20px 40px rgba(0,0,0,.8)', fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '10px'}}>Save</Button>
                      <Button type='button'  variant="text" className=' buttons-font-weight' style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize'}} onClick={setBackStep}>Back</Button>

                      </div>
                      </div>
                    </Grid>
                    
                  </Grid>

                  <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-screens">
                    <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                      <Button style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px', fontWeight: 'bold', textTransform: 'capitalize'}}  onClick={setActiveStep} >Next</Button>
                      <Button style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform: 'capitalize'}}>Save</Button>
                      <Button variant="text" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform: 'capitalize'}} onClick={setBackStep}>Back</Button>

                    </div>

                  </Grid>

               </div>
             </form>

            )}

        </Formik> 
      {/* </Grid> */}

     

    </div>


 </Grid>
 </>

   )
}

export default TrackDetails
// Customizable Area End

