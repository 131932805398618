// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import type { FormInstance } from 'antd/es/form';
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { openNotification } from "../../../../../components/src/Notification.web";
import { HISTORY } from "../../../../../components/src/common";
export const configJSON = require('../../config');
import { AudioContextApi } from "../../AudioContext/AudioContext.web";

interface S {
  apiToken: any;
  openMessageSendPopup: any;
  openLinkPopup: any;
  copied: any;
  openRepostPopup: any;
  openPopUp: any;
  textFieldValue:any;
  repostingSong:any;
  CopiedForLink:any;
}
interface SS {
}
interface Props{
    id: any;
    open: any;
    handleClose: any;
    playlist ?: any;
    selectedSong ?: any;
    track ?: any;
    isPlaylistRepost ?: any;
    
}

export default class SharePlaylistPopupController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiDeletePlaylistCallId: string = "";
  apiIncreaseCopylinkCountCallId: string = "";
  apiHandlePlayistCallId:string=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      apiToken: localStorage.getItem('token'),
      openMessageSendPopup: false,
      openLinkPopup: false,
      copied: false,
      openRepostPopup: false,
      openPopUp: false,
      textFieldValue:"",
      repostingSong: '',
      CopiedForLink:""
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  formRef = React.createRef<FormInstance>();
   getBase64(video: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(video);
  }


  async componentDidMount() {
    super.componentDidMount();
  }



onSingleCopy = () => {
  const el = document.createElement('input');
  const userId = localStorage.getItem('user_id');
  const origin = window.location.origin;

  let path;
  if (this.props.id === "Playlist") {
      path = `/home/playlist/public-view/${this.props.playlist?.id}`;
  } else if (this.props.id === "Track") {
      path = `/home/expand/song/songId=${this.props.track?.id}`;
  } else if (this.props.id === "Video") {
      path = `/home/charts/videos?id=${this.props.track?.id}`;
  } else if (this.props.id === "Profile") {
      const currentUserId = window.location.href.indexOf("comments") > -1
          ? Number(localStorage.getItem("loggedIn_userId"))
          : userId;
      path = `/creatives/beats?creative_id=${currentUserId}`;
  } else {
      path = window.location.href;
  }

  el.value = origin.concat(path);
  document.body.appendChild(el);
  el.select();
  el.focus();
  navigator.clipboard?.writeText(origin.concat(path));
  document.body.removeChild(el);
  
  this.setState({ copied: true, openLinkPopup: true });
  if ((this.props.id === "Track") || (this.props.id === "Video")) {
  this.increaseCopyLinkCount(this.props.track?.id)
  }
}

onScanQR = () =>{
  let scanTo: any;
  const userId = localStorage.getItem('user_id')
  if(this.props.id == "Playlist"){
    scanTo =  window.location.origin.concat(`/home/playlist/public-view/${this.props.playlist?.id}`)
  }else if(this.props.id=="Track"){
    scanTo =  window.location.origin.concat(`/home/expand/song/songId=${this.props.track?.id}`)
  }else if(this.props.id=="Video"){
    scanTo =  window.location.origin.concat(`/home/charts/videos?id=${this.props.track?.id}`)
  }else if(this.props.id=="Profile"){
    scanTo = window.location.origin.concat(`/creatives/beats?creative_id=${userId}`)
  }
  else{
    scanTo = window.location.href
  }
  return scanTo
}
 
handleRepost=()=>{ 
   if(this.props.id == "Playlist"){
      const {repostSong} = this.context;
      this.setState({repostingSong: repostSong})
      if(this.state.apiToken && repostSong?.id){
          this.setState({openRepostPopup:!this.state.openRepostPopup,
          openPopUp:true })
      }else{
              openNotification("Select Single Tracks To Proceed Your Request", "Error")
              this.props.handleClose()
      }
  }else if(this.props.id == ("Track") || this.props.id == ("Video")){
    this.setState({repostingSong: this.props.track})
    if(this.state.apiToken && this.props.track?.id){
      this.setState({openRepostPopup:!this.state.openRepostPopup,
      openPopUp:true,
      
      })

      }else{
              openNotification("Something Went Wrong", "Error")
              this.props.handleClose()
      }
  }
}

linkopenPopup=()=>{
  this.setState({ CopiedForLink: this.onScanQR() })
  this.setState({ openMessageSendPopup: true })
}

handleOpenMessageSendPopup=()=>{
  this.setState({ openMessageSendPopup: false })
}

handleLinkCopied=()=>{
  this.setState({ openLinkPopup: false, copied: false }) 
}


handleProfileHide = () =>{
  if(this.props.id == "Profile"){
    return "none"
  }else{
    return "flex"
  }
}

handleProfileWidth = () => {
  if(this.props.id == "Profile"){
    return "75px"
  }else{
    return "250px"
  }
}

handleSpace = () =>{
  if(this.props.id == "Profile"){
    return "space-evenly"
  }else{
    return 'space-between'
  }
}



handleRepostClose = () => {
    this.setState({openRepostPopup:false
        })
}

respostSubmit = () => {
    this.handleChartTrackRepost(this.state.textFieldValue)
    this.handleRepostClose()
}
handleCaptionChange = (e: any) => {
    this.setState({textFieldValue:e.target.value})
}

handleDeletePlaylistCallId=(responseJson:any,errorReponse:any)=>{
  if (responseJson != null) {
  if (!responseJson.error) {
    openNotification(responseJson?.data&&responseJson?.data[0].message, 'Success')
    this.props.handleClose()
    HISTORY.push('/home/all-playlist')
  } else {
    openNotification(responseJson?.error, 'Error')
    this.props.handleClose()
   //failed
  }
}else{
  this.parseApiCatchErrorResponse(errorReponse);
  }
}

handlePlaylistCallId=(responseJson:any)=>{
  if (responseJson != null) {
    openNotification("Successfully Reposted", "Success")
   }else{
    openNotification("Select Single Tracks To Proceed Your Request", "Error")
   }
}


handleChartTrackRepost = async (caption: any) => {
    const {repostSong} = this.context;
    let Url: any;
    if(this.props.id == ("Track") || this.props.id == ("Video")){
      Url = `${configJSON.baseUrl}/bx_block_audiomusic/repost?id=${this.props.track?.id}&caption=${caption}`
    }else if(this.props.id == "Playlist"){
      Url = `${configJSON.baseUrl}/bx_block_audiomusic/repost?id=${repostSong.id}&caption=${caption}`
    }
    

      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': this.state.apiToken
      };
      
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiHandlePlayistCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        Url
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        null
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  

  };

  increaseCopyLinkCount = (id: any) => {

  const header = {
    "Content-Type": configJSON.dashboarContentType,
    'token': this.state.apiToken
  };
  
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiIncreaseCopylinkCountCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.baseUrl + `/bx_block_audiomusic/views/update_copy_link_count?id=${id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postAPIMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiDeletePlaylistCallId) {
       
         this.handleDeletePlaylistCallId(responseJson,errorReponse)
        
      }
      else if (apiRequestCallId === this.apiIncreaseCopylinkCountCallId) {
        if (responseJson != null) {
         console.log(responseJson.message)
        }else{
        this.parseApiCatchErrorResponse(errorReponse);
        }
      }else if(apiRequestCallId== this.apiHandlePlayistCallId){
        this.handlePlaylistCallId(responseJson)
      }
    }
  }

}
SharePlaylistPopupController.contextType = AudioContextApi;
// Customizable Area End