// Customizable Area Start
import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core'
import { defaultProfile } from '../../../dashboard/src/assets';

// Customizable Area End


// Customizable Area Start
interface Props {
    selected: boolean;
    unread: boolean;
    media: any;
    type?: string;
    onUserProfileClick: (comment: any) => void;
    onHandleUserId: (comment: any) => void;
    onHandleNotUserId: (comment: any) => void;
    TrackIndex : any;
    setTrackIndex : (index: any) => void;
    selectedTrackIndex: any;
}
// Customizable Area End

// Customizable Area Start
 const NewDMContact = (props: Props) => {
    const handleUserClick = (e: any) => {
        props.onUserProfileClick(props.media)
    }

    return (
        <Box className="MediaDMCPMB"style={{backgroundColor: props.selectedTrackIndex == props.TrackIndex ?  '#141414' : "transparent",border: props.selectedTrackIndex == props.TrackIndex ? 'solid 3px #212121': 'transparent', borderRadius: '10px',cursor:"pointer",padding:"5px 10px",marginTop:"0"}} >
            <Grid container xs className={`CommentSummary ${props.selected && 'Active'}`}
               onClick={() => {
                // props.setTrackIndex(props.TrackIndex)
                            props.onHandleNotUserId(props.media.id)

                }}>

                <Grid item xs={2} className={`LeftImageBox`} style={{ height: "100%", width: "100%" }}>
                    <img src={props.media.attributes.profile_image||defaultProfile} className='DMProfileImage' onClick={handleUserClick} />
                </Grid>
                <Grid container item xs={10} className='SummaryBox' >
                    <Grid item xs={12} >
                        <Typography className='MediaDMText'>
                            {props.media.attributes.artist_name}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} style={{ display: "flex" ,padding:"0 20px 0 0"}}>
                    <Grid item xs={12} className='CommentCountBox new-conversation-border' style={{marginTop:"0"}}>

                          {/* <Typography style={{color:"white"}}>bbbbb</Typography> */}

                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
// Customizable Area End


// Customizable Area Start
export default NewDMContact;
// Customizable Area End