// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
export const configJSON = require("../../config.js");
import { Message } from "../../../../../framework/src/Message";
import millify from "millify";

export interface Props {
  navigation: any;
  id: string;
}

interface Attributes {
  total_view_count: number;
  play_view_count: number;
  total_play_count: number;
  total_repost_count: number;
  total_comment_count: number;
  [key: string]: any; // Allow dynamic keys
}

interface StateListItemAttributes {
  attributes: Attributes;
}

interface CityData {
  city: string | null;
  total_play_count:number;
  total_view_count:number;
}

interface StateData {
  state: string | null;
  total_play_count:number;
  total_view_count:number;
}

interface ListenerData {
  user_name: string | null;
  total_play_count:number;
  total_view_count:number;
}

interface StateListItem {
  type: string;
  attributes: CityData | StateData | ListenerData;
}


interface ResponseJson {
  data?: StateListItem[];
  errors?: any;
}
interface S {
  index: string;
  loading: boolean;
  stateList: any[];
  totalPlayCount:number;
  totalViewCount:number;
}

interface SS {
  id: any;
}


export default class DashboardAnalyticscontroller extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetStatesData: string = "";
  apiGetCitiesData: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      index: "state",
      loading: false,
      stateList: [],
      totalPlayCount:0,
  totalViewCount:0
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getStatesData("top_states");
  }

  formatNumber = (value: number | string) => {
    if (typeof value !== "number") {
      return millify(Number(value)); // or handle it in a way that makes sense for your application
    }

    return millify(value);
  };

  getStatesData = (data: string) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetStatesData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_audiomusic/${data}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  changetabs = (index: string, currentToggle: string) => {
    this.setState({ index: index });
    this.getStatesData(currentToggle);
  };

  calculateProgressValue=(item: StateListItemAttributes)=> {
    const totalPlayAndViewCount = this.state.totalPlayCount + this.state.totalViewCount;

    // Prevent division by zero
    if (totalPlayAndViewCount === 0) {
        return 0;
    }

    return (item.attributes.total_view_count + item.attributes.total_play_count) * 100 / totalPlayAndViewCount;
}

  handleStateList = (responseJson: ResponseJson) => {
    if (responseJson !== undefined && !responseJson.errors) {
      if (responseJson.data) {
        let totalPlayCount = 0;
          let totalViewCount = 0;
      
          responseJson.data.forEach((item: StateListItem) => {
              totalPlayCount += item.attributes.total_play_count;
              totalViewCount += item.attributes.total_view_count;
          });
      
          // Update state
          this.setState({
              totalPlayCount: this.state.totalPlayCount + totalPlayCount,
              totalViewCount: this.state.totalViewCount + totalViewCount
          });
        this.setState({
          stateList: responseJson.data.filter((item: any) => {
            if (item.type === "top_cities") {
              return (
                item.attributes.city !== null && item.attributes.city !== ""
              );
            } else if (item.type === "top_states") {
              return (
                item.attributes.state !== null && item.attributes.state !== ""
              );
            } else if (item.type === "top_thousand_listeners") {
              return (
                item.attributes.user_name !== null &&
                item.attributes.user_name !== ""
              );
            }
          }),
          loading: false,
          
      
        });

      } else {
        this.setState({
          stateList: [],
          loading: false,
        });
      }
    } else {
      this.setState({
        stateList: [],
        loading: false,
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == this.apiGetStatesData) {
      this.handleStateList(responseJson);
    }

    // Customizable Area End
  }
}
// Customizable Area End
