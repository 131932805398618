// Customizable Area Start
import React from "react";
import {
  Text,
  View,
  ScrollView,
  ImageBackground,
  Image,
  StatusBar,
  StyleSheet
} from "react-native";
import scale, { verticalScale } from '../../../components/src/Scale';
import SplashScreenController, { Props } from "./SplashscreenController";


export default class SplashScreen extends SplashScreenController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <ScrollView style={styles.container}>
        <>
        <StatusBar barStyle="light-content" />
        <ImageBackground
          source={require("../assets/bg.png")}
          style={styles.bgimg}
        >
          <View style={styles.logoview}>
            <Image
              source={require("../assets/Brandlogo.png")}
              style={styles.logo}
            />
            <Text style={styles.projectname}>NXZSOUND10</Text>
          </View>
        </ImageBackground>
        </>
      </ScrollView>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,

  },

  bgimg: {
    flex: 1,
    height: scale(900),
  },

  logoview: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: verticalScale(350)
  },

  logo: {
    height: scale(60),
    width: scale(75)
  },

  projectname: {
    fontSize: scale(30),
    color: '#ffffff',
    marginTop: verticalScale(20),
    fontWeight: 'bold'
  },
  mainContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff"
  }
});
// Customizable Area End
