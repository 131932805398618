// Customizable Area Start
import React,{useState} from 'react'
import { Grid, Button } from "@material-ui/core";
import '../../../user-profile-basic/src/Creatives/AddTrack.web.css'


import './index.web.css'
import { SellingPreferenceCover} from '../assets';

function SellingPreferences({setActiveStep, setBackStep}: any) {
  
  const [selectDownload, setSelectDownload] = useState(false);
  const [selectSubscription, setSelectSubscription] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(0);


  const setDownloadPlan = () =>{
    setSelectDownload(true)
    setSelectSubscription(false)
  }

  const setSubscriptionPlan = () =>{
    setSelectDownload(false)
    setSelectSubscription(true)
  }

  const setPlan =(plan: any) =>{
    setSelectedPlan(plan)
  }
  
  return (
    <Grid container style={{overflowX: 'hidden',overflowY: 'auto'}}>
          
           <div className='main-div'>
            <Grid container item xs={12} md={12} lg={7}  style={{justifyContent: 'center', padding: '10px'}} >
              <div className='selling-preference-margin'> 
                  <Grid item container spacing = {1}  style={{ textAlign: 'center',padding: '15px'}}>
                    <Grid item xs={12} sm={6} >
                        <div test-id="selling_preferences_down_paln_test_id" style={{backgroundColor: selectDownload? 'blue' : '#141414', borderRadius: '10px', padding: '10px'}} onClick={setDownloadPlan} >
                          <Grid className='download-name-font' style={{color: 'white',height: '95px'}} >Download</Grid>
                          <Grid style={{color: 'white',height: '175px',padding: '10px',fontFamily: 'Inter', fontWeight: 'bold', fontSize: '14px'}}>Allow user to stream and download your content if they have a subscription to NXZSOUND </Grid>
                        </div>
                    </Grid> 

                    <Grid item xs={12} sm={6} >
                      <div test-id="selling_preferences_subscription_paln_test_id" style={{backgroundColor: selectSubscription? 'blue' : '#141414', borderRadius: '10px', padding: '10px'}} onClick={setSubscriptionPlan}>
                          <Grid className='download-name-font' style={{color: 'white' ,height: '95px'}} >Subscribe</Grid>
                          <Grid style={{color: 'white' ,height: '175px',padding: '10px', fontFamily: 'Inter', fontWeight: 'bold', fontSize: '14px'}}>Select how much you want users to pay to access the content you're uploading now. </Grid>
                        </div>                    
                    </Grid>
                  </Grid>
    
                {
                  selectSubscription &&
                  <Grid item container xs={12} className='margin_top-audio' style={{width: '100%', padding: '10px'}}>
                   <Grid test-id="selling_preferences_set_paln_test_id" item xs={12} style={{display: 'flex',justifyContent: 'space-between',backgroundColor: selectedPlan == 1 ? 'blue' : '#141414', alignItems: 'center',padding: '20px',borderRadius: '10px', marginBottom: '10px'}} onClick = {() => {setPlan(1)}} >
                        <div className='preference-charge-level'>
                          Level 1
                        </div>
                        <div className='preference-charge-level'>
                          $1/M
                        </div>
                   </Grid>

                   <Grid item xs={12} style={{display: 'flex',justifyContent: 'space-between',backgroundColor: selectedPlan == 2 ? 'blue' : '#141414', alignItems: 'center',padding: '20px',borderRadius: '10px', marginBottom: '10px'}} onClick = {() => {setPlan(2)}}>
                        <div className='preference-charge-level'>
                          Level 2
                        </div>
                        <div className='preference-charge-level'>
                          $5/M
                        </div>
                   </Grid>

                   <Grid item xs={12} style={{display: 'flex',justifyContent: 'space-between',backgroundColor: selectedPlan == 3 ? 'blue' : '#141414', alignItems: 'center',padding: '20px',borderRadius: '10px', marginBottom: '50px'}} onClick = {() => {setPlan(3)}}>
                        <div className='preference-charge-level'>
                          Level 3
                        </div>
                        <div className='preference-charge-level'>
                          $10/M
                        </div>
                   </Grid>
                </Grid>
                }
                
                </div>    
            </Grid>

            {
              <>
              <Grid item xs={12} md={12} lg = {5}  className="hide-side-image-in-responsive">
                <div className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)',position: 'relative', overflow: 'hidden', marginLeft: '10px' }}>
                   <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414',width: '100%', height: '100%'}} src={SellingPreferenceCover} />
                  
                   <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                       <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <>
                    <div className= 'name-font-weight' style={{color: 'white' ,fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>Secure The Bag</div>
                    <p className='name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>Your Way</p>
                    </>
                   
                   


                   <Button className='buttons-font-weight' style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white',fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '10px'}} onClick={setActiveStep}>Next</Button>
                   <Button className='buttons-font-weight' style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px',boxShadow: '-50px 26px 20px 40px rgba(0,0,0,.8)', fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '10px'}}>Save</Button>
                   <Button variant="text" className='buttons-font-weight' style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize'}} onClick={setBackStep}>Back</Button>

                </div>
                </div>
                </div>
                
            </Grid>

            <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-screens">
              <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                <Button style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px', fontWeight: 'bold', textTransform: 'capitalize'}}  onClick={setActiveStep} >Next</Button>
                <Button style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform: 'capitalize'}}>Save</Button>
                <Button variant="text" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px', fontWeight: 'bold', textTransform: 'capitalize'}} onClick={setBackStep}>Back</Button>

              </div>

            </Grid>

            </>
            }
            
            </div> 
         </Grid>
  )
}

export default SellingPreferences
// Customizable Area End