// Customizable Area Start
import React from 'react'
import {
    Grid,
    Dialog,
} from "@material-ui/core";
import { Row, Col } from "antd";
import { CheckBlueLink } from './assets';
import './link_popup.css'

interface Props{
    id: any;
    open: any;
    handleClose: any;
}

export class LinkCopiedPopup extends React.Component<Props> {

  render() {    
    return (
     <Dialog 
     open={this.props.open}
     onClose={this.props.handleClose}
     PaperProps = {{className: "dialog-Paper-link"}}
     >
         <Row justify="center" >
                <Col >
                 <Grid container  style={{justifyContent: 'center', padding: '10px', flexDirection: 'column', alignItems: 'center'}}>

                  <Grid item style={{display: 'flex',flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px', width: '275px'}}>
                    <Grid style={{fontSize: '42px', fontWeight: 900, color: 'white', fontFamily: 'inter' }} data-test-id="send-sms" >Link</Grid>
                    <Grid style={{fontSize: '42px', fontWeight: 900, color: 'white', fontFamily: 'inter', marginTop: '-20px'}} data-test-id="send-sms" >Copied</Grid>
                  </Grid>
                  <Grid item> 
                    <img src={CheckBlueLink} style={{width: '45px', height: '45px'}}/>
                  </Grid>
              </Grid>
            </Col>
         </Row>
     </Dialog>
    )
  }
}

export default LinkCopiedPopup
// Customizable Area End