// Customizable Area Start
import React from "react";
import { Layout, Row } from "antd";
import "../Creatives/index.web.css";
import { Hidden } from "@material-ui/core";
import "../Creatives/Home.web.css";
import ComposeBlock from "../Creatives/ComposeBlock.web";
import Original from "./Original.web";
import UserProfileBasicController from "../UserProfileBasicController";
import AsideLeft from "components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "components/src/CustomChart/PopUps/HomeHeader.web";
import { nxzloader } from "blocks/dashboard/src/assets";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
const { Content } = Layout;

export default class OriginalBlock extends UserProfileBasicController {
  render() {
    const {
      get_All_Activity_data,
      beatList,
      creativeById,
      new_released_video,
      unfollowState,
      loadingFollow,
      openScanQr,
      visible,
      new_released_video_attributes,
    } = this.state;
    const { isPlaylist } = this.context;
    let className = "listener_div_creative_nodata"; // Default class

    if (
      (this.state.value === 5 && get_All_Activity_data.length > 0) ||
      (this.state.value === 6 && this.state.musicButton && this.state.playlistVideo.length > 0) ||
      beatList.length > 0
    ) {
      className = "listener_div_creative_withData";
    }
    return (
      <div className="user-profile-parent-grid">
        <Layout className="user-profile-child-grid">
          {/* .......................SideBar...................................... */}
          <Hidden smDown>
            <AsideLeft />
          </Hidden>

          <Content className="user-profile-content-grid">
            <HomeHeader />
            {Object.keys(creativeById).length <= 0 ? (
              <>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                  }}
                >
                  <img src={nxzloader} style={{ width: "5%" }} />
                </Row>
              </>
            ) : (
              <div
                className={
                  isPlaylist
                    ? "mainBody-audio-withoutheight"
                    : "mainBody-audio-withoutaudio"
                }
              >
                <ComposeBlock
                  creativeById={creativeById}
                  new_released_video={new_released_video}
                  new_released_video_attributes={new_released_video_attributes}
                  openPopupFollowers={this.state.openPopupFollowers}
                  openPopupFollowing={this.state.openPopupFollowing}
                  setopenPopupFollowers={(data) =>
                    this.setState({ openPopupFollowers: data })
                  }
                  setopenPopupFollowing={(data) =>
                    this.setState({ openPopupFollowing: data })
                  }
                  allFollowerData={this.state.allFollowerData}
                  followingData={this.state.followingData}
                  loading={this.state.loading}
                  unfollowState={unfollowState}
                  loadingFollow={loadingFollow}
                  setLoadingFollow={(data) =>
                    this.setState({ loadingFollow: data })
                  }
                  openScanQr={openScanQr}
                  setopenScanQr={(data) => this.setState({ openScanQr: data })}
                  visible={visible}
                  setVisible={(data) => this.setState({ visible: data })}
                  getFollowerData={this.getFollowerData}
                  unfollowUserById={this.unfollowUserById}
                  followUserByID={this.followUserByID}
                  handleRedirectToMessage={this.handleRedirectToMessage}
                  FolloweUser={this.FolloweUser}
                  getFollowingData={this.getFollowingData}
                  UnFolloweUser={this.UnFolloweUser}
                  handlePopupVisible={this.handlePopupVisible}
                />
                <div className={className}>
                  <Original
                    value={this.state.value}
                    playPlaylist={this.state.playPlaylist}
                    playlistData={this.state.playlistData}
                    selectedPlayList={this.state.selectedPlayList}
                    checkType={this.state.checkType}
                    handleAudioVideo={this.state.handleAudioVideo}
                    getTopPlaylists={this.state.getTopPlaylists}
                    handleplay={this.state.handleplay}
                    tabPanelList={this.state.tabPanelList}
                    beatList={this.state.beatList}
                    getCreativeTabsLoader={this.state.getCreativeTabsLoader}
                    window_width={this.state.window_width}
                    // @ts-ignore 
                    video_window_width={this.state.video_window_width}
                    getTop100={this.state.getTop100}
                    selected={this.state.selected}
                    top_playlistVideo={this.state.top_playlistVideo}
                    playlistVideo={this.state.playlistVideo}
                    musicButton={this.state.musicButton}
                    setMusicBtn={(data: any) =>
                      this.setState({ musicButton: data })
                    }
                    seeAll={this.state.seeAll}
                    profileCountData={this.state.profileCountData}
                    filterValue={this.state.filterValue}
                    gridSizes={this.state.gridSizes}
                    creativeById={this.state.creativeById}
                    openAlertPopUp={this.state.openAlertPopUp}
                    dialogText={this.state.dialogText}
                    OpenUpgradModal={this.state.OpenUpgradModal}
                    tabName={this.state.tabName}
                    get_All_Activity_data={this.state.get_All_Activity_data}
                    get_notification={this.state.get_notification}
                    checkCurrentTab={this.checkCurrentTab}
                    handleRedirectPlaylist={this.handleRedirectPlaylist}
                    handleCardPlaylist={this.handleCardPlaylist}
                    handleTrackPlay={this.handleTrackPlay}
                    handleFilter={this.handleFilter}
                    handleClose={this.handleClose}
                    handleCloseOpenUpgradModal={this.handleCloseOpenUpgradModal}
                    handleUpgradeModal={this.handleUpgradeModal}
                    checkCurrentOriginalsTab={this.checkCurrentOriginalsTab}
                    handlePlayList={this.handlePlayList}
                  />
                </div>
              </div>
            )}
          </Content>
        </Layout>
      </div>
    );
  }
}
OriginalBlock.contextType = AudioContextApi;
// Customizable Area End
