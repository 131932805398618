// Customizable Area Start
import React, { useState } from "react";
import "./UserCurrentPlan.css";
import  PlanCancel  from "./PlanCancel.web";
import AddIcon from '@material-ui/icons/Add';
import "./Index.css";
import moment from "moment";
import UserCurrentPlanController from './UserCurrentPlanController'
export default class UserCurrentPlan extends UserCurrentPlanController{
  
render(){
  const {title,userType,price,nextPaymentDue,data,current_plan,cancelledSubscription,status_of_plan} = this.props
  return (
    <>
      {current_plan ? (
        <div className="userCurrentPlan">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="currentPlan_text">{title}</span>
            <div className="plan-name-div">
              <span className="plan-name">
                {userType === "Creatives" || userType === "Listener" ? (
                  <span>{userType}<AddIcon className='redColor' fontSize='medium' /></span>
                ) : (
                  userType
                )}
              </span>
            </div>
          </div>
          <div className="subscriber_content">
            <span className="subscriber_title">Subscription:</span>
            <span className="subscriber_plan_rupees">${price}</span>
          </div>
          {/* {status_of_plan &&
          <div className='subscriber_content'>
            <span className='subscriber_title'>Current Plan Expiry Date</span>
            <span className='subscriber_plan_rupees'>{moment(nextPaymentDue).format('DD MMM ,YYYY')}</span>
          </div>
} */}
          <div className='subscriber_content'>
            <span className='subscriber_title'>{status_of_plan ? 'Next Payment Due' : 'Expire Date'}</span>
            <span className='subscriber_plan_rupees'>{nextPaymentDue?moment(nextPaymentDue).format('DD MMM ,YYYY'): "----"}
          </span>
          </div>

          <div className="cancel_button">
            {status_of_plan ? (
              <button
                className="cancelPlan_button"
                onClick={() => this.setState({OpenPopup:true})}
              >
                Cancel Plan
              </button>
            ) : (
              <button
                className="cancelPlan_button"
                style={{ cursor: "not-allowed" }}
              >
                Plan Cancelled
              </button>
            )}
            <PlanCancel
              open={this.state.OpenPopup}
              closeCancelledModal={this.props.closeCancelledModal}
              cancelledSubscription={cancelledSubscription}
              handleClose={() => this.setState({OpenPopup:false})}
            />
          </div>
        </div>
      ) : (
        <div className="userCurrentPlan">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="currentPlan_text">{title}</span>
          </div>
          <div className="no-plans">
            <p>{data}</p>
          </div>
        </div>
      )}
    </>
  );
      }
};
// Customizable Area End