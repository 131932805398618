// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createBrowserHistory } from "history";
import { openNotification } from "../../../../components/src/Notification.web";
import React from "react";

export const configJSON = require("./config");
const history = createBrowserHistory()

export interface Props {
  navigation: any;
  id: string;
  history: any;
}

interface S {
  songsList: any;
  hooksList: any;
  beatsList: any;
  versesList: any;
  playList: any;
  Top100List: any;
  isPlaylist: boolean;
  playlistData: any;
  loading: boolean;
  alertPopup: boolean;
  open: boolean;
  handleAudioVideo: number;
  dialogText: any;
  checkType: any;
  songIndex: number;
  active: any;
  selectedPlayList: any;
  handleplay: boolean;
  filterValue: any;
  hideDownloadInPlaylist:boolean;
  itemsToShow:number;
}

interface SS {
  id: any;
}

export default class ChartController extends BlockComponent<
  Props,
  S,
  SS
> {

  
  subscriptionPlan = localStorage.getItem("subscription_plans")

  getTop100ListsApiCallId: any
  getSongsListsApiCallId: any
  beatsListsApiCallId: any
  gethooksListsApiCallId: any
  getversesListsApiCallId: any
  getplayListsApiCallId: any
  handleFilterApiCallId: any

  scrollContainer:any;
  songScrollContainer:any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleScroll = this.handleScroll.bind(this); 
    this.scrollContainer = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
      songsList: [],
      hooksList: [],
      beatsList: [],
      versesList: [],
      playList: [],
      Top100List: [],
      isPlaylist: false,
      playlistData: {},
      selectedPlayList: [],
      loading: false,
      alertPopup: false,
      open: false,
      handleAudioVideo: 0,
      dialogText: "",
      checkType: "",
      songIndex: 0,
      active: 0,
      handleplay: false,
      filterValue: "Filter",
      hideDownloadInPlaylist:true,
      itemsToShow:10
    };


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    let urlArr = window.location.href.split("/");
    let slug = urlArr.length !== 0 ? urlArr[5] : "";
    if (slug.includes('videos?id=')){
      slug = 'videos'
    }
    
     this.handleSlug(slug)
  
  }

 

  async componentWillUnmount() {
       if (this.scrollContainer.current) {
        this.scrollContainer.current.removeEventListener("scroll", this.handleScroll);
      }
  
  }

  handleScroll = () => {
    let urlArr = window.location.href.split("/");
    let slugs = urlArr.length !== 0 ? urlArr[5] : "";
    if (slugs.includes('videos?id=')){
      slugs= 'videos'
    }
    const { itemsToShow,loading } = this.state;
    const lists:any = {
      beats: this.state.beatsList,
      hooks: this.state.hooksList,
      playlists: this.state.playList,
      verses:this.state.versesList
  };
  
  let currentList:any = lists[slugs];
  
    if (loading || currentList.length === 0) {
      return; // Don't proceed until data is loaded
    }
    if (this.scrollContainer.current) {
      const scrollTop = this.scrollContainer.current.scrollTop;
      const clientHeight = this.scrollContainer.current.clientHeight;
      const scrollHeight = this.scrollContainer.current.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        if (itemsToShow < currentList.length) {
          this.setState((prevState) => ({
            itemsToShow: prevState.itemsToShow + 10, 
          }));
        }
      }
    }
  };

  componentDidUpdate(prevProps:any, prevState:any) {
    // Once beatsList or other lists are populated, set the scroll listener
    if (
      (prevState.beatsList.length === 0 && this.state.beatsList.length > 0) ||
      (prevState.hooksList.length === 0 && this.state.hooksList.length > 0) ||
      (prevState.playList.length === 0 && this.state.playList.length > 0) ||
      (prevState.versesList.length === 0 && this.state.versesList.length > 0)
    ) {
      if (this.scrollContainer && this.scrollContainer.current) {
        this.scrollContainer.current.addEventListener("scroll", this.handleScroll);
      }
    }
    if(prevState.active !== this.state.active){
      
              this.setState({itemsToShow:10})
    }
  }

  getTop100ListsApiCallFunction = (responseJson: any) => {
  
    if (responseJson?.data) {
      this.setState({ Top100List: responseJson.data, loading: false })
    } else if (responseJson?.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }
  
  getSongsListsApiCallFunction = (responseJson: any) =>{
    if (responseJson?.data) {
      this.setState({ songsList: responseJson.data, loading: false })
    } else if (responseJson?.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  beatsListsApiCallFunction = (responseJson: any) =>{
    if (responseJson?.data) {
      this.setState({ beatsList: responseJson.data, loading: false })
    } else if (responseJson?.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  gethooksListsApiCallFunction = (responseJson: any) =>{
    if (responseJson.data) {

      this.setState({ hooksList: responseJson.data, loading: false })
    } else if (responseJson.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  getversesListsApiCallFunction = (responseJson: any) =>{
   
    if (responseJson.data) {
      this.setState({ versesList: responseJson.data, loading: false })
    } else if (responseJson.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  getplayListsApiCallFunction = (responseJson: any) =>{
   
    if (responseJson.data) {
      this.setState({ playList: responseJson.data, loading: false })
    } else if (responseJson.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handleFilterApiCallFunctionIf = (list:any, responseJson: any ) =>{
    if (list === 'beatsList') {
      this.setState({ beatsList: responseJson.data, loading: false })
    } else if (list === 'hooksList') {
      this.setState({ hooksList: responseJson.data, loading: false })
    } else if (list === 'versesList') {
      this.setState({ versesList: responseJson.data, loading: false })
    } else if (list === 'songsList') {
      this.setState({ songsList: responseJson.data, loading: false })
    } else {
      this.setState({ playList: responseJson.data, loading: false })
    }
  }

  handleFilterApiCallFunctionMessageElseIf = (list: any,responseJson: any) =>{
    if (list === 'beatsList') {
      this.setState({ beatsList: [], loading: false })
      openNotification(responseJson?.message, "Success")
    } else if (list === 'hooksList') {
      this.setState({ hooksList: [], loading: false })
      openNotification(responseJson?.message, "Success")

    } else if (list === 'versesList') {
      this.setState({ versesList: [], loading: false })
      openNotification(responseJson?.message, "Success")

    } else if (list === 'songsList') {
      this.setState({ songsList: [], loading: false })
      openNotification(responseJson?.message, "Success")

    } else {
      this.setState({ playList: [], loading: false })
      openNotification(responseJson?.message, "Success")

    }
  }

  filterFunction = () =>{
    if(window.location.href.includes("/hooks")){
      return "hooksList"
    }else if(window.location.href.includes("/verses")){
      return "versesList"
    }else if(window.location.href.includes("/songs")){
      return "songsList"
    }else{
      return "playList"
    } 
  }

  filterApiSubFunction = (list: any, responseJson: any) =>{
    if (responseJson.data) {
      this.handleFilterApiCallFunctionIf(list,responseJson)
    } else if (responseJson.message) {
      this.handleFilterApiCallFunctionMessageElseIf(list,responseJson)            
    }
    else if (responseJson.errors) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

   apiResuestReceiveCallFunction = (apiRequestCallId: any, responseJson: any) =>{
    if (apiRequestCallId === this.getTop100ListsApiCallId) {
      this.getTop100ListsApiCallFunction(responseJson)
    }


    if (apiRequestCallId === this.getSongsListsApiCallId && responseJson !== undefined) {
      this.getSongsListsApiCallFunction(responseJson)
    }

    if (apiRequestCallId === this.beatsListsApiCallId && responseJson !== undefined) {
      this.beatsListsApiCallFunction(responseJson)
    }


    if (apiRequestCallId === this.gethooksListsApiCallId && responseJson !== undefined) {
      this.gethooksListsApiCallFunction(responseJson)
    }

    if (apiRequestCallId === this.getversesListsApiCallId && responseJson !== undefined) {
       this.getversesListsApiCallFunction(responseJson)
    }

    if (apiRequestCallId === this.getplayListsApiCallId && responseJson !== undefined) {
      this.getplayListsApiCallFunction(responseJson)
    }


    if (apiRequestCallId === this.handleFilterApiCallId && responseJson !== undefined) {
      let list = window.location.href.includes("/beats") ? "beatsList" : this.filterFunction()
      this.filterApiSubFunction(list,responseJson)
    }
   }

   

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
        this.apiResuestReceiveCallFunction(apiRequestCallId,responseJson)
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

  }  

  handleSlug=(slug:any)=>{
    if (slug === "hooks") {
      this.setState({ active: 1, songIndex: 0 })
      this.gethooksLists()
    } else if (slug === "verses") {
      this.setState({ active: 2, songIndex: 0 })
      this.getversesLists()
    } else if (slug === "songs") {
      this.setState({ active: 3, songIndex: 0 })
      this.getSongsLists()
    } else if (slug == "videos") {
      this.setState({ active: 4, songIndex: 0 })
      this.getTop100Lists()
    } else if (slug === "playlists") {
      this.setState({ active: 5, songIndex: 0 })
      this.getplayLists()
    } else {
      this.setState({ active: 0, songIndex: 0 })
      this.getbeatsLists()
    }
  }



  handleClickOpen = () => {
    const token: any = localStorage.getItem("token")
    const userType: any = localStorage.getItem("user_type")
    this.setState({
      open: true,
      dialogText: token && userType !== "listener" ? "1" : userType === "listener" && token ? "3" : "2"
    })
  }

  handleClose = () => {
    this.setState({ open: false })
  }



  handleRedirectPlaylist = (id: any) => {
    const user_id: any = localStorage.getItem("user_id");
    const token: any = localStorage.getItem("token")
    const userType: any = localStorage.getItem("user_type")
    const subscriptionPlan: any = localStorage.getItem("subscription_plans")
    if (token && userType !== "listener" && id?.attributes?.artist_id == user_id || userType === "listener" && token && subscriptionPlan === "Listener+" && id?.attributes?.artist_id == user_id) {
      // history.push(`/home/PlaylistPage/${id.id}`),
      history.push(`/home/self-view/${id.id}`)
        localStorage.setItem("playlist_type", "Private")
    } else if (token && userType !== "listener" && id?.attributes?.artist_id !== user_id || userType === "listener" && token && subscriptionPlan === "Listener+" && id?.attributes?.artist_id !== user_id) {

      // history.push(`/home/PlaylistPage/${id.id}`),
      history.push(`/home/playlist/public-view/${id.id}`)
        localStorage.setItem("playlist_type", "Public")
    } else if (!token) {
      this.setState({ open: true, dialogText: "2" })
    } else {

      this.setState({ isPlaylist: false, alertPopup: true })
      this.handleClickOpen()
    }

  }



  callback = (event: any, newValue: number) => {
    localStorage.removeItem("selectedTab")
    if (newValue === 0) {
      history.push(`/home/Charts/beats`)
      this.setState({ filterValue: 'Filter' })
      this.getbeatsLists()
      this.setState({ songIndex: 0 })
    } else if (newValue === 1) {
      history.push(`/home/Charts/hooks`)
      this.setState({ filterValue: 'Filter' })

      this.gethooksLists()
      this.setState({ songIndex: 0 })

    } else if (newValue === 2) {
      history.push(`/home/Charts/verses`)
      this.setState({ filterValue: 'Filter' })

      this.getversesLists()
      this.setState({ songIndex: 0 })
    } else if (newValue === 3) {
      history.push(`/home/Charts/songs`)
      this.setState({ filterValue: 'Filter' })

      this.getSongsLists()
      this.setState({ songIndex: 0 })
    } else if (newValue === 4) {
      let token= localStorage.getItem('token')
      if(token){
        history.push(`/home/Charts/videos`)
        this.setState({ filterValue: 'Filter' })
  
        // setShowFooter(false);
        this.getTop100Lists()
      }else{
        history.push(`/home/Charts/playlists`)
        this.setState({ filterValue: 'Filter' })

        this.getplayLists()
        this.setState({ songIndex: 0 })
      }
      
    } else if (newValue === 5) {
      history.push(`/home/Charts/playlists`)
      this.setState({ filterValue: 'Filter' })

      this.getplayLists()
      this.setState({ songIndex: 0 })
    }

    let urlArr = window.location.href.split("/");
    let slug = urlArr.length !== 0 ? urlArr[5] : "";
    let token: any = localStorage.getItem("token")
    if(slug == "playlists" && !token){
      this.setState({ active: 4 })
    }else{
      this.setState({ active: newValue })
    }
  }



  getTop100Lists = async () => {
    try {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getTop100ListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetTop100Lists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetTop100Lists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  getSongsLists = async () => {
    try {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getSongsListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetSongsLists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetSongsLists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  getbeatsLists = async () => {
    try {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.beatsListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetBeatsLists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetBeatsLists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  gethooksLists = async () => {
    try {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.gethooksListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetHooksLists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetHooksLists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  getversesLists = async () => {
    try {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getversesListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetVersesLists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetVersesLists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }


   handleLoginFilter = (value: any) => {
   let token= localStorage.getItem('token')
    if (!token) {
      history.push('/Welcome')
      window.location.reload()
    } else {
      this.handleFilter(value,window)

    }
  }
 
   setDialogText = (value: any) => {
    this.setState({ dialogText: value })
  }

  setHandleAudioVideo=(value: any) =>{

    this.setState({ handleAudioVideo: value })
  } 

  setHandlePlay=(value: any) =>{
    this.setState({ handleplay: value })

  } 

   setOpen = (value: any) => {
    this.setState({ open: value })
  }


  getplayLists = async () => {
    try {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getplayListsApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetplayLists}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetplayLists
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }


  handleFilter = async (value: any,window:any) => {
    const urlArr = window.location.href.split("/");
  const slug = urlArr.length !== 0 ? urlArr[5] : "";

  let CurrentValue;
  if (value === "today") {
    CurrentValue = "Today";
  } else if (value === "this_week") {
    CurrentValue = "This week";
  } else if (value === "this_month") {
    CurrentValue = "This month";
  } else {
    CurrentValue = "This Year";
  }

  let currentFilter;
  switch (slug) {
    case "beats":
      currentFilter = "Beat";
      break;
    case "hooks":
      currentFilter = "Hook";
      break;
    case "songs":
      currentFilter = "Song";
      break;
    case "verses":
      currentFilter = "Verse";
      break;
    case "videos":
      currentFilter = "Video";
      break;
    default:
      currentFilter = "Playlist";
  }

  this.setState({ filterValue: CurrentValue });
    try {
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        
        token: localStorage.getItem('token'),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.handleFilterApiCallId = requestMessage.messageId;
      this.setState({ loading: true })

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointHandleFilter}?upload_type=${currentFilter}&filter_value=${value}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodHandleFilter
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  //   editProfile = async(values) => {
  //     try {

  //       const requestMessage = new Message(
  //         getName(MessageEnum.RestAPIRequestMessage)
  //       );

  //       this.editProfileApiCallId = requestMessage.messageId;

  //     const id = localStorage.getItem("userId")

  //     requestMessage.addData(
  //       getName(MessageEnum.RestAPIResponceEndPointMessage),
  //       configJSON.endPointeditProfile+`${id}`
  //     );
  //     const header = {
  //       // 'Content-Type': 'multipart/form-data',
  //       token: localStorage.getItem("userToken")
  //     };

  //     requestMessage.addData(
  //       getName(MessageEnum.RestAPIRequestHeaderMessage),
  //       JSON.stringify(header)
  //     );

  //     const formData = new FormData();
  //     formData.append("account[user_name]", values.userName);
  //     formData.append("account[first_name]", values.name);
  //     formData.append("account[email]", values.email);
  //     formData.append("account[full_phone_number]", values.phoneNumber);
  //     formData.append("account[gender]", values.gender);

  //     requestMessage.addData(
  //       getName(MessageEnum.RestAPIRequestBodyMessage),
  //       formData
  //     );

  //     requestMessage.addData(
  //       getName(MessageEnum.RestAPIRequestMethodMessage),
  //       configJSON.MethodeditProfile
  //     );

  //     runEngine.sendMessage(requestMessage.id, requestMessage);
  //     return true;
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   }


  //   }

}
// Customizable Area End