// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createBrowserHistory } from "history";
import { openNotification } from "../../../../packages/components/src/Notification.web";
import { IBlock } from "../../../framework/src/IBlock";
import { AudioContextApi } from "../../dashboard/src/AudioContext/AudioContext.web";

export const configJSON = require("./config.js");
const history = createBrowserHistory()
export interface Props {
  props?: any;
  navigation?: any;

}
interface S {
  handleAudioVideo: number;
  token: string;
  apiToken: any;
  isPlaylist:boolean;
  data:any;
  filteredData:any;
  isFilter:boolean;
  currentTrack:any;
  selectedIndex:null;
  playlistData:any;
  checkType:any;
  selectedPlayList:any;
  songIndex:0;
  loading:boolean;
  filterValue: any;
}
interface SS {
  id: any;
}

export default class DownloadController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getDataByType: string = "";
  getDownloadHistories:any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
        handleAudioVideo: 0,
      token: "",

      apiToken: localStorage.getItem("token"),
      //playlist
      isPlaylist:false,
      data:[],
      filteredData:[],
      isFilter:false,
      currentTrack:{},
      selectedIndex:null,
      playlistData:{},
      checkType:"",
      selectedPlayList:[],
      songIndex:0,
      loading:false,
      filterValue: "Filter"

    };
   
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDownloadsHistory();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }

  }
 
   handlePlayList = (data: any, index: number, arr: [], name: any, id: any) => {
    this.setState({isPlaylist:true})
    this.setState({playlistData:data})
    this.setState({checkType:name})
    this.setState({selectedPlayList:arr})
    this.setState({songIndex:id})
  }

  topPlayMusic = (item: any, index: any, songsList: any, trackName: any, id: any, isFilter: boolean) => {
    const { handleAudioVideo, setHandleAudioVideo, handlePlayList } = this.context;
    
    const obj = { attributes: item, id: item.id }
      
  
    const tempArr = songsList.map((data: any) => ({ attributes: data, id: data.id }))

  
    this.handleCurrentTrack(item);
  
    if (this.state.selectedIndex !== index || handleAudioVideo === 1 || handleAudioVideo === 0) {
      setHandleAudioVideo(2);
      this.setState({ selectedIndex: index });
      handlePlayList(obj, index, tempArr, trackName, id);
    } else {
      setHandleAudioVideo(1);
      this.setState({ selectedIndex: null });
    }
  }
   handleCurrentTrack = (data: any) => {
    this.setState({currentTrack:data})
  }

   getDownloadsHistory = async () => {
    this.setState({loading:true})
 

    const header = {
      "Content-Type": "application/json;charset=UTF-8",
      token: localStorage.getItem('token'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDownloadHistories = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.downloadhistory}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
    
  }


  //end


  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
      if (apiRequestCallId== this.getDownloadHistories){
        if (responseJson !== undefined && !responseJson.errors) {
          this.setState({loading:false,
            data:responseJson,
          isFilter:false})
          Object.values(responseJson).forEach((items: any, index: any) => {
            this.setState({ currentTrack: items[0] });
          });
      
    
        } else {
          
            openNotification("Please LOGIN to continue", "Error")
          
      }
      }
    }else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
}
DownloadController.contextType = AudioContextApi;
// Customizable Area End