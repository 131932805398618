// Customizable Area Start
import React from 'react';
import PlanAndPaymentsController from "../PlanAndPaymentsController";
import MConfirmPlan from "./MConfirmPlan.web";
import { createBrowserHistory } from "history";
const history = createBrowserHistory()



export default class MConfirmPlanTemp extends PlanAndPaymentsController {

    render() {

        const { next_invoice_no }: any = this.state;
        return (
            <>
                <MConfirmPlan
                    updateSubscription={this.updateSubscription}
                    CreateNewPayment={this.CreateNewPayment}
                    cancelSubscription={this.cancelSubscription}
                    handleUpdateCard={this.handleUpdateCard}
                    next_invoice_no={next_invoice_no}

                />
            </>
        )
    }
}
// Customizable Area End