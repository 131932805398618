// Customizable Area Start
import React from "react"
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createBrowserHistory } from "history";
import { HISTORY } from "../../../components/src/common";

// Customizable Area End

export const configJSON = require("./config");
const history = createBrowserHistory()
export interface Props {
    navigation: any;
    id: string;

}
interface S {
    dashboardData: any;
    errorMsg: string;
    loading: boolean;
    banner: any;
    creativeList: any;
    apiToken: any;
    originalList: any,
    bannerData: any;
    originalId: any;
    trendingList: any;
    releaseList: any;
    selected: any;
    handleAudioVideo1: any
    bannerOption1:any;
    bannerTrack1:any;

}
interface SS {
    id: any;
}

export default class VideoPageController extends BlockComponent<Props, S, SS> {
    apiDashboardItemCallId: string = "";
    dashboardApiCallId: string = "";
    apiGetQueryStrinurl: string = "";
    gethandlepreviewlist: string = ""
    orginalRef: any;
    getplaylists: any;
    getoriginallists: any;
    getalloriginallists: any;
    getcreativedataList: any;
    getbannerList: any
    getTrendinglists: any;
    getReleaseList: any;
    trendingRef: any;
    newReleseRef: any;
    vidRef: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.trendingRef = React.createRef();
        this.vidRef = React.createRef();
        this.newReleseRef = React.createRef()
        console.disableYellowBox = true;
        this.orginalRef = React.createRef();


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            dashboardData: [],
            errorMsg: "",
            loading: false,
            banner: [],
            creativeList: [],
            originalList: [],
            apiToken: localStorage.getItem("token"),
            originalId: 0,
            bannerData: [],
            trendingList: [],
            releaseList: [],
            selected: null,
            handleAudioVideo1: 0,
            bannerOption1:"",
            bannerTrack1:""

        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        localStorage.removeItem("selectedTab")
        localStorage.removeItem("selectedSingleVideo");
        this.getOriginalID()
        this.getBannerList();
        this.getTrendingLists();
        this.getReleaseLists();
        if (this.state.apiToken) {
            localStorage.removeItem("selectedSingleVideo");
            this.getCreativeLists(this.state.apiToken)
        }

    }



    getToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(msg);
    }

    getDashboardData(): boolean {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: this.state.apiToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiDashboardItemCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.dashboardGetUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getBannerList = async () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: this.state.apiToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getbannerList = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getbannerdata}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }


    handleBannerPreview = async (options: any, id: any, track: any) => {
        this.setState({bannerOption1:options,bannerTrack1:track})
       
        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.gethandlepreviewlist = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.gethandlepreview}?id=${id}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }
       
    }

    getCreativeLists = async (token: any) => {
        this.setState({ loading: true });

        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getcreativedataList = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getcreativedata}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }
    }



    //play lists
    getOriginalID = async () => {
        // try {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getoriginallists = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getoriginaldata}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
        // } catch (error) {
        //     console.log(error);
        // }


    }


    getallOriginalLists = async (id: any) => {
        this.setState({ loading: true });


        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getalloriginallists = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getalloriginallist}?upload_type=Video&user_id=${id}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }

    }

    redirectToOriginals = () => {
        localStorage.removeItem("selectedTab")
        const id: any = localStorage.getItem("user_id")
        localStorage.setItem("temp_id", id)
        localStorage.removeItem("user_id")
        localStorage.setItem("user_id", this.state.originalId)
        localStorage.setItem("creatives_screen", "true")
        // await localStorage.removeItem("current_type")
        const user = "original";
        localStorage.setItem("current_type", user);
        history.push(`/originals/videos?originals_id=${this.state.originalId}`)
    }

    getTrendingLists = async () => {
        localStorage.removeItem("selectedSingleVideo");
        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getTrendinglists = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getTrendingList}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }

    }
    getReleaseLists = async () => {

        localStorage.removeItem("selectedSingleVideo");
        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getReleaseList = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getReleaseList}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }

    }

    setDataBasedOnCreatives = (responseJson: any, errorReponse: any) => {
        if (responseJson !== undefined && !responseJson.error && !responseJson.message) {
            this.setState({
                creativeList: responseJson?.data,
                loading: false
            });
        } else {
            this.setState({ loading: false });
        }
    }

    setDataBasedOnTrending = (responseJson: any, errorReponse: any) => {
        if (responseJson !== undefined && !responseJson.error && !responseJson.message) {
            this.setState({
                trendingList: responseJson?.data
            });
        }
    }


    setDataBasedOnRelease = (responseJson: any, errorReponse: any) => {
        if (responseJson !== undefined && !responseJson.error && !responseJson.message) {
            this.setState({
                releaseList: responseJson?.data,
            });
        }
    }



    setDataBasedOnAllOriginal = (responseJson: any, errorReponse: any) => {
        if (responseJson !== undefined && !responseJson.error && !responseJson.message) {
            this.setState({ originalId: responseJson?.data?.id })
            this.getallOriginalLists(responseJson?.data?.id)

        } else {
            this.setState({ loading: false })
        }
    }

    setDataBasedOnAllOriginalList = (responseJson: any, errorReponse: any) => {
        if (responseJson !== undefined && !responseJson.errors && !responseJson.message) {
            this.setState({
                originalList: responseJson?.data,
                loading: false
            });
        } else {
            this.setState({ loading: false })
        }
    }

    handlePreviewList=(responseJson:any)=>{
        
        if (responseJson !== undefined && !responseJson.errors) {
            this.setState({ bannerData: responseJson?.data })
            if (this.state.bannerOption1 == "Watch") {
                HISTORY.push({
                    pathname: "/home/Charts/videos",
                    state: { video_list: this.state.bannerTrack1, activeNo: "5", data: responseJson?.data, currentList: this.state.banner ? this.state.banner : [] }
                })
            } else {
                //console.log(this.state, 'banner full view')
                HISTORY.push({
                    pathname: "/home/expand/song/songId=banner",
                    state: {
                        data: responseJson?.data,
    
                    }
                })
            }
        }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
            return;
        }

        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));


        console.log(responseJson, errorReponse, "console main")
        if (apiRequestCallId == null) {
            this.parseApiCatchErrorResponse(errorReponse);
            return;
        }
        switch (apiRequestCallId) {
            case this.gethandlepreviewlist:
                this.handlePreviewList(responseJson)

                break;
            case this.getcreativedataList:
                this.setDataBasedOnCreatives(responseJson, errorReponse);


                break;
            case this.getTrendinglists:

                this.setDataBasedOnTrending(responseJson, errorReponse);

                break;
            case this.getReleaseList:
                this.setDataBasedOnRelease(responseJson, errorReponse);
                break;

            case this.getoriginallists:
                this.setDataBasedOnAllOriginal(responseJson, errorReponse);
                break;
            case this.getalloriginallists:
                this.setDataBasedOnAllOriginalList(responseJson, errorReponse);

                break;
            case this.getbannerList:
                if (responseJson !== undefined && !responseJson.error && !responseJson.message) {
                    this.setState({
                        banner: responseJson?.data,
                        loading: false
                    });
                } else {
                    this.setState({ loading: false })
                }
                break;

            default:
                break;
        }
    }

}
// Customizable Area End