// Customizable Area Start
import { Carousel, Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import AsideLeft from "components/src/CustomChart/PopUps/AsideLeft.web";
import {  nxzloader } from "../../dashboard/src/assets";
import CreativeBox from "components/src/CustomChart/PopUps/CreativeBox.web";
import CustomCarousel from "components/src/CustomChart/PopUps/CustomCarousel.web";
import HomeHeader from "components/src/CustomChart/PopUps/HomeHeader.web";
import "./videos.css";
import VideoCard from './VideoCard.web'
import { Grid, Box ,Hidden} from "@material-ui/core";
import { AudioContextApi } from "../../dashboard/src/AudioContext/AudioContext.web";
//@ts-ignore
import {
  Link,
} from "react-router-dom";
import VideoPlayer from "components/src/CustomChart/PopUps/VideoPlayer.web";
import VideoPageController from "./VideoPageController";
import { NextButtonOrginal, PrevButtonOrginal } from "components/src/CustomChart/PopUps/CarouselButtonHandler";
// @ts-ignore
import { videosettings } from "../../../components/src/CustomChart/PopUps/CaraouselSetting";

export default class Videos extends VideoPageController {

  renderCreativeList = () => {
   const { creativeList } = this.state
   if (creativeList?.length) {
     return <div className="boxes" style={{marginBottom:"20px"}}>
       <div className="head">
         <div>
           <h2 className="dashboard-fonts">Creatives</h2>
         </div>
         <div>
           <Link to="/home/Creatives">
             <Box className="home-see-all cursor_pointer" >
               SEE ALL</Box>
           </Link>
         </div>
       </div>
       <div className="box creative-height" style={{ border: "none", borderRadius: 0 }}>
         <Grid container className="creative-block">
           {
             (creativeList && creativeList?.length !== 0) &&
             creativeList.map((creativeLists: any, index: number) => {
               if (index <= 11) {
                 return (
                   <Grid item md={3} style={{ marginBottom: 10 }} className="outer_card_width">
                     <CreativeBox artistNo={index + 1} key={index}
                       id={creativeLists?.attributes?.id != null && creativeLists?.attributes?.id}
                       avatar={creativeLists?.attributes?.avatar != null && creativeLists?.attributes?.avatar}
                       trackCount={creativeLists?.attributes?.track_count != null && creativeLists?.attributes?.track_count}
                       userName={creativeLists?.attributes?.user_name != null && creativeLists?.attributes?.user_name}
                       type={creativeLists?.attributes?.type != null && creativeLists?.attributes?.type}
                       fullName={(creativeLists?.attributes?.type != null && creativeLists?.attributes?.last_name != null) && creativeLists?.attributes?.first_name + " " + creativeLists?.attributes?.last_name} />
                   </Grid>
                 )
               }
             })}
         </Grid>

       </div>
     </div>
   }
 }

  renderTrendingList = () => {
   const { trendingList, selected,handleAudioVideo1 } = this.state
   
   if (trendingList?.length) {
     return <div className="boxes" >
       <div className="head_mainContent" style={{ marginBottom:"20px"  }}>
         <div>
           <h2 style={{ marginTop: "0px" }} className="dashboard-fonts"> Trending</h2>
         </div>
         <div>
           <Link to={{
             pathname: "/home/Charts/videos",
             state: { currentList: trendingList }
           }}>
             <Box className="home-see-all cursor_pointer">
               SEE ALL</Box>
           </Link>
         </div>
       </div>

       <div
         className="videos-orginal-content"
         style={{
           position: "relative",
          
         }}
       >
         <Carousel ref={this.trendingRef} {...videosettings}>
           {trendingList.length !== 0 && trendingList.map((video: any, index: number) => {
             return (
               video.attributes.video_file !== null && (
                 <VideoCard songName={video?.attributes?.title}
                   author={video?.attributes?.artist_name}
                   img={video?.attributes?.art_work}
                   playCount={video?.attributes?.play_count}
                   trackFile={video?.attributes?.video_file}
                   repost_count={video?.attributes?.repost_count}
                   cardId={index}
                   id={video.id}
                   selected={selected}
                   setHandleAudioVideo={(value: any) => this.setState({handleAudioVideo1:value})}
                   handleAudioVideo1={handleAudioVideo1}
                   setSelected={(key: any) =>  this.setState({selected:key})}
                   currentList={trendingList}
                   />
               )


             )
           })}
         </Carousel>
         {trendingList.length > 4 &&
           <>
             <PrevButtonOrginal
               top="40%"
               marginLeft="-10px"
               buttonRef={this.trendingRef}
             />
             <NextButtonOrginal
               top="40%"
               right="-10px"
               buttonRef={this.trendingRef}
             >
               {" "}
             </NextButtonOrginal>
           </>
         }
       </div>

     </div>
   }
 }

  renderReleaseList = () => {
   const {releaseList,selected,handleAudioVideo1}= this.state
   if (releaseList.length) {
     return <div className="boxes" style={{ marginBottom:"20px" }}>
       <div className="head">
         <div>
           <h2 className="dashboard-fonts"> New Releases</h2>
         </div>
         <div>
           <Link to={{
             pathname: "/home/Charts/videos",
             state: { currentList: releaseList }
           }}>
             <Box className="home-see-all cursor_pointer">
               SEE ALL</Box>
           </Link>
         </div>
       </div>
       <div
         className="videos-orginal-content"
         style={{
           position: "relative",
           // marginLeft: "15px",
           // marginRight: "15px",
         }}
       >
         <Carousel ref={this.newReleseRef} {...videosettings}>
           {releaseList.length !== 0 && releaseList.filter((item: any) => item?.attributes?.video_file !== null).map((video: any, index: number) => {
             return (
               // <VidCard songName={video?.attributes?.title} author={video?.attributes?.artist_name} image={video?.attributes?.art_work ? video?.attributes?.art_work : album1} playCount={video?.attributes?.play_count} key={index}
               //   repostCount={video?.attributes?.repost_count} video_file={video?.attributes?.video_file}
               // />

               <VideoCard songName={video?.attributes?.title}
                 author={video?.attributes?.artist_name}
                 img={video?.attributes?.art_work}
                 playCount={video?.attributes?.play_count}
                 trackFile={video?.attributes?.video_file}
                 repost_count={video?.attributes?.repost_count}
                 cardId={index}
                 id={video.id}
                 selected={selected}
                 
                 handleAudioVideo1={handleAudioVideo1}
                 setHandleAudioVideo={(value: any) => this.setState({handleAudioVideo1:value})}
                 setSelected={(key: any) =>  this.setState({selected:key})}

                 currentList={releaseList}
               />
             )
           })}
         </Carousel>
         {releaseList.length > 4 &&
           <>
             <PrevButtonOrginal
               top="40%"
               buttonRef={this.newReleseRef}
             />
             <NextButtonOrginal
               top="40%"
               right="-10px"
               buttonRef={this.newReleseRef}
             />

           </>
         }
       </div>
     </div>
   }
 }
 
 render() {

   const {  isPlaylist } = this.context;
const {creativeList,banner,originalList,selected,handleAudioVideo1} =this.state
 
 return (
   <div className="videoMain" >
     <Layout className="videoLayout">
       {/* .......................SideBar...................................... */}
       <Hidden smDown>
         <AsideLeft />
       </Hidden>
       <Content className="videoContent">
         <HomeHeader />
         {creativeList.length === 0 ? (<Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
           <img src={nxzloader} style={{ width: "5%" }} />
         </Row>) : (<>

           <div className="MainBody" style={{
             height: isPlaylist ? "70vh" : "90vh",
           }}>
             {banner.length &&
               <div>
                 <CustomCarousel data={banner}
                   handlePreview={this.handleBannerPreview}
                 />
               </div>
             }
             {originalList.length &&
               <div className="boxes">
                 <div className="head_mainContent ">
                   <div>
                     <h2 className="dashboard-fonts"> Originals</h2>
                   </div>
                   <div>
                     <Box className="home-see-all cursor_pointer" onClick={this.redirectToOriginals}>
                       SEE ALL</Box>
                   </div>
                 </div>
                 <div
                   // className="videos-orginal-content"
                   className='orginal-content'
                   style={{ position: "relative", }}
                 >
                   <Carousel ref={this.vidRef} {...videosettings} infinite={false}>
                     {originalList.length !== 0 && originalList.map((video: any, index: number) => {

                       return (

                         <div key={video.id}>
                           <VideoCard songName={video?.attributes?.title}
                             author={video?.attributes?.artist_name}
                             img={video?.attributes?.art_work}
                             playCount={video?.attributes?.play_count}
                             trackFile={video?.attributes?.video_file}
                             repost_count={video?.attributes?.repost_count}
                             cardId={index}
                             id={video.id}
                             selected={selected}
                             setHandleAudioVideo={(value: any) => this.setState({handleAudioVideo1:value})}
                             setSelected={(key: any) =>  this.setState({selected:key})}
                             handleAudioVideo1={handleAudioVideo1}
                             currentList={originalList}

                           />
                         </div>
                       )
                     })}
                   </Carousel>
                   {originalList.length > 4 &&
                     <>
                       <PrevButtonOrginal top="40%" marginLeft="-10px" buttonRef={this.vidRef} />
                       <NextButtonOrginal top="40%" right="0%" buttonRef={this.vidRef}>

                       </NextButtonOrginal>
                     </>
                   }
                 </div>
               </div>
             }

             {/* creative box */}
             {this.renderCreativeList()}
             {/* Trending part */}
             {this.renderTrendingList()}
             {/* New Realese part */}
             {this.renderReleaseList()}
           </div>
         </>)}
       </Content>
     </Layout>
   </div>
 );
           }
}

Videos.contextType = AudioContextApi;
// Customizable Area End