// Customizable Area Start
import { Content } from "antd/lib/layout/layout";
import { Layout, Row, Col } from 'antd';
import React from 'react';
import "../../../Playlist.css";
import { Hidden, Box } from "@material-ui/core";
import TopPlaylist from "./TopPlaylist.web";
import PlaylistBanner from '../../PlaylistCardNew/PlaylistBanner.web'
import SinglePlaylistCardWeb from "../../../SongCard/SinglePlaylistCardWeb";
import millify from "millify";
import SinglePlaylistController from "../Self/SinglePlaylistController";
import AsideLeft from "components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "components/src/CustomChart/PopUps/HomeHeader.web";
import { nxzloader, player_Banner } from "../../../../../dashboard/src/assets";

const returnHeight = (isPlaylist: any) =>{
  if(isPlaylist){
   return '75vh'
  }
  else{
    return '90vh'
  }
}

export default class PublicPlaylist extends SinglePlaylistController {
   
  render() {
    const {setRepostSong,isPlaylist} = this.context;
    const { selfPlaylistLoader, follow, showPlaylist, handleAudioVideo, follower, playlistData, handleplay, playlistType, playList, musicButton, selectedDiv }: any = this.state;
    let currentclass:any;
    if(isPlaylist){
      currentclass="trackList_div_audioplayer mostly-customized-scrollbar"
    }else{
      currentclass="trackList_div mostly-customized-scrollbar"
    }

    

    return (
      <>
        <div className="playListMain">
          <Layout style={{ backgroundColor: "black", height: "100vh" }}>
            <Hidden smDown>
              <AsideLeft />
            </Hidden>
            <Content style={{ margin: "0px", backgroundColor: "black", }}  >
              <HomeHeader />
              {selfPlaylistLoader ? (
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                  <img src={nxzloader} style={{ width: "5%" }} />
                </Row>
              ) : (
                <>
                <div style={{display: 'flex', flexDirection: 'column', overflowY: 'auto',height: `${returnHeight(isPlaylist)}` }}>
                  <PlaylistBanner
                    setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideo: value })} handleplay={handleplay} setHandlePlay={(value: any) => this.setState({ handleplay: value })} playList={showPlaylist} handlePlayList={this.handleSelectedSongPlay} id={showPlaylist?.id} artist_id={showPlaylist?.attributes?.artist_id} cover_image={showPlaylist?.attributes?.cover_image} playlistName={showPlaylist?.attributes?.name} song_count={showPlaylist?.attributes?.song_count} avatar={showPlaylist?.attributes?.avatar} artist_name={showPlaylist?.attributes?.artist_name}
                    user_type={showPlaylist?.attributes?.user_type} handleRepostPlaylist={this.handleRepostPlaylist} handleFollowStatus={follow} play_count={showPlaylist?.attributes?.play_count}
                    following_count={showPlaylist?.attributes?.following_count} playListData={playlistData} followers_count={follower}
                    repost_count={showPlaylist?.attributes?.repost_count} handleFollow={() => this.handleFollow(showPlaylist?.attributes?.artist_id)} handleUnfollow={() => this.handleUnfollow(showPlaylist?.attributes?.artist_id)} />
                  {
                    showPlaylist && showPlaylist.attributes.playlist_items.data.length === 0 ? (<>
                      <div >
                        <div className="song-div mostly-customized-scrollbar"><Row align="middle" justify="center" style={{ height: "180px" }} className="no_data_available">
                          No Song Available
                        </Row></div></div>
                    </>) :
                      <div className="track_listing">
                        <TopPlaylist />
                        <div className={currentclass}>
                          {
                            showPlaylist && showPlaylist.attributes.playlist_items.data.map((song: any, index: any) => (
                              <div key={song.id} onClick={() => setRepostSong(song)}>
                                <Box
                                  className=" mostly-customized-scrollbar"
                                  onClick={() => {
                                    this.handleDeleteSong(() => this.setState({ delete_track_id: song.id }))
                                  }
                                  } >
                                  <Col span={24} className={`musicRow section-divs ${selectedDiv === index ? " selected" : undefined}`}
                                    onClick={() => {
                                      this.setState({ selectedDiv: index })
                                    }}
                                  >
                                    <SinglePlaylistCardWeb
                                      key={index} number={index} id={song.id} playlistData={playlistData}
                                      arrow={song.attributes.arrow}
                                      handlePlayList={() => this.handleSelectedSongPlay(song, showPlaylist, index)} playerBanner={player_Banner}
                                      artist_name={song.attributes.artist_name} image={song.attributes.art_work} songName={song.attributes.title}
                                      playCount={millify(song.attributes.play_count)} rePostCount={millify(song.attributes.repost_count)} commentCount={millify(song.attributes.comments_count)}
                                      lastTwentyFourHours={song.attributes.last_twenty_four_hours_percentage} lastSevenHours={song.attributes.last_seven_days_percentage}
                                      last_seven_days_graph={song.attributes.last_seven_days_graph} handleAudioVideo={handleAudioVideo}
                                      setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideo: value })} track_file={song?.attributes?.track_file}
                                      handleDeleteSong={() => this.handleDeleteSong(song.id)} musicButton={musicButton} playList={playList}
                                      playlistType={playlistType} changeMusicToogleFalse={this.changeMusicToogleFalse} changeMusicToogleTrue={this.changeMusicToogleTrue}
                                      play_list_type={song.attributes.video_file ? false : true}
                                      track_upload_type={song.attributes.track_upload_type}
                                      handlerSingleVideos={() => this.handlerSingleVideos(song.id, song.attributes.video_file)}
                                    />
                                  </Col> </Box> </div>))}  </div></div>
                  }
                  </div>
                </>)}
            </Content>
          </Layout>
        </div>
      </>
    );
  }
}
// Customizable Area End

