// Customizable Area Start
import React from 'react';
import { Grid } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import '../../../../components/src/CustomChart/SingleTrack/index.css'
export const LabelGraph = () => {

      const graphdata: any = [1,2,3,4,5,6,7,8,9,10,9,8]
     const graphCategory: any = ["January","February","March","April","May","June","July","August","September","October","November","December"]
    const datas: any = {
        labels: graphCategory,
        datasets: [
          {
            // data: [5, 4, 3, 6, 8, 7, 6, 8],
            data: graphdata,
            //   fill: false,
            backgroundColor: '#ff0000',
            // backgroundColor: "#3959f9",
            // borderColor: "#3959f9",
            barPercentage: 0.9,
            categoryPercentage: 1,
            borderWidth: 1,
            borderSkipped: false,
            borderRadius: 15,
            hoverBackgroundColor: '#3959f9',
            // margin: "10px",
            // height: "150px"
          },
        ],
      };
      const options = {
        cornerRadius: 12,
        elements: {
          point: {
            radius: 25,
            hoverRadius: 35,
            pointStyle: 'rectRounded',
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                // color: "#3959f9",
                color: '#ff0000',
                display: graphdata?.every((current: any) => current === 0) ? true : false,
                drawOnChartArea: false,
                drawTicks: false,
                // cornerRadius: 12,
              },
              radius: 25,
              stacked: true,
              ticks: {
                padding: 10,
                display: false,
              },
              scaleLabel: {
                color: '#ff0000',
                display: true
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
              // cornerRadius: 12,
              radius: 25,
              ticks: {
                stepSize: 2,
                display: false,
                beginAtZero: true,
              },
            },
          ],
        },
        legend: {
          display: false,
          boxWidth: 9,
        },
        tooltips: {
          callbacks: {
           
            title: function (context: any) {
              return '';
            },
            label: (context: any) => {
              return context.value;
            },
            
          },
          xAlign: 'right',
          displayColors: false,
          intersect: false,
        },
        maintainAspectRatio: false,
        responsive: true,
      };
    return (
      <>
        <Grid style={{ width: '100%', height: '310px', flexGrow: 1 }} >
                
                <Bar data={datas} options={options} />
                <div style={{display: 'flex', justifyContent: 'center', borderRadius: '5px', backgroundColor: '#212121', padding: '10px', marginTop: '-10px'}}>
                   <div style={{color: 'white'}}>Last Quarter</div>
                </div>
        </Grid>
    
        </>
    )
}
// Customizable Area End