// Customizable Area Start
import React from 'react'
import AddIcon from "@material-ui/icons/Add";
import { Grid } from "@material-ui/core";
import moment from 'moment';

type ConfirmPlanType = {
    planName: string;
    planPrice: string;
}

const MSwitchPlan = ({ planName, planPrice, next_invoice_no }: any) => {

    console.log(planName, typeof planName ,"planName");


    let label: any;
    if (planName.includes("CREATIVE")) {
        label = (
          <>
            Creative
          </>
        );
      }
      else {
        label = (
          <>
          Listener
          </>
        );
      }
    
   

    return (
        <Grid item xs={12} container className="m_user_plan_div">
            <Grid item xs={12} container className="m_user_plan_continer">
                <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                    <span className="m_currentPlan_text">Plan Switching To</span>
                    <Grid className="m_plan_name_div">
                        <Grid className="m_plan_name">{label}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="m_subscriber_content">
                    <span className="m_subscriber_title">Subscription:</span>
                    <span className="m_subscriber_plan_rupees">${planPrice} Monthly</span>
                </Grid>
                <Grid item xs={12} className='m_subscriber_content'>
                    <span className='m_subscriber_title'>Next Payment Due</span>
                    <span className='m_subscriber_plan_rupees'>{next_invoice_no?moment(next_invoice_no, "MMM-DD-YYYY").format("MMM DD,YYYY"):"----"}</span>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MSwitchPlan
// Customizable Area End