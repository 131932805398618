// Customizable Area Start
import { Grid, Hidden } from '@material-ui/core';
import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import './Index.css';
import { Row, Layout } from 'antd';
import  UserListenerPlan from './UserListenerPlan.web';
import UserCreativePlan from './UserCreativePlan.web';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import {  creativeplus, getPlan, yourPlanButton, } from '../assets';
import  UserCardAndCurrentPlan  from './UserCardAndCurrentPlan.web';
import PlanAndPaymentsController from "./PlanAndPaymentsController";
import { nxzloader } from '../../../dashboard/src/assets';
import MyPlanMobileView from './MobileView/Index.web';
import { AudioContextApi } from '../../../dashboard/src/AudioContext/AudioContext.web';


export default class Index extends PlanAndPaymentsController {

  handlerCreativePlan = (current_plan: any, getsubscriptions: any, openConfirmPaymentPopup: any, currentPaymentData: any, next_invoice_no: any) => {

    return (
      <>
       
        {current_plan &&
          <UserCreativePlan
            creativePlanSrc={creativeplus}
            creativePlanAlt={true}
            creativeBtnSrc={yourPlanButton}
            creativeBtnAlt='yourPlanButton'
            price={current_plan?.attributes?.amount}
            name={current_plan?.attributes?.name}
            updateSubscription={() => this.updateSubscription(current_plan.attributes.stripe_plan_id)}
            CreateNewPayment={() => this.CreateNewPayment(current_plan.attributes.id, current_plan.attributes.stripe_plan_id)}
            openConfirmPaymentPopup={openConfirmPaymentPopup}
            closePayModal={this.closePayModal}
            currentPaymentData={currentPaymentData}
            next_invoice_no={next_invoice_no}
            isCurrentPlan={current_plan ? true : false} />
        }

      </>
    )
  }

  handlerListenerPlan = (current_plan: any, getsubscriptions: any, openConfirmPaymentPopup: any, currentPaymentData: any, next_invoice_no: any) => {
   return (
      <>
      {/* getsubscriptions.slice(1, getsubscriptions.length).map((data: any, index: any) */}
        {getsubscriptions.map((data: any, index: any) => {
          return (

            <Grid container xs={12}>
              <UserListenerPlan
                listenerTitle={data.attributes.name}
                listenerPlan={data.attributes.amount}
                getPlanSrc={getPlan}
                getPlanAlt='getPlan'
                updateSubscription={() => this.updateSubscription(data.attributes.stripe_plan_id)}
                CreateNewPayment={() => this.CreateNewPayment(data.attributes.id, data.attributes.stripe_plan_id)}
                openConfirmPaymentPopup={openConfirmPaymentPopup}
                closePayModal={this.closePayModal}
                currentPaymentData={currentPaymentData}
                isCurrentPlan={current_plan ? true : false}
                next_invoice_no={next_invoice_no}
                interval={data.attributes.interval}
              />
            </Grid>
          )
        })}
      </>
    )
  }

  handlerCardAndCurrentPlan = (current_plan: any, next_invoice_no: any, currentPaymentData: any, cancelledSubscription: any, status_of_plan: any) => {
    return (
      <>
        {current_plan && Object.keys(current_plan).length > 0 ?
          <UserCardAndCurrentPlan
            currentplan={current_plan}
            cancelSubscription={this.cancelSubscription}
            cancelledSubscription={cancelledSubscription}
            closeCancelledModal={this.closeCancelledModal}
            next_invoice_no={next_invoice_no}
            currentPaymentData={currentPaymentData}
            handleUpdateCard={this.handleUpdateCard}
            status_of_plan={status_of_plan}
            isCurrentPlan={current_plan ? true : false}
            
          />

          :
          <UserCardAndCurrentPlan
            currentplan={{}}
            handleUpdateCard={this.handleUpdateCard}
            currentPaymentData={currentPaymentData}
          />
        }
      </>
    )
  }


  render() {
    const { loading, getsubscriptions, current_plan, openConfirmPaymentPopup, cancelledSubscription, currentPaymentData, next_invoice_no, status_of_plan }: any = this.state;

    const { isPlaylist} = this.context;


    return (
      <>
        <Layout className='myPlanLayout'>
          <Hidden smDown>
            <AsideLeft />
          </Hidden>
          <Content className='myPlanContent'>
            <HomeHeader />
            {loading ? (
              <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                <img src={nxzloader} style={{ width: "5%" }} />
              </Row>
            ) : (
              <>
                <div style={{ margin: '10px 0px 15px 20px' }}>
                  <Grid container className={isPlaylist?'audio_planContainer':'myplancontainer'}>
                    <Grid container spacing={3} className='PlanHeight'>

                      <Hidden only={["xs"]}>
                        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                          <Grid container className='creative_Container'>
                            {this.handlerCreativePlan(current_plan, getsubscriptions, openConfirmPaymentPopup, currentPaymentData, next_invoice_no)}
                            <Grid item xs={12} className="listener_contnt">
                              {this.handlerListenerPlan(current_plan, getsubscriptions, openConfirmPaymentPopup, currentPaymentData, next_invoice_no)}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Hidden>
                      <Hidden only={["sm", "md", "lg", "xl"]}>
                        <MyPlanMobileView
                          loading={loading}
                          getsubscriptions={getsubscriptions}
                          current_plan={current_plan}
                          openConfirmPaymentPopup={openConfirmPaymentPopup}
                          cancelledSubscription={cancelledSubscription}
                          currentPaymentData={currentPaymentData}
                          next_invoice_no={next_invoice_no}
                          status_of_plan={status_of_plan}
                          handleUpdateCard={this.handleUpdateCard}


                        />
                      </Hidden>
                      {this.handlerCardAndCurrentPlan(current_plan, next_invoice_no, currentPaymentData, cancelledSubscription, status_of_plan)}

                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </Content>
        </Layout>
      </>
    );
  }
}
Index.contextType = AudioContextApi;

// Customizable Area End
