// Customizable Area Start
import React, { useContext } from 'react'
import {
    defaultProfile,
    musicMore,
    musicNext,
    musicPrev,
  } from "../../dashboard/src/assets";
import {
    pauseA,
    queueA,
    repeatA,
    repeatBlue,
  } from "./assets";
import {
    Grid,
  } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { CaretRightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { AudioContextApi } from '../../dashboard/src/AudioContext/AudioContext.web';


function CustomAudioPlayerCenterIcons(props: any) {
  const { token, checkType, playlistData, attributes, title, artist_name, loopSys, AudioLoop, toPrevTrack, loader, finishCheck, restart, IconColor, playBt, downloadType, pause,  nextOrder, play, handleAddToPlaylist } = props;  
  const history = useHistory();
  //@ts-ignore
  const { queueSongs } = useContext(AudioContextApi);


  const playerButton = () =>{
    if(playBt) {

    return (
      <Tooltip placement="top" title="Pause">

        <img src={pauseA} id="music-icon" onClick={pause} className='music-play-icon' alt="" />
      </Tooltip>
    )
      }else{
        return   (
      // play Buttion
      <Tooltip placement="top" title="Play">
        {
        //@ts-ignore 
        <CaretRightOutlined
        id="playercenter_icons_play_test_id"
        onClick={() => play(playlistData?.id)}
        style={{
          fontSize: "25px ",
          background: "#3959f9 ",
          borderRadius: "50%",
          padding: "7px 6px 7px 8px",
          color: "white"
        }}
        />
        }
  

      </Tooltip>
    )}
  }

  const finishCheckPlayer = () =>{
    if(finishCheck()) {
      return (
          // @ts-ignore 
      <CaretRightOutlined
        onClick={restart}
        style={IconColor}
      />
    )} else{
     return playerButton()
    } 
  }

  const redirectExpandMore =() => {
    if(playlistData?.attributes?.track_id != ('' || null)){
      history.push(`/home/expand/song/songId=${playlistData?.attributes?.track_id}`)
    }else{
      history.push({
        pathname: `/home/expand/song/songId=${playlistData.id}`,
        state: { data: playlistData }
      })
    }
  }
  return (
    <Grid container item xs={12} style={{ display: "flex", alignItems: "center " }}>
    <Grid item xs={3}>
      <div className="song-details">
        <img src={checkType === "Playlists" ? playlistData?.attributes?.cover_image : attributes?.art_work ||attributes?.track_artwork || defaultProfile} alt="" />
        <div className="label">
          <h3 style={{
            textOverflow: "ellipsis",
            overflow: "hidden", width: "4rem"
          }} className="mobile-view-audio-title"> {checkType === "Playlists" ? playlistData?.attributes?.name : title || "Unknown"}</h3>
          <p style={{
            textOverflow: "ellipsis",
            overflow: "hidden", width: "4rem"
          }}>{artist_name || "Unknown"}</p>
        </div>
      </div>
    </Grid>
    <Grid item xs={6} style={{ display: "flex", justifyContent: "space-between" }}>
      <div className="song-control" style={{ width: "100%" }}>
        <div className="play-control">
          {/* <Tooltip placement="top" title="Songs in a queue will be shuffled">
            <img src={isShuffle ? shuffle_18 : shuffleA}
              id="music-icon" alt="" onClick={() => setIsShuffle(!isShuffle)} />
          </Tooltip> */}
          <Tooltip placement="top" title="Currently playing songs will be repeated">

            {loopSys ? (
              <img
                id="music-icon"
                src={repeatBlue}
                onClick={() => AudioLoop(false)}

              />
            ) : (

              <img
                src={repeatA}
                id="music-icon"
                test-id="audio_loop_center_icons_test_id"
                onClick={() => AudioLoop(true)}
              />
            )}
          </Tooltip>
          {/* <CustomPlayWaveWeb 
                        // url={playlistData?.attributes?.track_file}
                    // handlePlayPause={props.handlePlayList}
                  /> */}

          <Tooltip placement="top" title="Previous">

              <input type="image" src={musicPrev}  onClick={toPrevTrack}  disabled = {queueSongs.length > 0} style={{ width: '38px', height: '37px', cursor: queueSongs.length > 0 ? "not-allowed" : 'pointer'}}   alt="" />
          </Tooltip>
          {loader ?
            <CircularProgress />
            :
            finishCheckPlayer()
            }
          <Tooltip placement="top" title="Next">

            <img src={musicNext} id="music-icon" onClick={nextOrder} alt="" />
          </Tooltip>
          <Tooltip placement="top" title="Redirect to Songs Details">

            <img
              //  src={isShuffle ? shuffle_18 : shuffleA}
              test-id="redirectExpand_center_icons_test_id"
              src={musicMore}
              alt=""
              onClick={() => redirectExpandMore()}
              className="music-icon-size" />
          </Tooltip>
        </div>
      </div>
    </Grid>
    {
      token ? (
        <>
          <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
            {downloadType}
          </Grid>
          <Grid item xs={1} style={{ display: "flex" }} >
            <div className="menuIcon cursor_pointer" style={{ margin: "auto" }} >
              <img src={queueA} onClick={handleAddToPlaylist} id="music-icon-volumn" alt="" />
            </div>
          </Grid>
        </>
      ) : (
        <Grid item xs={3} style={{ display: "flex", justifyContent: "center" }}>
          <div className="menuIcon cursor_pointer" >
            <img src={queueA} onClick={handleAddToPlaylist} id="music-icon-volumn" alt="" />
          </div>
        </Grid>
      )
    }

  </Grid>  )
}

export default CustomAudioPlayerCenterIcons
// Customizable Area End