// Customizable Area Start
import React from 'react';
import '../../../blocks/dashboard/src/Charts/Videos/MessageSection.web.css'


import { Bar } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import { VideosSplitProgress } from './VideosSplitProgress.web';


export default function LineChart({
  graphdata,
  graphCategory,
  filterValue,
  userSplit,
  userTrackLangth,
  handlerSelectedTime,
  handlerSelectedFilter,
  chartType,
  chartTime,

}: any) {



  const month_name = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let txt: any = "";

  switch (chartTime) {
    case "Week":
      let curr = new Date();
      let day = curr.getDay();
      let firstday = new Date(curr.getTime() - 60 * 60 * 24 * day * 1000);
      let lastday = new Date(firstday.getTime() + 60 * 60 * 24 * 6 * 1000);

      if (firstday.getMonth() == lastday.getMonth()) {
        txt = `${month_name[firstday.getMonth()]} ${firstday.getDate()} - ${lastday.getDate()} `
      } else {
        txt = `${month_name[firstday.getMonth()]} ${firstday.getDate()} - ${month_name[lastday.getMonth()]} ${lastday.getDate()} `
      }
      // console.log(txt)
      break;

    case "Month":
      let date = new Date();
      txt = `${month_name[new Date().getMonth()]} 1 - ${new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()} `
      break;

    case "Year":
      txt = `Year - ${new Date().getFullYear()} `
      break;

    default:
      break;
  }




  const datas: any = {
    labels: graphCategory,
    datasets: [
      {
        // data: [5, 4, 3, 6, 8, 7, 6, 8],
        data: graphdata,
        //   fill: false,
        backgroundColor: '#ff0000',
        // backgroundColor: "#3959f9",
        // borderColor: "#3959f9",
        barPercentage: 0.9,
        categoryPercentage: 1,
        borderWidth: 1,
        borderSkipped: false,
        borderRadius: 15,
        hoverBackgroundColor: '#3959f9',
        // margin: "10px",
        // height: "150px"
      },
    ],
  };
  const options = {
    cornerRadius: 12,
    elements: {
      point: {
        radius: 25,
        hoverRadius: 35,
        pointStyle: 'rectRounded',
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            // color: "#3959f9",
            color: '#ff0000',
            display: graphdata?.every((current: any) => current === 0) ? true : false,
            drawOnChartArea: false,
            drawTicks: false,
            // cornerRadius: 12,
          },
          radius: 25,
          stacked: true,
          ticks: {
            padding: 10,
            display: false,
          },
          scaleLabel: {
            color: '#ff0000',
            display: true
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
          // cornerRadius: 12,
          radius: 25,
          ticks: {
            stepSize: 2,
            display: false,
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
      boxWidth: 9,
    },
    tooltips: {
      callbacks: {
        //   beforeTitle: function (context: any) 
        //     return 'before title';
        //   },
        title: function (context: any) {
          return '';
        },
        label: (context: any) => {
          return context.value;
        },
        //   afterTitle: function (context: any) {
        //     return 'after title';
        //   },
      },
      xAlign: 'right',
      displayColors: false,
      intersect: false,
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <Grid item xs={12} container style={{ height: "420px" }}>
      <Grid
        item
        xs={12}
        style={{
          padding: '15px 10px',
          backgroundColor: 'black',
          borderRadius: '15px',
          // height: "100px"
          // position: "relative",
          height: "200px",
          border: '2px solid #202020'
        }}>
        {/* <img src={barGraph} alt="" width="163px" height="117px" /> */}
        {/* <img src={barGraph} alt="" width="163px" height="117px" /> */}
        <Bar data={datas} options={options} />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', position: "relative", top: "-15px" }}>
        <div className='this_year_graph'>
          <span>
            This {chartTime}
            <span
              style={{
                width: '7px',
                height: '7px',
                color: '#3959f9',
                borderRadius: '7px',
                padding: '0px 10px',
              }}>
              &#9679;
            </span>
            {txt} ({chartType})
          </span>
        </div>
      </Grid>
      {/* <Grid item xs={12} style={{ padding: '0px 30px', height: "170px", overflowX: "hidden", overflowY: "auto" }}>
        <VideosSplitProgress userSplit={userSplit} />
      </Grid> */}
      <Grid item xs={12} style={{  }} className='no_splits_padding'>
        {userTrackLangth > 0 && userSplit?.length ? (
          <VideosSplitProgress userSplit={userSplit} />
        ) : (
          <span className='splits-not-available'>Splits not available</span>
        )}
      </Grid>
    </Grid>
  );
}
// Customizable Area End
