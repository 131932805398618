// Customizable Area Start
import React, { useContext, useEffect, useState } from 'react'
import { Grid, Tabs, Tab, Box, Hidden, IconButton } from '@material-ui/core';
import "./Index.web.css"
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import MSwitchPlan from './MSwitchPlan.web';
import MCardInformation from './MCardInformation.web';
import { useHistory } from 'react-router-dom';
import { ArrowBackRounded } from '@material-ui/icons';
import { AudioContextApi } from '../../../../dashboard/src/AudioContext/AudioContext.web';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


const MConfirmPlan = ({ updateSubscription, CreateNewPayment, handleUpdateCard, next_invoice_no }: any) => {

    const [tabvalue, setTabvalue] = useState(0);
    const history = useHistory();
    const [switchPlan, setSwitchPlan] = useState<any>({})
    const location = history?.location?.state
    console.log(location);

    useEffect(() => {
        setSwitchPlan(location);
    }, [])


    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box >
                        {children}
                    </Box>
                )
                }
            </div >
        );
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabvalue(newValue);

    };

    const { isPlaylist} = useContext(AudioContextApi);

    return (
        <>
            <Hidden only={["sm", "md", "lg", "xl"]}>
                <Layout className='myPlanLayout'>
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content className='myPlanContent'>
                        {/* <HomeHeader /> */}
                        <div style={{ margin: '10px 0px 15px 20px' }}>
                            <Grid container className={isPlaylist?'audio_planContainer':'myplancontainer'}>
                                {/* <Grid xs={12} container spacing={3} className='PlanHeight'> */}
                                <Grid item xs={12}>
                                    <Grid container item xs={12} style={{ justifyContent: "center", alignItems: "center" }}>
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => history.goBack()}>
                                                <ArrowBackRounded style={{ color: "white" }} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={11} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            {tabvalue !== 1 && <span className="m_myplan_font">Confirm Plan</span>}
                                            {tabvalue === 1 && <span className="m_myplan_font">My Card</span>}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ margin: "10px 10px" }}>
                                        {/* <AppBar position="static"  > */}
                                        <Tabs value={tabvalue} variant="scrollable" onChange={handleChange}
                                            TabIndicatorProps={{ style: { backgroundColor: 'red', color: "red", borderBottom: "1px solid", bottom: "7px" } }}
                                            className="m_custom_tab_font">
                                            <Tab className={tabvalue === 0 ? "active_myplan_tab" : "non_active_myplan_tab"} label="My Plan" {...a11yProps(0)} />
                                            <Tab className={tabvalue === 1 ? "active_myplan_tab" : "non_active_myplan_tab"} label="My Card" {...a11yProps(1)} />
                                        </Tabs>
                                        {/* </AppBar> */}
                                        <TabPanel value={tabvalue} index={0}>

                                            <MSwitchPlan planName={switchPlan?.planName} planPrice={switchPlan?.planPrice} next_invoice_no={next_invoice_no} />
                                            <Grid item xs={12} container className="m_user_plan_div">
                                                <MCardInformation currentPaymentData={switchPlan?.currentPaymentData} />
                                            </Grid>
                                            <Grid item xs={12} container className="m_user_plan_div" direction='column'>
                                                <button
                                                    className='m_pay_button'
                                                    style={{ marginBottom: '20px' }}
                                                    onClick={() => {
                                                        if (Object.keys(switchPlan?.currentPaymentData).length <= 1) {
                                                            updateSubscription(switchPlan?.currentPlan?.attributes?.stripe_plan_id)
                                                        } else {
                                                            if (!switchPlan?.isCurrentPlan) {
                                                                CreateNewPayment(switchPlan?.currentPlan.attributes.id, switchPlan?.currentPlan?.attributes?.stripe_plan_id)

                                                            } else {
                                                                updateSubscription(switchPlan?.currentPlan?.attributes?.stripe_plan_id)

                                                            }
                                                        }
                                                        history.push("/mupdateplan")
                                                    }}>
                                                    Pay
                                                </button>
                                                <button
                                                    className='m_keepmyplan_button'
                                                    style={{ marginBottom: '20px' }}
                                                    onClick={() => history.push("/planpayments")}
                                                >
                                                    Keep My Plan
                                                </button>
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={tabvalue} index={1}>
                                            <Grid item xs={12} container style={{ margin: "15px 0px 30px" }}>
                                                <MCardInformation currentPaymentData={switchPlan?.currentPaymentData} />
                                            </Grid>
                                            <Grid item xs={12} container className="m_user_plan_div" justifyContent='center'>
                                                <button
                                                    className='m_update_btn'
                                                    // style={{ margin: "30px 0px 10px 0px" }}
                                                    onClick={handleUpdateCard}

                                                >
                                                    {switchPlan?.currentPaymentData?.card ? "Update Card" : "Add Your Card"}
                                                </button>

                                            </Grid>
                                        </TabPanel>
                                    </Grid>

                                </Grid>
                            </Grid>
                            {/* </Grid> */}
                        </div>

                    </Content>

                </Layout>
            </Hidden>
        </>
    )
}

export default MConfirmPlan
// Customizable Area End