// Customizable Area Start
//@ts-nocheck
import React,{useContext} from "react";
import "../../../../user-profile-basic/src/Common/TopSongs.web.css";
import './index.css'
import { Typography, Grid, Button, Box, Hidden, ButtonGroup } from "@material-ui/core";
import { linkChainW, filterW, player_Banner, editplaylist, deleteplaylist, grouplaylist, sendplaylist } from "../../../../dashboard/src/assets";
import millify from "millify";
//@ts-ignore
import { Col, Row } from "antd";
import SinglePlaylistCardWeb from "../../SongCard/SinglePlaylistCardWeb";
import RepostPopup from "components/src/CustomChart/PopUps/RepostPopup.web";
import EditPopup from "../../../../dashboard/src/CustomComponent/EditPlaylistPopup/EditPlaylistPopup.web";
import SharePlaylistPopup from "../../../../dashboard/src/CustomComponent/SharePlaylistPopup/SharePlaylistPopup.web";
import LinkCopiedPopup from "../../../../dashboard/src/CustomComponent/LinkCopiedPopup/LinkCopiedPopup.web";
import MessageLinkPopup from 'components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web';
import DeletePlaylistPopup from "../../../../dashboard/src/CustomComponent/DeletePlaylistPopup/DeletePlaylistPopUp.web";
import { AudioContextApi } from "../../../../dashboard/src/AudioContext/AudioContext.web";

const TopPlaylists = (props: any) => {
  const { isPlaylist } = useContext(AudioContextApi);

  const { musicButton, playList, openPopup, playlistData, openEditPopup, openDeletePopup, openLinkPopup, openMessageLink, copied, showPlaylist, selectedDiv, handleAudioVideo, playlistType, privatetoggle, publicData, selectedPlaylistId,
    setOpenUp, setOpenEditPopup, setHandleLinkCopy, setOpenMessageLink, setSelectedDiv, setHandleAudioVideo, handleDeletePlayListClick, handlerSingleVideos, onCloseRepost, repost_track, _handleTextFieldChange, respostSubmit, displayedArray,
    setOpenEditPopupTrue, linkopenPopup, handleChangeStatusOfPlaylist, privatetoggleToggle, handleRepost, changeMusicToogleFalse, handleSelectedSongPlay, UpdateDetails, deleteTrackFromPlaylist,
    inSingleCopy,CopiedForLink
  }: any = props;
  const playlistPopups = () => {
    return (
      <>
        {
          <SharePlaylistPopup id="Playlist" open={openPopup} handleClose={setOpenUp} playlist={showPlaylist} selectedSong={selectedPlaylistId} />
        }

        {
          showPlaylist?.id &&
          <EditPopup id="Playlist" open={openEditPopup} handleClose={setOpenEditPopup} playlist={showPlaylist} UpdateDetails={UpdateDetails} />
        }

        {
          <DeletePlaylistPopup id="Playlist" open={openDeletePopup} handleClose={setOpenEditPopup} playlist={showPlaylist} />
        }

        {
          copied &&
          <LinkCopiedPopup id="Playlist" open={openLinkPopup} handleClose={setHandleLinkCopy} />
        }

        {
          <MessageLinkPopup id="Playlist" showMsgModal={openMessageLink} closeMsgModal={setOpenMessageLink}
            closeModalDialog={setOpenMessageLink} copiedLinked={CopiedForLink} currentUserId={1}
          />

        }
      </>
    )
  }

  const dataListing = (song: any, index: any) => {
    return (
      <div key={song.id} >
        <Box className=" mostly-customized-scrollbar">
          <Col span={24} className={`musicRow section-divs ${selectedDiv === index ? " selected" : undefined
            }`}
            onClick={() => {
              setSelectedDiv(index)
            }}
          >
            <SinglePlaylistCardWeb
              key={index}
              number={index}
              id={song.id}
              playlistData={playlistData}
              handlePlayList={() => {
                handleSelectedSongPlay(song, publicData, index)
              }
              }
              playerBanner={player_Banner}
              artist_name={song.attributes.artist_name}
              image={song.attributes.art_work}
              songName={song.attributes.title}
              playCount={millify(song.attributes.play_count)}
              rePostCount={millify(song.attributes.repost_count)}
              commentCount={millify(song.attributes.comments_count)}
              lastTwentyFourHours={song.attributes.last_twenty_four_hours_percentage}
              lastSevenHours={song.attributes.last_seven_days_percentage}
              last_seven_days_graph={song.attributes.last_seven_days_graph}
              handleAudioVideo={handleAudioVideo}
              setHandleAudioVideo={setHandleAudioVideo}
              track_file={song?.attributes.track_upload_type=="Video"?song.attributes.video_file :song?.attributes?.track_file}
              musicButton={musicButton}
              playList={playList}
              playlistType={playlistType}
              changeMusicToogleFalse={changeMusicToogleFalse}
              // changeMusicToogleTrue={changeMusicToogleTrue}
              selectedDiv={selectedDiv}
              handleDeletePlayListClick={handleDeletePlayListClick}
              /*eslint no-unused-expressions: ["error", { "allowTernary": true }]*/
              play_list_type={song.attributes.video_file ? false : true}
              arrow={song.attributes.arrow}
              track_upload_type={song.attributes.track_upload_type}
              handlerSingleVideos={( songId, songVideo) => {
                handlerSingleVideos( songId, songVideo)}
              }
            />

          </Col>
        </Box>
      </div>

    )

  }

  const noFilesMethod = (text: any) => {
    return <div className="song-div mostly-customized-scrollbar"><Row align="middle" justify="center" style={{ height: "180px" }} className="no_data_available">{text}</Row></div>
  }

  const typoGraphy = () => {
    return (
      <Typography className="top-head">{`${returnCondtional().length > 0 ? returnCondtional().length : 0}` + ' Tracks '}</Typography>
    )
  }



  const returnCondtional = () => {
    if (displayedArray.length > 0) {
      return displayedArray.map((play: any, index: number) => dataListing(play, index))
    } else {
      return noFilesMethod(" No Tracks Available")
    }
  }

  let currentclass: any;

  if (isPlaylist) {
    currentclass = "trackList_div_audioplayer mostly-customized-scrollbar"
  } else {
    currentclass = "trackList_div mostly-customized-scrollbar"
  }

  return (
    <>
      <Box className="self-listing-outer-div">
        <Grid className="self-listing-inner-div" container>
          <Grid item xs={12} sm={7} md={7} lg={8} className="self-flex">
            <Grid item xs={3} md={3} lg={3} className="self-center">
              {typoGraphy()}
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5}  >
              <div className="btn-groups ">
                <button> <img src={editplaylist} className="btn-img" onClick={setOpenEditPopupTrue} /></button>
                <button> <img src={grouplaylist} onClick={()=>
                  handleRepost()
                
                   } className="btn-img" /></button>
                <button > <img src={linkChainW} className="btn-img" onClick={() => inSingleCopy()}/></button>
                <button> <img src={sendplaylist} className="sendplaylist-responsive" onClick={() => linkopenPopup()} /></button>
                <button >  <img src={deleteplaylist} className="btn-img" onClick={() => deleteTrackFromPlaylist()} /></button>
              </div>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} >
              <ButtonGroup
                color="primary"
                className="outer-div-toggle private-public-self"
                aria-label="outlined primary button group"
              >
                <Button className="private-button-playlist"
                  value="audio"
                  variant="contained"
                  disabled={privatetoggle === false}
                  onClick={() => {
                    handleChangeStatusOfPlaylist("private_playlist")
                    privatetoggleToggle()

                  }
                  }>
                  <span className="audio-text"> Private </span>
                </Button>
                <Button variant="contained"
                  className="public-button-playlist"
                  onClick={() => {
                    handleChangeStatusOfPlaylist("public_playlist")
                    privatetoggleToggle()
                  }
                  }
                  disabled={privatetoggle === true}
                >
                  <span className="audio-text"> Public </span>
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid item xs={4}
            // md={4} 
            sm={5}
            lg={4}
            className="self-center"
          >
            <Hidden xsDown>
              <Grid item xs={3} style={{marginLeft: '30px'}}>
                <Typography className=" text_white1 top_size_16 hiddnDaysScore " >24hrs%</Typography>
              </Grid>
              <Grid item xs={4} style={{marginLeft: '40px'}}>

                <Typography className="text_white1 top_size_16" >7d %</Typography>
              </Grid>
              <Grid item xs={4} >
                <Typography className="text_white1 top_size_16 "> Last 7d </Typography>
              </Grid>

              <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }} >
                <img src={filterW} style={{
                  width: "17px",
                  height: " 14.8px",
                }} />
              </Grid>
            </Hidden>

          </Grid>
        </Grid>
        {playlistPopups()}
      </Box>
      <div className={currentclass}>
        {returnCondtional()}
      </div>
     
      <RepostPopup open={openPopup}
        onClose={onCloseRepost}
        profileImage={repost_track?.attributes?.art_work || repost_track?.attributes?.cover_image}
        artistName={repost_track?.attributes?.artist_name}
        songName={repost_track?.attributes?.title}
        repost_count={respostSubmit}
        _handleTextFieldChange={_handleTextFieldChange}
        />
    </>
  );
}
export default TopPlaylists;
  // Customizable Area End