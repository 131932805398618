// Customizable Area Start
import React,{useState} from "react";
import { Col, Row } from 'antd';
import { Grid ,Box} from "@material-ui/core";
import VideoPlayer from "./SearchVideoPlayer.web";
import { nxzloader } from "../../../../dashboard/src/assets";
const SearchVideosWeb = (props: any) => {
  const [selected,setSelected] = useState(null)
  const [handleAudioVideo,setHandleAudioVideo] = useState(0)
  const {videosList , loading} = props
  return (
<>
    {
      loading ? (
    
        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
        <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      
        ):
    
        (
          <Grid container style={{height: '100%'}}>
            <>
            <Box className=" search-videos-scroll " style={{width: '100%'}} > 
              <Grid container item lg={12} >
              {videosList.length!==0 ? videosList.map((video:any,index:number)=>{
                
                return  <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={video.id}>
                            <Grid style={{width: '95%'}}>
                            <VideoPlayer songName={video?.attributes?.title}
                              author={video?.attributes?.artist_name}
                              img={video?.attributes?.art_work}
                              playCount={video?.attributes?.play_count}
                              trackFile={video?.attributes?.video_file}
                              repost_count={video?.attributes?.repost_count}
                              cardId={index}
                              id={video.id}
                              selected={selected}
                              setHandleAudioVideo={(value: any) => setHandleAudioVideo(value)}
                              handleAudioVideo={handleAudioVideo}
                              setSelected={(key: any) => { setSelected(key)}}
                              playData={video}
                              currentList={videosList}
                            />
                            </Grid>
                            
                          </Grid>
                          
              })
              :
              (
                <Row style={{width: '100%', justifyContent: 'center'}}>
                   <div style={{textAlign: 'center',color: 'grey', fontSize: '20px',fontWeight:'bold',marginTop: '200px'}}>NO VIDEOS FOUND!</div>
                </Row>
              )}
              </Grid>
      </Box>
      </>
      
    </Grid>
    )
      }
      </>
  );
};

export default SearchVideosWeb;
// Customizable Area End
