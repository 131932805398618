// Customizable Area Start
import React from "react";
import { Row, Col, Form, Button, Input } from "antd";
import {ListenerCreativeBackground, atSign, lock, CreativeShade, mail, nxzborderlogo, user } from "./assets";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "../assets/listener.css";
import axios from "axios";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Link } from "react-router-dom";
import { openNotification } from "../../../components/src/Notification.web";
const urlConfig = require("./../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

export default function Creative() {
  const history = useHistory();
  const emailId = localStorage.getItem("email")
  const onFinish = async (values: any) => {
    const data = {
      type: "email_account",
      user_type: localStorage.getItem("accountType"),
      user_name: values.username,
      full_name: values.full_name,
      full_phone_number: `+${values.phone}`,
      email: values.email,
      // email: location.email ? location.email : values.email,
      password: values.password,
      password_confirmation: values.password,
      spotify_id: values.spotify,
      instagram_id: values.instagram,
    };
    await axios
      .post(`${baseUrl}/account_block/accounts?devise_type=web`, data)
      .then((res) => {
        // history.push("/Otp")
        localStorage.setItem("token", res.data.meta.token)
        history.push("/TermAndConditions")
        
        
        // localStorage.setItem("otp", res.data.data.attributes.otp)
      })
      .catch((err) => {
        openNotification(err?.response?.data?.errors[0]?.account, "Error")
      });
  };
  return (
    // <div className="bgDark" >
    <div className="listenerBgDark">
      <Row justify="space-around" style={{ height: "100%" }}>
      <Grid item lg={6} md={6} xs={12} className="bgImg-welcome-create" >
        <img className="listener-creative-image1 check2" style={{}} src={ListenerCreativeBackground} />
        <img className="image2" src={CreativeShade} />
      </Grid>

      <Col lg={12} md={12} sm={24} xs={24}>
        <Row align="middle" justify="center" className="width_100 " style={{ height: "100%" }}>
          <Col span={23} className="listener_acc_parent">
              <div>
                <img src={nxzborderlogo} alt="App Logo" className="nxzborderlogo" />
              </div>

            <div >
              <h1 className="mb_0 text_white create-account-title">Create Your Account</h1>
              <h4 className="text_white1 mb_0 text-align">
                For Creatives By Creatives
              </h4>
            </div>
            <Row justify="center">
              <Col span={22}>
                <Form
                  name="basic"
                  className="login_form"
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                  initialValues={{ email: emailId ? emailId : "" }}
                >
                  <Col md={24} sm={24} className="aligncolumn">
                    <Row className="width_100 font_12 gray1" >
                      <Row className="fullname_acc">
                        Full Name

                        <Form.Item
                          label="Full Legal Name"
                          name="full_name"
                          className="account_sub_title"

                          rules={[
                            {
                              required: true,
                              message: "Please Input Your FullName!",
                            },
                          ]}
                        >
                          <Input
                            prefix={<img src={user} style={{ width: "100%" }} />}
                            placeholder="John Doe"
                          />
                        </Form.Item>
                      </Row>
                    </Row>
                    <Row className="width_100 font_12 gray1 ">
                      <Row className="fullname_acc">
                        Username <span className="change-username">(cannot be changed)</span>
                        <Form.Item
                          label="Username will show on your public profile"
                          name="username"
                          className="account_sub_title"

                          rules={[
                            {
                              required: true,
                              message: "Please Input Your Username!",
                            },
                            {
                              pattern: /^[a-z0-9_@.\-]{2,25}$/,
                              message:
                              "Username must have lowercase and contains 2-25 character only",
                            },
                          ]}
                        >
                          <Input
                            prefix={<img src={atSign} style={{ width: "100%" }} />}
                            placeholder="Ex: nxzsound"
                          />
                        </Form.Item>
                      </Row>
                    </Row>
                  </Col>

                  <Col md={24} sm={24} className="aligncolumn" >
                    <Row className="width_100 font_12 gray1" >
                      <Row className="fullname_acc">
                        Password
                        <Form.Item
                          label="Enter a password for your account"
                          name="password"
                          style={{width:"98%"}}
                          rules={[
                            {
                              required: true,
                              message: "Please Input Your Password!",
                            },
                            {
                              pattern: new RegExp(
                                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                              ),
                              message:
                                "Password Must Be 8 Character And Contains At Least One A-Z, a-z, 0-9, @$%#",
                            },
                          ]}
                        >
                          <Input.Password
                            prefix={<img src={lock} style={{ width: "100%" }} />}
                            placeholder="*******************"
                          />
                        </Form.Item>
                      </Row>
                    </Row>
                    <Row className="width_100 font_12 gray1" >
                      <Row className="fullname_acc" style={{ width: "98%" ,height:"80%"}}>
                        Email
                        <Form.Item
                          label="Enter your email address"
                          name="email"
                          className="account_sub_title"
                          rules={[
                            {
                              type: "email",
                              required: true,
                              message: "Please Enter Your Valid Email Address!",
                            }
                          ]}
                        >
                          <Input
                            prefix={<img src={mail} style={{ width: "100%" }} />}
                            placeholder="youremail@gmail.com"
                          />
                        </Form.Item>
                      </Row>
                    </Row>
                  </Col>
                  {/* <Col md={24} sm={24} style={{ display: "flex", marginBottom: "2%" }}> */}
                  <Col md={24} sm={24} className="aligncolumn">
                    <Row className="width_100 font_12 gray1" >
                      <Row className="fullname_acc">
                        Phone
                        <Form.Item
                          label="Phone Number"
                          name="phone"
                          className="account_sub_title"
                          rules={[
                            {
                              required: true,
                              message: "Please Input Your Phone Number!",
                              validator: (_, value) => {
                                const isValidPhone =/^\d{11,}$/.test(value);
                                
                                if (isValidPhone) {
                                  return Promise.resolve();
                                }
                                return Promise.reject("Please enter a valid phone number with at least 9 digits and only digits.");
                              },
                            },
                           
                          ]}
                        >
                          <PhoneInput
                            inputStyle={{ background: 'black', width: '100%', borderBottom: "1px solid #7a7e8a", borderTop: 'none', borderLeft: 'none', borderRight: 'none', color: 'white' }}
                            buttonStyle={{ background: 'black', borderBottom: "1px solid #7a7e8a", borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                            dropdownStyle={{ height: '150px', background: 'black', color: 'white' }}
                            searchStyle={{ background: 'black', width: '100%', borderBottom: "1px solid #7a7e8a", borderTop: 'none', borderLeft: 'none', borderRight: 'none', marginLeft: '-10px', marginTop: "-10px" }}
                            country={'us'}
                            enableSearch
                            disableSearchIcon
                            // value={this.state.phone}
                            onChange={(phone: any) => console.log(phone)}
                          />
                          {/* <Input /> */}
                        </Form.Item>
                      </Row>
                    </Row>
                    <Row className="width_100 font_12 gray1" >
                      <Row className="fullname_acc" style={{ width: "98%" }}>
                        Instagram
                        <Form.Item
                          label="Enter your username"
                          name="instagram"
                          className="account_sub_title"
                          rules={[
                            {
                              required: true,
                              message: "Please Input Your Instagram ID!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Row>
                    </Row>
                  </Col>
                  <Col md={24} sm={24} style={{ display: "flex" }}>
                    <Row className="width_100 font_12 gray1" >

                      <Form.Item style={{ width: "100%", marginBottom: "0" }}>
                        <Button
                          className="red_custom_btn login_btn mt-1 next_back_account_create"
                          type="primary"
                          htmlType="submit"
                        // style={{
                        //   width: "50%",
                        //   margin: "auto"
                        // }}
                        >
                          Next
                        </Button>
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col md={24} sm={24} style={{ display: "flex" }}>
                    <Row className="width_100 font_12 gray1" >
                      <Form.Item style={{ width: "100%", marginBottom: "0" }}>

                        <Button
                          className="login_btn mt-1 next_back_account_create back_btn"
                          type="primary"

                          onClick={() => history.goBack()}

                        >
                          Back
                        </Button>
                      </Form.Item>
                    </Row>
                  </Col>
                </Form>
              </Col>
            </Row>

          </Col>
        </Row>
      </Col>
      </Row>
    </div>
  );
}
// Customizable Area End