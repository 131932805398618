// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { openNotification } from "../../../../../components/src/Notification.web";
import { HISTORY } from "../../../../../components/src/common";
import { AudioContextApi } from "../../AudioContext/AudioContext.web";
export const configJSON = require('../../config');

interface S {
  apiToken: any;
  openPlaylist: any;
}
interface SS {
}
interface Props {
  id: string;
  open: any;
  handleClose: any;
  playlistType: any;
  trackData: any;
}

export default class AddToQueuePopupController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiDeletePlaylistCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      apiToken: localStorage.getItem('token'),
      openPlaylist: false
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
  }
  
  async receive(from: string, message: Message) {

    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiDeletePlaylistCallId) {
        if (responseJson != null) {
          if (!responseJson.error) {
            openNotification(responseJson?.data[0].message, 'Success')
            this.props.handleClose()
            HISTORY.push('/home/all-playlist')
          } else {
            openNotification(responseJson?.error, 'Error')
            this.props.handleClose()
           //failed
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

}
AddToQueuePopupController.contextType = AudioContextApi;
// Customizable Area End