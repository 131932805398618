export const logo = require("../assets/logo.png");
export const PlaylistIcon = require("../assets/playlist.png");
export const homeIcon = require("../assets/homeIcon.svg");
export const Filter_black = require("../assets/filterBlack.png");
export const FilterGraph = require("../assets/filtergraph.png");
export const Profile = require("../assets/Oval.png");
export const path3 = require("../assets/Path3.png");
export const prevIC = require("../assets/prevIC.png");
export const nextIC = require("../assets/nextIC.png");
export const album1 = require("../assets/album 1.png");
export const album2 = require("../assets/Album2.png");
export const album3 = require("../assets/Album 3.png");
export const playButton = require("../assets/Play 2.png");
export const playNow = require("../assets/playNow.png");
export const loopIc = require("../assets/Resjare.png");
export const gradient = require("../assets/Gradient 1.png");
export const triangle = require("../assets/Triangle.png");
export const img1 = require("../assets/img 1.png");
export const appleIc = require("../assets/DownloadAssets/apple.png");
export const playIconApple = require("../assets/DownloadAssets/playIconApple.png");
export const downBg = require("../assets/DownloadAssets/downBg.png");
export const topBg = require("../assets/DownloadAssets/topBg.png");
export const Fullmask2 = require("../assets/DownloadAssets/Fullmask.png");
export const playStore = require("../assets/DownloadAssets/playstore.png");
export const telegram = require("../assets/telegram.png");
export const grad1 = require("../assets/grad1.png");
export const grad2 = require("../assets/grad2.png");
export const pplays = require("../assets/pplays.png");
export const following = require("../assets/following.png");
export const follower = require("../assets/following.png");
export const request = require("../assets/Request.png");
export const reshare = require("../assets/Reshares.png");
export const pprofile = require("../assets/profileperson.png");
export const chartcover = require("../assets/chart-cover.png");
export const addUser = require("../assets/adduser.png");
export const removeUser = require("../assets/Unfollow.png");
export const album11 = require("../assets/album11.png");
export const player_Banner = require("../assets/Player-banner.png");
export const graph = require("../assets/graph.png");
export const playOutline = require("../assets/plays.png");
export const fullscreen = require("../assets/fullscreen.png");
export const comment = require("../assets/Comment.png");
export const ResharedIc = require("../assets/Reshared.png");
export const DummyProfileImage = require("../assets/follower.png");
export const playerFullBanner = require("../assets/Player -img- banner.png");
export const searchIcon = require("../assets/Search.png");
export const play1 = require("../assets/play1.png");
export const repost1 = require("../assets/repost1.png");
export const following1 = require("../assets/followings1.png");
export const follower1 = require("../assets/followers1.png");
export const removeUserFollower = require("../assets/removeUserFollower.png");

// musical assets
export const nextA = require("../assets/musicalAssets/nextButton.png");
export const pauseA = require("../assets/musicalAssets/pauseButton.png");
export const prevA = require("../assets/musicalAssets/prevButton.png");
export const queueA = require("../assets/musicalAssets/queueButton.png");
export const repeatA = require("../assets/repeat.svg");
export const shuffleA = require("../assets/musicalAssets/shuffleButton.png");
export const volumeA = require("../assets/musicalAssets/volumeButton.png");
export const pauseSmall = require("../../AudioMusic/assets/pauseButton.png");
//listener
export const iconUp = require("../assets/icon-up.png");

//playlist modal
export const plusIcon = require("../assets/plusIcon.jpg");
export const verticalMenu = require("../assets/verticalMenu.png");
export const verticalMenu2 = require("../assets/verticalMenu2.svg");

// my plan
export const creative = require("../../Payments/assets/creative.png");
export const updateCard = require("../assets/updateCard.png");
export const creativeplus = require("../../Payments/assets/creativeplus.png");
export const getPlanButton = require("../../Payments/assets/get plan button.png");
export const yourPlanButton = require("../../Payments/assets/your plan button.png");
export const getPlan = require("../../Payments/assets/get plan.png");
export const cancelPlan = require("../../Payments/assets/cancel plan.png");
export const mastercardLogo = require("../../Payments/assets/Mastercard Logo.png");
export const payButton = require("../../Payments/assets/pay button.png");
export const keepMyPlan = require("../../Payments/assets/keepmyplan.png");
export const background = require("../../Payments/assets/background.png");
export const nxzsoundLogo = require("../../Payments/assets/nxzsoundlogotwo.png");
export const nxzsoundlogoSmall = require("../../Payments/assets/nxzsoundlogo.png");
export const visitprofile = require("../../Payments/assets/visitprofile.png");
export const viewplan = require("../../Payments/assets/viewplan.png");
export const savecard = require("../../Payments/assets/savecard.png");
export const cancelPlanRed = require("../../Payments/assets/cancelplanred.png");
export const cancelPlanBlue = require("../../Payments/assets/cancelPlanBlueBtn.png");
export const mobileBackground = require("../../Payments/assets/mobileBackground.png");

//Upload assests
export const CameraImage = require("../assets/iconlyLightCamera.png");
export const GalaryImage = require("../assets/iconlyLightImage2.png");

//comments and message assests
export const commentUp = require("../assets/comment/comment_up.png");
export const commentDown = require("../assets/comment/comment_down.png");
export const seeAll = require("../assets/see_all.png");
export const send_icon = require("../assets/send_icon.png");

// download track
export const first = require("../assets/download1.png");
export const second = require("../assets/download2.png");
export const third = require("../assets/download3.png");
export const forth = require("../assets/download4.png");
export const fifth = require("../assets/download5.png");

//sidebar
export const BarcodeIcon = require("../assets/Barcode.png");
export const DownloadIcon = require("../assets/Download from the Cloud.png");
export const IncreaseIcon = require("../assets/Increase.png");
export const PlaylistSideIcon = require("../assets/Playlist play.png");
export const AccountIcon = require("../assets/User Account.png");
export const VideoIcon = require("../assets/Video Playlist.png");
export const defaultProfile = require("../assets/Default-Profile.png");

export const composeprofile = require("../assets/composeProfile.png");
export const composeComment = require("../assets/compose-comment.png");
export const people = require("../assets/compose-people.png");
export const play = require("../assets/compose-play.png");
export const composerequest = require("../assets/compose-request.png");
export const composeBg = require("../assets/composeBg.png");
export const composeReshares = require("../assets/composeReshares.png");
export const song1 = require("../assets/topSong1.png");
export const play_white = require("../assets/Play 2.png");
export const streaming = require("../assets/Player-banner.png");
export const plays = require("../assets/plays.png");
export const reshared = require("../assets/Reshared.png");
export const commentBlue = require("../assets/Comment.png");
export const leftAngle = require("../assets/left-angle.png");
export const rightAngle = require("../assets/right-angle.png");
export const filter = require("../assets/filter.png");
export const songPlay = require("../assets/song-play.png");
export const video1 = require("../assets/video1.png");
export const video2 = require("../assets/video2.png");
export const video3 = require("../assets/video3.png");
export const video4 = require("../assets/video4.png");
export const Bitmap = require("../assets/Bitmap.png");
export const down = require("../assets/Arrow-Up.png");
export const fourth = require("../assets/Rectangle Copy 16.png");
export const nxz = require("../assets/NXZ.png");
export const lShape = require("../assets/Group 8.png");
export const activity = require("../assets/activity-pic.png");
export const menu_btn = require("../assets/Menu_Btn.png");
export const blueCheck = require("../assets/blueCheck.png");
export const shape = require("../assets/Shape.png");
export const send_button = require("../assets/fluent-send.png");
export const qrCode = require("../assets/qr-code.png");
export const linkChain = require("../assets/link-chain.png");
export const messageShape = require("../assets/message-shape.png");
export const reshareBlue = require("../assets/Reshare blue.png");
export const songsCard = require("../assets/songsCard.png");
export const profile = require("../assets/Default-Profile.png");
export const defaultImage = require("../assets/default.jpeg");

export const FilterGray = require("../assets/filterGray.png");
export const queue = require("../assets/Queue.png");
export const see_all = require("../assets/see_all.png");
export const repeat_red = require("../assets/Repeat_18.png");
export const shuffle_18 = require("../assets/Shuffle18.png");
export const loader = require("../assets/loader.gif");
export const profileRed = require("../assets/profileRed.png");
export const arrowBack = require("../assets/back.png");

// web player
export const musicPrev = require("../assets/previousPlayer.png");
export const musicNext = require("../assets/playerNext.png");
export const musicMore = require("../assets/playermoreicon.webp");
export const musicPlaylist = require("../assets/playlistplayicon.webp");
export const musicDownload = require("../assets/downloadicon.webp");
export const musicDollar = require("../assets/deal-dollar.webp");

//dashboard icons

export const dashbordicon = require("../assets/iconly-light-outline-category.webp");
export const profileIcon = require("../assets/vuesax-outline-user.webp");
export const shareIcon = require("../assets/iconly-light-outline-scan.webp");
export const emptyWalletIcon = require("../assets/vuesax-outline-empty-wallet.webp");
export const historyIcon = require("../assets/vuesax-outline-setting.webp");
export const logoutIcon = require("../assets/vuesax-outline-logout.webp");

// playlist
export const playlistBg = require("../assets/playlistBG.png");
export const linkChainW = require("../assets/linkChainW.svg");
export const trashW = require("../assets/trashW.svg");
export const Shape = require("../assets/Shape.png");
export const plus = require("../assets/plus.png");
export const minus = require("../assets/MINUS.png");
export const expand = require("../assets/expand.png");
export const filterW = require("../assets/Filter (2).svg");
export const playlistBackgroundPic = require("../assets/Playlist Background Pic.png");
export const playlistProfile = require("../assets/playlist-profile.png");
export const sendplaylist = require("../assets/send-playlist.png");
export const grouplaylist = require("../assets/Group-playlist.png");
export const deleteplaylist = require("../assets/Delete-playlist.png");
export const editplaylist = require("../assets/edit-playlist.png");
export const followplaylist = require("../assets/follow-playlist.png");
export const messageplaylist = require("../assets/message-playlist.png");
export const playplaylist = require("../assets/play-playlist.png");
export const repeatplaylist = require("../assets/repeat-playlist.png");

// expand screen
export const blurimg = require("../assets/expand_screen/blur.png");
export const barGraph = require("../assets/expand_screen/Bar_Graph.png");
export const bgexpand = require("../assets/expand_screen/Believer.jpg");
export const chartFilter = require("../assets/expand_screen/Filter.png");
export const validIcon = require("../assets/expand_screen/validicon.png");
export const repostIcon = require("../assets/expand_screen/RepostIcon.png");
export const addToPlaylist = require("../assets/expand_screen/Add_To_Playlist.png");
export const shareTrack = require("../assets/expand_screen/ShareIcon.png");
export const dealTrack = require("../assets/expand_screen/DealIcon.png");
export const PlayIcon = require("../assets/expand_screen/Play_arrow.png");
export const repeatIcon = require("../assets/expand_screen/repeat.png");
export const messageIcon = require("../assets/expand_screen/message.png");

// Sidebar Icons
export const Homeicon = require("../assets/Home-icon.webp");
export const Videoicon = require("../assets/Videos-icon.webp");
export const Chartsicon = require("../assets/Charts-icon.webp");
export const Playlisticon = require("../assets/Playlist-icon.webp");
export const Creativesicon = require("../assets/Creatives-icon.webp");
export const Homeblue = require("../assets/Home-blue-icon.png");
export const Videoblue = require("../assets/Video-blue-icon.png");
export const Chartsblue = require("../assets/Charts-blue-icon.png");
export const Playlistblue = require("../assets/Playlist-blue-icon.png");
export const Downloadblue = require("../assets/Download-blue-icon.webp");
export const Creativeblue = require("../assets/Creative-blue-icon.png");
//videos screen
export const AddToPlaylistfill = require("../assets/expand_screen/AddToPlaylistfill.png");
export const DealIconfill = require("../assets/expand_screen/DealIconfill.png");
export const RepostIconfill = require("../assets/expand_screen/RepostIconfill.png");
export const ShareIconfill = require("../assets/expand_screen/ShareIconfill.png");
export const ArrowUp = require("../assets/ArrowUp.png");
export const Sort_Up_large = require("../assets/videoPlaylistIcons/Sort_Up_large.png");
export const Skip_Backward = require("../assets/videoPlaylistIcons/Skip_Backward.png");
export const Skip_Forward = require("../assets/videoPlaylistIcons/Skip_Forward.png");

/*replay*/
// viewreplay
export const viewreplay = require("../assets/comment/viewreplay.png");
export const nxzloader = require("../assets/nxzloader.gif");
export const lockIcon = require("../assets/Lock-icon.png");
export const expandMore = require("../assets/expandMore.png");
export const sendLink = require("../assets/send-link.svg");

// filter Icons
export const PlaysFilter = require('../assets/filterIcons/Plays.png');
export const playsInactive = require('../assets/filterIcons/Plays_inactive.png');
export const CommentsFilter = require('../assets/filterIcons/Comments.png');
export const CommentsInactive = require('../assets/filterIcons/Comments_inactive.png');
export const RepostFilter = require('../assets/filterIcons/Repost.png');
export const RepostInactive = require('../assets/filterIcons/Repost_inactive.png');

//activity 
export const ActivityProfileIcon = require('../assets/activity_profile.png');
export const RProfileIcon = require('../assets/r icon.png');
export const FProfileIcon = require('../assets/f icon.png');
export const UProfileIcon = require('../assets/u icon .png');
export const ActivityProfileCover = require('../assets/Group 63.png');
export const ActivityMore = require('../assets/More.png');
export const playlistMoreTwoDots = require('../assets/MoreInfoTwoDots.png');
export const VideoCover = require('../assets/Video Frame.png');
export const fire = require('../assets/fire.png');
export const GroupCopy = require('../assets/GroupCopy.png');
export const Frame313 = require('../assets/Frame 313.png');
export const notification = require('../assets/notification.png');

export const VideoUserAvatar = require('../assets/Video User Avatar.png');
export const VideoUserAvatar4 = require('../assets/Video User Avatar4.png');
export const VideoUserAvatar3 = require('../assets/Video User Avatar3.png');
export const VideoUserAvatar2 = require('../assets/Video User Avatar2.png');
export const VideoUserAvatar5 = require('../assets/Video User Avatar5.png');
export const warner = require('../assets/warner.png');
export const playedicon = require('../assets/playedicon.png');
export const Message = require('../assets/Message.png');
export const reposticon = require('../assets/reposticon.png');
export const messageProfile = require('../assets/Message-profile.webp');
export const profileperson = require('../assets/profileperson.png');
export const profileplay = require('../assets/profileplay.png');
export const profileinvite = require('../assets/profileinvite.png');
export const keyIcon = require('../assets/key-icon.png');
export const musicIcon = require('../assets/music-icon.png');
export const videoIcon = require('../assets/video-icon.png');

// deal flow
export const MusicCover = require("../assets/DealFlow/CoverArt3x.png");
export const videoOffer = require("../assets/DealFlow/videooffer.png");
export const Playcirclefill = require("../assets/DealFlow/Playcircle.png");
export const userLogo = require("../assets/DealFlow/nxzsoundlogo.png");
export const CircledRight = require("../assets/DealFlow/CircledRight.png");
export const Qrcode = require('../assets/QR Code.webp');

// original 
export const originalIcon = require('../assets/original-icon.svg');
export const chatPlaylist = require('../assets/Chat-playlist.png');
export const playcountP = require('../assets/playcount playlist.png');
export const OriginalBlue = require('../assets/OriginalBlue.png');

// dashboard
export const userDashobard = require('../assets/profilenav.png')
export const recievedmoney = require('../assets/profilenav.png')
export const shareWallet = require('../assets/Share.svg')
export const receiveWallet =require('../assets/Frame 61.svg')
export const cashout = require('../assets/Frame 62.svg')
export const userdeal =require("../assets/In Progress.svg")
export const revenue_arrow = require("../assets/arrow@2x.png")
export const arrowup= require('../assets/ArrowUp.png')