// Customizable Area Start
import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import "./Index.css";
import ConfirmPlanTemp from "./ConfirmPlanTemp.web";
import Typography from "antd/lib/typography/Typography";
import UserCreativePlanController from './UserCreativePlanController'
export default class UserCreativePlan extends UserCreativePlanController {

  render(){
    const {  creativePlanAlt, creativeBtnSrc, creativeBtnAlt, price, name, openConfirmPaymentPopup, currentPaymentData, next_invoice_no, isCurrentPlan }: any = this.props;

  let SubcriptionName;
  

  if (name === "CREATIVE" ) {
    SubcriptionName = "Creative";
  } 
  else {
    SubcriptionName = (
      <>
        <span>Listener</span>
        {/* <AddIcon className="redColor" fontSize="large" /> */}
      </>
    );
  }

  return (
    <>
      <Grid container item xs={12} sm={6} md={6} lg={6} xl={6} >
        <div
          className="creativePlan"
          style={{
            border: creativePlanAlt
              ? "10px solid #0f6fd1"
              : "10px solid #141414",
            margin: "10px",
          }}
        >
          <div className="plan-name-div">
            <Typography className="plan-name">{SubcriptionName}</Typography>
          </div>
          <span className="creativePlanPriceFont">${price}</span>
          <img
            src={creativeBtnSrc}
            alt={creativeBtnAlt}
            onClick={() => {
              !creativePlanAlt && 
              this.setState({OpenPopup:true})
            }}
            // className="creativeGetPlan"
            className={
              creativePlanAlt ? "creativeNoPlanBtn" : "creativePlanButton"
            }
          />

          <ConfirmPlanTemp
            open={this.state.OpenPopup}
            handleClose={() =>  this.setState({OpenPopup:false})}
            listenerTitle={SubcriptionName}
            subscription={price}
            updateSubscription={this.props.updateSubscription}
            openConfirmPaymentPopup={openConfirmPaymentPopup}
            closePayModal={this.props.closePayModal}
            currentPaymentData={currentPaymentData}
            CreateNewPayment={this.props.CreateNewPayment}
            isCurrentPlan={isCurrentPlan}
            next_invoice_no={next_invoice_no}
            interval={""}
          />
        </div>
      </Grid>
    </>
  );
          }
};
// Customizable Area End