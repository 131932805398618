// Customizable Area Start
import React from "react";
import "../Creatives/ComposeBlock.web.css";
import { List, Avatar, ListItemAvatar, ListItemText, Typography, ListItem, Grid } from "@material-ui/core";
import LabelProfileUserDiv from './LabelProfileUserDiv.web'
export const configJSON = require("../../../../framework/src/config");
import LabelController from './LabelController.web'
import { defaultProfile } from "blocks/dashboard/src/assets";

export default class LabelCoverProfileData extends LabelController {
  render() {
    const { creativeById } = this.state;
    if (Object.keys(creativeById).length > 0) {
      return (
        <Grid container xs={12} className="listener-profile-justify">
          <Grid item xs={12} sm={7} className="compose-grid">
            <List>
              <ListItem alignItems="flex-start" className="left-padding">
                <ListItemAvatar>
                  <Avatar
                    alt="Loading..."
                    src={creativeById.attributes?.avatar || defaultProfile}
                    className="profileImg"/>
                </ListItemAvatar>

                <ListItemText
                  className="title"
                  primary=
                  {
                    <span className="list-name" style={{ fontSize: "22px", fontWeight: "bold", fontFamily: "karla", textTransform: "capitalize" }}>
                      {creativeById?.attributes && creativeById?.attributes?.user_name}
                    </span>
                  }
                  secondary={
                    <Grid item xs={12} sm={12} className="subtitle-grid" style={{ fontFamily: "karla" }}>
                      <Typography component="span" variant="body1" style={{ fontFamily: "karla" }}> {creativeById?.attributes && (creativeById?.attributes?.creative_type[0]?.name)} </Typography>

                      {
                        creativeById?.attributes?.city && (
                          <Typography component="span" variant="body2" style={{ fontFamily: "karla" }}> {creativeById?.attributes && creativeById?.attributes?.city},{creativeById?.attributes && creativeById?.attributes?.country} </Typography>
                        )

                      }
                    </Grid>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <LabelProfileUserDiv />
        </Grid>
      )
    } else {
      return null
    }
  }
}
// Customizable Area End