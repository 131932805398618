// Customizable Area Start
import React, { useState, useEffect } from "react";
import { Row } from "antd";
import { Grid, Box } from "@material-ui/core";
import "../assets/ChoosePlan.css";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { PlanShade, subscriptionBackground } from './assets';
const urlConfig = require("./../../../framework/src/config")
const baseUrl = urlConfig.baseURL;
import { openNotification } from "../../../components/src/Notification.web";
import { nxzloader } from "../../dashboard/src/assets";
import { nxzborderlogo } from '../../email-account-registration/src/assets';

const setKey = () => {
  if(urlConfig.baseURL.includes("prod") ){
      return `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}`
  }else{
      return `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`
  }
  //return `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}`
}
const baseStripePromise: any = setKey()
//    baseUrl.includes("prod") ? `${process.env.REACT_APP_STRIPE_PUBLISH_PROD_KEY}` : `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`
const stripePromise = loadStripe(baseStripePromise);


const ForCreative = () => {
  const history = useHistory();
  let [planData, setplanData] = useState([]);
  let [electedPlanData, setelectedPlanData] = useState('');
  let [enablePlanData, setenablePlanData] = useState(true);
  const [stripeId, setStripeId] = useState<any>('');

  const [loading, setLoading] = useState(false);
  const [subscriptionClicked, setSubscriptionClicked] = useState("")
  const [currentSubscription, setCurrentSubscription] = useState("")
  const token: any = localStorage.getItem("token")
  useEffect(() => {
    getSubcriptionPlan(token)
  }, []);
console.log(currentSubscription,"currentSubscription")
  const getSubcriptionPlan = async (token: any) => {
    setLoading(true);
    if (token) {
      await axios.get(`${baseUrl}/bx_block_subscription_plan/subscription_plans`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        }
      })
        .then((response) => {
          setplanData(response?.data?.data)
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          setLoading(false);
        });
      document.getElementById('continue')?.setAttribute('style', 'color:brown')
    } else {
      history.push("/Welcome");
    }
  }

  const selectPlan = (id: any, stripeId: any) => {
    setStripeId(stripeId);

    setelectedPlanData(id)
    setenablePlanData(false)
    document.getElementById('plan' + id)?.setAttribute('style', 'background-color:#3131d7')
    document.getElementById('continue')?.setAttribute('style', 'color:white')
  }

  const urlReturn = () =>{
    if(baseUrl.includes("prod")){
      return "https://app.nxzsound.com"
    }else if(baseUrl.includes("uat")){
      return "https://nxzsound10-66173-react.b66173.uat.eastus.az.svc.builder.ai"
    }
    else{
      return "https://nxzsound10-66173-react.b66173.stage.eastus.az.svc.builder.ai"
    }
  }

  const handleSubscribe = async () => {
    const dev_url = "https://nxzsound10-66173-react.b66173.dev.eastus.az.svc.builder.cafe"
    const currentRedirect = baseUrl.includes("dev") ? dev_url : urlReturn()
    const data = {

      success_url:
        `${currentRedirect}/CreativeProfile?session_id={CHECKOUT_SESSION_ID}`,
      // "https://nxzsound10-66173-react-native.b66173.dev.eastus.az.svc.builder.cafe/CreativeProfile?session_id={CHECKOUT_SESSION_ID}",
      cancel_url: `${currentRedirect}/ForCreative`,
      price: stripeId,
      device_type: "web",
      quantity: 1,

    }
    await axios
      .post(`${baseUrl}/bx_block_subscription_plan/checkout_session`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        }
      })
      .then(async (res) => {
        const stripe: any = await stripePromise;
        localStorage.setItem("session_id", res.data.session_id.id)
        // eslint-disable-next-line
        const paymentLinkUrl = res.data.session_id.url; // Assuming your backend returns this URL
        window.location.href = paymentLinkUrl;
      })
      .catch((error) => {
        console.log(error, "bbnb");

        // openNotification(err?.response?.data?.errors[0]?.token, "Error")
        openNotification(error?.response?.data?.message, "Error")
      });
  }



  const acceptPlan = async () => {
    const data = {
      subscription_plan_id: electedPlanData

    }
    await axios
      .post(`${baseUrl}/bx_block_subscription_plan/subscribe`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        }
      })
      .then((res) => {
        console.log(res.data, "kkkkkkkkkkkkkkk");
        handleSubscribe();
      })
      .catch((err) => {
        openNotification(err?.response?.data?.errors[0]?.token, "Error")
      });
  }
  return (
    <Grid container className="ForListener">
      <Grid item lg={6} md={6} xs={12} className="bgImg-welcome-create" >
        <img className="image1" src={subscriptionBackground} />
        <img className="image2" src={PlanShade} />
      </Grid>

        <Grid item lg={6} md={6} xs={12} className="listener-plan" >
          <Grid item xs={12} >

              <div>
                <img src={nxzborderlogo} alt="App Logo" className="nxzborderlogo" />
              </div>
          </Grid>
          <Grid item xs={12} className="termsandcondition-head">
            <h1 className="mb_0 text_white tc_acc">Select Your Plan</h1>

          </Grid>
          {loading ? (<Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
          <img src={nxzloader} style={{ width: "15%" }} />
          </Row>) : (
          <>
            <Grid className="listener-buttons ">



              {planData?.length !== 0 && planData?.map((plan: any, index) => {
                return (
                  <Grid container item xs={12} sm={8} key={index} className={subscriptionClicked == plan.id ? "subscription-list-acc-active" : "subscription-list-acc"} zeroMinWidth
                    onClick={() => {
                      setSubscriptionClicked(plan.id)
                      setCurrentSubscription(plan.subscriptionName)
                    }}
                    style={{ marginBottom: "2%" }}
                  >
                    <button type="button" key={index + index} id={'plan' + plan?.attributes?.id}
                      onClick={() => {
                        if (electedPlanData) {
                          document.getElementById('plan' + electedPlanData)?.setAttribute('style', 'background-color:rgb(63, 63, 63)')
                        }
                        selectPlan(plan?.attributes?.id, plan?.attributes?.stripe_plan_id.replace('\t', ''))
                      }}
                    >
                      {/* <p>{plan?.attributes?.name}</p> */}


                      <Grid item xs={12} className='p-10 plan_align' >
                        <Box className='font_22 width_100 font_W_600 plan_name_acc'>{plan?.attributes?.name}</Box>
                        {/* <Box className="font_16" style={{ color: "#d8d8d8" }}>{plan?.attributes?.sub_title}</Box> */}

                        <Box className='font_28 width_100 font_W_600 plan_name_acc'>${plan?.attributes?.amount + '/' + plan?.attributes?.interval.charAt(0).toUpperCase() + plan?.attributes?.interval.slice(1)}</Box>

                      </Grid>
                    </button>
                  </Grid>
                )
              })}
            </Grid>


            <Grid container xs={12} zeroMinWidth className="acc-next-back-parent">

              <Grid item xs={8} sm={6} style={{ width: "100%" }}>
                <button className="acc-next-btn"
                  //   disabled={subscriptionClicked == ""}
                  onClick={
                    acceptPlan
                  }
                  disabled={enablePlanData}
                >Next</button>
              </Grid>
              <Grid item xs={8} sm={6} style={{ width: "100%" }} onClick={() => history.goBack()}>
                <button className="acc-back-btn">

                  Back
                </button>
              </Grid>
            </Grid>
          </>

          )}
        </Grid>
    </Grid>
  );
};

export default ForCreative;
// Customizable Area End
