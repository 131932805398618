// Customizable Area Start
import { Button, Col, Input, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { Dialog, Grid } from '@material-ui/core';
import axios from 'axios';
import { nxzloader,defaultProfile } from '../../dashboard/src/assets';
import { circleBlue, circleOption } from '../../../components/src/CustomMessage/MessageLinkPopup/assets';
export const configJSON = require("../../../framework/src/config.js");

interface Props {
  showMsgModal: boolean;
  closeMsgModal: any;
  closeModalDialog: any;
}


export default function ListofNewChat({ showMsgModal, closeMsgModal, closeModalDialog }: Props) {
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [userList, setUserList] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function haldleSearchInput(e: any) {
    setSearchTxt(e.target.value)
  }



  
  useEffect(() => {
    _getUserList()
  }, [searchTxt])

  useEffect(()=>{
    setSearchTxt("")
    setSelectedUser(null)
  },[showMsgModal])




  const token: any = localStorage.getItem("token")

  const _getUserList = async () => {
    setIsLoading(true)
    axios.get(
      `${configJSON.baseURL}/bx_block_messages/messages/search_by_user?search=` + searchTxt,
      {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      }
    )
      .then((responseJson: any) => {
        if (responseJson.data) {
          setUserList(responseJson.data?.data)
        } else {
          setUserList([])
        }
      }).finally(() => {
        setIsLoading(false)
      });
  };

  return (
    <>
      <Dialog
        open={showMsgModal}
        onClose={closeModalDialog}
        PaperProps={{ className: "dialog-Paper-message" }}
      >
        <Row justify="center" >

          <Col>
            <Grid container className="messageLink-grid">

              <Grid item className="messageLink-parent-div">
                <Grid  className="messageLink-child-div"  data-test-id="send-sms" >
                  Message Link</Grid>
                <Button
                  className="red_custom_btn_sms login_btn mt-1"
                  style={{ width: '108px', height: '33px', borderRadius: '33px', fontSize: '14px', fontWeight: 'bold' }}
                  onClick={() => {
                    if (selectedUser) {
                      let id = token
                      if (id != null) {
                          {closeMsgModal(selectedUser)}
                      }
                    }

                  }}
                >
                  Send DM
                </Button>
              </Grid>

              <Grid item  className="messageLink-inputBtn">
                <Row className="search_message_link" >
                  <Input
                    onChange={haldleSearchInput}
                    className=""
                    prefix={
                      <div style={{ color: 'white', fontWeight: 'bold' }}>To:</div>
                    }

                    style={{ width: "100%" }}
                    placeholder="Search Users"
                  />
                </Row>
              </Grid>
              {isLoading
                ? (<Row className="messageLink-loader">
                  <img src={nxzloader} className="messageLink-loadersize" />
                </Row>)
                :
                <Grid item  className="messageLink-select-user">
                  <Grid container className="messageLink-user">
                    {
                      userList.length > 0 ?
                      userList.map((item: any) => {
                        return (
                          <Grid item className="messageLink-list"
                           onClick={() => {
                            selectedUser?.id == item?.id ?
                              setSelectedUser(null)
                              :
                              setSelectedUser(item)
                          }}>
                            <Grid container className="messageLink-userid">
                              <Grid item className="messageLink-circle-grid">
                              <img src={item?.attributes?.profile_image || defaultProfile} style={{ width: '45px', height: '45px', borderRadius: "50%",border:item?.attributes?.profile_image?"none":"1px solid white"}} />
                                <div style={{ marginLeft: '10px', color: 'white',fontFamily:"Inter",letterSpacing:"0.7px" }}>{item?.attributes?.artist_name}</div>
                              </Grid>
                              {
                                item.id == selectedUser?.id ?
                                  <Grid item>
                                    <img src={circleBlue} style={{ width: '20px', height: '20px' }} />
                                  </Grid>
                                  :
                                  <Grid item>
                                    <img src={circleOption} style={{ width: '20px', height: '20px' }} />
                                  </Grid>
                              }
                            </Grid>
                          </Grid>
                        )
                      })
                      :
                      <div style={{ fontSize: "20px", fontWeight: "bold", color: "white", fontFamily: "Inter", display: "flex", justifyContent: "center" }}>
                        No Data Found
                      </div>
                    }
                  </Grid>
                </Grid>
              }
            </Grid>
          </Col>


        </Row>
      </Dialog>
    </>
  )
}
// Customizable Area End