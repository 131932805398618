// Customizable Area Start
import {  Grid, Box } from "@material-ui/core";
import React from "react";
import Buttongroup from "../Common/Buttongroup.web";
import FilterDropDown from "components/src/CustomChart/PopUps/FilterDropDown.web";
import {shareWallet,receiveWallet,cashout,blueCheck,recievedmoney} from "blocks/dashboard/src/assets";
import "./DashboardWallet.css"
import DashboardWalletcontroller from "./DashboardWalletcontroller.web";
import { jsonsinger } from "../assests";




export default class DashboardWallet extends DashboardWalletcontroller {
    constructor(props: any) {
        super(props);

    }

    // Customizable Area Start
    showButtonGroup = () => {
       
      return (
        <Grid item md={2} xs={6} lg={2} className="audio-div">
          <Buttongroup
            buttonshow={false}
            width= "184px"
            musicButton={this.state.musicButton}
            setMusicButton={() => {
              this.setVideoButtonTrue()
            }}
            setMusicButtonTrue={() => {
              
               this.setMusicButtonTrue()
            }}
            setVideoButtonTrue={()=>{
              this.setUserButtontrue()
            }} />
            
        </Grid>
      )
    
  }
    // Customizable Area End

    render() {
    const audioWallet=()=>{
        return (
          <Box className="wallet_container_wrapper">     
          <Grid item xs={12}  className= "filter-div filter_align_right audio_wallet_button_group" >
          <Grid  className="buttonfilterbox">
            

               <div >
                {this.showButtonGroup()}
               </div>
               <div style={{ position: "relative" }}>
               <FilterDropDown filterValue="Filter"
                />
               </div>
             </Grid>
           </Grid>
           <Box className="wallettop_container">
               <Box className="wallet_container_element">
                 <Box style={{display: "flex",justifyContent: "space-between"}}>
                 <span className="Music-Royalties">
                         Music Royalties
               </span>
               <span className="This-Week">
                This Week
             </span>
                 </Box>
                 <Box className="wallet_leftside_box_wrapper">
                   {Array.from({ length: 5 }).map((item, index) => <Box className="wallet_leftside_container">
                       <Box className="wallet_leftside_element">
                           <img src={jsonsinger} className="iconImg" style={{ borderRadius: '10px' }}
                              height="60px"
                              width="60px"
                             />
                  <Box className="wallet_leftside_ownerstats">
                          <span className="Swalla">
                          Swalla
                          </span>
                          <span className="Jason-Derulo">
                          Jason Derulo <img src={blueCheck} className="verfied"/>
                         </span>
                          </Box>   
                       </Box>
                       <Box className="wallet_leftside_ownerstats">
                       <span className="money">
                        $357.95
                     </span>
                  <span className="text-style-1">
                     <span className="plus">+</span>630%
                     </span>
                       </Box>
                   </Box>)}


               </Box>
               </Box>
               <Box className="rightsidecontainer">
               <Box className="wallet_rightside_container_box">
                 <Box className="revenue_right_box_wrapper" >
                 <Box className='revenue_right_box'>
              <div  className='revenue_balance' >
                 <span className="balance_text">BALANCE</span>
                 </div>
                <div>
                  <span className="revenue_amount">
                $10,3,58<span style={{fontSize:"22px"}}>.15</span>
            </span>
            </div>
               <div className="wallet_wraper">
                <div >
                <img src={shareWallet} className="shareButton" alt="name"/>
                <span className="Send">
                  Send
                 </span>
                </div>
                <div>
                <img src={receiveWallet} className="shareButton"/>
                <span className="Send ">
                Receive
                 </span>
                </div>
                <div>
                <img src={cashout} className="shareButton"/>
                <span className="Send">
                 Cashout
                 </span>
                </div>
              </div>
          </Box>
                 </Box>

               </Box>
               </Box>
           </Box>
         </Box>
        )
      }

    const videoWallet=()=>{
        return (
          <Box className="wallet_container_wrapper">  

          <Grid  className= "filter-div filter_align_right audio_wallet_button_group"  item xs={12}   >
          <Grid  className="buttonfilterbox">
              <div >

                 {this.showButtonGroup()}
               </div>

               <div style={{ position: "relative" }}>

               <FilterDropDown filterValue="Filter"
                 />
               </div>
             </Grid>
           </Grid>
                        <Box className="wallettop_container">
                               <Box className="wallet_container_element">
                 <Box style={{display: "flex",justifyContent: "space-between"}}>
                 <span className="Music-Royalties">
                           Video Royalties
               </span>
             <span className="This-Week">
                          This Week
             </span>
                 </Box>
                 <Box  className="wallet_leftside_box_wrapper">
                   {Array.from({ length: 25 }).map((item, index) => <Box className="wallet_leftside_container">
                       <Box className="wallet_leftside_element">
                           <img src={jsonsinger} className="iconImg" style={{ borderRadius: '11px' }}
                              width="100px"  
                               height="60px"
                                 
                           />

                           <Box className="wallet_leftside_ownerstats">
                           <span className="Swalla">
                           Swalla
                          </span>
                          <span className="Jason-Derulo">
                          Jason Derulo   <img src={blueCheck} className="verfied"/>
                         </span>
                          </Box>   
                       </Box>
                       <Box className="wallet_leftside_ownerstats">
                       <span className="money">
                       $15,000
                     </span>
                  <span className="text-style-1"  >
                            <span  className="plus">+</span>60%
                     </span> 
                       </Box>
                   </Box>)}
               </Box>
               </Box>
               <Box  className="rightsidecontainer">
             
               <Box  className="wallet_rightside_container_box">
             
               <Box className="revenue_right_box_wrapper" >
                 <Box className='revenue_right_box'>


              <div  className='revenue_balance'>
                 <span className="balance_text">BALANCE</span>
                 </div>
                <div>

                  <span className="revenue_amount">
               
                  $10,3,58<span style={{fontSize:"22px"}}>.15</span>
            </span>
            </div>

               <div className="wallet_wraper">
                <div >
                <img src={shareWallet} className="shareButton"/>
                <span className="Send">
                  Send

                 </span>
                </div>
                <div >

                <img src={receiveWallet} className="shareButton"/>
                <span className="Send">
                Receive
                 </span>

                </div>
                <div >
                  
                <img src={cashout} className="shareButton"/>
                <span className="Send">
                 Cashout
                 </span>
                </div>
              </div>
          </Box>
                 </Box>
               </Box>
               </Box>  
           </Box> 
         </Box>
        )
      }

    const userWallet=()=>{
      return (
         <Box className="wallet_container_wrapper">
           


        <Grid  item xs={12}  className= "audio_wallet_button_group filter-div filter_align_right" >
            <Grid   className="buttonfilterbox">
              <div >
                 {this.showButtonGroup()}
               </div>
               
               <div style={{  position: "relative" }}>
               <FilterDropDown filterValue="Filter"
                 />
               </div>
             </Grid>
           </Grid>
               <Box className="wallettop_container">
               <Box className="wallet_container_element">
                 <Box style={{display: "flex",justifyContent: "space-between"}}>
                 <span className="Music-Royalties">
                    Transactions
               </span>
             <span className="This-Week">
                This Week
             </span>
                 </Box>
                 <Box className="wallet_leftside_box_wrapper">
                   {Array.from({ length: 20 }).map((item, index) => <Box className="wallet_leftside_container" style={{padding:"0px 14px 0px 10px"}}>
                       <Box className="wallet_leftside_element" style={{gap:"0%"}}>
                         
                           <img src={jsonsinger} className="singer_icon" 
                           />
                          <img src={recievedmoney} style={{position:"relative",right:"42px"}}/>
                           <Box  className="wallet_leftside_ownerstats">
                           <span className="singer_title">
                           Metro Bommbin <img src={blueCheck} className="verfied"/>
                          </span>
                          <span className="Jason-Derulo">
                          For: Studio Time
                         </span>
                           </Box>      
                       </Box>
                       <Box className="wallet_leftside_ownerstats">
                       <span className="money ok">
                        $357.95
                     </span>
                  <span>
                     <span className="received">Recieved</span>
                     </span>
                       </Box>
                   </Box>)}


               </Box>
               </Box>
               <Box  className="rightsidecontainer">
               <Box  className="wallet_rightside_container_box">
               <Box className="revenue_right_box_wrapper" >
                 <Box className='revenue_right_box'>
              <div  className='revenue_balance'>
                 <span className="balance_text">BALANCE</span>
                 </div>
                <div>
                  <span className="revenue_amount">
                  $10,3,58<span style={{fontSize:"22px"}}>.15</span>
            </span>
            </div>
               <div  className="wallet_wraper">
                <div >
                <img src={shareWallet} className="shareButton"/>
                <span className="Send o">
                  Send
                 </span>
                </div>
                <div >
                <img src={receiveWallet} className="shareButton"/>
                <span className="Send o">
                  Receive
                 </span>
                </div>
                <div >
                <img src={cashout} className="shareButton"/>
                <span className="Send">
                 Cashout
                 </span>
                </div>
              </div>
          </Box>


                 </Box>
               </Box>
               </Box>  
           </Box>
         </Box>
        )
      }
      const compRender = () => {
        switch (this.state.musicButton) {
            case 1:
                return audioWallet()
            case 2:
                return videoWallet()
            default:
                return userWallet()
        }
    }
        return (
            //Merge Engine DefaultContainer
         compRender()

            //Merge Engine End DefaultContainer
        );
    }
}
// Customizable Area End