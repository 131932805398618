// Customizable Area Start
import React from 'react'
import {
    Grid,
    Dialog,
} from "@material-ui/core";
import { Row, Col} from "antd";
import './delete_playlist_popup.css'
import DeletePlaylistPopupController from './DeletePlaylistPopupController.web';


export class DeletePlaylistPopup extends DeletePlaylistPopupController {

  render() {    
    return (
     <Dialog 
     open={this.props.open}
     onClose={this.props.handleClose}
     PaperProps = {{className: "dialog-Paper-delete-popup"}}
     >
         <Row justify="center" >
                <Col >
                  <Grid container  style={{justifyContent: 'center', padding: '10px', flexDirection: 'column', alignItems: 'center'}}>
                    <Grid item>
                       <Grid style={{fontSize: '22px', fontWeight: 'bold', color: 'white', width: '100%', display: 'flex', alignItems: 'center',justifyContent: 'center'}} > Are you sure you want to</Grid>
                      <Grid style={{fontSize: '22px', fontWeight: 'bold',  color: 'white', width: '100%', display: 'flex', alignItems: 'center',justifyContent: 'center'}} >delete this playlist?</Grid>
                  </Grid>
                  <Grid item>
                      <button
                        className="red_custom_btn_sms  mt-1"
                        type='button'
                        onClick={this.deletePlaylist}
                      >
                        YES
                      </button>
                      </Grid >
                      <Grid item>
                        <button
                            className="grey_custom_btn mt-1"
                            type='button'
                            onClick={this.props.handleClose}
                        >
                            NO
                        </button>
                      </Grid>
              </Grid>
            </Col>
         </Row>
     </Dialog>
    )
  }
}

export default DeletePlaylistPopup
// Customizable Area End