// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { HISTORY } from "../../../../../components/src/common";
import { getStorageData, setStorageData } from "../../../../../framework/src/Utilities";
import { openNotification } from "components/src/Notification.web";

export const configJSON = require("../../config.js");
// Customizable Area End

// Customizable Area Start
const tokenStr: any = localStorage.getItem("token");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: any;
    loading: boolean;
    selectedUser: any;
    dealAmount: number;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class DealAmountController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            loading: false,
            token: tokenStr,
            selectedUser: {
                id: 1,
                name: 'yfrs',
                type: 'Producer',
                equity: 60
            },
            dealAmount: 0,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
  
    onAmountChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            dealAmount:Number(e.target.value)
        })
    }

    onNextClick = async () => {

        if (this.state.dealAmount <= 0 ) {
            openNotification("enter amount", 'error')
        }
        else {
            await setStorageData('dealAmount', this.state.dealAmount)
            HISTORY.push({
                pathname: `/deal/select-deal-term`,
                state: {}
            });
        }
       
    }

    onBackClick = () => {
        HISTORY.goBack();
    }
    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const user = await getStorageData('selectedUser')
        if (user) {
            this.setState({
                selectedUser: user,
            })
        } else {
            HISTORY.push('/deal')
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End
}
