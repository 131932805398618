// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { HISTORY } from "../../../../../components/src/common";
import { getStorageData } from "../../../../../framework/src/Utilities";
import { openNotification } from "components/src/Notification.web";

export const configJSON = require("../../config.js");
// Customizable Area End

// Customizable Area Start
const tokenStr: any = localStorage.getItem("token");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: any;
    loading: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ImportentNoticeController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    sendotpId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            loading: false,
            token: tokenStr,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    onNextClick = () => {
        this.sendotp()

    }

    onBackClick = () => {
        HISTORY.goBack();
    }
    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const selectedUser = await getStorageData('selectedUser');
        if (!selectedUser) {
            HISTORY.push('/deal')
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);


        
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
           
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.sendotpId) {
                if (responseJson.message === 'OTP sent successfully') {
                    HISTORY.push({
                        pathname: `/deal/verify`,
                        state: {}
                    });
                }
                else{


                    openNotification(responseJson?.message, 'Error')
                }

            }



        }
        // Customizable Area End
    }

    // Customizable Area Start
    sendotp = async () => {
        // this.setState({loading: true})


        const header = {
            "Content-Type": configJSON.dashboarContentType,
            'token': localStorage.getItem('token')
        };



        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.sendotpId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "https://nxzsound10-66173-ruby.b66173.dev.eastus.az.svc.builder.cafe/account_block/resend_otp"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );



        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    // Customizable Area End
}
