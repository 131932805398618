// Customizable Area Start
import React from "react";
import "../Creatives/ComposeBlock.web.css";
import { play1, repost1, following1, follower1, messageProfile, keyIcon } from "blocks/dashboard/src/assets";
import { Typography, Grid, Button, ButtonGroup } from "@material-ui/core";
import LabelController from './LabelController.web'

export const configJSON = require("../../../../framework/src/config.js");
export default class LabelProfileUserDiv extends LabelController {
//    handleFollowState=()=>{
//     let currentFollowCheck = ""
//     if (this.state.unfollowState) {
//         currentFollowCheck = "Unfollow"
//     } else {
//         currentFollowCheck = "follow"
//     }
//     return currentFollowCheck

// }

//  handlePopupVisible=()=>{
//   if (this.state.unfollowState) {
//       this.setState({ visible: true })
//   } else {
//       this.setState({ loadingFollow: true })
//       this.FolloweUser(this.state.creativeById?.id)
//   }
// }
  render() {
    const {creativeById} = this.state;
    const token: any = localStorage.getItem("token")
    const user_id: any = localStorage.getItem("loggedIn_userId");
    const id_check = user_id


    if (Object.keys(creativeById).length > 0) {
      return (

        <Grid container xs={8} sm={4} className={creativeById.id !== id_check ? "icons-group listener-count-grid listener-public" : " icons-group listener-count-grid label-private"}>
           <Grid container item xs={12} className="justify-center" style={{padding: '10px'}} >
            <Grid item xs={6} sm={6} md={6} lg={5} className="div-group">
              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={play1} className="listener-icons-img" style={{ cursor: "not-allowed" }} />


                <Typography className="icons-text"><Typography className="icons-text" style={{ fontFamily: "karla" }}>

                  {creativeById?.attributes?.play_count||0}
                </Typography> </Typography>


              </Grid>
              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={repost1} className="listener-icons-img" style={{ cursor: "not-allowed" }} />

                <Typography className="icons-text"><Typography className="icons-text">
                  {"0"}
                </Typography></Typography>
              </Grid>

            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={5} className="div-group">
              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={following1} className="listener-icons-img" style={{ cursor: "pointer" }} onClick={() => {} } />
                {/* <ProfileFollowing open={openPopupFollowing}
                 handleClose={() => this.setState({ openPopupFollowing: false })}
                  data={allFollowerData} 
                  loading={loading}
                  followUserByID={this.followUserByID}
                  unfollowUserById={this.unfollowUserById}
                  creativeById={creativeById}

                  value="followers" /> */}

                <Typography className="icons-text"><Typography className="icons-text">
                  {"0"}
                </Typography> </Typography>

              </Grid>

              <Grid item xs={6} className="listener-profile-play-grid">
                <img src={follower1} className="listener-icons-img" style={{ cursor: "pointer" }} onClick={() => {}} />
                {/* <ProfileFollowing open={openPopupFollowers} handleClose={() => this.setState({ openPopupFollowers: false })} data={followingData} loading={loading}
                  followUserByID={this.followUserByID}
                  unfollowUserById={this.unfollowUserById}
                  creativeById={creativeById}

                  value="following" /> */}
                {Object.keys(creativeById).length ?
                  <Typography className="icons-text">
                    <Typography className="icons-text" style={{ fontFamily: "karla" }}>
                      {creativeById?.attributes?.following_count || "0"}
                    </Typography></Typography>
                  :
                  <Typography className="icons-text">
                    <Typography className="icons-text" style={{ fontFamily: "karla" }}>
                        {"0"}
                    </Typography>
                  </Typography>
                }
              </Grid>

            </Grid>
          </Grid>
          {token &&  (
             <Grid container item xs={12} sm={8} lg={6} className="buttongroup-grid label-follow-handle">
              {/* <ButtonGroup aria-label="outlined primary button group" className="btn-group-style" >
                <Button className="one-button" >
                  <img src={messageProfile} className="icons" style={{ fontFamily: "karla" }} />
                </Button>
                <Button className="listener-follow-btn" id="followBtn" 
                  style={{ fontFamily: "karla" }}
                  > 
                  {/* {this.state.loadingFollow ?
                    <Spin indicator={antIcon} /> : this.handleFollowState()
                  } *
                  </Button>
              </ButtonGroup> */}
              <ButtonGroup aria-label="outlined primary button group" className="btn-group-style" >
                  <Button className="one-button" style={{border:"1px solid red"}} >
                      <img src={messageProfile} className="icons" style={{ fontFamily: "karla" }} />
                  </Button>
                  <Button className="follow-button" id="followBtn" 
                      style={{ fontFamily: "karla" }}
                      >
                        {'Follow'}
                      </Button>

                  <Button className="third-button" disabled={true} style={{ fontFamily: "karla" }}>
                      <img src={keyIcon} className="icons" style={{ fontFamily: "karla" }} />
                  </Button>
              </ButtonGroup>
            </Grid>
          )}
           <div>
                {/* <Modal
                  title=""
                  visible={this.state.visible}
                  footer={null}
                  onCancel={() => {
                    this.setState({ visible: false })
                  }}
                  style={{ textAlign: 'center' }}
                >
                  <h4 style={{ color: 'white' }}>Do you really want to unfollow?</h4>
                  <Button style={{ color: 'white', background: 'red' }} onClick={() => {
                    this.setState({ visible: false })
                  }}>No</Button> &nbsp;&nbsp;
                  <Button style={{ color: 'white', background: 'green' }} onClick={() => {
                    if (creativeById?.id) {
                      this.setState({ loadingFollow: true, visible: false })
                      this.UnFolloweUser(creativeById?.id)
                    }
                  }}>Yes</Button>
                </Modal> */}
              </div>
        </Grid>
      );
    } else {
      return null
    }
  }
}
// Customizable Area End