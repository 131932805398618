// Customizable Area Start
import React from "react";
import DealController, {
    Props,
} from "./DealController.web";
import "./Deal.web.css";
import AsideLeft from "../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import { Layout } from "antd";
import { Hidden,Typography,Grid, Button} from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import DealHeader from "./DealHeader.web";

import Loader from "./Loader.web";
import TrendingList from "./TrendingList.web"
import HomeHeader from "../../../../components/src/CustomChart/PopUps/HomeHeader.web";

import {  MusicCover} from '../assets'

// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class Deal extends DealController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
   
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading, topTwentyCreatives, topTwentyLabels ,allDealtype} = this.state;

        return (
            <>
                <Layout className="MainLayoutContainer">
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        className="MainContent"
                    >
                        <HomeHeader />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="DealContent">
                                <DealHeader />
                                <Grid container>
            <Grid item xs={12}>
                <Typography className='DealTypeTitle'>Select Deal Type</Typography>
            </Grid>
            <Grid container spacing={1} >
            <Grid container item xs={12} spacing={3} className='DealTypeCardGrid' >
            {allDealtype?.map((item,i)=>
                <Grid className="DealTypeCard">
                    <div className='TextDiv'>
                        <Typography className='Title'>{item.name}</Typography>
                        <Typography className='SplitText'>SPLIT</Typography>
                        <Typography className='Description'>Buy ownership license in this <b>SONG</b> and monetize as it makes royalties</Typography>
                    </div>
                    <div className='MediaDiv'>
                        <img src={MusicCover} className='Image' />
                    </div>
                    <div className='OfferButtonDiv'>
                        <Button data-test-id="MakeAudioOfferBtn1" className='Button'  onClick={()=>{this.onClickAudioOffer(item.id,item.name)}}>
                            Make An Offer
                        </Button>
                    </div>
                </Grid>
                
          )}
              </Grid> 
            </Grid>
        </Grid>
                                <Grid container className="TLContainer">
                                    <Grid item xs={12} md={6}>
                                        <TrendingList title={"Trending 20 Creatives"} list={topTwentyCreatives} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TrendingList title={"Trending 20 Labels"} list={topTwentyLabels} />
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Content>
                </Layout>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default Deal;
// Customizable Area End