// Customizable Area Start
import React from 'react';
import { Typography, Grid, Box, Avatar, createStyles, withStyles, Theme } from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckIcon from '@material-ui/icons/Check';
// Customizable Area End


// Customizable Area Start
export interface Props {
    title: string;
    list: any[];
}

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#212121',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#3959f9',
        },
    }),
)(LinearProgress);
// Customizable Area End

// Customizable Area Start
export const TrendingList = (props: Props) => {
    const { list } = props;
    return (
        <Grid container className="TrendingListContainer">
            <Grid item xs={12} className='TLTitleGrid'>
                <Typography className='Title' data-test-id="ListTitleText">{props.title}</Typography>
                <Typography className='RightText'>This week</Typography>
            </Grid>
            {list.map((item: any, index:number) => {
                return (
                    <Grid key={item.id} item xs={12} className='TLListGrid'>
                        <Box><Typography className='IndexNumber'>{index+1}</Typography></Box>
                        <Box>
                            <Avatar src={item.avatar} className='Image' />
                        </Box>
                        <Box className='detailBox'>
                            <Box className='FlexBetween'>
                                <Typography className='Title FS14'>{item.name}<CheckIcon htmlColor='#ffffff' className='CheckIcon' /></Typography>
                                <Typography className='Title MR48 FS16'>{item.funded}</Typography>
                            </Box>
                            <Box className='FlexBetween'>
                                <Typography className='SubTitle FS12'>{item.type}</Typography>
                                <Typography className='SubTitle MR48 FS14'>{item.deals_accepted} Deals</Typography>
                            </Box>
                            <Box className='ProgressBox'>
                                <BorderLinearProgress variant="determinate" value={item.popularity} />
                            </Box>
                        </Box>
                    </Grid>
                )
            })}

        </Grid>
    );
}
// Customizable Area End


// Customizable Area Start
export default TrendingList;
// Customizable Area End
