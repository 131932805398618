// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { openNotification } from "../../../../components/src/Notification.web";
import { AudioContextApi } from "../AudioContext/AudioContext.web";
import axios from "axios";
import { HISTORY } from "../../../../components/src/common";
import { readMessageMedia } from "blocks/Sms/src/ChatUtils";


export const configJSON = require("../config.js");
const tokenStr: any = localStorage.getItem("token");


export interface Props {
  navigation: any;
  id: string;
  setSelectedComment: (track: any) => void;
  setSelectedMedia: (track: any) => void;
  setUnreadComments: (comments: any) => void;
  setSelectedTrack: (track: any) => void;
}
interface S {
  showLoader: boolean;
  selectedPage: string;
  userType: string;
  selectedComment: any;
  selectedMedia: any;
  unreadComments: any;
  unreadReplies: any;
  selectedTrack: any;
  newComment: any;
  apiToken: any;
  selectedCommentForReply: any;
  selectedTrackMediaDetails: any;
  selectedTrackStatsDetails: any;
  commentsCount: any;
  selectedReplyCommentId: any;
  anchorEl: any;
  particularUserDataRes: any;
  otherUserId: string;
  messageCount: number;
  tracks: any;
  tempTracks: any;
  newUserAdded: any;
  loading: any;
  selectedTab: string;
  openMessageLink: any;
  searchTerm: any;
  showMediaDetailDialog: boolean;
  showCommentChatDialog: boolean;
  searchText: string;
  listenerMsgList: any[];
  isGeneralLoading: boolean;
  isFollowersLoading: boolean;
  isListnerMsgLoading: boolean;
  generalList: any[];
  followerList: any[];
  selectedParticularList: any
  isShowMsgModal: boolean;
  isShowMsgModalMobile: boolean;
  selectedDMIndex: any;
  selectedDMId: any;
  showSmallDMView: boolean;
  showProfileDialog: boolean;
  isTrackChnage: boolean;
  generalCurrentChat:any;
  followerCurrentChat:any;
  SubsCurrentChat:any;

}
interface SS {
  id: any;
}


export default class InboxCommentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apigetCommentedTracksCallId: string = "";
  apipostSendCommentTrackCallId: string = "";
  apipostReplyOnCommentCallId: string = "";
  apigetTrackBioDetailsCallId: string = "";
  apigetTrackStatsDetailsCallId: string = "";
  apigetSelectedListnerTrackComments: string = "";
  apigetCommentRepliesDetailsCallId: string = "";
  apigetFollowUserListenerCallId: string = "";
  particularUserId: string = "";
  apigetSearchTermCommentedTracksCallId: string = "";
  apigetSelectedTrackComments: string = "";
  unFollowUser: string = ""
  IntervalID: any;
  getGeneralMsgApiCallId: any;

  commentEndRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.commentEndRef = React.createRef()
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];


    this.state = {
      showLoader: false,
      selectedPage: 'comment',
      userType: localStorage.getItem('user_type') || '',
      selectedComment: [],
      selectedMedia: '',
      unreadComments: 0,
      unreadReplies: 0,
      selectedTrack: '',
      newComment: '',
      apiToken: localStorage.getItem('token'),
      selectedCommentForReply: '',
      selectedTrackMediaDetails: '',
      selectedTrackStatsDetails: '',
      commentsCount: 0,
      selectedReplyCommentId: '',
      anchorEl: null,
      particularUserDataRes: {},
      otherUserId: "",
      messageCount: 0,
      tracks: [],
      tempTracks: [],
      newUserAdded: false,
      loading: false,
      selectedTab: 'General',
      openMessageLink: false,
      searchTerm: '',
      showMediaDetailDialog: false,
      showCommentChatDialog: false,
      searchText: "",
      listenerMsgList: [],
      isListnerMsgLoading: false,
      generalList: [],
      followerList: [],
      isGeneralLoading: false,
      isFollowersLoading: false,
      selectedParticularList: {},
      isShowMsgModal: false,
      isShowMsgModalMobile: false,
      selectedDMIndex: null,
      showSmallDMView: false,
      showProfileDialog: false,
      isTrackChnage: false,
      selectedDMId: "",
      generalCurrentChat:{},
      followerCurrentChat:{},
      SubsCurrentChat:{}




    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  switchPage = (page: string) => {
    this.setState({
      loading: true,
      selectedPage: page,
      selectedTrack: '',
      selectedCommentForReply: ''
    })
  }
  async componentDidMount() {
    super.componentDidMount();
    const token: any = localStorage.getItem('token')
    if(!token){
      HISTORY.push('/welcome')
    }
    this._getGeneralMessageList();
    this._getFollowersMessageList();
    this.IntervalID = setInterval(() => {
      const { selectedPage, userType, newUserAdded, selectedTab } = this.state;
      if (selectedPage !== "comment") {
        if (userType == "listener" && !newUserAdded) {
          this._getListenerMessageList()
        } else if (selectedTab === "General" && !newUserAdded) {
          this._getGeneralMessageList();
        } else if (selectedTab === "Followers" && !newUserAdded) {
          this._getFollowersMessageList();
        } else {
          this.setState({ loading: false });
        }


      }
    }, 3000);
  }

  async componentDidUpdate(prevProps:any,prevState:any) {

    if (this.state.generalList !== prevState.generalList || this.state.followerList !== prevState.followerList ) {
      this.setState({
      selectedParticularList: this.state.tracks.filter((item: any) => item?.attributes?.receiver_id == this.state.otherUserId || item?.attributes?.sender_id == this.state.otherUserId)
    })
    }
    
  }
  
  async componentWillUnmount() {
    clearInterval(this.IntervalID)
  }


  postNewCommentOnTracksApiSuccessCall = (responseJson: any) => {
    this.setState({ selectedComment: [responseJson?.data, ...this.state.selectedComment], newComment: '' })
  }
  handleAddNewUser = async (newUser: any) => {
    if (this.state.userType == "listener") {
      const filteredData: any = this.state.tracks.filter((item:any) => item.attributes.receiver_id == newUser.id || item.attributes.sender_id == newUser.id);
      if (filteredData.length === 0) {
        this.setState({ newUserAdded: true, tempTracks: newUser })
      } else {
        this.setState({ newUserAdded: false, tempTracks: [] })
      }

    } else {
       this._getGeneralMessageList();
       this._getFollowersMessageList();
       this.setState({selectedPage:"dm"})
       this.handleContinueAddUser(newUser)
    }

    this.handleUserId(newUser.id)
  }

  handleContinueAddUser=(newUser:any)=>{
    const filteredData: any = [...this.state.generalList, ...this.state.followerList].filter(item => item.attributes.receiver_id == newUser.id || item.attributes.sender_id == newUser.id);

    if (filteredData.length === 0) {
      if (newUser.attributes.user_type == "generals") {
        this._getGeneralMessageList();
        this.setState({ selectedTab: "General" })
      } else {
        this._getFollowersMessageList();
        this.setState({ selectedTab: "Followers" })
      }
      this.setState({ newUserAdded: true, tempTracks: newUser })

    } else {
      // new code
      if (filteredData[0]?.attributes?.userType == "general") {
        this._getGeneralMessageList();
        this.setState({ selectedTab: "General" })

      } else {
        this._getFollowersMessageList();
        this.setState({ selectedTab: "Followers" })
      }
      //end of new code
      this.setState({ newUserAdded: false, tempTracks: [] })
    }
  }

  handlePageChange = async(page: string) => {
    
    if(Object.keys(this.state.particularUserDataRes).length>0){
      if(this.state.selectedTab=="General"){
          this.setState({generalCurrentChat:this.state.particularUserDataRes})
      }else if(this.state.selectedTab=="Followers"){
        this.setState({followerCurrentChat:this.state.particularUserDataRes})
      }else if (this.state.selectedTab== "Subs"){
        this.setState({SubsCurrentChat:this.state.particularUserDataRes})
    
      }else{
        this.setState({particularUserDataRes:{}})
    
      }
    }
    this.setState({
      selectedTab: page,
      tracks: []
    })

    if (this.state.selectedPage === "dm") {
      if (this.state.userType == "listener") {
        this.setState({ loading: true })
        this._getListenerMessageList()
      } else {
       this.handleContinePageChange(page)
      }
    }
  }

  handleContinePageChange=(page:any)=>{
    if (page == "General") {
      this.setState({particularUserDataRes:this.state.generalCurrentChat,
        selectedDMIndex: this.state.generalCurrentChat.id,
        otherUserId: this.state.generalCurrentChat.id
      })
      readMessageMedia( this.state?.generalCurrentChat?.id)
      this.setState({ loading: true, isGeneralLoading: true, otherUserId: this.state.selectedDMId.includes('General') ? this.state.selectedDMId.substring(this.state.selectedDMId.lastIndexOf("/") + 1) : "" })
      
      this._getGeneralMessageList()
    } else if (page == "Followers") {
    this.setState({particularUserDataRes:this.state.followerCurrentChat ,
      selectedDMIndex: this.state.followerCurrentChat.id,
      otherUserId: this.state.followerCurrentChat.id
    })
    readMessageMedia( this.state.followerCurrentChat?.id)

      this.setState({ loading: true, otherUserId: this.state.selectedDMId.includes('Followers') ? this.state.selectedDMId.substring(this.state.selectedDMId.lastIndexOf("/") + 1) : "" })
      this._getFollowersMessageList()
    } else {
    this.setState({particularUserDataRes:this.state.SubsCurrentChat})
      this.setState({ tracks: [], loading: false, otherUserId: this.state.selectedDMId.includes('subs') ? this.state.selectedDMId.substring(this.state.selectedDMId.lastIndexOf("/") + 1) : "" })
    }
  }
 
    // Customizable Area Start
   
   
  handleMenu = (event: any) => {


    this.setState({ anchorEl: event.currentTarget });
  };


  handleClose = () => {
    this.setState({ anchorEl: null });
  };

onClearNewAddedUser=()=>{
  this.setState({
  tempTracks:[],
      newUserAdded:false
  })
}

onClearNewAddedUser1=()=>{
  console.log("onClearUser")
  this.setState({
  tempTracks:[],
      newUserAdded:false
  })
}

  handleUserId = (id: any) => {
  

    this.setState({
      otherUserId: id,
      selectedParticularList: this.state.tracks.filter((item: any) => item?.attributes?.receiver_id == id || item?.attributes?.sender_id == id)
    }, () => {
      this.getParticularUserData();
    })
    if (window.innerWidth <= 1239) {
      this.setState({
        showSmallDMView: true,
      })
    }
  }


  closeProfileMediaDM = () => {
    
    this.setState({
      otherUserId: '',
      showSmallDMView: false,
      showProfileDialog: false,
      
    })
  }


  // DM API Calls start
  searchDMs = (value: any) => {
    this.setState({ searchTerm: value.target.value })
    this.debounceLog(value.target.value)
  }


  getDmData = (selctedType: any) => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apigetCommentedTracksCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_messages/messages/listener_profile`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );




    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  _getListenerMessageList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.apiToken,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getGeneralMsgApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_messages/messages/listener_profile` + "?search=" + this.state.searchText
    );




    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  getGeneralMsgSuccessCallBack(responseJson: any) {
    if (responseJson.data.length > 0) {
        this.setState({
           tracks: responseJson.data,
          followerList: responseJson.data?.map((item: any) => ({
            ...item, attributes: {
              ...item.attributes, userType: "follower"
            }
          })),
          loading: false,
           isFollowersLoading: false,
        })
      // }
    } else {
      this.setState({ tracks: [], loading: false, isFollowersLoading: false })
    }
  }





  _getGeneralMessageList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.apiToken,
    };

    

    axios.get(`${configJSON.baseUrl}/bx_block_messages/messages/all_generals?search=${this.state.searchText}`,{
    headers: {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    }
  })
   .then((response: any) => {
          this.setState({
            generalList: response.data?.data.map((item: any) => ({
              ...item, attributes: {
                ...item.attributes, userType: "general"
              }
            })),
            tracks: response.data?.data,

            loading: false, 
            isGeneralLoading: false
          })
  }).catch((err: any) => {
      this.setState({ tracks: [], loading: false })
    })


  };


  _getFollowersMessageList = () => {
    this.setState({isFollowersLoading:true})
    const header = {
      "Content-Type": "application/json",
      token: this.state.apiToken,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getGeneralMsgApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + "/bx_block_messages/messages/all_followers_user" + "?search=" + this.state.searchText
    );




    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  doUnFollow = (userID: any) => {
    const header = { token: localStorage.getItem('token') }
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unFollowUser = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/unfollow_user?user_id=${userID}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  // DM API Calls End


  closeProfileMedia = () => {
    this.setState({
      selectedMedia: '',
      showSmallDMView: false,
      otherUserId: '',
      showProfileDialog:false
    })
  }
  sendComment = (mediaId: any) => {


  }
  selectedComentReply = (comment: any) => {
    this.setState({ selectedCommentForReply: comment })
  }






  closeCommentReply = () => {
    this.setState({ selectedCommentForReply: '' })
  }




  closeCommentChatDialog = () => {
    this.setState({
      selectedMedia: '',
      showCommentChatDialog: false,
    })
  }






  debounce = (callback: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(...args)
      }, delay)
    }
  }


  debounceLog = this.debounce((text: any) => this.getSearchkeyCommentedTracks(text), 2000);
  getSearchkeyCommentedTracks = (searchTerm: any) => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apigetSearchTermCommentedTracksCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/commented_tracks_web?upload_type=${this.state.selectedTab}&search=${searchTerm}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );




    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  searchComment = (value: any) => {
    this.setState({ searchTerm: value.target.value })
    this.debounceLog(value.target.value)
  }


  getParticularUserData = () => {


    const header = { token: localStorage.getItem('token') }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.particularUserId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_messages/messages/user_profile_dm?sender_id=${this.state.otherUserId}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getSearchTermCommentedTracksApiSuccessCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ tracks: responseJson?.data })
    } else if (responseJson?.message) {
      this.setState({ tracks: [], loading: false })
    } else {
      this.setState({ tracks: [], loading: false })
    }
  }






  addReplyToComment = (commentsList: any, reply: any) => {
    let comments = Object.assign(commentsList, [])
    for (let comment of comments) {
      if (comment.attributes.id === reply.attributes.comment_id) {
        comment.attributes.replies = [reply, ...comment.attributes.replies]
        break;
      }
    }
    return comments;
  }


  postReplyOnCommetApiSuccessCall = (responseJson: any) => {
    this.setState({ selectedComment: this.addReplyToComment(this.state.selectedComment, responseJson?.data), selectedCommentForReply: '', newComment: '' })
  }


  getTrackBioDetailsApiSuccessCall = (responseJson: any) => {
    this.setState({ selectedTrackMediaDetails: responseJson?.data })
  }


  getTrackStatsDetailsApiSuccessCall = (responseJson: any) => {
    this.setState({ selectedTrackStatsDetails: responseJson })
  }


  getSelectedListnerTrackCommentsApiSuccessCall = (responseJson: any) => {
    this.setState({ selectedComment: responseJson?.data, commentsCount: responseJson?.comments_count })
  }


  getfollowUserListnerApiSuccessCall = (responseJson: any) => {
    this.setState({selectedTrackMediaDetails : this.addStatusToState(true)})
  }

  addStatusToState = (status: any) =>{
    let mediaDetails = Object.assign(this.state.selectedTrackMediaDetails, {})
    mediaDetails.attributes.is_following = status;
    return mediaDetails
  }

  getunfollowUserListnerApiSuccessCall = (responseJson: any) => {
    this.setState({selectedTrackMediaDetails : this.addStatusToState(false)})
  }


  getCommentedTracksApiSuccessCall = (responseJson: any) => {


    if (responseJson?.data) {
      this.setState({
        messageCount: responseJson?.data.length,
        tracks: [...responseJson.data]
      })
      const messageCount = responseJson.data.filter((item: any) => !item.attributes?.read_status)
      localStorage.setItem("messageCount", messageCount.length)
    }
  }
  getselectedTrackcommentsApiSuccessCall = (responseJson: any) => {
    this.props.setSelectedComment(responseJson.data)
    this.setState({ selectedComment: responseJson.data });
  }


  getListenerMsgSuccessCallBack(responseJson: any) {
    if (responseJson.data.length > 0) {
      this.setState({ tracks: responseJson.data, isListnerMsgLoading: false, loading: false })
    } else {
      this.setState({ tracks: [], isListnerMsgLoading: false, loading: false })
    }
  }

  handleRestApiCall = (apiRequestCallId: any, responseJson: any, errorReponse: any) => {
    if (apiRequestCallId === this.apigetCommentedTracksCallId) {
      this.getCommentedTracksApiSuccessCall(responseJson)
      this.parseApiCatchErrorResponse(errorReponse);
      this.setState({ loading: false })
    }
    else if (apiRequestCallId === this.apigetFollowUserListenerCallId) {
      this.getfollowUserListnerApiSuccessCall(responseJson)
      this.parseApiCatchErrorResponse(errorReponse);
      //this.setState({loading: false})
    }
    else if (apiRequestCallId === this.unFollowUser) {
      this.getunfollowUserListnerApiSuccessCall(responseJson)
      this.parseApiCatchErrorResponse(errorReponse);
      //this.setState({loading: false})
    }
    else if (apiRequestCallId === this.getGeneralMsgApiCallId) {
      this.getGeneralMsgSuccessCallBack(responseJson)
      this.parseApiCatchErrorResponse(errorReponse);
      //this.setState({loading: false})
    } else if (apiRequestCallId === this.particularUserId) {
      if (responseJson.data.data) {
        this.setState({
          particularUserDataRes: { ...responseJson.data.data }
        })
      }

    }
  }



  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      if (apiRequestCallId === this.apipostSendCommentTrackCallId) {
        this.postNewCommentOnTracksApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
        //this.setState({loading: false})
      }
      else if (apiRequestCallId === this.apipostReplyOnCommentCallId) {
        this.postReplyOnCommetApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
        //this.setState({loading: false})
      }
      else if (apiRequestCallId === this.apigetSearchTermCommentedTracksCallId) {
        this.getSearchTermCommentedTracksApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false })
      }
      else if (apiRequestCallId === this.apigetSelectedTrackComments) {
        this.getselectedTrackcommentsApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetTrackBioDetailsCallId) {
        this.getTrackBioDetailsApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
        //this.setState({loading: false})
      }
      else if (apiRequestCallId === this.apigetTrackStatsDetailsCallId) {
        this.getTrackStatsDetailsApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
        //this.setState({loading: false})
      }
      else if (apiRequestCallId === this.apigetSelectedListnerTrackComments) {
        this.getSelectedListnerTrackCommentsApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
        //this.setState({loading: false})
      }
      this.handleRestApiCall(apiRequestCallId, responseJson, errorReponse)

    }
  }


  setSelectedComment = (track: any) => {
    if (this.state.userType === 'listener') {
      this.getListnerCommentsBySelectedTrack(track.add_track_id);
      this.getCommentReplies(track.comment_id)
      this.setState({ selectedReplyCommentId: track.comment_id })
    } else {
      this.setState({ selectedComment: track });
    }
  }




  setSelectedMedia = (track: any) => {
    if (this.state.userType === 'listener') {
      this.setState({ selectedMedia: track });
      this.getTrackMediaDetails(track)
    } else {
      this.setState({ selectedMedia: track });
      this.getTrackMediaDetails(track)
      this.getTrackStatsDetails(track);
    }


  }
  getSelectedTrackComments = (track: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apigetSelectedTrackComments = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/show_comment?add_track_id=${track.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );




    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  viewTrackDetail = (track: any) => {
    this.props.setSelectedMedia(track)
    this.props.setSelectedTrack(track)
    this.setState({ selectedTrack: track })
    this.getTrackMediaDetails(track)
    this.getTrackStatsDetails(track)
    this.getSelectedTrackComments(track)
    if (window.innerWidth <= 1239) {
      this.setState({
        selectedMedia: track,
        showMediaDetailDialog: true,
      })
    }
  }


  setFollowUser = (userId: any) => {
    this.followUserListnerFlow(userId)
  }


  setOnChangeNewComment = (value: any) => {
      this.setState({ newComment: value });
  }


  sendNewComment = (value: any) => {
    if (this.state.selectedCommentForReply == '') {
      this.postNewCommentOnTracks()
      const element = document.getElementById('scroll-new-comment')
        if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest'});
        }
    } else {
      const element = document.getElementById(`comment_id_${this.state.selectedCommentForReply.id}`)
        if (element) {
        element.scrollIntoView({ behavior: 'smooth' , block: 'nearest'});
        }
      this.postReplyOnComment()
      
    }
  }


  viewUserDetail = (data: any) => {
    if (window.innerWidth <= 1239) {
      this.setState({
        showProfileDialog: true
      })
    }
  }
  postReplyOnComment = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };


    const data = {


      description: this.state.newComment,
      comment_id: this.state.selectedCommentForReply.id


    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apipostReplyOnCommentCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/replies`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }




  postNewCommentOnTracks = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };


    let data = {}


    if (this.state.userType === 'listener') {
      data = {
        description: this.state.newComment,
        add_track_id: this.state.selectedTrack.add_track_id
      };
    }
    else {
      data = {
        description: this.state.newComment,
        add_track_id: this.state.selectedTrack.id
      };
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apipostSendCommentTrackCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/comments?description=${this.state.newComment}&add_track_id=${this.state.userType === 'listener' ? this.state.selectedTrack.add_track_id : this.state.selectedTrack.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);


  }


  followUserListnerFlow = (userId: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apigetFollowUserListenerCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/account_block/follow_user?user_id=${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );




    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getCommentReplies = (CommentId: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apigetCommentRepliesDetailsCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/replies?comment_id=${CommentId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );




    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getTrackMediaDetails = (track: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apigetTrackBioDetailsCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_audiomusic/expan_track?id=${this.state.userType === 'listener' ? track.add_track_id : track.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );




    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getTrackStatsDetails = (track: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apigetTrackStatsDetailsCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_messages/messages/inbox_state?id=${track.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );




    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  getListnerCommentsBySelectedTrack = (trackID: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };




    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apigetSelectedListnerTrackComments = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/show_comment?add_track_id=${trackID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );




    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


}
InboxCommentsController.contextType = AudioContextApi;
// Customizable Area End