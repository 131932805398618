// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import { defaultProfile } from "../../../blocks/dashboard/src/assets";
import axios from "axios";
import { useHistory } from "react-router-dom";
const urlConfig = require("../../../framework/src/config");
const baseUrl = urlConfig.baseURL;

export const VideosSplitProgress = ({ userSplit }: any) => {

  const token: any = localStorage.getItem("token");
  const history = useHistory();


  const handleUserProfile = async (artist_name: any) => {

    await axios
      .get(
        `${baseUrl}/account_block/show_all_creatives_data`,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            token: token,
          },
        }
      ).then((res) => {
        console.log(res, artist_name);
        res.data.data.forEach((item: any) => {
          if (item.attributes.user_name == artist_name) {
            console.log(item.attributes.user_name, item.id);
            history.push(`/creatives/beats?creative_id=${item.id}`)
          }
        }, [])
      }).catch((error) => {
        console.log(error)
      })
  }


  return (
    <>
      <Grid
        item
        xs={12}
        className="splitlist_height"
      >
        {userSplit.map(function (item: any, i: any) {
          if (item?.attributes?.percentage > 0) {

            return (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: "10px" }}
                >
                  <Grid item xs={1}>
                    <img
                      alt="Remy Sharp"
                      src={item?.attributes?.artist_image || defaultProfile}
                      style={{ width: "42px", height: "42px", borderRadius: "60px", border: "none", cursor: "pointer" }}
                      onClick={() => handleUserProfile(item?.attributes?.artist_name)}
                    />
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    item
                    xs={8}
                    style={{ paddingLeft: "30px" }}
                  >
                    <div onClick={() => handleUserProfile(item?.attributes?.artist_name)}>
                      <span
                        className="display_chart_font"
                        style={{ marginRight: "5px", cursor: "pointer" }}
                      >
                        {item?.attributes?.artist_name || "unknown"}
                      </span>
                    </div>
                    <span className="split_category_font">{item?.attributes?.user_track_role?.name || "NA"}</span>

                  </Grid>

                  <Grid
                    container
                    item
                    xs={3}
                    justifyContent="flex-end"
                    style={{ paddingRight: "10px" }}
                  >
                    <span className="display_chart_font">
                      {item?.attributes?.percentage || 0}%
                    </span>
                  </Grid>
                </Grid>
              </>
            );
          }
        })}
      </Grid>
    </>
  );
};
// Customizable Area End