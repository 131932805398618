// Customizable Area Start
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Creatives/Home.web.css";
import {  withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Box, AppBar, Tabs, Tab } from "@material-ui/core";
import '../Common/Activity-new/Activity.web.css'
import ActivitySelfView from "../Common/Activity-new/ActivitySelfView.web";
import ActivityPublicView from "../Common/Activity-new/ActivityPublicView.web";
import FilterDropDown from "components/src/CustomChart/PopUps/FilterDropDown.web";
import ButtonGroupWeb from "components/src/CustomChart/PopUps/ButtonGroup.web";
export const configJSON = require("../../../../framework/src/config.js");
import LabelController from './LabelController.web'
import { LabelGraph } from "./LabelGraph.web";
import { LabelStats } from './LabelStats.web';
import { TopMobileCreatives } from './LabelTopCreativesMobile.web';
import { LabelTopAudioPlaylistMobile } from './LabelTopAudioPlaylistMobile.web'
import './label.web.css'
import { TopDesktopCreatives } from './LabelCreativesDesktop.web'
import  LabelTopSongs  from './LabelTopSongs.web'

interface TabPanelProps { children?: React.ReactNode; index?: any; value: any; beats?: any; isPlaylist?:any}

function getClassName(isPlaylist:any, beats:any) {
    if (isPlaylist && beats > 0) {
      return 'mostly-customized-scrollbar tabpanel-scroll listener-tab-parent-height-audio';
    } else if (beats === 0) {
      return 'mostly-customized-scrollbar tabpanel-scroll listener-tab-parent-without-data';
    } else {
      return 'mostly-customized-scrollbar tabpanel-scroll listener-tab-parent-height';
    }
  }

function TabPanel(props: TabPanelProps) {
    const { children, value, index, beats,isPlaylist, ...other } = props;
   return (
     <div
       role="tabpanel"
       hidden={value !== index}
       id={`simple-tabpanel-${index}`}
       aria-labelledby={`simple-tab-${index}`}
       {...other}
       className={getClassName(isPlaylist, beats)}
     >
       {value === index && (
         <Box p={3} style={{ height: "100%", overflowX: "clip", position: "relative" }}>
           {children}
         </Box>
       )}
     </div>
   );
  }
  const token: any = localStorage.getItem("token")
  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
  interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  }
  
  const StyledTabs = withStyles({
    indicator: {
      display: 'none',
  
  
    },
  })((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
  interface StyledTabProps {
    label: string;
  }
  
  const StyledTab = withStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: 'none',
        color: '#fff',
        borderRadius: "35px",
        backgroundColor: "#212121",
        padding: "3px 5px ",
        margin: "0px 2px ",
        '&:focus': {
          // opacity: 1,
          backgroundColor: 'red',
        },
      },
    }),
  )((props: StyledTabProps) => <Tab disableRipple {...props} />);

 export const ChartAndStats = () =>{
   return (
    <Grid container >
      <Grid item xs={12} sm={6} md={7} style={{height: '100%'}}>
        <LabelGraph />
      </Grid>
      <Grid item xs={12} sm={6} md={5} className="custom-grid-item">
        <LabelStats />
      </Grid>
    </Grid>
   )
 }
  
  
export default class LabelTabItems extends LabelController {
    showButtonGroup = () => {
        if (this.state.labelValue === 2) {
          return (
            <Grid item md={2} xs={6} lg={2} className="audio-div">
              <ButtonGroupWeb
                musicButton={this.state.musicButton}
                setMusicButtonTrue={() => {
                  this.setState({ musicButton: false })
                }}
                setMusicButton={() => {
                  this.setState({ musicButton: true })
                }} />
            </Grid>
          )
        }
      }
    render() {

         const { creativeById, beatList, labeltabName, get_All_Activity_data, get_notification, labelValue }: any = this.state;
        const currentUser = localStorage.getItem('temp_id') ? localStorage.getItem('temp_id') : localStorage.getItem('user_id')
        const { isPlaylist } = this.context;

        const creativeLoop: any = [1,2,3,4,5]
        return (
    
    
          <div style={{height:"100%"}}>
          <AppBar position="static" 
                   className="listener-header-height" >
              <Grid container xs={12} style={{ display: 'flex' }} >
                <Grid item xs={12} sm={8} lg={this.state.labelValue == 2 ? 9 : 10} xl={this.state.labelValue == 2 ? 9 : 10} >
                  <StyledTabs
                    value={this.state.labelValue} onChange={this.checkListenerCurrentTab} aria-label="nxz-tabs"
                    // className="tabs"
                    // @ts-ignore
                    variant="scrollable"
                  >
                    {labeltabName.map((item: any) => {
                      return (
    
                        <StyledTab label={item.tabName}
                          //@ts-ignore
                          className={this.state.labelValue === item.value ? "active-tab" : "non-active-tab"}
                          {...a11yProps(item.value)} />
                      ) })}
    
                  </StyledTabs>
                </Grid>
                <Grid style={{ backgroundColor: "black" }} item xs={12}  sm={3} lg={2} className= "listener-filter-div" >
                  <Grid style={{ display: "flex", alignItems: "flex-start" }} alignItems="flex-end">
                    <div >
                      {this.showButtonGroup()}
                    </div>
                    <FilterDropDown filterValue={this.state.filterValue}
                    handleFilter={(data:any)=>this.handleFilter(data)}
                    listener={true}
                    />
                  </Grid>
    
                </Grid>
              </Grid>
            </AppBar>

           
            <TabPanel index={0} value={labelValue}
              beats={get_All_Activity_data?.length}  isPlaylist={isPlaylist}
            >
              <Grid style={{display: 'flex', flexDirection: 'column',height: '45vh'}}>
              <Grid style={{overflowY: 'auto', overflowX: 'hidden', height: '100%', flexGrow: 1}}>
                <ChartAndStats />
                <Grid style={{height: '100px'}} >
                  {/* <div style={{color: 'white', height: '100%'}}>Hello div</div> */}

                  <Grid style={{display: 'flex',justifyContent: 'space-between'}}>
                     <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '22px', fontWeight: 900}}>Top 100</Grid>
                     {/* <Grid style={{display: 'flex'}}>
                      <Grid style={{color: 'white', padding: '0px 15px',fontFamily: 'Karla', fontSize: '16px', fontWeight: 'bold'}}>24h%</Grid>
                      <Grid style={{color: 'white', fontFamily: 'Karla', fontSize: '16px', fontWeight: 'bold', marginRight: '15px'}}>7d%</Grid>
                      {
                      <Grid style={{width: '60px'}}>
                      
                     </Grid>
                     }
                     </Grid> */}
                     
                     
                  </Grid>
                  <Grid style={{margin: '5px', overflowY: 'auto', height: '30vh'}}>
                  {
                    creativeLoop.map(() => {
                      return(<div style={{margin: '5px'}}>
                      {/* <TopMobileCreatives /> */}
                      {/* <LabelTopAudioPlaylistMobile /> */}
                      <div className="hide_in_mobile">
                        <TopDesktopCreatives />
                      </div>
                      <div className="hide_in_medium_and_screen">
                        <TopMobileCreatives />
                      </div>
                      
                      </div>)
                    })
                  }
                  </Grid>
                  
                </Grid>
               </Grid>
               </Grid>
               
              
            </TabPanel>
            <TabPanel index={1} value={labelValue}
              beats={get_All_Activity_data?.length}  isPlaylist={isPlaylist}
            >
              <Grid style={{display: 'flex', flexDirection: 'column',height: '45vh'}}>
              <Grid style={{overflowY: 'auto', overflowX: 'hidden', height: '100%', flexGrow: 1}}>
                <ChartAndStats />
                <Grid style={{height: '100px'}} >
                  <Grid style={{margin: '5px', overflowY: 'auto', minHeight: '30vh', paddingTop: '11px'}}>
                  {currentUser != creativeById.id ? <ActivitySelfView get_All_Activity_data={get_All_Activity_data}
                      creativeById={creativeById} get_notification={get_notification}
                      handleReadNotification={{}}
                      />
                    :
                    <ActivityPublicView get_All_Activity_data={get_All_Activity_data} creativeById={creativeById} />
                  }
                  </Grid>
                  
                </Grid>
               </Grid>
               </Grid>
              
            </TabPanel>
            <TabPanel value={labelValue} index={2}  beats={this.state.musicButton?this.state.playlistVideo.length: beatList?.length}   isPlaylist={isPlaylist}>
            <Grid style={{display: 'flex', flexDirection: 'column',height: '45vh'}}>
              <Grid style={{overflowY: 'auto', overflowX: 'hidden', height: '100%', flexGrow: 1}}>
                <ChartAndStats />
                <Grid style={{height: '100px'}} >
                  <Grid style={{margin: '5px', overflowY: 'auto', minHeight: '30vh'}}>
                   {/* <Hidden xsDown> */}
                   <div className="hide_in_mobile">
                     <LabelTopSongs isMusicButton={this.state.musicButton}/>
                   </div>
                   {/* </Hidden> */}
                   {/* <Hidden smUp> */}
                   <div className="hide_in_medium_and_screen">
                   <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '22px', fontWeight: 900}}>Top 100</Grid>
                      {
                        creativeLoop.map(() => {
                          return(<div style={{margin: '5px'}}>
                            <LabelTopAudioPlaylistMobile isMusicButton={this.state.musicButton}/>
                        </div>)
                        })
                      }
                  </div>
                   {/* </Hidden> */}
                  </Grid>
                  
                </Grid>
               </Grid>
               </Grid>

              
            </TabPanel>
            
            {/* <LoginAlert open={openAlertPopUp} handleClose={this.handleClose} dialogText={dialogText} handleCloseOpenUpgradModal={this.handleCloseOpenUpgradModal} OpenUpgradModal={OpenUpgradModal} handleUpgradeModal={this.handleUpgradeModal} /> */}
          </div>
        );
      }
  
}
// Customizable Area End