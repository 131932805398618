
// Customizable Area Start
import React, { useState, useEffect } from "react";
import { TermsandConditions, TermsShade } from "./assets";
import "../assets/TermAndConditions.css";
import { Grid } from "@material-ui/core";
import { Row } from "antd";
import {
  useHistory,Link
} from "react-router-dom";
import axios from "axios";
const urlConfig = require("./../../../framework/src/config")
const baseUrl = urlConfig.baseURL;
import { openNotification } from "../../../components/src/Notification.web";
import { nxzloader } from "../../dashboard/src/assets";
import { nxzborderlogo } from "../../email-account-registration/src/assets";


const TermAndConditions = (props: any) => {
  let [termsData, settermsData] = useState([]);
  let [checkData, setcheckData] = useState(true);
  const token: any = localStorage.getItem("token")
  const history = useHistory();

  const [finishStatus, setfinishStatus] = useState(false);
  useEffect(() => {
    getTermsAndCondition(token)
  }, []);
  const accountType=localStorage.getItem("accountType")||localStorage.getItem("user_type")
  const acceptCondition = async () => {
    const token: any = localStorage.getItem("token")
    if (token) {
      await axios
        .put(`${baseUrl}/bx_block_profile/profile/accept_terms_and_conditions`, null, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",

            "token": token,
          },
        })
        .then((res) => {
          if (accountType == "listener") {
            history.push("/ForListener");
          } else if (accountType== "creative") {
            history.push("/ForCreative");
          } else if (accountType == "licensor") {
            history.push("/ForCreative");
          }
        })
        .catch((err) => {
          openNotification(err?.response?.data?.errors[0]?.token, "Error")
        });
    }
  };
  const [loading, setLoading] = useState(false);
  const getTermsAndCondition = async (token: any) => {
    setLoading(true);
    if (token) {
      await axios.get(`${baseUrl}/bx_block_terms_of_use/term_conditions`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        }
      })
        .then((response) => {
          settermsData(response?.data?.data)
          setcheckData(false)
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          setLoading(false);
        });
    } else {
      history.push("/TermAndConditions");
    }
  }
  

  // useEffect(() => {
  //   console.log("history", history)
  //   if (history.action == 'PUSH') {
  //     alert("sdsdasd")
  //     history.go(1);
  //   }
  // }, [history]);

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (!finishStatus) {
      alert("You cannot GO BACK")
      setfinishStatus(true)
      // your logic
      history.push("/TermAndConditions");
    }
  }



  useEffect(() => {


    window.history.pushState(null, "{}", window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // window.onpopstate = onBackOrForwardButtonEvent;
  }, []);
  return (
    <Grid container className="TermsAndConditions width_100">
      <Grid item lg={6} md={6} xs={12} className="bgImg-welcome-create" >
        <img className="image1" src={TermsandConditions} />
        <img className="image2" src={TermsShade} />
      </Grid>
      <Grid container item lg={6} md={6} xs={12} className="termsandcondition-parent">
        <Grid item xs={12} >

        {/* <Link to="/home" > */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={nxzborderlogo} alt="App Logo" className="nxzborderlogo" />
            </div>
          {/* </Link> */}
        </Grid>
        <Grid item xs={12} className="termsandcondition-head">
          <h1 className="mb_0 text_white tc_acc">Terms And Conditions</h1>
          <h4 className="text_white1 mb_0">
            Please Review and Agree to Continue
          </h4>
        </Grid>
        <Grid item xs={12} className="container">

          <div className="terms mostly-customized-scrollbar">
            {loading ? (<Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
              <img src={nxzloader} style={{ width: "5%" }} />
            </Row>) : (<>
              {termsData?.length !== 0 &&
                termsData.map((element: any, index) => {
                  return (
                    <>
                      <h3 key={index}>{element?.attributes?.title}</h3>
                      <div className="mr_2 mb_2" key={index + index} dangerouslySetInnerHTML={{ __html: element?.attributes?.description }} />
                    </>
                  )
                })
              }
            </>)}

          </div>

        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <div className="agree-btn">
            <button
              type="button"
              onClick={() => {
                acceptCondition();
              }}
              disabled={checkData}
            >
              I AGREE
            </button>
          </div>
        </Grid>


      </Grid>
    </Grid >
  );
};

export default TermAndConditions;
// Customizable Area End
