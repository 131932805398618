// Customizable Area Start
import React,{useState} from 'react'
import { Grid, Button } from "@material-ui/core";
import '../../../user-profile-basic/src/Creatives/AddTrack.web.css'
import { Row, Col, Spin, Upload ,Progress} from "antd";

import './index.web.css'
import { ScanCover, PlusIcon , CheckTick, ScanProcessedCover, PlayIcon, VideoFrame, armIcon} from '../assets';
import { openNotification } from '../../../../components/src/Notification.web';
import ScanProcessController from './ScanProcessController.web';

const renderAudioUIScreen = (audioFile: any, videoFile: any, uploadClassName: any, uploadType: any, uploadMp3: any) =>{
  return(
    <>

{
                    videoFile?
                    <Grid item style={{ alignItems: 'center',justifyContent: 'center'}}>     
                       <img src={VideoFrame} style={{height: '82%', width: '100%'}}/>  
                   </Grid>
                    :

                  <Grid item className={`${uploadType == 'audio'? 'Frame-135-audio': 'Frame-135'} justify-audio height-width margin-left-main `} style={{justifyContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        
                  <Grid  className={uploadClassName}  style={{borderTopLeftRadius: '25px',borderTopRightRadius: '25px',height: '82%' ,width: '102%',backgroundColor: '#141414'}}>
                  <Upload
                    name="uploadMP3"
                    
                    showUploadList={false}
                    

                    customRequest={(options: any) => {
                      uploadMp3(options, uploadType)

                    }}
                  >

                 {
                  audioFile? 
                  <Grid item style={{ alignItems: 'center',justifyContent: 'center', paddingTop: '20%'}}>     
                    <img src={PlayIcon} style={{height: '43.3px', width: '43.3px'}}/>  
                  </Grid>
                  :
                  <Grid item style={{ alignItems: 'center',justifyContent: 'center', paddingTop: '20%'}}>     
                    <img src={PlusIcon} style={{height: '43.3px', width: '43.3px'}}/>  
                  </Grid>
                  } 
                  

                  </Upload>
                  </Grid>
                  
                  <Grid item style={{borderBottomLeftRadius: '30px',borderBottomRightRadius: '30px', width: '100%', color: 'white', fontFamily: 'Inter', fontSize: '12px', fontWeight: 'bold', padding: '15px'}}>{!videoFile && <div>(Drag And Drop Or Click To Upload)</div>}</Grid>

                  </Grid>

                  }

    
    </>
  )
}

export default class ScanProcess extends ScanProcessController {
  render(){
    const {loading, scaned, audioFile, videoFile}: any = this.state;
    const {setActiveStep}: any = this.props;
    const uploadType = window.location.href.split('/')[4]

    
let uploadClassName=""
if(uploadType == 'audio'){
  uploadClassName="drag_and_drop_padding-audio"
} else if(videoFile){
  uploadClassName="drag_and_drop_padding-video-selected"
}else{
  uploadClassName="drag_and_drop_padding-video"
}

  return (
    <>
    {loading?
      <>
      <Row align="middle" justify="center"
                  className="gray1 width_100 pt_01 pb_1 " >
                  <Col span={24} style={{ textAlign: 'center' }}>
                  <h1 style={{color: 'white'}}>Loading...</h1>{' '}  <Spin size="large" /></Col>
                </Row>
      </>

      :
     
         <Grid container style={{overflowX: 'hidden',overflowY: 'auto'}}>
          
           <div className='main-div'>
            <Grid container item xs={12} md={12} lg={7} className= "flex-direction-on-medium" style={{justifyContent: 'center', marginTop: '30px',padding: '10px', display: 'flex', alignItems: 'center'}} >
                  
                  {renderAudioUIScreen(audioFile, videoFile, uploadClassName, uploadType, this.uploadMp3)} 

                  <Grid item  className='margin-left-main' style={{width: '456px', marginTop: '10px'}}>
                     <div style={{position: 'relative'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}}>Recognition</p>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}} >100%</p>
                        </div>
                        <Progress percent={scaned ? 100 : 0} showInfo={false} />
                        <img src={CheckTick}  style={{position: 'absolute', height: '26.7px', width: '26.7px', right: '0px'}}/>
                     </div>
                     <div style={{marginTop: '20px',position: 'relative'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}}>Fingerprint</p>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}} >100%</p>
                        </div>
                        <Progress percent={scaned ? 100 : 0} showInfo={false} />
                        <img src={CheckTick}  style={{position: 'absolute', height: '26.7px', width: '26.7px', right: '0px'}}/>
                     </div>
                     <div style={{marginTop: '20px',position: 'relative'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}}>Upload</p>
                          <p style={{color: 'white', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold'}} >100%</p>
                        </div>
                        <Progress percent={scaned ? 100 : 0 } showInfo={false} />
                        <img src={CheckTick}  style={{position: 'absolute', height: '26.7px', width: '26.7px', right: '0px'}}/>
                     </div>
                  </Grid>
            </Grid>

            {
              scaned?
//margin-left-name-div
              <>
              <Grid item xs={12} md={12} lg = {5} className="hide-side-image-in-responsive">
                <div className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)',position: 'relative', overflow: 'hidden'}}>
                   
                   <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414', width: '100%', height: '100%'}} src={ScanProcessedCover} />
                  
                   <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                   <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                   <div className=' name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>IP Convergence</div>
                   <p className='name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}> Calculating... </p>


                   <Button className=' buttons-font-weight' style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px'}} onClick={setActiveStep} >NEXT</Button>
                   <Button className='buttons-font-weight' style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px',boxShadow: '-50px 26px 20px 40px rgba(0,0,0,.8)', marginBottom: '10px'}}>SAVE</Button>
                   <Button variant="text" className='buttons-font-weight' style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px'}} onClick={this.backClick}>BACK</Button>

                  </div>
                  </div>
                </div>
                
              </Grid>


              <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-screens">
                <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                   <Button style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px'}}  onClick={setActiveStep} >NEXT</Button>
                   <Button style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px'}}>SAVE</Button>
                   <Button variant="text" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px'}} onClick={this.backClick}>BACK</Button>

                </div>
                
              </Grid>
            </>
            :

            <>
            <Grid item xs={12} md={12} lg = {5} className="hide-side-image-in-responsive">
              <div className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)',position: 'relative', overflow: 'hidden' }}>
                <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414', width: '100%', height: '100%'}} src={ScanCover} />
                
                <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div className='name-font-weight' style={{color: 'white' ,fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>Its Your Sound,</div>
                <p className='name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}> Protect It. <img style={{width: '40px', height: '40px', marginTop: '-15px'}} src={armIcon}></img></p>


                <Button className='buttons-font-weight' style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px',fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '10px'}} onClick={setActiveStep}>Next</Button>
                <Button className='buttons-font-weight' style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px',boxShadow: '-50px 26px 20px 40px rgba(0,0,0,.8)',fontWeight: 'bold', textTransform: 'capitalize', marginBottom: '10px'}}>Save</Button>
                <Button variant="text" className='buttons-font-weight' test-id="scanprocess_back_id" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px',fontWeight: 'bold', textTransform: 'capitalize'}} onClick={this.backClick}>Back</Button>

              </div>
              </div>
              </div>
             </Grid>

              <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-screens">
                <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                   <Button style={{backgroundColor:'red',padding: '15px 127px 15px 126px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px',fontWeight: 'bold', textTransform: 'capitalize'}}  onClick={setActiveStep}>Next</Button>
                   <Button style={{backgroundColor:'#212121',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px',fontWeight: 'bold', textTransform: 'capitalize'}}>Save</Button>
                   <Button variant="text" style={{backgroundColor:'trasparent',padding: '15px 127px 15px 126px' ,borderRadius: '30px',color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginBottom: '10px', marginLeft: '25px',fontWeight: 'bold', textTransform: 'capitalize'}} onClick={this.backClick}>Back</Button>

                </div>
                
              </Grid>
             </>
            }
            
            </div> 
         </Grid>
    
    }
    </>
   
  )
}
}
// Customizable Area End