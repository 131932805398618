// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { HISTORY } from "../../../../../components/src/common";
import { getStorageData } from "../../../../../framework/src/Utilities";

export const configJSON = require("../../config.js");
// Customizable Area End

// Customizable Area Start
const tokenStr: any = localStorage.getItem("token");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: any;
    loading: boolean;
    selectedUser: any;
    OfferPreview: number;
    type: string;
    amount: string;
    percentage: string;
    date: string;
    media:any
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class OfferPreviewController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            loading: false,
            token: tokenStr,
            amount: '',
            percentage: "",
            date: '',
            media:{},
            selectedUser: [{
                id: 1,
                name: 'yfrs',
                type: 'Producer',
                equity: 60
            }],
            OfferPreview: 0,
            type: 'user'
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    onNextClick = () => {
        HISTORY.push({
            pathname: `/deal/deal-importent-notice`,
            state: {}
        });
    }

    onBackClick = () => {
        HISTORY.goBack();
    }
    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const selectedUser = await getStorageData('selectedUser');
        const getamount = await getStorageData("dealAmount")
        const splitType = await getStorageData('splitType');
        const getprecentage = await getStorageData('selectedPercentage');
        const media = await getStorageData("media")
        console.log("medi", media)
        const term = await getStorageData('selectedTerm')
        const interval = term.duration + term.type
        if (splitType) {
            this.setState({
                type: splitType,
                selectedUser: selectedUser,
                amount: getamount,
                percentage: getprecentage,
                date: interval,
                media:media
            })
        } else {
            HISTORY.push('/deal')
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End
}
