// Customizable Area Start
import React, { useEffect, useState } from "react";
import { Grid, Dialog } from "@material-ui/core";
import { Row, Col, Input, Button } from "antd";
import "./message-link-popup.css";
import { circleBlue, circleOption } from "./assets";
import axios from "axios";
import { usePubNub } from "pubnub-react";
import { defaultProfile ,nxzloader} from "blocks/dashboard/src/assets";
import { openNotification } from "../../Notification.web";
import { getChannelName,sendMessage } from "blocks/Sms/src/ChatUtils";

export const configJSON = require("../../../../framework/src/config");

interface Props {
  id: any;
  showMsgModal: any;
  closeMsgModal: any;
  closeModalDialog: any;
  currentUserId: any;
  copiedLinked: any;
}

export default function MessageLinkPopup({
  id,
  showMsgModal,
  closeMsgModal,
  closeModalDialog,
  copiedLinked,
  currentUserId,
}: Props) {
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [userList, setUserList] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const pubnub = usePubNub();
  const user_id = localStorage.getItem("user_id");
  const ChannelName = getChannelName(String(user_id), selectedUser);
  function handleSearchInput(e: any) {
    setSearchTxt(e.target.value);
  }

  useEffect(() => {
    _getUserList();
  }, [searchTxt]);

  useEffect(() => {
    setSearchTxt("");
    setSelectedUser(null);
  }, [showMsgModal]);

  const token: any = localStorage.getItem("token");

  const _getUserList = async () => {
    setIsLoading(true);
    axios
      .get(
        `${configJSON.baseURL}/bx_block_messages/messages/search_by_user?search=` +
          searchTxt,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      )
      .then((responseJson: any) => {
        if (responseJson.data) {
          setUserList(responseJson.data?.data);
        } else {
          setUserList([]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSend = (selectedUser: any) => {
    const message = {
      content: {
        text: copiedLinked.trim(),
        sender: {
          id: selectedUser.id,
          name: selectedUser.attributes.artist_name,
          avtar: selectedUser.attributes.profile_image,
        },
      },
      type: "",
      id: crypto.getRandomValues(new Uint8Array(16)),
      // id: Math.random().toString(16).substr(2)
    };
    sendMessage(selectedUser.id, copiedLinked);
    pubnub.publish({ channel: ChannelName, message }, function(
      status: any,
      response: any
    ) {});
    openNotification("Message has been sent successfully", "Success");
    increaseSendCount();
  };

  const increaseSendCount = async () => {
    const track_id = copiedLinked.split("=")[1]
    let trackId
    if (!track_id) {
      const parts = copiedLinked.split("/");
      trackId = parts[parts.length - 1];
    }else{
      trackId= track_id
    }

    axios
      .post(
        `${configJSON.baseURL}/bx_block_audiomusic/views/update_send_count?id=${track_id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      )
      .then((responseJson: any) => {
        console.log(responseJson?.data);
      })
      .finally(() => {});
  };

  return (
    <Dialog
      open={showMsgModal}
      onClose={closeModalDialog}
      PaperProps={{ className: "dialog-Paper-message" }}
    >
      <Row justify="center">
        <Col>
          <Grid container className="messageLink-grid">
            <Grid item className="messageLink-parent-div">
              <Grid className="messageLink-child-div" data-test-id="send-sms">
                Message Link
              </Grid>
              <Button
                className="red_custom_btn_sms login_btn mt-1 messageLink-send"
                onClick={() => {
                  if (selectedUser) {
                    let id = token;
                    if (id != null) {
                      onSend(selectedUser);
                      closeMsgModal();
                    }
                  }
                }}
              >
                Send Link
              </Button>
            </Grid>

            <Grid item className="messageLink-inputBtn">
              <Row className="search_message_link">
                <Input
                  onChange={handleSearchInput}
                  className=""
                  prefix={
                    <div style={{ color: "white", fontWeight: "bold" }}>
                      To:
                    </div>
                  }
                  style={{ width: "100%" }}
                  placeholder="Search Users"
                />
              </Row>
            </Grid>
            {isLoading ? (
              <Row className="messageLink-loader">
                <img src={nxzloader} className="messageLink-loadersize" />
              </Row>
            ) : (
              <Grid item className="messageLink-select-user">
                <Grid container className="messageLink-user">
                  {userList?.length > 0 ? (
                    userList?.map((item: any) => {
                      return (
                        <Grid
                          item
                          className="messageLink-list"
                          onClick={() => {
                            selectedUser?.id == item?.id
                              ? setSelectedUser(null)
                              : setSelectedUser(item);
                          }}
                        >
                          <Grid container className="messageLink-userid">
                            <Grid item className="messageLink-circle-grid">
                              <img
                                src={
                                  item?.attributes?.profile_image ||
                                  defaultProfile
                                }
                                style={{
                                  width: "45px",
                                  height: "45px",
                                  borderRadius: "50%",
                                  border: item?.attributes?.profile_image
                                    ? "none"
                                    : "1px solid white",
                                }}
                              />
                              <div className="messageLink-default">
                                {item?.attributes?.artist_name}
                              </div>
                            </Grid>
                            {item.id == selectedUser?.id ? (
                              <Grid item>
                                <img
                                  src={circleBlue}
                                  className="messageLink-img"
                                />
                              </Grid>
                            ) : (
                              <Grid item>
                                <img
                                  src={circleOption}
                                  className="messageLink-img"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })
                  ) : (
                    <div className="messageLink-nodata">No Data Found</div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Col>
      </Row>
    </Dialog>
  );
}

// Customizable Area End
