// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { HISTORY } from "../../../../components/src/common";
import { setStorageData } from "../../../../framework/src/Utilities";
import { openNotification } from "components/src/Notification.web";

export const configJSON = require("../config.js");
// Customizable Area End

// Customizable Area Start
const tokenStr: any = localStorage.getItem("token");
const selectedsong: any = localStorage.getItem("audioplayer")
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    dashboardData: any;
    token: any;
    errorMsg: string;
    loading: boolean;
    repostName: string;
    topTwentyCreatives: any[];
    topTwentyLabels: any[];
    allDealtype: any[];
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class DealController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAlllDealApiCallId: string = ""
    getTrackidsplitId: string = "" 
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            dashboardData: [],
            errorMsg: "",
            loading: false,
            token: tokenStr,
            allDealtype: [],
            repostName: "",
            topTwentyCreatives: [{
                id: 1,
                name: 'Wakanda',
                avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQW_bBpbPd9Hcyiam2fTnspAxgsRYXEI5hGOliCBjoE&s',
                funded: '$4.7M',
                type: 'Artist',
                deals_accepted: '33',
                popularity: 80
            }, {
                id: 2,
                name: 'Pink Lavish',
                avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR49WWL1yR4_hgrlfLNBh3mIBTTXNOBv-66FYxEdx-h-g&s',
                funded: '$4.39M',
                type: 'Songwriter',
                deals_accepted: '27',
                popularity: 76
            }, {
                id: 3,
                name: 'Ria',
                avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSVu2HoiejfhVB5BCtyiOOVKwX45xg0Au3XJ5LfvrCxA&s',
                funded: '$3.95M',
                type: 'Artist',
                deals_accepted: '24',
                popularity: 65
            }, {
                id: 4,
                name: 'Justin Miles',
                avatar: '',
                funded: '$3.29M',
                type: 'Producer',
                deals_accepted: '19',
                popularity: 57
            }],
            topTwentyLabels: [
                {
                    id: 1,
                    name: 'Warner Music',
                    avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d8/Warner_Music_Group_logo_%282021%29.svg/220px-Warner_Music_Group_logo_%282021%29.svg.png',
                    funded: '$4.71M',
                    type: 'Label',
                    deals_accepted: '33',
                    popularity: 80
                }, {
                    id: 2,
                    name: 'Roc Nation',
                    avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Roc_Nation_Logo.svg/220px-Roc_Nation_Logo.svg.png',
                    funded: '$4.39M',
                    type: 'Label',
                    deals_accepted: '27',
                    popularity: 76
                }, {
                    id: 3,
                    name: 'Rca',
                    avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/RCA_Electronics_logo.svg/220px-RCA_Electronics_logo.svg.png',
                    funded: '$3.95M',
                    type: 'Label',
                    deals_accepted: '24',
                    popularity: 65
                }, {
                    id: 4,
                    name: 'Sprite',
                    avatar: '',
                    funded: '$3.29M',
                    type: 'Label',
                    deals_accepted: '19',
                    popularity: 57
                }
            ]
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getAlldealtype();
        this.setState({loading:true})
        // Customizable Area End
    }

    // Customizable Area Start
    onClickAudioOffer = async (id: any, type: any) => {
      if (type === "Music Split") {
        await setStorageData('splitType', 'audio');
        await setStorageData("dealtypeid", id)
        const storedValue: string | null = localStorage.getItem("selectedAudioPlayer");
  
        if (storedValue !== null) {
          const selectedsong: any = JSON.parse(storedValue);
          console.log("fjjfjfj", selectedsong)
          await setStorageData('media', { id: selectedsong?.playlistData?.id, name: 
          selectedsong?.playlistData?.attributes?.title, writer: 
          selectedsong?.playlistData?.attributes?.artist_name, play_count: 
          selectedsong?.playlistData?.attributes?.play_count, image: 
          selectedsong?.playlistData?.attributes?.art_work });
          HISTORY.push({
            pathname: `/deal/audio-video-split`,
            state: {}
          });
        } else{
          openNotification("select a audio to make a audio deal","Error")
        }
  
  
  
      } else if (type === "Video Split") {
        await setStorageData('splitType', 'video');
        await setStorageData("dealtypeid", id)
        const videoid: string | null = localStorage.getItem("selectedSingleVideo")
  
  
  
        if (videoid !== null) {
          const selectedsong: any = JSON.parse(videoid);
          console.log("ssssssssssss", selectedsong?.attributes?.art_work);
  
          await setStorageData('media', { id: selectedsong?.id, name: selectedsong?.attributes?.title, writer: selectedsong?.attributes?.artist_name, play_count: selectedsong?.attributes?.play_count, image: selectedsong?.attributes?.art_work });
          HISTORY.push({
            pathname: `/deal/audio-video-split`,
            state: {}
          });
        } else {
          openNotification("select a video to make a video deal","Error")
        }
      }
      else if (type === 'User Split') {
        await setStorageData('splitType', 'user');
        await setStorageData("dealtypeid", id)
        await setStorageData('media', '');
       
        HISTORY.push({
          pathname: `/deal/user-split`,
          state: {}
      });
      }
  }
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          if (apiRequestCallId === this.getAlllDealApiCallId) {
            console.log("response", responseJson)
            this.setState({ allDealtype: responseJson?.deals })
            this.setState({loading:false})
          }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getAlldealtype = async() => {
      // this.setState({loading: true})
  
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        'token': localStorage.getItem('token')
      };
  
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getAlllDealApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
              "/bx_block_subscription_plan/deal_types"
       
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
    }
    // Customizable Area End
}
