// Customizable Area Start
import React, { useState, useEffect } from "react";
import { Row, Col, Input, Spin, Upload, Avatar, message, Select } from "antd";
import "../assets/UpdateProfile.css";
import { Grid } from "@material-ui/core";
import { useHistory, } from "react-router-dom";
import { ListenerUpdateBackground, listenerimageupload, plusRed, CreativeShade } from './assets';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
import * as Yup from "yup";
import { Formik, Form } from "formik";
import axios from "axios";
import { openNotification } from "../../../components/src/Notification.web";
import { nxzloader } from "../../dashboard/src/assets";
// @ts-ignore
import { countries } from "../../../components/src/CustomChart/PopUps/CountryCode";
const urlConfig = require("../../../framework/src/config")
const baseUrl = urlConfig.baseURL;


const CreativeProfile = () => {
  const history = useHistory();
  const [genre, setGenre] = useState([])
  const [vibes, setVibes] = useState([])
  const [creative, setCreative] = useState([])
  const [profile, setProfile] = useState<any>([])
  const [city, setCity] = useState<any>()
  const [country, setCountry] = useState("");
  const token: any = localStorage.getItem("token");
  const [currentUserName, setCurrentUserName] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [profileImageURL, setProfileImageURL] = useState("");
  const [coverImgURL, setCoverImgURL] = useState("");
  const [finishStatus, setfinishStatus] = useState(false);
  const session_id: any = localStorage.getItem("session_id")

  useEffect(() => {
    if (token) {
      document.getElementById('genreIn')?.setAttribute('style', 'background-color:#17181c !important;box-shadow: none;border:none !important; border-bottom:1px solid #7a7e8a !important;width:-webkit-fill-available;color:white;resize:none;')
      document.getElementById('vibeIn')?.setAttribute('style', 'background-color:#17181c !important;box-shadow: none;border:none !important; border-bottom:1px solid #7a7e8a !important;width:-webkit-fill-available;color:white;resize:none;')
      document.getElementById('creatIn')?.setAttribute('style', 'background-color:#17181c !important;box-shadow: none;border:none !important; border-bottom:1px solid #7a7e8a !important;width:-webkit-fill-available;color:white;resize:none;')

      getGenre(token)
      getVibes(token)
      getProfile()
      getCreative(token)
    } else {
      history.push("/home");
    }
  }, []);
  const getGenre = async (token: any) => {
    await axios.get(`${baseUrl}/bx_block_music_types/genres`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response) => {
        setGenre(response?.data?.data)
      }).catch((err) => {
       
      });
  }

  const getVibes = async (token: any) => {
    await axios.get(`${baseUrl}/bx_block_music_types/vibes`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        token: token,
      }
    })
      .then((response) => {
        setVibes(response?.data?.data)
      }).catch((err) => {
        console.log(err);
      });
  }




  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (!finishStatus) {
      if (true) {
        alert("You cannot GO BACK")
        setfinishStatus(true)
        // your logic
        history.push("/CreativeProfile");
      } else {
        setfinishStatus(false)
      }
    }
  }

  useEffect(() => {
    UpdateSubsriptionPlan(session_id)
    UpdateDefaultCardUpdate()
  }, [])


  const UpdateSubsriptionPlan = async (val: any) => {
    await axios
      .put(`${baseUrl}/bx_block_subscription_plan/update_subscription?cs_id=${val}`, null, {
        headers: {
          token: token,
        }
      })
      .then((res) => {
      })
      .catch((err) => {
        
      });
  }

  const UpdateDefaultCardUpdate = async () => {
    await axios
      .get(`${baseUrl}/bx_block_subscription_plan/set_default_card`, {
        headers: {
          token: token,
        }
      })
      .then((res) => {
      })
      .catch((err) => {
        console.log("error in UpdateSubsriptionPlan", err)
      });
  }

  useEffect(() => {
      window.history.pushState(null, "{}", window.location.pathname);
      window.addEventListener('popstate', onBackButtonEvent);
      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
      };
    },[]);


  function getBase64(video: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(video);
  }
  const getProfile = async () => {
    setLoading(true);
    await axios.get(`${baseUrl}/bx_block_profile/profile`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response) => {
        setProfile(response?.data?.data)
        setCurrentUserName(response?.data?.data?.attributes?.user_name)
        setCountry(response?.data?.data?.attributes?.country)
        setCity(response?.data?.data?.attributes?.city)
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      })
  }


  const getCreative = async (token: any) => {
    await axios.get(`${baseUrl}/account_block/accounts/creative_types`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response) => {
        setCreative(response?.data?.creative_types)
      }).catch((err) => {
        console.log(err);
      });
  }

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    return isJpgOrPng;
  }

  const uploadImage = async (options: any, value: any) => {

    setLoading(true);

    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        "token": token
      },
    };
    if (value === 'remove_profile') {
      fmData.append('data[avatar]', '');
    } else {
      if (value === 'coverImage') {
        getBase64(file, (imageUrl: any) =>
        (
          setCoverImgURL(imageUrl))

        );
        fmData.append('data[cover_image]', file);
      } else {
        getBase64(file, (imageUrl: any) =>
        (
          setProfileImageURL(imageUrl))

        );
        fmData.append('data[avatar]', file);
      }
    }
    try {
      await axios.put(
        `${baseUrl}/bx_block_profile/profile`,
        fmData,
        config
      ).then((res) => {


        getProfile();
        setProfile(res?.data?.data)
        
        if (value === 'coverImage') {
          openNotification("Cover Photo Set Successfully", "Success")
        } else {
          openNotification("Profile Picture Set Successfully", 'Success')
        }
        console.log("server res: ", res)
      }).catch((err) => {
        getProfile();
      }).finally(() => {
        setLoading(false);
      })


    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const ExploreSchema = Yup.object().shape({
    generes: Yup.string()
      .required('Please Select Genres.'),
    vibes: Yup.string()
      .required('Please Select Vibes.'),
    creatives: Yup.string()
      .required('Please Select Creatives.'),
      country: Yup.string()
      .required('Please Select Country.'),
      city: Yup.string()
      .required('Please Select City.'),
  });


  const iphoneRedirection = () => {
    window.location.href = `nxzsound://SignInScreen`;
    setTimeout(function () {
      history.push("/home");
      openNotification('Profile Created Successfully', 'Success')
    }, 4000);
  }

  

  const doExplore = async (value: any) => {
    if (profileImageURL !== "" && coverImgURL !== "") {
      const data = {
        "data": {
          "vibes": value.vibes,
          "genres": value.generes,
          "creative_type_name": value.creatives,
          "country": value.country,
          "city": value.city
        }
      }
      await axios
        .put(`${baseUrl}/bx_block_profile/profile/update_genre`, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",

            "token": token,
          },
        })
        .then((res) => {
          const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
          if (/iPhone/.test(userAgent) && !(window as any).MSStream) {
            iphoneRedirection()
          } else {
            history.push("/home");
            openNotification('Profile Created Successfully', 'Success')
          }

        })
        .catch((err) => {
          console.log("error", err);

        });
    }
    else if (profileImageURL == '') {
      openNotification("Please Upload Profile Image", "Error")
    } else if (coverImgURL == "") {
      openNotification("Please Upload Cover Photo", "Error")

    } else {
      ""
    }
  }
  const { Option } = Select;


  function arrayRemove(arr: any, value: any) {

    return arr.filter(function (ele: any) {
      return ele != value;
    });
  }


  const removeProfile = async () => {

    await axios.delete(`${baseUrl}/bx_block_profile/remove_profile_image`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response) => {
        setProfileImageURL("")
        openNotification(response.data.errors[0].profile, 'Success')

      }).catch((err) => {
        openNotification(err.response.data.errors[0].profile, 'Error')
      });
  }

  const getBackgroundId = (data: any) => {
    if (data) {
      return '#3959F9'
    } else {
      return 'rgb(48, 48, 48)'
    }
  }

  const getColorId = (data: any) => {
    if (data) {
      return '#ffffff'
    } else {
      return '#8f92a1'
    }
  }

  const gerAvatarProfile = () => {
    if (profileImageURL !== "") {
      return <Avatar
        src={profileImageURL}
        alt="avatar" />
    } else {
      return <img src={plusRed}
        style={{ width: "24px", height: "24px" }}
      />
    }
  }

  const getCoverProfile = () => {
    if (coverImgURL !== "") {
      return <img src={profile?.attributes?.cover_image} alt="avatar" style={{ height: "100%", width: "100%",backgroundPosition:"center",objectFit:"cover" }}
      />
    } else {
      return <img src={plusRed}
        style={{ width: "24px", height: "24px" }} />
    }
  }

  const mapCountries = () => {
    return countries?.length !== 0 &&
      countries.map((genres: any, index:any) => {
        return (
          <Option value={genres?.label} style={{ backgroundColor: "white" }}
            key={index} id={genres?.id}>
            {genres?.label}</Option>
        )
      })
  }

  const showError = (touched: any, errors: any) => {
    if (touched && errors) {
      return <p style={{ color: '#aa3b3b' }}>{errors}</p>
    } else {
      return
    }
  }

  const returnSelectedGenre = (getFieldProps: any, genres: any, setFieldValue: any, val: any) => {
    let data: any = getFieldProps(val).value
    if (data.length == 0) {
      data[0] = genres?.attributes?.id
    }
    else {
      if (data.includes(genres?.attributes?.id)) {
        const filterdArray: any = arrayRemove(data, genres?.attributes?.id)
        data = filterdArray
      } else {
        data.push(genres?.attributes?.id)
      }
    }
    setFieldValue(val, data)
  }

  return (
    <Row className="ListenerProfile  width_100"  >
      <Grid container className="ForListener">

        <Grid item lg={6} md={6} xs={12} className="bgImg-welcome-create" >
          <img className="listener-creative-image1" src={ListenerUpdateBackground}  />
          <img className="image2 " src={CreativeShade} />
        </Grid>
        <Grid item lg={6} md={6} xs={12} className="update-profile login_form " >

          {loading ? (<Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
            <img src={nxzloader} style={{ width: "5%" }} />
          </Row>) : (<>
            <Formik
              initialValues={{
                generes: [],
                vibes: [],
                creatives: "",
                userName: currentUserName,
                country: "",
                city: ""
              }}
              validationSchema={ExploreSchema}
              onSubmit={(values): any => {
                doExplore(values)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                handleBlur,
                setFieldValue, getFieldProps
              }) => (
                //@ts-ignore
                <Form>
                  <>
                    <Row justify="space-between" className="width_100 mt-4 inputs" style={{ alignItems: "end" }}>
                      <Col xl={10} lg={10} md={12} sm={12} xs={24} className="">
                        <div className=" width_100 mt-3 upload-avatar-profile"
                          style={{ display: "flex" }}
                        >
                          <div
                            className="upload-profile  " style={{ alignSelf: "end" }}>
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar_uploader"
                              showUploadList={false}
                              customRequest={(options: any) => uploadImage(options, "avatar")}
                              beforeUpload={beforeUpload}
                            >

                              {gerAvatarProfile()}
                              <img src={listenerimageupload} className="upload-cover-icon" />
                            </Upload>
                          </div>
                        </div>
                        <div className="upload-font-stroke align-stroke-left">
                          UPLOAD
                        </div>
                        <div className="with-stroke align-stroke-left">
                          AVATAR
                        </div>
                      </Col>

                      <Col xl={14} lg={14} md={12} sm={12} xs={24} >
                        <div className=" width_100 mt-3 justify-center-listener-creative"
                          style={{ display: "flex" }}>
                          <div
                            className="upload-profile" style={{ alignSelf: "end" }}></div>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="coverImg_uploader mt-1"
                            showUploadList={false}
                            customRequest={(options: any) => uploadImage(options, "coverImage")}

                            beforeUpload={beforeUpload}
                          >
                            {getCoverProfile()}
                            <img src={listenerimageupload} className="upload-cover-icon" />
                          </Upload>
                        </div>
                        <div className="upload-font-stroke align-stroke-center">
                          UPLOAD
                        </div>
                        <div className="with-stroke align-stroke-center">
                          COVER
                        </div>
                      </Col>
                    </Row>
                    <Row justify="space-between" className="width_100 inputs ">
                      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="select_genera">
                        <div className="mt-3 mb_2 " style={{ display: "flex", flexDirection: "column" }} >
                          <Row className=" font-title-profile ">Select Country</Row>
                          <Select
                            className=" width_100 "
                            placeholder={<b>Choose Country </b>}
                            optionFilterProp="children"
                            clearIcon={false}
                            removeIcon
                            showArrow
                            showSearch={false}
                            onChange={(values) => {
                              setFieldValue('country', values)
                            }}
                            onBlur={handleBlur}
                            value={values.country}
                            //@ts-ignore
                            helperText={errors.country}
                            error={touched.country && errors.country ? true : false}
                            style={{
                              color: "white",
                              borderBottom: "1px solid #7a7e8a"
                            }}>
                            {mapCountries()}
                          </Select>
                      {showError(touched.country, errors.country)}

                        </div>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="select_genera">
                        <div className=" mt-3 mb_2 ml-1-profile"
                          style={{ display: "flex", flexDirection: "column" }} >
                          <Row className=" font-title-profile "
                          //  style={{ marginTop: "2%" }}
                           >Enter City Name</Row>
                          <input type="text" id="city" name="city" style={{
                            width: "100%",
                            padding: "0px 4px",
                            margin: "8px 0px 0px 0px",
                            outline: 0,
                            border: "none",
                            borderBottom: " 1px solid #7a7e8a ",
                            backgroundColor: "transparent"
                          }}
                            value={values.city }
                            onChange={(e: any) => setFieldValue('city', e.target.value)}
                          />
                      {showError(touched.city, errors.city)}

                        </div>
                      </Col>
                    </Row>
                    <Row justify="space-between" className="width_100 mt-1 ">
                      <Col lg={24} md={24} sm={24} xs={24} className="inputs select_genera">
                        <Row className=" font-title-profile ">Creative Type</Row>

                        {showError(touched.creatives, errors.creatives)}

                        <Row>
                          {
                            creative?.length !== 0 &&
                            creative.map((genres: any, index) => {
                              return (
                                <button type="button" style={{
                                  background: getFieldProps('creatives').value == genres?.name ? "#3959F9" : 'rgb(48, 48, 48)',
                                  color: getColorId(getFieldProps('creatives').value == genres?.name)
                                }}
                                  onClick={() => {
                                    setFieldValue('creatives', genres?.name)

                                  }}
                                  key={index} id={genres?.id}>{genres?.name.toUpperCase()}</button>
                              )
                            })}
                        </Row>

                      </Col>

                    </Row>
                    <Col lg={24} className="inputs select_genera">
                      <Row className="font-title-profile ">Select Genre</Row>
                      {showError(touched.generes, errors.generes)}
                      <Row className="width_100">
                        {
                          genre?.length !== 0 &&
                          genre.map((genres: any, index) => {
                            return (
                              <button type="button" style={{
                                background: getBackgroundId(getFieldProps('generes').value.includes(genres?.attributes?.id)),
                                color: getColorId(getFieldProps('generes').value.includes(genres?.attributes?.id))

                              }}
                                onClick={() => returnSelectedGenre(getFieldProps, genres, setFieldValue, 'generes')}
                                key={index} id={genres?.attributes?.id}>{genres?.attributes?.name.toUpperCase()}</button>
                            )
                          })}
                      </Row>
                    </Col>
                    <Row justify="space-between" className="width_100 mt-1 ">
                      <Col md={24} sm={24} xs={24} lg={24} className="inputs select_genera">
                        <Row className=" font-title-profile">Select Vibe</Row>
                        {showError(touched.vibes, errors.vibes)}
                        <Row>
                          {
                            vibes?.length !== 0 &&
                            vibes.map((genres: any, index) => {
                              return (
                                <button type="button" style={{
                                  background: getBackgroundId(getFieldProps('vibes').value.includes(genres?.attributes?.id)),
                                  color: getColorId(getFieldProps('vibes').value.includes(genres?.attributes?.id))

                                }}

                                  onClick={() => returnSelectedGenre(getFieldProps, genres, setFieldValue, 'vibes')}

                                  key={index} id={genres?.attributes?.id}>{genres?.attributes?.name.toUpperCase()}</button>
                              )
                            })}

                        </Row>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col xs={18} sm={17} md={15} lg={16} className="listener-profile-button">
                        <button
                          type="button"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          UPDATE PROFILE
                        </button>
                      </Col>
                    </Row>
                  </>
                </Form>
              )}</Formik> </>)}

        </Grid>
      </Grid>
    </Row>
  );
};

export default CreativeProfile;
// Customizable Area End