// Customizable Area Start
import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
export interface Props {
  window_width?: any;
  handleAudioVideos?: number;
  selected?: any;
  playlistLoading?: boolean;
  selectedPre?: boolean;
  playList?: any;
  songsList?: any;
  value: number;
  activityList?: any;
  musicButton?: boolean;
  open?: boolean,
  openScanQr?: boolean,
  seeAll?: boolean,
  TrackDetails?: any;
  isHover?: boolean;
  songByName?: any;
  dashboardData?: any;
  token?: string;
  errorMsg?: string;
  loading?: boolean;
  toggleComment?: boolean;
  commentTabs?: any;
  beatList?: any;
  top_playlistVideo?: any;
  playlistVideo?: any;
  getTopPlaylists?: any;
  apiToken?: any;
  followerData?: any;
  loader?: boolean;
  creativeById?: any;
  openPopupFollowers?: boolean,
  openPopupFollowing?: boolean,
  loadingFollow?: boolean;
  unfollowState?: boolean;
  userFollowerListData?: any;
  visible?: boolean;
  getTop100?: any;
  beatErrorResponse?: any;
  topTrackErrorResponse?: any;
  playPlaylist?: boolean;
  playlistData?: any;
  checkType?: any;
  selectedPlayList?: any;
  openAlertPopUp?: boolean;
  OpenUpgradModal?: boolean;
  dialogText?: any;
  slickCount?: any;
  handletokenError?: boolean;
  handleAudioVideo?: any;
  creativeId?: any;
  tabLoading?: any;
  crativeIdtabLoading?: any;
  playlistGraph?: any;
  userType?: any;
  subscription_plans?: any;
  handleplay?: boolean;
  filterValue?: any;
  videoList?: any;
  tabPanelList?: any;
  playLists?: any;
  tabName?: any
  listenertabName?: any
  profileCountData?: any;
  gridSizes?: any;
  new_released_video?: any;
  get_All_Activity_data?: any;
  getTopVideos?: any;
  get_notification?: any;
  getCreativeTabsLoader?: any;
  followingData?: any;
  allFollowerData?: any;
  listenerValue?: any;
  profileLoader?: any;
  followstatuscheck?: any;
  checkCurrentTab: (event:any, newValue: any) => void;
  handleRedirectPlaylist: (id:any) => void;
  handleCardPlaylist: (id:any) => void;
  handleTrackPlay: (data: any, index: any, songsList: any, name: any) => void;
  handleFilter: (value:any) => void;
  handleClose: () => void;
  handleCloseOpenUpgradModal: () => void;
  handleUpgradeModal: () => void;
  checkCurrentOriginalsTab:(event:any, newValue: any)=>void;
  handlePlayList:(data: any, index: number, arr: [], id: any, type: any, name: any)=>void;
  setMusicBtn:(data:any)=>void;
  handleReadNotification:(id:any,userType:any,notificationType:any,userid:any)=>void
}
interface S {
 
}
interface SS {
  id: any;
}

export default class ComposeProfileController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getDataByType: string = "";
  getPlayListId: string = "";
  getTopVideoList: string = "";
  getFollowerListId: string = "";
  getCreativeListId: string = "";
  getactivityListId: string = "";
  getTop100List: string = "";
  getallPlaylistId: string = "";
  getFollowId: string = '';
  getUnFollowId: string = '';
  getUserFollowerListId: string = '';
  handleFilterApiCallId: any
  myRef: any;
  nextRef: any;
  vedioPlayer: any;
  previousHref !: string;
  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
    this.nextRef = React.createRef();
    this.vedioPlayer = React.createRef()
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

   

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  
  // Customizable Area End



  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    // Customizable Area End
  }
}
// Customizable Area End