// Customizable Area Start
import React, { forwardRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import FullScreen from "@material-ui/icons/Fullscreen";
import { Hidden, Dialog } from "@material-ui/core";
import {
  Cast,
  Forward10,
  FullscreenExit,
  FullscreenExitRounded,
  FullscreenRounded,
  PictureInPictureAltRounded,
  Replay10,
} from "@material-ui/icons";
import { nxzloader } from "../../../blocks/dashboard/src/assets";

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // height: "100%",
    background: "rgba(0,0,0,0.1)",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#777",

    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "#fff",
    },
  },

  volumeSlider: {
    width: 73,
  },
}));

const PrettoSlider = withStyles({
  root: {
    height: 3,
    padding: 0,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "2px solid #fff",
    // marginLeft: "-1px",
    // marginLeft: 0,
    // marginTop: -8,
    // marginLeft: -12,
    // borderRadius: "25px",

    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    // borderRadius: 2,
    backgroundColor: "#fff",
  },
  rail: {
    // height: 8,
    // borderRadius: 4,
    // backgroundColor: "#fff",
    display: "none",
  },
})(Slider);

const VolumeSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    // height: 24,
    // width: 24,
    // backgroundColor: "#fff",
    // border: "2px solid currentColor",
    // marginTop: -8,
    // marginLeft: -12,
    // "&:focus, &:hover, &$active": {
    //   boxShadow: "inherit",
    // },
    display: "none",
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "#ff0000",
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "#3a4045",
    border: "4px solid #3a4045",
    // top: "13px",
    opacity: 1,
  },
})(Slider);

function ValueLabelComponent(props: any) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const VideosControls = forwardRef(
  (
    {
      onSeek,
      onSeekMouseDown,
      onSeekMouseUp,
      onDuration,
      onRewind,
      onPlayPause,
      onFastForward,
      playing,
      played,
      elapsedTime,
      totalDuration,
      onMute,
      muted,
      onVolumeSeekDown,
      onChangeDispayFormat,
      playbackRate,
      onPlaybackRateChange,
      onToggleFullScreen,
      volume,
      onVolumeChange,
      onBookmark,
      showFullScreen,
      onPipMode,
      playVideoFromRedirection,
      topVideo
    }: any,
    ref: any
  ) => {
    const classes = useStyles();
    const handlerVolumeButton = () => {
      if (muted) {
        return <VolumeMute style={{ color: "white" }} />;
      }

      return volume > 0.5 ? (
        <VolumeUp style={{ color: "white" }} />
      ) : (
        <VolumeDown style={{ color: "white" }} />
      );
    };

    const [openCastPopUp, setOpenCastPopUp] = useState(false);
    const [openPipPopUp, setOpenPipPopUp] = useState(false);

    const handleCastPopUp = () =>{
      setOpenCastPopUp(true)
     }
    
     const handleCloseCastPopUp = () =>{
      setOpenCastPopUp(false)
     }

     
    
     const handleClosePipPopUp = () =>{
      setOpenPipPopUp(false)
     }


    return (
      <div ref={ref} className={classes.controlsWrapper}>
        
        {
          topVideo != null &&
          (
<Grid
          container
          direction="column"
          justifyContent="flex-end"
          style={{ flexGrow: 1 }}
        >
          {/* <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            // style
            style={{ padding: 16 }}
          >
            <Grid item>
              <Typography variant="h5" style={{ color: "#fff" }}>
                Video Title
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={onBookmark}
                variant="contained"
                color="primary"
                startIcon={<BookmarkIcon />}
              >
                Bookmark
              </Button>
            </Grid>
          </Grid> */}
          {/* <Grid container direction="row" alignItems="center" justify="center">
            <IconButton
              onClick={onRewind}
              className={classes.controlIcons}
              aria-label="rewind"
            >
              <FastRewindIcon
                className={classes.controlIcons}
                fontSize="inherit"
              />
            </IconButton>
            <IconButton
              onClick={onPlayPause}
              className={classes.controlIcons}
              aria-label="play"
            >
              {playing ? (
                <PauseIcon fontSize="inherit" />
              ) : (
                <PlayArrowIcon fontSize="inherit" />
              )}
            </IconButton>
            <IconButton
              onClick={onFastForward}
              className={classes.controlIcons}
              aria-label="forward"
            >
              <FastForwardIcon fontSize="inherit" />
            </IconButton>
          </Grid> */}
          {/* bottom controls */}
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          // style={{ padding: 16 }}
          >
            {/* <Grid item xs={12} direction="row" alignItems="flex-end">

            </Grid> */}

            <Grid container item xs={12} alignItems="center">
              <PrettoSlider
                min={0}
                max={100}
                ValueLabelComponent={(props) => (
                  <ValueLabelComponent {...props} value={elapsedTime} />
                )}
                aria-label="custom thumb label"
                value={played * 100}
                onChange={onSeek}
                onMouseDown={onSeekMouseDown}
                onChangeCommitted={onSeekMouseUp}
              // onDuration={onDuration}
              />
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                style={{
                  backgroundColor: "black",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  padding: '1px'
                }}
              >
                <Hidden xsDown>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    container
                    alignItems="center"
                  >
                    <Button
                      variant="text"
                      onClick={
                        onChangeDispayFormat
                        //     () =>
                        //   setTimeDisplayFormat(
                        //     timeDisplayFormat == "normal" ? "remaining" : "normal"
                        //   )
                      }
                    >
                      <span className="elapsedTimeFont">
                        {/* {elapsedTime}/{totalDuration} */}
                        {elapsedTime}
                      </span>
                    </Button>

                    <IconButton
                      // onClick={() => setState({ ...state, muted: !state.muted })}
                      onClick={onMute}
                      className={`${classes.bottomIcons}`}
                    >
                      {handlerVolumeButton()}
                    </IconButton>
                    <VolumeSlider
                      min={0}
                      max={100}
                      value={muted ? 0 : volume * 100}
                      onChange={onVolumeChange}
                      aria-labelledby="input-slider"
                      className={classes.volumeSlider}
                      onMouseDown={onSeekMouseDown}
                      onChangeCommitted={onVolumeSeekDown}
                    />
                  </Grid>
                </Hidden>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconButton
                    onClick={onRewind}
                    className={classes.controlIcons}
                    aria-label="rewind"
                    style={{ padding: "0px 5px" }}
                  >
                    {/* <FastRewindIcon
                      // className={classes.controlIcons}
                      style={{ color: "white" }}
                    /> */}
                    {/* <img src={Skip_Backward} alt={"Skip_Backward"} /> */}
                    <Replay10 style={{ color: "white" }} fontSize="large" />
                  </IconButton>
                  <IconButton
                    onClick={onPlayPause}
                    className={classes.bottomIcons}
                    style={{ padding: "0px 5px" }}
                  >
                    {playing || playVideoFromRedirection ? (
                      <PauseIcon style={{ color: "white" }} fontSize="large" />
                    ) : (
                      <PlayArrowIcon
                        style={{ color: "white" }}
                        fontSize="large"
                      />
                    )}
                  </IconButton>
                  <IconButton
                    onClick={onFastForward}
                    className={classes.controlIcons}
                    aria-label="forward"
                    style={{ padding: "0px 5px" }}
                  >
                    {/* <FastForwardIcon style={{ color: "white" }} /> */}
                    <Forward10 style={{ color: "white" }} fontSize="large" />
                  </IconButton>
                </Grid>

                <Hidden smDown>
                  <Grid
                    item
                    xs={6}
                    // style={{ display: { xs: "none" } }}
                    // xsDown
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {/* <Button
                  onClick={handleClick}
                  aria-describedby={id}
                  className={classes.bottomIcons}
                  variant="text"
                >
                  <span className="elapsedTimeFont">{playbackRate}X</span>
                </Button> */}

                    {/* <Popover
                  container={ref.current}
                  open={open}
                  id={id}
                  onClose={handleClose}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Grid container direction="column-reverse">
                    {[0.5, 1, 1.5, 2].map((rate) => (
                      <Button
                        key={rate}
                        //   onClick={() => setState({ ...state, playbackRate: rate })}
                        onClick={() => onPlaybackRateChange(rate)}
                        variant="text"
                      >
                        <Typography
                          color={
                            rate === playbackRate ? "secondary" : "inherit"
                          }
                        >
                          {rate}X
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </Popover> */}
                    <IconButton style={{ padding: "0px 5px" }} onClick={handleCastPopUp}>
                      <Cast style={{ color: "white" }} />
                    </IconButton>
                    {
                      openCastPopUp &&
                      <Dialog open={openCastPopUp}
                       onClose={handleCloseCastPopUp}
                       PaperProps = {{className: "dialog-Paper-Deal"}}
                      >
                           <div style={{color: 'white', fontSize: '20px', fontWeight: 'bold', padding: '40px'}}>Screen Cast <img src={nxzloader} style={{ width: "20px", height: '20px' }} /> coming soon </div>
                      </Dialog>
                    }
                    {/* {isPictureInPictureAvailable && ( */}
                    <IconButton style={{ padding: "0px 5px" }}>
                      <PictureInPictureAltRounded
                        style={{ color: "white" }}
                        onClick={() => onPipMode()}
                      />

        {/* <button
          onClick={() => togglePictureInPicture(!isPictureInPictureActive)}
        >
          {isPictureInPictureActive ? 'Disable' : 'Enable'} Picture in Picture
        </button> */}
     
                    </IconButton>

                    {
                      openPipPopUp &&
                      <Dialog open={openPipPopUp}
                       onClose={handleClosePipPopUp}
                       PaperProps = {{className: "dialog-Paper-Deal"}}
                      >
                           <div style={{color: 'white', fontSize: '20px', fontWeight: 'bold', padding: '40px'}}> Feature <img src={nxzloader} style={{ width: "20px", height: '20px' }} /> coming soon </div>
                      </Dialog>
                    }
                    {/* )} */}

                    <IconButton
                      onClick={onToggleFullScreen}
                      className={classes.bottomIcons}
                      style={{ padding: "0px 0px" }}
                    >
                      {showFullScreen ? (
                        <FullscreenExitRounded style={{ color: "white", }} className="control_icon" />
                      ) : (
                        <FullscreenRounded style={{ color: "white" }} className="control_icon" />
                      )}
                    </IconButton>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
          )
        }

        
      </div>
    );
  }
);

export default VideosControls;
// Customizable Area End