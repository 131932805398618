// Customizable Area Start
import React from "react";
import "./DashboardMusicdeal.css";
import { Avatar, Box, Button, Grid, IconButton, Slider, Typography } from "@material-ui/core";

import { album1, album11, album2, blueCheck, logo } from "blocks/dashboard/src/assets";
import FilterDropDown from "components/src/CustomChart/PopUps/FilterDropDown.web";
import Buttongroup from "../../Common/Buttongroup.web";
import DashboardMusicDealController from "./DashboardMusicDealController.web";
import { jsonsinger, dealerslogo } from "../../assests"
export default class MusicSplitDeal extends DashboardMusicDealController {
  constructor(props: any) {
    super(props);
  }
  showButtonGroup = () => {

    return (
      <Grid item md={2} xs={6} lg={2} className="audio-div">
        <Grid item md={2} xs={6} lg={2} className="audio-div">
          <Buttongroup
            musicButton={this.state.musicButton}
            buttonshow={true}
            setMusicButton={
              this.setVideoButtonTrue
            }
            setMusicButtonTrue={

              this.setMusicButtonTrue
            }
            setVideoButtonTrue={
              this.setUserButtontrue
            }
            setDealertrue={
              this.setDealerbuttontrue
            }
          />

        </Grid>

      </Grid>
    )

  }


  render() {
    console.log("render")
    const musicSplitDeal = () => {

      return (
        <Box style={{ padding: "0px 52px 0px 31px" }}>
              <Grid style={{ backgroundColor: "black" }} item xs={12} className="filter-div filter_align_right" >
        <Grid style={{ display: "flex", alignItems:"flex-end",justifyContent: "flex-end", paddingBottom: "30px" }} >
              <div >
                  {this.showButtonGroup()}
              </div>

              <FilterDropDown filterValue="Filter"
              />

            </Grid>
          </Grid>
          <Box className="Musicdealtop_container">
          <Box className="Musicdealtop_container_element">
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="Music-Royalties">
                   Music Split Deals
                </span>
                <span className="This-Week">
        
         This Week
                </span>
              </Box>
               <Box style={{ height: "693px", marginTop: "10px", overflowX: "scroll" }}>


                 {Array.from({ length: 20 }).map((item, index) =>
                   <Box className="musicdeal_leftside_container" onClick={this.selectanysong}  key={index}>
                     <Box style={{ gap: "11%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                       <img src={album1} className="iconImg" style={{ borderRadius: '10px' }}
                         width="60px"
                         height="60px"

                      />
                       <Box style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                        <span className="Swalla">
                           Get ugly
                        </span>
                        <span className="Jason-Derulo">
                          Jason Derulo <img src={blueCheck} className="verfied" />
                        </span>
                       </Box>
                    </Box>
                <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                       <span className="money cash">
                        $357.95
                      </span>
                      <span>
                        <span className="text-style-1">-</span>60%
                      </span>
                    </Box>
                  </Box>)}
              </Box>
            </Box>
            <Box className="top_dealers_musicdeal_right_side">
            <Box className="musicdeal_right_side_contain">
            <Box className="boximage" style={{
                 width: '356px',
                 backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 72%), url(${jsonsinger})`,
                 backgroundSize: 'cover',
                 backgroundPosition: 'center',
              }}>
                <Box className="split">
                   <span className="Music-Split">MusicSplit</span>
                </Box>
                <Box  className="sold">
                  <div>
                       <img src={logo}   className="logoverified" />
                  </div>

                  <div>
                     <span className="Music-Split ">  Sold To: <br></br></span>
                     <span className="Music-Split ">  nxzsound20  <img src={blueCheck} className="verfied" /></span>
                  </div>

                </Box>
                <div style={{ display: "flex", gap:  "64px" }}>
                  <div className="deal_elements" >
                    <span className="deal_elements_firstchild">
                       Split
                    </span>
                     <span className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                       Plays
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                       Deal
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                </div>
                <div style={{ display: "flex", gap: '64px' }}>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                        Term
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className="deal_elements" >
                    <span className="deal_elements_firstchild" >
                         Change
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className="deal_elements"  >
                    <span className="deal_elements_firstchild"  >
                        Made
                    </span>
                    <span className="deal_elements_secondchild"   >30%</span>
                  </div>
                </div>

                <Box style={{ alignItems: "center", display: "flex" ,  marginLeft:"-30%"}}>
                  <img src={jsonsinger} className="iconImg"    style={{ borderRadius: '10px' }}
                     width="60px"
                     height="60px"
 
                  />
                  <Box style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                    <span className="Swalla">
                       Get ugly
                    </span>
                    <span className="Jason-Derulo">
                       Jason Derulo <img src={blueCheck} className="verfied" />
                    </span>
                  </Box>
                </Box>
                <Box className="deal_timing">
                  <span className="Deal-Expires-in-72h">
                    Deal Expires in:
                    <span className="text-style-1">72h</span>
                  </span>
                </Box>
              </Box>
            </Box>
             
              {/* <img src={album1} alt="Album Cover"  className="boximage"/> */}
            </Box>
          </Box>
        </Box>
      )
    }
    const videoSplitDeal = () => {


      return (
        <Box style={{ padding: "0px 52px 0px 31px" }} >
               <Grid style={{ backgroundColor: "black" }} item xs={12} className="filter-div filter_align_right" >
                <Grid style={{ display: "flex", justifyContent: "flex-end",alignItems:"flex-end", paddingBottom: "30px" }} >
              <div >
                   {this.showButtonGroup()}
              </div>

           <FilterDropDown filterValue="Filter"
              />

            </Grid>
          </Grid>
          <Box className="Musicdealtop_container">
            <Box className="Musicdealtop_container_element">
                 <Box style={{ display: "flex", justifyContent: "space-between" }}>
                     <span className="Music-Royalties">
                       Video Split Deals
                    </span>
                    <span className="This-Week">
                          This Week
                </span>
              </Box>
              <Box style={{ height: "693px", marginTop: "10px", overflowX: "scroll" }}>
                              {Array.from({ length: 20 }).map((item, index) =>
                           <Box    className=    "musicdeal_leftside_container" key={index}    onClick={this.selectanysong}>
                             
                             
                              <Box style={{ gap: "11%",    alignItems: "center", display: "flex", justifyContent: "center" }}>
                                
                                 <img src={album1} className="iconImg" style={{ borderRadius: '10px' }}
                                
                                width="60px"
                                height="60px"

                      />
                      <Box style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                        <span className="Swalla">
                                 Get ugly
                        </span>
                        <span className="Jason-Derulo">
                                 Jason Derulo <img src={blueCheck} className="verfied" />
                        </span>
                      </Box>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                      <span className="money cash">
                               $357.95
                      </span>
                      <span>
                                 <span className="text-style-1">-</span>60%
                      </span>
                    </Box>
                  </Box>)}
              </Box>
            </Box>
            <Box className="top_dealers_musicdeal_right_side">
            <Box className="musicdeal_right_side_contain">
            <Box className="boximage" style={{
                  width: '356px',
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 72%), url(${jsonsinger})`,
                  backgroundSize: 'cover',
                   backgroundPosition: 'center',

              }}>
                <Box className="split">
                      <span className="Music-Split">VideoSplit</span>
                </Box>
                <Box       className="sold ">
                  <div>
                    <img src={logo}   className="logoverified" />
                  </div>

                  <div>
                       <span className="Music-Split ">Sold To: <br></br></span>
                      
                       <span className="Music-Split ">nxzsound20  <img src={blueCheck} className="verfied" /></span>
                  </div>

                </Box>
                <div style={{ display: "flex",      gap: "64px" }}>
                  <div className="deal_elements">
                       <span className=    "deal_elements_firstchild">
                      Split
                    </span>
                       <span className= "deal_elements_secondchild">30%</span>
                  </div>
                  <div className=  "deal_elements">
                    <span className=   "deal_elements_firstchild">
                                 Plays
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                          Deal
                    </span>
                       <span className="deal_elements_secondchild">30%</span>
                  </div>
                </div>
                <div style={{ display: "flex", gap: '64px' }}>
                  <div className="deal_elements">
                       <span className="deal_elements_firstchild">
                      Term
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                           Change
                    </span>
                    <span className=" deal_elements_secondchild">30%</span>
                  </div>
                  <div className=" deal_elements">
                    <span className=" deal_elements_firstchild">
                        Made
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                </div>

                <Box style={{ alignItems: "center", display: "flex" ,marginLeft:"-30%"}}>
                  <img src={jsonsinger} className="iconImg" style={{ borderRadius: '10px' }}
                       width="60px"

                        height="60px"

                  />
                  <Box style={{ display: 'flex',    gap: '10px', flexDirection: 'column'}}>
                    <span className="Swalla">
                      Get ugly
                    </span>
                    <span className="Jason-Derulo">
                                      Jason Derulo   <img src={blueCheck} className="verfied" />
                    </span>
                  </Box>
                </Box>
                <Box className="deal_timing">
                  <span className="Deal-Expires-in-72h">
                            Deal Expires in:
                    <span   className="text-style-1">72h</span>
                  </span>
                </Box>
              </Box>
            </Box>
            
            </Box>
          </Box>
        </Box>
      )
    }
    const userSplitDeal = () => {



      return (
        <Box style={{ padding: "0px 52px 0px 31px" }}>
     <Grid style={{ backgroundColor: "black" }} item xs={12} className="filter-div filter_align_right" >
              <Grid style={{ alignItems:"flex-end",display: "flex", justifyContent: "flex-end", paddingBottom: "30px" }} >
              <div >
                {this.showButtonGroup()}
              </div>

                          <FilterDropDown filterValue="Filter"
              />

            </Grid>
          </Grid>
      <Box className="Musicdealtop_container">
                 <Box className="Musicdealtop_container_element">
                      <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="Music-Royalties">
                            User Split Deals
                </span>
                <span className="This-Week">
                             This Week

                </span>

              </Box>
              <Box style={{ height: "693px", marginTop: "10px", overflowX: "scroll" }}>
                               
                                    {Array.from({ length: 20 }).map((item, index) =>
                                  <Box             className="musicdeal_leftside_container"    key={index} onClick={this.selectanysong}>
                                <Box style={{ gap: "11%",   alignItems: "center", display: "flex", justifyContent: "center" }}>

                                  <img src={album1}    style={{ borderRadius: '10px' }}
                                className="iconImg"
                                width="60px"
                        height="60px"

                      />
                      <Box style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                        <span className="Swalla">
                                    Get ugly
                        </span>
                        <span className="Jason-Derulo">
                                     Jason Derulo <img src={blueCheck} className="verfied" />
                        </span>
                      </Box>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                      <span className="money cash">
                                         $357.95
                      </span>
                      <span>
                        <span  className="text-style-1">-</span>60%
                      </span>
                    </Box>
                  </Box>)}
              </Box>
            </Box>
            <Box className="top_dealers_musicdeal_right_side">
            <Box className="musicdeal_right_side_contain">
            <Box className="boximage" style={{
                            width: '356px',
                            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 72%), url(${jsonsinger})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',

              }}>
                <Box className="split">
                            <span className="Music-Split">UserSplit</span>
                </Box>
                              <Box className="sold">
                  <div>
                              <img src={logo} className="logoverified" />
                  </div>

                  <div>
                              <span className="Music-Split ">Sold To: <br></br></span>
                            <span className="Music-Split ">nxzsound20  <img src={blueCheck} className="verfied" /></span>
                  </div>

                </Box>
                <div style={{ display: "flex", gap: "64px" }}>
                          <div className="deal_elements">
                    <span 
                    className="deal_elements_firstchild">
                                  Split
                    </span>
                    <span 
                    className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className               ="deal_elements">
                    <span            className="deal_elements_firstchild">
                                 Plays
                    </span>
                    <span      className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                                Deal
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                </div>
                <div style={{ display: "flex", gap: '64px' }}>
                <div className="deal_elements">
                     <span className="deal_elements_firstchild">
                                  Term
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                                        Change
                    </span>
                 <span className="deal_elements_secondchild">30%</span>
                  </div>
                  <div              className="deal_elements">
                    <span className="deal_elements_firstchild">
                                         Made
                    </span>
                    <span       className="deal_elements_secondchild">30%</span>
                  </div>
                </div>

        <Box style={{ alignItems: "center", display: "flex" ,    marginLeft:"-30%"}}>
                  <img src={jsonsinger} className="iconImg" style={{ borderRadius: '10px' }}
                            width="60px"
                    height="60px"

                  />
                  <Box style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                    <span className="Swalla">
                                Get ugly
                    </span>
                    <span className="Jason-Derulo">
                              Jason Derulo <img src={blueCheck} className="verfied" />
                    </span>
                  </Box>
                </Box>
                <Box className="deal_timing">
                  <span className="Deal-Expires-in-72h">
                               Deal Expires in:
                    <span className="text-style-1">72h</span>
                  </span>
                </Box>
              </Box>
            </Box>
             



              {/* <img src={album1} alt="Album Cover"  className="boximage"/> */}
            </Box>
          </Box>
        </Box>
      )
    }
    const topDealers = () => {

      return (


        <Box style={{ padding: "0px 52px 0px 31px" }}>

          <Grid style={{ backgroundColor: "black" }}    item xs={12} className="filter-div filter_align_right" >
            <Grid style={{   display: "flex",     justifyContent: "flex-end", paddingBottom: "30px",   alignItems:"flex-end" }} >
              <div >
 

                {this.showButtonGroup()}
              </div>

              <FilterDropDown filterValue="Filter"
              />
            </Grid>
          </Grid>

          <Box className="Musicdealtop_container">

            <Box className="Musicdealtop_container_element">


                     <Box style={{ display: "flex", justifyContent: "space-between" }}>
               
                     <span className="Music-Royalties">
                  Top 100 Dealers
                </span>
                <span className="This-Week">
                  This Week
                </span>
              </Box>

              <Box style={{ height: "693px", marginTop: "10px", overflowX: "scroll" }}>

                {Array.from({ length: 20 }).map((item, index) =>(
                   
                  
                   <Box className="leftside_music_deal" key={index} onClick={this.selectanysong}>

                      <Box >

                        <img src={album1} className="iconImg deal_image" />
                      </Box>

                      <Box style={{ width: "90%" }}>
                        <div className="leftside_second_element">
                          <Box style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "11px"
                          }}>
                            <span className="Swalla">
                              Get ugly <img src={blueCheck} className="verfied" />
                            </span>
                            <span className="Jason-Derulo">
                              Jason Derulo
                            </span>
                          </Box>
                          <Box style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "11px"
                          }}>
                            <span className="money cash">
                              $4.71M
                            </span>
                            <span>
                              <span className="text-style-1">33 deals</span>
                            </span>
                          </Box>

                        </div>

                        <Box className="leftside_second_element">
                          <Box style={{
                            width: "100%"


                          }} >

                            <Slider defaultValue={60} aria-label="Default" valueLabelDisplay="auto" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}




              </Box>
            </Box>
            <Box className="top_dealers_musicdeal_right_side" style={{ flexDirection: "column" }}>
                                   <Box className="musicdeal_right_side_contain">
                                   <Box className="boximage" style={{
            width: '355px', height: "230px",
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 72%), url(${dealerslogo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
              }}>
            <Box className="split">
                      <span className="Music-Split">#1 Dealer</span>
                </Box>
                <Box className="sold">

                          <img src={logo} className="logoverified" />
                  <span className="Music-Split ">nxzsound20</span>
                            <img src={blueCheck} className="verfied" style={{ marginLeft: "24px" }} />


                </Box>

                <div style={{ display: "flex", gap: '20px' }}>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                                           Paid
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                                            Made
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                  <div className="deal_elements">
                    <span className="deal_elements_firstchild">
                                  Chnage
                    </span>
                    <span className="deal_elements_secondchild">30%</span>
                  </div>
                </div>

              </Box>
              <Box className="DealinsideBox">
                      <span className={this.state.selecteddeals == "transactions" ? "clickedDealoofers" : "Deal-Offers"} onClick={this.selectedDealSection.bind(this,"transaction") } data-test-id="top-dealer">
                               Deal Offers
                </span>
                <span className={this.state.selecteddeals == "Topdeals" ? "clickedDealoofers" : "Deal-Offers"} onClick={() => this.selectedDealSection("")}>
                               Top Dealers
                </span>

              </Box>
       </Box>
             
            </Box>
          </Box>
        </Box>
      )
    }
    const dealOffers = () => {
  
        return (
          <Box style={{ padding: "0px 52px 0px 31px" }}>
           
           
            <Grid   item xs={12} style={{ backgroundColor: "black" }} className="filter-div filter_align_right" >
              <Grid style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "30px" ,alignItems:"flex-end"}} >
                <div >
                        {this.showButtonGroup()}
                </div>
            
                                  <FilterDropDown filterValue="Filter"
                />

              </Grid>
            </Grid>
            <Box className="Musicdealtop_container">


              <Box className="Musicdealtop_container_element">
                      <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <span className="Music-Royalties">
                    Deal Transactions
                  </span>
                  <span className="This-Week">
                    This Week
                  </span>
                </Box>
                <Box style={{ height: "693px", marginTop: "10px", overflowX: "scroll" }}>
                  {Array.from({ length: 20 }).map((item, index) =>
                 
                 <Box className={index === this.state.selectedIndex ? "selected_deal" : "musicdeal_leftside_container"} key={index} onClick={this.selectedDeal.bind(this,index)} data-test-id="selecteddeal">
                      <Box style={{ gap: "11%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                      
                        <img src={jsonsinger} className="iconImg" style={{ borderRadius: '60px' }}
                              width="60px"
                          height="60px"

                        />
                        <Box style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                          <span className="Swalla">
                            Get ugly  <img src={blueCheck} className="verfied" />
                          </span>
                          <span className="Jason-Derulo">
                            Jason Derulo
                          </span>
                        </Box>
                      </Box>
                   
                   
                      <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        <span className="money cash">
                              $357.95
                        </span>
                        <span>
                             <span className="text-style-1"></span>Negotiating
                        </span>
                      </Box>
                    </Box>)}
                </Box>
              </Box>


              {!this.state.showdealstatus ? <><Box className="top_dealers_musicdeal_right_side" style={{ flexDirection: "column" }}>
              <Box className="musicdeal_right_side_contain">
              <Box className="boximage" style={{ height: "250px" }}>
                <Box style={{width:'355px'}}>
                  <div className="dealoffer_container">
                   
                   
                    <div className="dealofferstats">
                      <span className="Pending">pending</span>
                      <span className="pendingStats">2</span>
                    </div>
                    <div className="dealofferstats">
                      <span className="Pending">pending</span>
                      <span className="pendingStats">2</span>
                    </div>
                  </div>
                
                
                  <div style={{ marginTop: '10px' }} className="dealoffer_container">
                    <div className="dealofferstats">
                      <span className="Pending">pending</span>
                      <span className="pendingStats">2</span>
                    </div>
                    <div className="dealofferstats">
                      <span className="Pending">pending</span>
                      <span className="pendingStats">2</span>
                    </div>
                  </div>
                </Box>

                <Box className="DealinsideBox">
              
              
                  <span className={this.state.selecteddeals == "transactions" ? "clickedDealoofers" : "Deal-Offers"} onClick={this.selectedDealSection.bind(this,"transaction")} data-test-id="transactions">
                    Deal Offers
                  </span>
                  <span className={this.state.selecteddeals == "Topdeals" ? "clickedDealoofers" : "Deal-Offers"} onClick={() => this.selectedDealSection("")}>
                    Top Dealers
                  </span>

                </Box>
              </Box>
              </Box>
               
               </Box></> : <>
             
             
                <Box style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className="rightsidecontainer">
                  <Box style={{ backgroundImage: "linear-gradient(to bottom, #ff0010 0%, #000000 68%)" }} className="iaiaiaiai">
                    <Box style={{ background: "black", width: "80%", height: "80%" }}>
                      <Box className='dealselection_right_box'>
                        <Box className="change_status">
                          <div className='MainContentDiv'>
                            <Grid item xs={12} sm={12} md={6} lg={7} xl={8} className='ColumnCenter'>
                              <div className='ColumnCenter AICenter'
                              >


                                {/* Offer Preview START*/}
                                <Box
                                  className="dashboardDeal_offer_preview"

                                >
                                  {/* Label */}
                                  <Box className="LabelBox">
                                    <Box
                                      className="AvatarBox"
                                    >
                                      <Avatar src={jsonsinger}
                                        className="Avater" />
                                    </Box>
                                    <Box
                                      className="LabelInfoBox">
                                      <Typography className="PVCommonText LabelTitleText">
                                        nxzsound
                                      </Typography>
                                      <Typography
                                        className="PVCommonText LabelSubTitleText"
                                      >
                                        Label
                                      </Typography>
                                    </Box>
                                  </Box>
                                  {/* Label */}
                                  <Box className="MT32">
                                    <Typography className="PVCommonText OfferingText">Is Offering:</Typography>
                                  </Box>
                                  <Box>
                                    <Typography className="PVCommonText OfferingMoneyText">$300</Typography>
                                  </Box>
                                  <Box className="MT23">
                                    <Typography className="PVCommonText ForText">For:</Typography>
                                  </Box>
                                  <Box className="SplitDetailBox">
                                    <Box>
                                      <Avatar src={album11} className="Avatar" />
                                      <Typography className="SplitPercentageText">
                                        50%*
                                      </Typography>
                                    </Box>
                                    <Box className="LineBox">
                                      <Box className="ChildBox">
                                        <Box className="Line">
                                          <Avatar src={jsonsinger} className="Avatar" />
                                        </Box>
                                      </Box>
                                      <Box className="SplitDurationBox">
                                        <Typography className="PVCommonText DurationText">FOR</Typography>
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Avatar src={album2} className="Avatar" />
                                      <Typography className="SplitPercentageText">
                                        10%
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box className="MT23">
                                    <Typography className="PVCommonText FromText">From:</Typography>
                                  </Box>
                                  <Box className="PVAudioVideoContentDiv">

                                    <Box className="PVAudioVideoImageDiv">
                                      <img src={jsonsinger} className="PVAudioVideoImage" />
                                      <IconButton className="PVAudioVideoPlayBtn" >
                                        <Avatar src={album1} className="Icon" />
                                      </IconButton>
                                    </Box>


                                    <Box className="PVAudioVideoInfoDiv">
                                      <Box className="PVSongInfoBox">
                                        <Typography className="SongNameText">
                                          HUllala
                                        </Typography>
                                        <Typography className="SongWriterText">by:J</Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {this.state.offerStatus ? <span className="Accepted">
                                    {this.state.offerStatus}
                                  </span> : <Box className="button_box">
                                    <Button className="Make-An-Offer---Button" onClick={this.setofferStatus.bind(this,"Accepted")} data-test-id="offeraccept">
                                      Accept
                                    </Button>
                                    <Button className="Make-An-Offer---Button" onClick={this.setofferStatus.bind(this,"Rejected")}>
                                      Reject
                                    </Button>
                                  <Button className="Make-An-Offer---Button" onClick={  this.setofferStatus.bind(this,"Negotiating")}>
                                      Negotiate
                                    </Button>
                                  </Box>}

                                </Box>

                                {/* Offer Preview END */}
                              </div>
                            </Grid>

                          </div>
                        </Box>


                      </Box>
                    </Box>
                  </Box>
                </Box> </>}

            </Box>
          </Box>

        )
      
    }
    const maindealcontent=(type:any)=>{
      if (type == 'transactions') {
        return dealOffers()
      }
      else{
        return topDealers()
      }
    }
    const compRender = () => {
      const { musicButton } = this.state
      console.log(musicButton, "musicbtn")
      switch (musicButton) {
        case 1:
          return musicSplitDeal()
         
        case 2:
          return videoSplitDeal()
        
        case 3:
          return userSplitDeal()
          
        case 4:
              return maindealcontent(this.state.selecteddeals)
          
        default:
          return null
      }
    }
    return (
      //Merge Engine DefaultContainer
      compRender()

      //Merge Engine End DefaultContainer
    );

  }
}

// Customizable Area End