// Customizable Area Start
import React from "react";
import "blocks/dashboard/assets/css/repost.css";
import { Grid, Button, ButtonGroup, } from "@material-ui/core";
import 'blocks/user-profile-basic/src/Common/CardItem.web.css'
import { videoIcon, musicIcon} from "blocks/dashboard/src/assets";

export default function ButtonGroupWeb({musicButton,setMusicButton,setMusicButtonTrue}: any) {
  console.log(musicButton,"musicButton")  
  return (
        <Grid  item md={2} xs={6} lg={2} className="audio-div">
        <ButtonGroup  color="primary" className="mr_1"  aria-label="outlined primary button group"
        >
          <Button className="audio-button-home"
            value="audio"
            variant="contained"
            disabled={musicButton === false}
            onClick={setMusicButtonTrue}>
            <span className="audio-text">
              <img src ={musicIcon} className="music-icons" />
            </span>
          </Button>
          <Button variant="contained"
            className="video-button-home"
            onClick={setMusicButton}
            disabled={musicButton === true}
          >
            <span className="audio-text">
            <img src ={videoIcon} className="music-icons" />
            </span>
          </Button>
        </ButtonGroup>
      </Grid>
    );
}
// Customizable Area End
