// Customizable Area Start
import React, { useState } from 'react'
import { Grid, Tabs, Tab, Box, IconButton } from '@material-ui/core';
import "./Index.web.css"
import { MCurrentPlan } from './MCurrentPlan.web';
import PlanCustomCarousel from './MPlanCarousel.web';
import MCardInformation from './MCardInformation.web';
import { useHistory } from 'react-router-dom';
import { ArrowBackRounded } from '@material-ui/icons';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const MyPlanMobileView = ({ loading, getsubscriptions, current_plan, openConfirmPaymentPopup,
    cancelledSubscription, currentPaymentData, next_invoice_no, status_of_plan, handleUpdateCard }: any) => {

    const [tabvalue, setTabvalue] = useState(0);
    const history = useHistory();



    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box >
                        {children}
                    </Box>
                )
                }
            </div >
        );
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabvalue(newValue);

    };

    return (
        <>
            <Grid item xs={12}>
                <Grid container item xs={12} style={{ justifyContent: "center", alignItems: "center" }}>
                    <Grid item xs={1}>
                        <IconButton onClick={() => history.push("/home")}>
                            <ArrowBackRounded style={{ color: "white" }} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={11} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {tabvalue === 0 && <span className="m_myplan_font">My Plan</span>}
                        {tabvalue === 1 && <span className="m_myplan_font">My Card</span>}
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ margin: "10px 10px" }}>
                    {/* <AppBar position="static"  > */}
                    <Tabs value={tabvalue} variant="scrollable" onChange={handleChange}
                        TabIndicatorProps={{ style: { backgroundColor: 'red', color: "red", borderBottom: "1px solid", bottom: "7px" } }}
                        className="m_custom_tab_font">
                        <Tab className={tabvalue === 0 ? "active_myplan_tab" : "non_active_myplan_tab"} label="My Plan" {...a11yProps(0)} />
                        <Tab className={tabvalue === 1 ? "active_myplan_tab" : "non_active_myplan_tab"} label="My Card" {...a11yProps(1)} />
                    </Tabs>
                    {/* </AppBar> */}
                    <TabPanel value={tabvalue} index={0}>
                        <MCurrentPlan currentPlan={current_plan}
                            status_of_plan={status_of_plan}
                            next_invoice_no={next_invoice_no}
                            cancelledSubscription={cancelledSubscription}

                        />
                        <PlanCustomCarousel
                            getsubscriptions={getsubscriptions}
                            currentPlan={current_plan}
                            currentPaymentData={currentPaymentData}
                        />
                    </TabPanel>
                    <TabPanel value={tabvalue} index={1}>
                        <Grid item xs={12} container style={{ margin: "15px 0px 30px" }}>
                            <MCardInformation currentPaymentData={currentPaymentData} />
                        </Grid>
                        <Grid item xs={12} container className="m_user_plan_div" justifyContent='center'>
                            <button
                                className='m_update_btn'
                                onClick={handleUpdateCard}
                            >
                                {currentPaymentData?.card ? "Update Card" : "Add Your Card"}
                            </button>

                        </Grid>
                    </TabPanel>
                </Grid>

            </Grid>
        </>
    )
}

export default MyPlanMobileView
// Customizable Area End