// Customizable Area Start
import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Upload, Avatar, message, Select } from "antd";
import "../../../../blocks/user-profile-basic/assets/UpdateProfile.css";

import { LeftOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
import * as Yup from "yup";
import { Formik, Form } from "formik";
import axios from "axios";
import { openNotification } from "../../Notification.web";
import { defaultProfile, nxzloader } from "../../../../blocks/dashboard/src/assets";
const urlConfig = require("../../../../framework/src/config");
import { AudioContextApi } from "../../../../blocks/dashboard/src/AudioContext/AudioContext.web";
import {
  listenerimageupload,
  plusRed,
} from "../../../../blocks/user-profile-basic/src/assets";
import { useHistory } from "react-router-dom";
//@ts-ignore
import { countries } from "components/src/CustomChart/PopUps/CountryCode";

const baseUrl = urlConfig.baseURL;
const EditProfile = () => {
  const history = useHistory();
  const userType = localStorage.getItem("user_type");
  const [genre, setGenre] = useState([]);
  const [vibes, setVibes] = useState([]);
  const [creative, setCreative] = useState([]);
  const [profile, setProfile] = useState<any>([]);
  const [allGenre, setAllGenre] = useState<any>([]);
  const [allVibes, setAllVibes] = useState<any>([]);
  const [allCreativeId, setAllCreativeId] = useState<any>("");
  const [allCreative, setAllCreative] = useState<any>("");
  const [allVibesId, setAllVibesId] = useState<any>([]);
  const [allGenreID, setAllGenreId] = useState<any>([]);
  const [genreArray, setGenreArray] = useState<any>([]);
  const [vibesArray, setVibesArray] = useState<any>([]);
  const [city, setCity] = useState<any>();
  const [userName, setUserName] = useState<any>(false);
  const [editUserName, setEditUserName] = useState<any>("");
  const { isPlaylist } = useContext(AudioContextApi);
  const token: any = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      document
        .getElementById("genreIn")
        ?.setAttribute(
          "style",
          "background-color:#17181c !important;box-shadow: none;border:none !important; border-bottom:1px solid #7a7e8a !important;width:-webkit-fill-available;color:white;resize:none;"
        );
      document
        .getElementById("vibeIn")
        ?.setAttribute(
          "style",
          "background-color:#17181c !important;box-shadow: none;border:none !important; border-bottom:1px solid #7a7e8a !important;width:-webkit-fill-available;color:white;resize:none;"
        );
      document
        .getElementById("creatIn")
        ?.setAttribute(
          "style",
          "background-color:#17181c !important;box-shadow: none;border:none !important; border-bottom:1px solid #7a7e8a !important;width:-webkit-fill-available;color:white;resize:none;"
        );

      getGenre(token);
      getVibes(token);
      getProfile();
      getCreative(token);
    } else {
      history.push("/home");
    }
  }, []);

  console.log(allCreativeId, allCreative, "edit profile");

  const getGenre = async (token: any) => {
    await axios
      .get(`${baseUrl}/bx_block_music_types/genres`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((response) => {
        setGenre(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getVibes = async (token: any) => {
    await axios
      .get(`${baseUrl}/bx_block_music_types/vibes`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((response) => {
        setVibes(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [currentUserName, setCurrentUserName] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [profileImageURL, setProfileImageURL] = useState("");
  const [coverImgURL, setCoverImgURL] = useState("");
  const [creativeName, setCreativeName] = useState("");
  const [country, setCountry] = useState("Select Country");

  function getBase64(video: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(video);
  }
  const getProfile = async () => {
    setLoading(true);
    await axios
      .get(`${baseUrl}/bx_block_profile/profile`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((response) => {
        setProfile(response?.data?.data);
        setCurrentUserName(response?.data?.data?.attributes?.user_name);
        setGenreArray(
          response?.data?.data?.attributes?.user_genres.map(
            (genre: any) => genre.id
          )
        );
        setVibesArray(
          response?.data?.data?.attributes?.user_vibes.map(
            (vibe: any) => vibe.id
          )
        );
        setCreativeName(response?.data?.data?.attributes?.creative_type_name);
        setCountry(response?.data?.data?.attributes?.country);
        setCity(response?.data?.data?.attributes?.city);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCreative = async (token: any) => {
    await axios
      .get(`${baseUrl}/account_block/accounts/creative_types`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((response) => {
        setCreative(response?.data?.creative_types);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    return isJpgOrPng;
  }
  const uploadImage = async (options: any, value: any) => {
    setLoading(true);

    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      },
    };
    if (value === "remove_profile") {
      fmData.append("data[avatar]", "");
    } else {
      if (value === "coverImage") {
        getBase64(file, (imageUrl: any) => setCoverImgURL(imageUrl));
        fmData.append("data[cover_image]", file);
      } else {
        getBase64(file, (imageUrl: any) => setProfileImageURL(imageUrl));
        fmData.append("data[avatar]", file);
      }
    }
    try {
      await axios
        .put(`${baseUrl}/bx_block_profile/profile`, fmData, config)
        .then((res) => {
          getProfile();
          setProfile(res?.data?.data);
          // toast.success('Profile Picture set Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
          if (value === "coverImage") {
            openNotification("Cover Photo Set Successfully", "Success");
          } else {
            openNotification("Profile Picture Set Successfully", "Success");
          }
          console.log("server res: ", res);
        })
        .catch((err) => {
          getProfile();
          // console.log(err, "jjjjjjj");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
    }
  };
  const uploadProfilePicture = async (value: any, identity?: any) => {
    setLoading(true);
    await axios
      .put(`${baseUrl}/bx_block_profile/profile`, value, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((res) => {
        if (identity === "coverType") {
          // toast.success('Cover Picture set Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
        } else {
          // toast.success('Profile Picture set Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
          getProfile();
        }
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        openNotification("Profile Picture set Successfully", "Success");
        setLoading(false);
      });
  };
  let ExploreSchema;
  if (userType === "listener") {
    ExploreSchema = Yup.object().shape({
      generes: Yup.string().required("Please Select Genres."),
      vibes: Yup.string().required("Please Select Vibes."),
      country: Yup.string().required('Please Select Country.'),
      city: Yup.string().required('Please Select City.')
    });
  } else {
    ExploreSchema = Yup.object().shape({
      generes: Yup.string().required("Please Select Genres."),
      vibes: Yup.string().required("Please Select Vibes."),
      creatives: Yup.string().required("Please Select Creatives."),
      country: Yup.string().required('Please Select Country.'),
      city: Yup.string().required('Please Select City.')
    });
  }

  const selectGenre = (id: any, setFieldValue: any, data: any) => {
    //@ts-ignore
    if (!allGenre?.some((e) => e === data)) {
      const genreData = [...allGenre, data];
      document
        .getElementById(data)
        ?.setAttribute("style", "background-color:#3131d7");
      //@ts-ignore
      setAllGenre((allGenre) => [...allGenre, data]);

      const genreObj = {
        genre_id: id,
      };
      //@ts-ignore
      setAllGenreId((allGenreID) => [...allGenreID, genreObj]);
      //@ts-ignore
      let text = genreData.join(",  ");
      setFieldValue("generes", text);
    } else {
      for (let i = 0; i < allGenre.length; i++) {
        if (allGenre[i] === data) {
          allGenre.splice(i, 1);
        }
      }
      for (let i = 0; i < allGenreID.length; i++) {
        if (allGenreID[i].genre_id === id) {
          allGenreID.splice(i, 1);
        }
      }
      document
        .getElementById(data)
        ?.setAttribute("style", "background-color:rgb(48, 48, 48)");
      //@ts-ignore
      let text = allGenre.join(",  ");
      //@ts-ignore
      setFieldValue("generes", text);
    }
  };
  const selectVIbes = (id: any, setFieldValue: any, data: any) => {
    //@ts-ignore
    if (!allVibes?.some((e) => e === data)) {
      const vibeData = [...allVibes, data];
      document
        .getElementById(data)
        ?.setAttribute("style", "background-color:#3131d7");
      //@ts-ignore
      setAllVibes((allVibes) => [...allVibes, data]);

      const vibesId = {
        vibe_id: id,
      };
      //@ts-ignore
      setAllVibesId((allVibesId) => [...allVibesId, vibesId]);
      //@ts-ignore
      let text = vibeData.join(",  ");
      //@ts-ignore
      setFieldValue("vibes", text);
    } else {
      for (let i = 0; i < allVibes.length; i++) {
        if (allVibes[i] === data) {
          allVibes.splice(i, 1);
        }
        for (let i = 0; i < allVibesId.length; i++) {
          if (allVibesId[i].vibe_id === id) {
            allVibesId.splice(i, 1);
          }
        }
      }
      document
        .getElementById(data)
        ?.setAttribute("style", "background-color:rgb(48, 48, 48)");
      //@ts-ignore
      let text = allVibes.join(",  ");
      setFieldValue("vibes", text);
    }
  };

  const selectCreative = (id: any, setFieldValue: any, data: any) => {
    document
      .getElementById(data)
      ?.setAttribute("style", "background-color:#3131d7;width:auto;");
    setAllCreativeId(id);
    setAllCreative(data);
    setFieldValue("creatives", data);
  };
  console.log(profileImageURL, "profileImageURL");
  console.log(profile.attributes?.avatar, "avatarcard");

  const doExplore = async (value: any) => {
    let data;
    if (userType != "listener") {
      data = {
        data: {
          vibes: value.vibes,
          genres: value.generes,
          creative_type_name: value.creatives,
          country: value.country,
          city: value.city,
          user_name: userName ? editUserName : currentUserName,
        },
      };
    } else {
      data = {
        data: {
          vibes: value.vibes,
          genres: value.generes,
          user_name: userName ? editUserName : currentUserName,
          country: value.country,
          city: value.city,
        },
      };
    }
    await axios
      .put(`${baseUrl}/bx_block_profile/profile/update_genre`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((res) => {
        history.push("/home");
        openNotification("Profile Updated Successfully", "Success");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const { Option } = Select;

  function onChange(value: any) {
    console.log(`selected ${value}`);
  }
  function arrayRemove(arr: any, value: any) {
    return arr.filter(function(ele: any) {
      return ele != value;
    });
  }

  const removeProfile = async () => {
    await axios
      .delete(`${baseUrl}/bx_block_profile/remove_profile_image`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((response) => {
        setProfileImageURL("");
        getProfile();
        gerAvatarProfile();
        openNotification(response.data.errors[0].profile, "Success");
      })
      .catch((err) => {
        openNotification(err.response.data.errors[0].profile, "Error");
      });
  };

  function countryToFlag(isoCode: any) {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char: any) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }

  const getColorId = (data: any) => {
    if (data) {
      return "#ffffff";
    } else {
      return "#8f92a1";
    }
  };

  const getBackgroundId = (data: any) => {
    if (data) {
      return "#3959F9";
    } else {
      return "rgb(48, 48, 48)";
    }
  };

  const gerAvatarProfile = () => {
    if (profileImageURL !== "") {
      return <Avatar src={profileImageURL} alt="avatar" />;
    } else {
      if (profile.attributes?.avatar) {
        return (
          <img src={profile.attributes?.avatar} className="avatar_outline" />
        );
      } else {
        return <img src={defaultProfile} className="avatar_outline" />;
      }
    }
  };

  const getCoverProfile = () => {
    if (coverImgURL !== "") {
      return <img src={coverImgURL} alt="avatar" style={{ height: "100%", width: "100%",backgroundPosition:"center",borderRadius:"13px", objectFit: 'cover' }}/>;
    } else {
      if (profile.attributes?.cover_image) {
        return (
          <img
            src={profile.attributes?.cover_image}
            style={{ height: "100%", width: "100%",backgroundPosition:"center",borderRadius:"13px" , objectFit: 'cover' }}
          />
        );
      } else {
        return <img src={plusRed} style={{ width: "24px", height: "24px" }} />;
      }
    }
  };

  const mapCountries = () => {
    return (
      countries?.length !== 0 &&
      countries.map((genres: any, index: any) => {
        return (
          <Option
            value={genres?.label}
            style={{ backgroundColor: "white" }}
            key={index}
            id={genres?.id}
          >
            {genres?.label}
          </Option>
        );
      })
    );
  };

  const showError = (touched: any, errors: any) => {
    if (touched && errors) {
      return <p style={{ color: "#aa3b3b" }}>{errors}</p>;
    } else {
      return;
    }
  };

  const returnSelectedGenre = (
    getFieldProps: any,
    genres: any,
    setFieldValue: any,
    val: any
  ) => {
    let data: any = getFieldProps(val).value;
    if (data.length == 0) {
      data[0] = genres?.attributes?.id;
    } else {
      if (data.includes(genres?.attributes?.id)) {
        const filterdArray: any = arrayRemove(data, genres?.attributes?.id);
        data = filterdArray;
      } else {
        data.push(genres?.attributes?.id);
      }
    }
    setFieldValue(val, data);
  };

  return (
    <Row className="CreativeProfile width_100">
      <Col sm={24} lg={24} className="bgimg">
        <Col
          sm={18}
          md={18}
          lg={17}
          xl={17}
          xxl={14}
          className={
            isPlaylist
              ? "update-profile-audio login_form"
              : "update-profile login_form "
          }
        >
          <Row className="profile-back-btn">
            <button
              className="gray1"
              onClick={() => {
                history.push("/home");
              }}
            >
              {/* @ts-ignore */}
              <LeftOutlined translate /> Back
            </button>
          </Row>
          <Row
            justify="start"
            className="padding_left_3  width_100 text_white  font_25 width_100 font_W_600"
          >
            Edit Profile
          </Row>

          {loading ? (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: isPlaylist ? "50vh" : "80vh",
              }}
            >
              <img src={nxzloader} style={{ width: "5%" }} />
            </Row>
          ) : (
            <>
              <Formik
                initialValues={{
                  generes: genreArray,
                  vibes: vibesArray,
                  creatives: creativeName,
                  userName: currentUserName,
                  country: country,
                  city: city,
                }}
                onSubmit={(values): any => doExplore(values)}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  getFieldProps,
                }) => (
                  //@ts-ignore
                  <Form>
                    <>
                      <Row
                        justify="space-between"
                        className="width_100 mt-4 "
                        style={{ alignItems: "end" }}
                      >
                        <Col
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                          className="mb_1"
                        >
                          <div
                            className=" width_100 mt-3 ml-1"
                            style={{ display: "flex" }}
                          >
                            <div
                              className="upload-profile "
                              style={{ alignSelf: "end" }}
                            >
                              <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar_uploader ml-2 "
                                showUploadList={false}
                                customRequest={(options: any) =>
                                  uploadImage(options, "avatar")
                                }
                                beforeUpload={beforeUpload}
                              >
                                {gerAvatarProfile()}
                                <img
                                  src={listenerimageupload}
                                  className="upload-cover-icon"
                                />
                              </Upload>
                            </div>
                            <div>
                              <div
                                className="font_18 no_border  text_white width_100 mt-3 ml-2"
                                style={{ textTransform: "capitalize" }}
                              >
                                {userName ? (
                                  <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    style={{
                                      width: "100%",
                                      padding: "12px 4px",
                                      margin: "8px 0",
                                      outline: 0,
                                      border: "none",
                                      borderBottom: " 1px solid #7a7e8a ",
                                      backgroundColor: "transparent",
                                    }}
                                    onChange={(e: any) =>
                                      setEditUserName(e.target.value)
                                    }
                                  />
                                ) : (
                                  <Row
                                    className="font_18"
                                    style={{ textTransform: "capitalize" }}
                                    onClick={() => setUserName(true)}
                                  >
                                    {values.userName}
                                  </Row>
                                )}
                              </div>{" "}
                              <div
                                style={{
                                  textAlign: "start",
                                  cursor: "pointer",
                                }}
                                className="ml-2 font_14 gray1"
                                //  onClick={() => setFieldValue('userName', "")}
                                onClick={removeProfile}
                              >
                                Remove
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                          className="mb_1"
                          // style={{ alignItems: "center", display: "flex" }}
                        >
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="coverImg_uploader mt-1"
                            showUploadList={false}
                            customRequest={(options: any) =>
                              uploadImage(options, "coverImage")
                            }
                            beforeUpload={beforeUpload}
                          >
                            {getCoverProfile()}
                            <img
                              src={listenerimageupload}
                              className="upload-cover-icon"
                            />
                          </Upload>
                         
                        </Col>
                      </Row>

                      <Row justify="space-between" className="width_100 mt-1 ">
                        <Col
                          md={24}
                          sm={24}
                          xs={24}
                          lg={12}
                          className="inputs select_genera"
                        >
                          <Row className="width_100 font_12 gray1 ">
                            Select Genre
                          </Row>
                          {showError(touched.generes, errors.generes)}
                          <Row className="width_100">
                            {genre?.length !== 0 &&
                              genre.map((genres: any, index) => {
                                return (
                                  <button
                                    type="button"
                                    style={{
                                      background: getBackgroundId(
                                        getFieldProps("generes").value.some(
                                          (e: any) =>
                                            e === genres?.attributes?.id
                                        )
                                      ),
                                      color: getColorId(
                                        getFieldProps("generes").value.some(
                                          (e: any) =>
                                            e === genres?.attributes?.id
                                        )
                                      ),
                                    }}
                                    onClick={() =>
                                      returnSelectedGenre(
                                        getFieldProps,
                                        genres,
                                        setFieldValue,
                                        "generes"
                                      )
                                    }
                                    key={index}
                                    id={genres?.attributes?.id}
                                  >
                                    {genres?.attributes?.name.toUpperCase()}
                                  </button>
                                );
                              })}
                          </Row>
                        </Col>
                        <Col
                          md={24}
                          sm={24}
                          xs={24}
                          lg={12}
                          className="inputs select_genera"
                        >
                          <Row className=" font_12 gray1">Select Vibe</Row>
                          {showError(touched.vibes, errors.vibes)}
                          <Row>
                            {vibes?.length !== 0 &&
                              vibes.map((genres: any, index) => {
                                return (
                                  <button
                                    type="button"
                                    style={{
                                      background: getBackgroundId(
                                        getFieldProps("vibes").value.some(
                                          (e: any) =>
                                            e === genres?.attributes?.id
                                        )
                                      ),
                                      color: getColorId(
                                        getFieldProps("vibes").value.some(
                                          (e: any) =>
                                            e === genres?.attributes?.id
                                        )
                                      ),
                                    }}
                                    onClick={() =>
                                      returnSelectedGenre(
                                        getFieldProps,
                                        genres,
                                        setFieldValue,
                                        "vibes"
                                      )
                                    }
                                    key={index}
                                    id={genres?.attributes?.id}
                                  >
                                    {genres?.attributes?.name.toUpperCase()}
                                  </button>
                                );
                              })}
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        {userType !== "listener" && (
                          <Col
                            lg={12}
                            md={24}
                            sm={24}
                            xs={24}
                            className="inputs select_genera"
                          >
                            <Row className=" font_12 gray1 ">Creative Type</Row>

                            <Row>
                              {creative?.length !== 0 &&
                                creative.map((genres: any, index) => {
                                  console.log(
                                    getFieldProps("creatives"),
                                    "creatives"
                                  );
                                  console.log(genres, "generes");
                                  return (
                                    <button
                                      type="button"
                                      style={{
                                        background:
                                          getFieldProps("creatives").value ==
                                          genres?.name
                                            ? "#3959F9"
                                            : "rgb(48, 48, 48)",
                                        color: getColorId(
                                          getFieldProps("creatives").value ==
                                            genres?.name
                                        ),
                                      }}
                                      onClick={() => {
                                        setFieldValue(
                                          "creatives",
                                          genres?.name
                                        );
                                      }}
                                      key={index}
                                      id={genres?.id}
                                    >
                                      {genres?.name.toUpperCase()}
                                    </button>
                                  );
                                })}
                            </Row>
                          </Col>
                        )}
                        <Col
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                          className="inputs select_genera"
                        >
                          <Row className=" font_12 gray1 ">Select Country</Row>
                          <Select
                            className=" width_100 "
                            placeholder={<b>Choose Country </b>}
                            optionFilterProp="children"
                            clearIcon={false}
                            removeIcon
                            showArrow
                            showSearch={true}
                            onChange={(values) => {
                              setFieldValue("country", values);
                            }}
                            onBlur={handleBlur}
                            value={values.country}
                            // value={values.creatives.map((item: any) => item)}
                            //@ts-ignore
                            helperText={errors.country}
                            error={
                              touched.country && errors.country ? true : false
                            }
                            // optionFilterProp="label"
                            >
                            {mapCountries()}
                          </Select>
                          {showError(touched.country, errors.country)}

                          <Row
                            className=" font_12 gray1 "
                            style={{ marginTop: "2%" }}
                          >
                            Enter City Name
                          </Row>
                          <input
                            type="text"
                            id="city"
                            name="city"
                            style={{
                              width: "100%",
                              padding: "0px 0px",
                              margin: "8px 0",
                              outline: 0,
                              border: "none",
                              borderBottom: " 1px solid #7a7e8a ",
                              backgroundColor: "transparent",
                            }}
                            value={values.city}
                            onChange={(e: any) =>
                              setFieldValue("city", e.target.value)
                            }
                          />
                          {showError(touched.city, errors.city)}
                        </Col>
                      </Row>
                      <Col lg={24} className="creative-profile-button">
                        <button type="submit">Update Profile</button>
                      </Col>
                    </>
                  </Form>
                )}
              </Formik>{" "}
            </>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default EditProfile;
// Customizable Area End