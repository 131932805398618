// Customizable Area Start
import { Carousel, Row, Col } from 'antd';
import React, { useRef } from 'react'
import { nextIC, prevIC } from '../../../../dashboard/src/assets';
import { MCreativePlan } from './MCreativePlan.web';

const MPlanCarousel = ({ getsubscriptions, currentPlan, currentPaymentData }: any) => {



    const carouselRef: any = useRef(null);

    const _settings = {
        autoplay: false,
        dots: true,

    }


    const PrevButtonOrginal = (props: any) => {
        return (
            <p
                style={{
                    position: "absolute",
                    top: `${props.top}`,
                    left: `${props.left}`,
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    margin: "0px -6px"

                }}
                onClick={() => props.buttonRef.current.prev()}
            >
                <img src={prevIC} style={{ height: "30px", width: "30px" }} />
            </p>
        )
    }

    const NextButtonOrginal = (props: any) => {
        return (
            <p
                style={{
                    position: "absolute",
                    top: `${props.top}`,
                    right: `${props.right}`,
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    margin: "0px -6px"

                }}
                onClick={() => props.buttonRef.current.next()}
            >
                <img src={nextIC} style={{ height: "30px", width: "30px" }} />
            </p>
        )
    }

    return (
        <>
        
            {/* Subscription plan */}
            {getsubscriptions &&
            <Row className='width_100' justify="center">
                <Col span={24} style={{ padding: "0px" }}>
                    <div>
                        <Carousel
                            ref={carouselRef}
                            {..._settings}
                        >
                            {getsubscriptions.map((data: any, index: any) => {
                                return(
                                <MCreativePlan
                                    creativePlanAlt={false}
                                    price={data?.attributes?.amount}
                                    name={data?.attributes?.name}
                                    currentPlan={data}
                                    isCurrentPlan={currentPlan?true: false}
                                    // isCurrentPlan={currentPaymentData?.attributes.current_plan.data ? true : false}
                                    currentPaymentData={currentPaymentData}
                                    activatedPlan={currentPlan}


                                />
                            )
                            }
                            )}             </Carousel>
                        {/* <PrevButtonOrginal top="50%" left="-1%" buttonRef={carouselRef}></PrevButtonOrginal> */}
                        {/* <NextButtonOrginal top="50%" right="-1%" buttonRef={carouselRef}> </NextButtonOrginal> */}
                    </div>
                </Col>
            </Row>
}
        </>
    )
}

export default MPlanCarousel
// Customizable Area End