// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import type { RcFile } from 'antd/es/upload/interface';
import { message } from 'antd';
import type { FormInstance } from 'antd/es/form';
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { openNotification } from "../../../../../components/src/Notification.web";
export const configJSON = require('../../config');
import axios from "axios";

interface S {
  values: any;
  cover: any;
  apiToken: any;
  updatedName: any;
  loading: any;
}
interface SS {
}
interface Props {
  id: string;
  open: any;
  handleClose: any;
  playlist: any;
  UpdateDetails: any
}

export default class EditPlaylistPopupController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  apiEditPlaylistCallId: string = "";
  apiEditPlaylistCoverUpdateCallId: string = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      values: {
        Playlist_cover: '',
        playlist_name: ''
      },
      cover: '',
      apiToken: localStorage.getItem('token'),
      updatedName: '',
      loading: false
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  formRef = React.createRef<FormInstance>();
   getBase64(video: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(video);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setState({values: {Playlist_cover: this.props.playlist?.attributes?.cover_image , playlist_name: this.props.playlist?.attributes?.name}})
  }
  
  onFinish = (values: any) =>{
    console.log(values,'values');
  }

   beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  handleChange = async (options: any) => {
    const { file } = options;
    this.getBase64(file, (imageUrl: any) => {
      this.formRef.current?.setFieldsValue({ Playlist_cover: file });
      const {values} = this.state;
      values.Playlist_cover = imageUrl;
      this.setState({values});
    }) 
    console.log(typeof file , "upload file type")
}

  returnBody = (values: any) =>{
    this.setState({updatedName: values.Playlist_name})
    console.log(values.Playlist_cover, 'image')
    if(this.props.playlist?.attributes?.name == values.Playlist_name && this.props.playlist?.attributes?.cover_image == values.Playlist_cover){
      
      openNotification('Nothing is changed for Update','Error')
      this.props.handleClose()
    }else if(this.props.playlist?.attributes?.name != values.Playlist_name && this.props.playlist?.attributes?.cover_image == values.Playlist_cover){
      return {
        name: values.Playlist_name,
        status: this.props.playlist.attributes.status
      }
    }
    else if(this.props.playlist?.attributes?.name == values.Playlist_name && this.props.playlist?.attributes?.cover_image != values.Playlist_cover){
      return  {
        cover_image: values.Playlist_cover,
        status: this.props.playlist.attributes.status
      }
    }
    else if(this.props.playlist?.attributes?.name != values.Playlist_name && this.props.playlist?.attributes?.cover_image != values.Playlist_cover){
      return  {
        cover_image: values.Playlist_cover,
        name: values.Playlist_name,
        status: this.props.playlist?.attributes?.status
      }
    }
  }


  updateCover = (fmData: any) => {
   
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };
    
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEditPlaylistCoverUpdateCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.updatePlaylist}${this.props.playlist?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(fmData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
   createFile = async (url:string) => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: 'image/jpeg'
    };
    let file = new File([data], "test.jpg", metadata);
    return file;
  }
  updatePlaylist = async(values: any) => {
    this.setState({loading: true})

    const fmData: any = new FormData();
    if(typeof(values.Playlist_cover) === 'object'){
      fmData.append(`cover_image`, values.Playlist_cover);
    }
    
    if(values.Playlist_name !== this.state.values.playlist_name){
      fmData.append(`name`, values.Playlist_name);
    }
    fmData.append(`status`, this.props.playlist.attributes.status);

    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        
        "token": this.state.apiToken
      },
    };
    try {
      await axios.put(
        `${configJSON.baseUrl}${configJSON.updatePlaylist}${this.props.playlist?.id}`,
        fmData,
        config
      ).then((res) => {        
          openNotification("Playlist Updated Successfully", "Success")
          let cover_container: any = document.getElementById('update_playlist_image_self_cover')
          cover_container.src = this.state.values.Playlist_cover;
          let cover_background_container: any = document.getElementById('update_playlist_image_self_container')
          cover_background_container.style.backgroundImage = `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 66%, rgba(0, 0, 0, 0.9) 90%),url(${this.state.values.Playlist_cover})`
          let playlistNameUpdate: any = document.getElementById('update_palylist_name_pop')
          playlistNameUpdate.innerText = `${values.Playlist_name}`
          this.setState({
            loading: false,
            values:{...this.state.values,playlist_name:values.Playlist_name}
          })
          this.props.handleClose()
          console.log("server res: ", res)
      }).catch((err) => {
      }).finally(() => {
      })
     }
    catch{

     }

  }

  apiEditResponseCall = (responseJson: any) => {
    if (responseJson != null) {
      if (!responseJson.error) {
        openNotification(responseJson?.data&&responseJson?.data[0].message, 'Success')
        this.props.handleClose()
      } else {
        openNotification(responseJson?.error, 'Error')
        this.props.handleClose()
      }
    }
  }

  apiUpdateCoverResponce = (responseJson: any) =>{
    if (responseJson != null) {
      if (!responseJson.error) {
        openNotification(responseJson?.data&&responseJson?.data[0].message, 'Success')
      } else {
        openNotification(responseJson?.error, 'Error')
      }
    }
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog('Message Received', message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiEditPlaylistCallId) {
        this.apiEditResponseCall(responseJson)
        
        this.props.UpdateDetails()
        this.parseApiCatchErrorResponse(errorReponse);
      }else if (apiRequestCallId === this.apiEditPlaylistCoverUpdateCallId){
        this.apiUpdateCoverResponce(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

}
// Customizable Area End