// Customizable Area Start
import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import "./UserListenerPlan.css";
import ConfirmPlanTemp from "./ConfirmPlanTemp.web";
import UserListenerPlanController from './UserListenerPlanController'


export default class  UserListenerPlan extends UserListenerPlanController {
  render(){
    const { listenerTitle, listenerPlan, interval, getPlanSrc, getPlanAlt, openConfirmPaymentPopup, currentPaymentData, next_invoice_no, isCurrentPlan }: any = this.props;

  let label;
 let popupDisplayTitle

  if (listenerTitle.includes("CREATIVE") ) {
    label = (
      <>
        <span className="listenerFont title_margin">Creative</span>
        {/* <AddIcon className="redColor" fontSize="medium" /> */}

      </>
    );
  } else {
    label = (
      <>
        <span className="listenerFont title_margin">Listener</span>
        {/* <AddIcon className="redColor" fontSize="medium" /> */}
      </>
    );
  }

  if (listenerTitle.includes("CREATIVE") ) {
    popupDisplayTitle = (
      <>
        <span className="displayTitle">Creative</span>
        {/* <AddIcon className="redColor" fontSize="medium" /> */}

      </>
    );
  } 
  else {
    popupDisplayTitle = (
      <>
        <span className="displayTitle">Listener</span>
        {/* <AddIcon className="redColor" fontSize="medium" /> */}
      </>
    );
  }


  return (
    <>
      <Grid container className="listener_container" >
        <Grid xs={12} sm={4} md={4} lg={4} xl={6} item className="listenerPlan_text" >
          {label}
        </Grid>
        <Grid xs={12} sm={4} md={4} lg={4} xl={3} item className="listenerPrice_text" >
          <span className="listenerFont">${`${listenerPlan}/${interval}`}</span>
        </Grid>
        <Grid xs={12} sm={4} md={4} lg={4} xl={3} item className="listenerPlan_Btn" >
          <img
            src={getPlanSrc}
            alt={getPlanAlt}
            style={{ cursor: "pointer" }}
            onClick={() => this.setState({OpenPopup:true})}
          />
          <ConfirmPlanTemp
            open={this.state.OpenPopup}
            handleClose={() =>  this.setState({OpenPopup:false})}
            listenerTitle={popupDisplayTitle}
            subscription={listenerPlan}
            updateSubscription={this.props.updateSubscription}
            openConfirmPaymentPopup={openConfirmPaymentPopup}
            closePayModal={this.props.closePayModal}
            currentPaymentData={currentPaymentData}
            CreateNewPayment={this.props.CreateNewPayment}
            isCurrentPlan={isCurrentPlan}
            next_invoice_no={next_invoice_no}
            interval={interval}
          />
          {/* </Grid> */}
        </Grid>
      </Grid>
    </>
  );
  }
};
// Customizable Area End