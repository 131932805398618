// Customizable Area Start
//@ts-nocheck
import React from "react";
import CommentListController, {
    Props,
} from "./CommentListController.web";
import { Box, Typography, IconButton } from "@material-ui/core";
import Search from '../Common/Search.web'
import CommentChatDialog from "./CommentChatDialog.web";
import MediaProfileDialog from "./MediaProfileDialog.web";
import CommentReplies from "../Common/CommentReplies.web";
import { Message_Icon_Blue } from "../../CustomComponent/SharePlaylistPopup/assets";
import MessageLinkPopup from 'components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web';
import IconMenu from "components/src/CustomChart/PopUps/IconMenu.web";
import { Menu as MenuIcon } from "@material-ui/icons";
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class CommentList extends CommentListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <Box style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                    <Box style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between'}}>
                      <Box style={{}} className="hide_in_desktop_menuicon">
                        <IconButton
                        style={{
                            color: "white",
                            padding: '5px'
                        }}
                        >
                        <MenuIcon className="menu-icon" style={{ width: '3rem', height: '2.5rem' }} onClick={this.handleMenu} />
                        </IconButton>
                        <IconMenu anchorEl= {this.state.anchorEl} handleClose={this.handleMenuClose}/>
                        </Box>
                        <Box style={{width: '80%'}}>
                          <Search onSearchChange={(value: any) => { this.searchComment(value)}} placeholder="Search Replies" />
                        </Box>
                        {/* <img src={Message_Icon_Blue} style={{height: '50px', width: '50px', borderRadius: '50%',marginRight: '2px', marginLeft: '5px'}} onClick={() => {this.setState({openMessageLink: true})}} /> */}
                    </Box>
                <Box className="RepliesBox"><Typography className="RepliesText"><span className="ReplyNoText">{this.state.repliesCount}</span> Replies</Typography></Box>
                <Box style={{height: '100%', flexGrow: 1 }} className="CommentContainerScroll">
                    {
                        this.state.tracks.length > 0 ?
                        this.state.tracks.map((track: any, index: any) => {
                            return (
                                <React.Fragment key={track.id} >
                                    <CommentReplies comment={track} selected={false} unread={track.is_read} isVideo={track.track_upload_type === 'Video'} onUserCommentClick={this.viewTrackComments} onUserProfileClick={this.viewTrackDetail} TrackIndex = {index} setTrackIndex = {(index: any) => {this.setState({selectedTrackIndex: index})}} selectedTrackIndex={this.state.selectedTrackIndex} />
                                </React.Fragment>
                            )
                        })
                        :
                        (
                            <>
                            <div style={{ height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{color: 'white', fontSize: '20px', fontWeight: 800}}> No Replied Tracks</div>
                            </div>
                            </>
                        )
                    }
                </Box>
                <CommentChatDialog
                    isOpen={this.state.showCommentChatBox}
                    closeCommentChat={this.closeCommentChatDialog}
                    commentMedia={this.state.selectedComment}
                    selectedComment={this.state.selectedCommentsList}
                    replies={[]}
                    sendComment={this.sendComment}
                    selectedComentReply={this.selectedComentReply}
                    selectedCommentForReply={this.state.selectedCommentForReply}
                    closeCommentReply={this.closeCommentReply}
                    newcomment = {this.state.newComment}
                    sendNewComment={this.sendNewComment}
                    setOnChangeNewComment={this.setOnChangeNewComment}
                />
                <MediaProfileDialog
                    isOpen={this.state.showMediaDetail}
                    closeMediaDialog={this.closeProfileMedia}
                    media={this.state.selectedComment}
                    selectedTrackMediaDetails={this.state.selectedTrackMediaDetails}
                    followUser={this.setFollowUser}
                    AlertMessage ={this.state.AlertMessage}
                    openAlertDialog ={this.state.openAlertDialog}
                    handleClose = {this.handleClose}
                    doUnFollow ={(userID: any) => this.doUnFollow(userID)}
                />
                <MessageLinkPopup id = "listner_Message" showMsgModal={this.state.openMessageLink} closeMsgModal={() => {this.setState({openMessageLink: false})}}
                      closeModalDialog={() => {this.setState({openMessageLink: false})}} copiedLinked={""} currentUserId={1}
                
                />
            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default CommentList;
// Customizable Area End
