// Customizable Area Start
import React from 'react'
import { Hidden } from "@material-ui/core";
import '../../../user-profile-basic/src/Creatives/AddTrack.web.css'
import { Layout, Row, Col, Spin} from "antd";

import { Content } from "antd/lib/layout/layout";
import HomeHeader from "../../../../components/src/CustomChart/PopUps/HomeHeader.web";
import './index.web.css'

import AddTrackController from './AddTrackController.web';
import { HISTORY } from "../../../../components/src/common";
import ChangeStep from './ChangeStep.web';
import AsideLeft from '../../../../components/src/CustomChart/PopUps/AsideLeft.web';



export default class AudioTrack extends AddTrackController {
  render(){
  const {loading, step, token, userType, subscriptionPlan}: any = this.state;
  

  if (!token) {
    HISTORY.push('/home')
  }

  if (userType == "listener" && subscriptionPlan == "NXZ") {
    HISTORY.push('/home')
  }
  return (
    <>
    {loading?
      <>
      <Row align="middle" justify="center"
        className="gray1 width_100 pt_01 pb_1 " >
        <Col span={24} style={{ textAlign: 'center' }}>
        <h1 style={{color: 'white'}}>Loading...</h1>{' '}  <Spin size="large" /></Col>
      </Row>
      </>
      :
    <div
      style={{
        //maxWidth: "100vw",
        overflow: "hidden",
        //overflowY: 'auto',
        backgroundColor: "#171717",
       // height: "100vh",
      }}
    >   
      <Layout style={{ backgroundColor: "#171717", height: "100vh"}}>   
        {/* .......................SideBar...................................... */}
        {/* <AsideLeft /> */}
        <Hidden smDown>
          <AsideLeft />
        </Hidden>
       
        <Content
          style={{
            margin: "0px",
            //height: "100%",
            backgroundColor: "rgb(5 4 4)",
            display: "flex",
            flexDirection: "column",
            //overflowY: 'auto'
          }}
        >
          <HomeHeader /> 
          <ChangeStep  step={step} setActiveStep={(step: any)=>{this.setStep(step)}} setBackStep={(step: any) => {this.setStep(step)}}/>
        </Content>
      </Layout>
      
    </div>
    }
    </>
  )
}

}
// Customizable Area End