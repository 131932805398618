// Customizable Area Start
import React from 'react'
import {
    Grid,
    Dialog,
} from "@material-ui/core";
import { Row, Col } from "antd";
import './add-to-queue.css'
import AddToQueuePopupController from './AddToQueueController.web';
import { AddtoQueueBlue, PlaylistQueueBlue } from './assets.web';
import {CreateAudioPlaylistPopup} from '../CreateAudioPlaylistPopup/CreateAudioPlaylistPopup.web';


export class AddToQueuePopup extends AddToQueuePopupController {

  render() {    
    const {addSongToQueue} = this.context;
    return (
     <Dialog 
     open={this.props.open}
     onClose={this.props.handleClose}
     PaperProps = {{className: "dialog-Paper-addtoqueue-popup"}}
     >
         <Row justify="center" style={{height: '100%'}} >
                <Col >
                  <Grid container  style={{justifyContent: 'center', padding: '10px', flexDirection: 'column', alignItems: 'center',height: '100%'}}>
                    <Grid item className='add-to-queue-frame'>
                       <Grid style={{alignItems: 'center',justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                         <Grid style={{fontSize: '22px',fontWeight: 900, color: 'gray'}}>Add To</Grid>
                         <Grid style={{fontSize: '32px',fontWeight: 900, color: 'white', fontFamily: 'inter'}} >QUEUE</Grid>
                         <Grid style={{fontSize: '16px',fontWeight: 'bold', color: 'gray', fontFamily: 'inter'}}>Add this to <span style={{fontSize: '16px',fontWeight: 'bold', color: 'blue', fontFamily: 'inter'}}>Next In Queue</span></Grid>
                         <Grid style={{fontSize: '16px',fontWeight: 'bold', color: 'gray', fontFamily: 'inter'}}>list of things to play</Grid>
                       </Grid>
                       <Grid style={{display: 'flex',width: '90%', cursor: 'pointer' ,alignItems: 'center',padding: '10px', margin: '15px', borderRadius: '60px', backgroundColor: '#141414', fontSize: '18px',fontWeight: 'bold', color: 'white', fontFamily: 'inter'}} onClick={() => {addSongToQueue(this.props.trackData); this.props.handleClose()}}>
                         <img src = {AddtoQueueBlue} />
                         <Grid style={{marginLeft: '15px'}}>Add To Queue</Grid>
                       </Grid>
                    </Grid>
                    <Grid item className='add-to-queue-frame' style={{marginTop: '30px'}}>
                       <Grid style={{alignItems: 'center',justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                         <Grid style={{fontSize: '22px',fontWeight: 900, color: 'gray'}}>Add To</Grid>
                         <Grid style={{fontSize: '32px',fontWeight: 900, color: 'white', fontFamily: 'inter'}} >PLAYLIST</Grid>
                         <Grid style={{fontSize: '16px',fontWeight: 'bold', color: 'blue', fontFamily: 'inter'}}>Save This Vibe <span style={{fontSize: '16px',fontWeight: 'bold', color: 'gray', fontFamily: 'inter'}}>and</span></Grid>
                         <Grid style={{fontSize: '16px',fontWeight: 'bold', color: 'gray', fontFamily: 'inter'}}>play it later</Grid>
                       </Grid>
                       <Grid test-id = "add_to_playlist_button" style={{display: 'flex',width: '90%', alignItems: 'center',padding: '10px', margin: '15px', borderRadius: '60px', backgroundColor: '#141414', fontSize: '18px',fontWeight: 'bold', color: 'white', fontFamily: 'inter',cursor:"pointer"}} onClick={() => this.setState({openPlaylist: true})}>
                         <img src = {PlaylistQueueBlue} />
                         <Grid style={{marginLeft: '15px'}}>Add To Playlist</Grid>
                       </Grid>

                       {this.state.openPlaylist &&

                       <CreateAudioPlaylistPopup id="Queue_Playlist" open={this.state.openPlaylist} handleClose={() => {this.setState({openPlaylist: false})}} playlistType = {this.props.playlistType} trackData = {this.props.trackData}/>

                       }
                    </Grid>
                 </Grid>
              </Col>
         </Row>
     </Dialog>
    )
  }
}

export default AddToQueuePopup
// Customizable Area End