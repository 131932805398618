// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { HISTORY } from "components/src/common";
import TextMsgCard from "./TextMsgCard.web";
import ProfileMsgCard from "./ProfileMsgCard.web";
import TrackMsgCard from "./TrackMsgCard.web";
export const configJSON = require("./config.js");

export interface Props {
    chatData: any;
    particularUserDataRes: any;
    isChartMessage: any;
}
interface S {
    messageType: any;
    user_id:any
    profile_img:any
}
interface SS {
  id: any;
}

export default class MessageCardController extends BlockComponent<
  Props,
  S,
  SS
> {
 
    messagesEndRef: any;


  constructor(props: Props) {
    super(props);
    this.messagesEndRef = React.createRef();

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
        messageType: {
            type: 'txt',
            id: ""
        },
        user_id: localStorage.getItem("user_id"),
        profile_img: localStorage.getItem("profile_img")
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    let msgData = this.checkMessageType(this.props.chatData.text);
    if (msgData.type !== 'txt') {
      this.setState({ messageType: msgData });
    }
    this.scrollToBottom();

  }

   extractIdFromText = (textData:any, regex:any) => {
    const match = textData.match(regex);
    return match ? match[1] : '0';
  };
  
   getMessageInfo = (textData:any, regex:any, type:any) => {
    const extractedId = this.extractIdFromText(textData, regex);
    return { extractedId, type };
  };
  
   checkMessageType = (textData:any) => {
    if (textData.includes('/home/expand/song/songId=')) {
      return this.getMessageInfo(textData, /\/home\/expand\/song\/songId=(\d+)/, 'track');
    }
    if (textData.includes('/invitation/songId=')) {
      return this.getMessageInfo(textData, /\/invitation\/songId=(\d+)/, 'invitation');
    }
    if (textData.includes('/invitation/videoId=')) {
      return this.getMessageInfo(textData, /\/invitation\/videoId=(\d+)/, 'videoinvitation');
    }
    if (textData.toLowerCase().includes('/home/charts/videos?id=')) {
      return this.getMessageInfo(textData, /\/home\/charts\/videos\?id=(\d+)/i, 'video');
    }
    if (textData.includes('/creatives/beats?creative_id=')) {
      return this.getMessageInfo(textData, /\/creatives\/beats\?creative_id=(\d+)/i, 'profile');
    }
    if (textData.includes('/invitation/playlistId=')) {
      return this.getMessageInfo(textData, /\/invitation\/playlistId=(\d+)/, 'playlist');
    }
    return { id: '', type: 'txt' };
  };
  
  

  componentDidUpdate(prevProps:any) {
    if (prevProps.chatData !== this.props.chatData) {
      let msgData = this.checkMessageType(this.props.chatData.text);
      this.scrollToBottom();
      if (msgData.type !== 'txt') {
        this.setState({ messageType: msgData });
      }
    }
  }
  redirectProfile = (currentuserType: any, currentUserId: any) => {
    const userId: any = localStorage.getItem("user_id");

    localStorage.setItem("temp_id", userId);
    localStorage.removeItem("user_id");
    localStorage.setItem("user_id", currentUserId);
    localStorage.setItem("creatives_screen", "true");
    localStorage.setItem("current_type", currentuserType);
    if (currentuserType?.includes("creative")) {
      HISTORY.push(`/creatives/beats?creative_id=${currentUserId}`);
    } else if (currentuserType?.includes("listener")) {
        HISTORY.push(`/listeners/activity?listener_id=${currentUserId}`);
    } else {
        HISTORY.push(`/originals/beats?originals_id=${currentUserId}`);
    }
  };



  returnMessageFormat = () => {
    const { chatData } = this.props;
    const messageType = this.state.messageType.type;
    const isTxtMessage = messageType === "txt";
    const isPlaylistMessage = messageType === "playlist";
    const isTrackMessage = messageType === "track" || messageType.includes("invitation");
    const isProfileMessage = messageType === "profile";
    const avtar = chatData.isRecived ? chatData.sender.avtar : this.state.profile_img;
    let returnMessageFormat;
    if (isTxtMessage) {
      returnMessageFormat = (
        <TextMsgCard
          isRecived={chatData.isRecived}
          avtar={avtar}
          index={chatData}
          senderName={chatData.sender.name}
          timetoken={chatData.timetoken}
          text={chatData.text}
          particularUserDataRes={this.props.particularUserDataRes}
          onRedirect={(userType, userId) => this.redirectProfile(userType, userId)}
        />
      );
    } else if (isTrackMessage || messageType === "video") {
      returnMessageFormat = (
        <TrackMsgCard
          isRecived={chatData.isRecived}
          avtar={avtar}
          index={chatData}
          isTrack={isTrackMessage}
          senderName={chatData}
          timetoken={chatData.timetoken}
          text={chatData.text}
          trackId={this.state.messageType.extractedId}
          userid={this.state.user_id}
          onRedirect={(userType: any, userId: any) => this.redirectProfile(userType, userId)}
          particularUserDataRes={this.props.particularUserDataRes}
          isChartMessage={this.props.isChartMessage}
          trackType={messageType}
          checkMessageType={()=>this.checkMessageType(chatData.text)}
        />
      );
    } else if (isProfileMessage || isPlaylistMessage) {
      returnMessageFormat = (
        <ProfileMsgCard
          isRecived={chatData.isRecived}
          avtar={avtar}
          index={chatData}
          senderName={chatData}
          timetoken={chatData.timetoken}
          text={chatData.text}
          userId={this.state.messageType.extractedId}
          onRedirect={(userType, userId) => this.redirectProfile(userType, userId)}
          particularUserDataRes={this.props.particularUserDataRes}
          isChartMessage={this.props.isChartMessage}
          trackType={messageType}

        />
      );
    } else {
      returnMessageFormat = (
        <TextMsgCard
          isRecived={chatData.isRecived}
          avtar={chatData.sender.avtar}
          index={chatData}
          senderName={chatData}
          timetoken={chatData.timetoken}
          text={chatData.text}
          onRedirect={(userType, userId) => this.redirectProfile(userType, userId)}
          particularUserDataRes={this.props.particularUserDataRes}
        />
      );
    }
  
    return returnMessageFormat;
  };
  

   scrollToBottom = () => {
    this.messagesEndRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end", // Scroll to the bottom of the container
      inline: "nearest", // Crop from the top
    });
  
  };

  
}
// Customizable Area End
