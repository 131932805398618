// Customizable Area Start
import React from 'react'
import { Grid, Button } from "@material-ui/core";
import '../../../user-profile-basic/src/Creatives/AddTrack.web.css'

import './index.web.css'
import {uploadCompleteCover, nxzSoundLogo} from '../assets';

function UploadComplete() {
  return (
    <Grid container style={{overflowX: 'hidden',overflowY: 'auto'}}>
          
       <div className='main-div'>
            <Grid container item xs={12} md={12} lg={7} style={{justifyContent: 'center', display: 'flex', alignItems: 'center'}} >

               <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                 <Grid>
                    <img className='responsive-margin'  src = {nxzSoundLogo}/>
                 </Grid>
                 <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{color: 'white',fontSize: '32px', fontWeight: 900,fontFamily: 'Inter'}}>Upload</div>
                    <div style={{color: 'white',fontSize: '32px', fontWeight: 900,fontFamily: 'Inter'}}>Complete</div>
                 </Grid>
               </Grid>  
            </Grid>

          
            {
              <>   
              <Grid item xs={12} md={12} lg = {5} className="hide-side-image-in-responsive">
                <div className='' style={{width: '100%', height: '100%', backgroundColor: 'rgb(5 4 4)',position: 'relative', overflow: 'hidden' }}>
                   <img style={{borderTopLeftRadius: '100px',border: '5px solid #141414',width: '100%', height: '100%'}} src={uploadCompleteCover} />
                   
                   <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                   
                     <div className=' name-font-weight' style={{color: 'white' ,fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>Share Your Release</div>
                     <p className=' name-font-weight' style={{color: 'white',fontFamily: 'Inter', fontSize: '32px',textShadow: '-10px -4px 34px rgba(0,0,0,.8)'}}>With The World</p>
                   

                   <Button className=' buttons-font-weight' style={{backgroundColor:'#212121',padding: '15px 118px 15px 104px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px',boxShadow: '20px 30px 70px 40px rgba(0,0,0,.8)', marginBottom: '10px'}} >Profile</Button>
                   <Button className=' buttons-font-weight' style={{backgroundColor:'#212121',padding: '15px 127px 15px 125px' ,borderRadius: '30px',color: 'white',fontFamily: 'Karla', fontSize: '20px',boxShadow: '20px 40px 30px 40px rgba(0,0,0,.8)'}}>CTRL</Button>
                   {/* <Button variant="text" className='name-position buttons-font-weight' style={{backgroundColor:'trasparent',padding: '19px 127px 19px 126px' ,borderRadius: '30px',color: 'white' ,position: 'absolute', top: '635px',fontFamily: 'Karla', fontSize: '20px'}}>BACK</Button> */}

                </div>
                </div>
                </div>

                
              </Grid>
            
            <Grid item xs={12} md={12} lg = {5} className="hide-buttons-in-large-screens">
              <div  style={{ backgroundColor: 'rgb(5 4 4)', overflow: 'hidden', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center'}}>
                <Button style={{backgroundColor:'#212121',padding: '15px 118px 15px 104px',borderRadius: '30px' , color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px', fontWeight: 'bold', textTransform: 'capitalize'}} >Profile</Button>
                <Button style={{backgroundColor:'#212121',padding: '15px 127px 15px 125px' ,borderRadius: '30px', color: 'white' ,fontFamily: 'Karla', fontSize: '20px', marginLeft: '25px', marginBottom: '10px', marginTop: '20px', fontWeight: 'bold', textTransform: 'capitalize'}}>CTRL</Button>

              </div>

            </Grid>
            </>
           }

            </div> 
         </Grid>
  )
}

export default UploadComplete
// Customizable Area End
