// Customizable Area Start
import React from 'react';
import { Box, Hidden, Modal } from '@material-ui/core';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import ConfirmplanAndPay from './ConfirmplanAndPay.web';
import './UserListenerPlan.css';
import AddIcon from '@material-ui/icons/Add';
import ConfirmPlanTempController from './ConfirmPlanTempController'

export default class ConfirmPlanTemp extends ConfirmPlanTempController {
  render(){
  const { open, handleClose, listenerTitle, subscription,interval, openConfirmPaymentPopup, updateSubscription, closePayModal, currentPaymentData, CreateNewPayment, isCurrentPlan, next_invoice_no}= this.props
  return (
    <Modal open={open} onClose={handleClose} className='PlanDialog'>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}>
        <Hidden smDown>
          <AsideLeft />
        </Hidden>
        <ConfirmplanAndPay
          planName={
            listenerTitle === 'Creatives' || listenerTitle === 'Listener' ?

              <button className='displayParentTitle'>
                <span>
                  {listenerTitle}
                </span>
                <AddIcon className='redColor' fontSize='medium' />
              </button>
              :
              <button className='displayParentTitle'>{listenerTitle}</button>

          }
          planSubscription={`${subscription} ${interval}`}
          nextPaymentDue='Feb 1, 2023'
          handleClose={handleClose}
          updateSubscription={updateSubscription}
          openConfirmPaymentPopup={openConfirmPaymentPopup}
          closePayModal={closePayModal}
          currentPaymentData={currentPaymentData}
          CreateNewPayment={CreateNewPayment}
          isCurrentPlan={isCurrentPlan}
          next_invoice_no={next_invoice_no}
        />
      </Box>
    </Modal>
  );
        }
}

// Customizable Area End