// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
export interface Props {
  title: any;
  closeCancelledModal: any;
  cancelSubscription: any;
  userType: any;
  price: any;
  nextPaymentDue: any;
  creativeSrc: any;
  creativeAlt: any;
  current_plan: any;
  status_of_plan: any;
  cancelledSubscription: any;
  data:any;
}
interface S {
    OpenPopup:boolean;
}
interface SS {
  id: any;
}

export default class ConfirmPlanTempController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
        OpenPopup:false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    // Customizable Area End
  }
}
// Customizable Area End
