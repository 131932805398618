// Customizable Area Start
import React from 'react';
import { Grid } from '@material-ui/core';
import {ArrowUp, PlayIcon, VideoUserAvatar, messageIcon, repeatIcon} from 'blocks/dashboard/src/assets'
export const TopMobileCreatives = () =>{
    return(
        <>
        <Grid style={{display: 'flex', flexDirection: 'column', backgroundColor: '#141414', padding: '5px 10px', borderRadius: '10px'}}>
        <Grid style={{display: 'flex', justifyContent: 'space-between'}}>
            <Grid style={{display: 'flex'}} >
                <Grid style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{color: 'white', fontSize: '16px'}}>1</div>
                <img src={ArrowUp}/>
                </Grid>
                <Grid>
                    <img style={{height: '48px', width: '48px'}} src={VideoUserAvatar}/>
                </Grid>
                <Grid style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start', marginLeft: '10px'}}>
                    <div style={{color: 'white', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold'}}>Cardi B</div>
                    <div style={{color: 'white'}}>Artist</div>
                </Grid>
            </Grid>
        </Grid>
        <Grid style={{display: 'flex', backgroundColor: 'black', padding: "2px", borderRadius: '10px', marginBottom: '10px', justifyContent: 'space-around'}}>
                 <Grid className="" style={{ color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={PlayIcon} height="24px" width="24px" />
                    <span className="play-songs-times">{'22k'}</span>
                </Grid>
                <Grid  className="" style={{ color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={repeatIcon} height="24px" width="24px" />
                    <span className="play-songs-times">{ '22k'}</span>

                </Grid>
                <Grid  className="" style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={messageIcon} height="24px" width="24px" />
                    <span className="play-songs-times" style={{ marginLeft: "4px" }}>{'22k'}</span>
                </Grid>

                <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                    <span style={{color: 'blue'}}>24h%</span>
                    <span>-0%</span>
                    </Grid>
                <Grid style={{color: 'white',fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', display: "flex", alignItems: "center" , justifyContent: 'center', flexDirection: 'column'}}>
                  <span style={{color: 'blue'}}>7d%</span>
                    <span>+0%</span>
                </Grid>
            </Grid>
            </Grid>
        </>
    )
}

// Customizable Area End