// Customizable Area Start
import React from "react";
import '../../../../../user-profile-basic/src/Common/TopSongs.web.css'
import './index.css'
import { Typography, Grid, Box, Hidden, } from "@material-ui/core";
//@ts-ignore
import WebPlaylistController from "../../../WebPlaylistController";
import { filterW } from "../../../../../dashboard/src/assets";
import SharePlaylistPopup from "../../../../../dashboard/src/CustomComponent/SharePlaylistPopup/SharePlaylistPopup.web";

export default class TopPlaylists extends WebPlaylistController {

  render() {
    const { showPlaylist, openPopup, selectedRepostSong }: any = this.state;
    return (
      <Box
        style={{ padding: "5px 0px 5px 0px", width: "100%", maxHeight: "415px", display: "flex", flexDirection: "column" }}>
        <Grid style={{ alignItems: "center", display: "flex", marginBottom: "2%" }} container>
          <Grid item xs={12} sm={7} md={7} lg={8} style={{ display: "flex",alignItems:"center" }}>
            <Grid item xs={3} lg={4} >
              <Typography className="top-head">{showPlaylist?.attributes?.playlist_items?.data ?  showPlaylist.attributes.playlist_items.data.length + ' Tracks ' : 'Tracks'}</Typography>
            </Grid>
            <Grid item xs={4} md={5} lg={4}  >
              <div className="outer-div-toggle playlist-share-toggle">
                <button className="playlist-share-btn" 
                  onClick={this.onShare}
                >Share</button>

              </div>
            </Grid>
            <Grid item xs={5} md={5} lg={5} style={{ display: "flex", justifyContent: "flex-start" }}>
              
            <div className="outer-div-toggle playlist-follow-toggle" >
            {showPlaylist?.attributes?.followed_by_current_user?
              
              <button className="playlist-unfollow-btn"
                onClick={()=>this.UnfollowPublicPlaylist(showPlaylist?.id)}
              >Unfollow</button>
            :
            <button className="playlist-follow-btn"
                onClick={()=>this.followPublicPlaylist(showPlaylist?.id)}
              >Follow</button>
            }

              </div>
            </Grid>
          </Grid>
          <Grid item xs={4}
            // md={4} 
            sm={5}
            md={5}
            lg={4}
            style={{ display: "flex", alignItems: "center" }}
          // className="second-head-percentage"
          >
            <Hidden xsDown>
              <Grid item xs={3}>
                <Typography className=" text_white1 top_size_16 hiddnDaysScore " >24hrs%</Typography>
              </Grid>
              <Grid item xs={4}>

                <Typography className="text_white1 top_size_16" >7d %</Typography>
              </Grid>
              <Grid item xs={4} >
                <Typography className="text_white1 top_size_16 ">
                  Last 7d
                </Typography>
              </Grid>

              <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }} >
                <img src={filterW} style={{
                 width: "20px",
                 height: " 17.8px",
                 marginTop: "9px"
                }} />
              </Grid>
            </Hidden>

          </Grid>
        </Grid>
        <SharePlaylistPopup
          id= "Playlist"
          open={openPopup}
          handleClose={() => {
            this.setState({ openPopup: false })
          }}
          playlist = {showPlaylist}
          selectedSong = {selectedRepostSong}
          isPlaylistRepost = {true}
        />
      </Box>

    );
  }
}
// Customizable Area End