// Customizable Area Start
import {
    Menu as MaterialMenu,
    MenuItem,
  } from "@material-ui/core";
import { Chartsblue, Chartsicon, Creativeblue, Creativesicon, DownloadIcon, Downloadblue, Homeblue, Homeicon, OriginalBlue, Playlistblue, Playlisticon, Videoblue, Videoicon, originalIcon } from "blocks/dashboard/src/assets";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
export const configJSON = require('blocks/dashboard/src/config');


const IconMenu = (props: any) => {

    const [currentKeyCharts, setCurrentKeyCharts] = useState("3")
    const history = useHistory();

    const {anchorEl, handleClose} = props;
    const token: any = localStorage.getItem("token");
    const userType = localStorage.getItem("user_type")
    const [originals, setOriginals] = useState("")
    const [currentKeyPlaylist, setCurrentKeyPlaylist] = useState("5")
    const [menuList, setMenuList] = useState([
        { key: "1", label: "Home", path: "/home", icon: Homeblue, defaultIcon: Homeicon },
        { key: "2", label: "Originals", path: `/originals/beats?originals_id=${originals}`, icon: originalIcon, defaultIcon: originalIcon },
        { key: "3", label: "Charts", path: "/home/Charts/beats", icon: Chartsblue, defaultIcon: Chartsicon },
        { key: "4", label: "Videos", path: "/home/VideosPage", icon: Videoblue, defaultIcon: Videoicon },
        { key: "5", label: "Playlist", path: "/home/all-playlist", icon: Playlistblue, defaultIcon: Playlisticon },
        { key: "6", label: "Download", path: "/home/downloadPage", icon: Downloadblue, defaultIcon: DownloadIcon },
        { key: "7", label: "Creatives", path: "/home/Creatives", icon: Creativeblue, defaultIcon: Creativesicon },
        { key: "8", label: "Comments & DM", path: "/comments", icon: Creativeblue, defaultIcon: Creativesicon }
      ])

      useEffect(() => {
        if (token) {
          getOriginals()
    
        } 
      }, []);
    
      const getOriginals = async () => {
        await axios.get(`${configJSON.baseUrl}/bx_block_audiomusic/original_profile`, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
    
            token: token,
          }
        })
          .then((response) => {
            setOriginals(response?.data?.data?.id)
          }).catch((err) => {
            console.log(err);
          });
      }


    const returnLabel = (labelName: any, label: any) => {
        if (userType !== 'original') {
          return (
            <span className={labelName}> {label}</span>
          )
    
        }
      }

    const returnLoginSidebar = (items: any) => {
        return items.map((_item: any) => {
          let pathName: any ;
          let labelName: any = 'seleted_text '
          let widthSpecific: any = 24
          let heightSpecific: any = 24
          let marginRightSpecific: any = "10px"
          if (location.pathname.split('/').includes('originals') && _item.key == "2") {
            pathName = OriginalBlue
          } else if (location.pathname.split('/').includes('Charts') && _item.key == currentKeyCharts) {
            pathName = Chartsblue
          } else if (
            (location.pathname.split('/').includes('all-playlist') || location.pathname.split('/').includes('public-view') || location.pathname.split('/').includes('self-view')) && _item.key == currentKeyPlaylist) {
            pathName = Playlistblue
          } else if (location.pathname === _item.path) {
            pathName = _item.icon
          } else {
            pathName = _item.defaultIcon
            labelName = 'non_seleted_text'
            if (_item.key === "4") {
              widthSpecific = 29
              heightSpecific = 26
              marginRightSpecific = "5px"
            } else if (_item.key === "6") {
              heightSpecific = 20
            }
          }
    
          return (
    
            <MenuItem
              style={{ display: "flex", alignItems: "center", marginBottom: "13px" }}
              key={_item.path}
              onClick={() => {
                pageNavigate(_item.path);
              }}>
              <img
                src={pathName}
                width={widthSpecific}
                height={heightSpecific}
                alt=""
                style={{ marginRight: marginRightSpecific }}
              />
              {returnLabel(labelName, _item.label)}
            </MenuItem>
          )
        }
    
        )
      }

      useEffect(() => {
        let items = [
          { key: "1", label: "Home", path: "/home", icon: Homeblue, defaultIcon: Homeicon },
          { key: "2", label: "Charts", path: "/home/Charts/beats", icon: Chartsblue, defaultIcon: Chartsicon },
          { key: "3", label: "Videos", path: "/home/VideosPage", icon: Videoblue, defaultIcon: Videoicon },
          { key: "4", label: "Playlist", path: "/home/all-playlist", icon: Playlistblue, defaultIcon: Playlisticon },
          { key: "5", label: "Download", path: "/home/downloadPage", icon: Downloadblue, defaultIcon: DownloadIcon },
          { key: "6", label: "Creatives", path: "/home/Creatives", icon: Creativeblue, defaultIcon: Creativesicon }
        ];
        if (userType === "original") {
          setMenuList(items)
          setCurrentKeyCharts("2")
          setCurrentKeyPlaylist('4')
        }
      }, [userType])

      const items1 = [
        { key: "1", label: "Home", path: "/home", icon: Homeblue, defaultIcon: Homeicon },
        { key: "2", label: "Charts", path: "/home/Charts/beats", icon: Chartsblue, defaultIcon: Chartsicon },
        // { key: "3", label: "Stream", path: "/home/Stream", icon: Creativeblue, defaultIcon: Creativesicon },
      ];

      const pageNavigate = (path: any) => {
        history.push(path);
      };
    
      const returnWithoutLoginSidebar = (items: any) => {
        return items1.map((_item) => {
          let pathName: any = ""
          let labelName: any = ""
          /*eslint no-unused-expressions: ["error", { "allowShortCircuit": true }]*/
          if (location.pathname.split('/').includes('Charts') && _item.key == '2') {
            pathName = Chartsblue
            labelName = 'seleted_text'
          } else if (location.pathname === _item.path) {
            pathName = _item.icon
            labelName = 'seleted_text '
          } else {
            pathName = _item.defaultIcon
            labelName = 'non_seleted_text'
          }
          return (
    
            <MenuItem
              key={_item.path}
              onClick={() => {
                pageNavigate(_item.path);
              }}>
              <img
                src={pathName}
                width={24}
                height={24}
                alt=""
                style={{ marginRight: "10px" }}
              />
              {returnLabel(labelName, _item.label)}
    
            </MenuItem>
          )
        })
      }

   return (
    <>
    <MaterialMenu
        elevation={0}
        getContentAnchorEl={null}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        // open={open}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ zIndex: 1 }}
        PaperProps={{
          style: {
            backgroundColor: "rgb(9, 9, 9)",
          }
        }}
      >

        {token ?
          returnLoginSidebar(menuList)
          :
          returnWithoutLoginSidebar(items1)
        }

      </MaterialMenu>
    </>
   )
}
export default IconMenu;
// Customizable Area End
