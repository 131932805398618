// Customizable Area Start
import React from "react";
import ImportentNoticeController, {
    Props,
} from "./ImportentNoticeController.web";
import "./ImportentNotice.web.css";
import AsideLeft from "../../../../../components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "../../../../../components/src/CustomChart/PopUps/HomeHeader.web";
import Loader from "../Loader.web";
import { Layout } from "antd";
import { Grid, Hidden, Box, Typography } from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import RightPanel from '../Common/RightPanel.web';
import { ImportentNoticeRightBg } from "../assets";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export class ImportentNotice extends ImportentNoticeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading } = this.state;
        return (
            <>
                <Layout className="MainLayoutContainer">
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        className="MainContent"
                    >
                        <HomeHeader />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="INMainPanel">
                                <Grid container>
                                    <div className='MainContentDiv'>
                                        <Grid item xs={12} sm={12} md={6} lg={7} xl={8} className='ColumnCenter'>
                                            <div className='ColumnCenter AICenter'
                                            >

                                                <Hidden only={['lg', 'xl']}>
                                                    <div className="ColumnCenter AICenter MT32">
                                                        <div className='CommonText InfoTextSm'>Like Wait... Do You</div>
                                                        <p className='CommonText InfoTextSm'>Understand?</p>
                                                    </div>
                                                </Hidden>
                                                {/* Importent Notice START*/}
                                                <Box
                                                    className="INBox"
                                                >
                                                    <Box className="WaitBox">
                                                        <Typography className="WaitText">WAIT</Typography>
                                                    </Box>
                                                    <Box className="SOBox">
                                                        <Typography className="SOText">Sent Offers</Typography>
                                                    </Box>
                                                    <Box className="SOBox">
                                                        <Typography className="SOText">Can’t Be</Typography>
                                                    </Box>
                                                    <Box className="CNCBox">
                                                        <Typography className="CNCText">Canceled</Typography>
                                                    </Box>
                                                    <Box className="NTCBox">
                                                        <Typography className="NTCText">
                                                            Funds used for this offer will be
                                                        </Typography>
                                                    </Box>
                                                    <Box className="NTCBox">
                                                        <Typography className="NTCText">
                                                            unavailable until this offer
                                                        </Typography>
                                                    </Box>
                                                    <Box className="NTCBox">
                                                        <Typography className="NTCText">
                                                            expires in 3 days or is rejected.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                {/* Importent Notice END */}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                                            <RightPanel
                                                line1={`Like Wait... Do You`}
                                                line2={`Understand?`}
                                                nextButtonName="I Understand"
                                                backgroundImage={ImportentNoticeRightBg}
                                                onNextClick={this.onNextClick}
                                                onBackClick={this.onBackClick}
                                            />
                                        </Grid>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </Content >
                </Layout >
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default ImportentNotice;
// Customizable Area End