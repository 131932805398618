// Customizable Area Start
import React,{useEffect, useState} from "react";
import "blocks/user-profile-basic/src/Common/TopSongs.web.css";
import { Grid, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
    defaultProfile,
    iconUp,
    PlayIcon,
    repeatIcon,
    messageIcon,
    Sort_Up_large,

} from "blocks/dashboard/src/assets";
const TopSongs = ({ songsList, setTopVideoObj, setSetCurrentKey, setRepostCount, trackId, getFilterTrack,
    setTrackId, setUserTrack, setPlayVideoFromRedirection,setSelectedVideo, setShareVideo }: any) => {

    const [topVideos, setTopVideos] = useState<any>([])

    const setTopSongsData = (item: any, index: any) => {

        if(item.type == "banner"){
          setPlayVideoFromRedirection(true)
          setTopVideoObj(item.attributes)
          setSelectedVideo(item)
          setTrackId(item.attributes.video_file_id)
          setUserTrack(item.attributes.user_tracks?.data)
          setSetCurrentKey(index < 9 ? "0" + Number(index + 1) : index + 1)
          setRepostCount(item.attributes.repost_count)
          setShareVideo(item)
          getFilterTrack(item?.attributes.video_file_id)
        }
        else{
          setPlayVideoFromRedirection(true)
          setTopVideoObj(item.attributes)
          setSelectedVideo(item)
          setTrackId(item.id)
          setUserTrack(item.attributes.user_tracks?.data)
          setSetCurrentKey(index < 9 ? "0" + Number(index + 1) : index + 1)
          setRepostCount(item.attributes.repost_count)
          setShareVideo(item)
          getFilterTrack(item?.id)
        }
        localStorage.setItem("selectedSingleVideo", JSON.stringify({ ...item, currentKey: index < 9 ? "0" + Number(index + 1) : index + 1 }));
    }

    useEffect(() =>{
      if(songsList[0].type == "banner"){
        setTopVideos(songsList.filter((item: any) => item.attributes.banner_button == "Watch"))
      }
      else{
        setTopVideos(songsList)
      }
     
    },[songsList])

    

  return (
    <Grid container xs={12} className="baseline height100">
      <Grid container item xs={12}>
        <Box className="top-head-Font mB8">Top {topVideos.length}</Box>
      </Grid>
      <Grid container item xs={12} className="overflow-hidden-auto">
        {topVideos &&
          topVideos.map((item: any, index: any) => {
            if (item.id != trackId) {
              return (

                 <Grid
                   item  
                    xs={12}    
                   className="parent-videos-list mr5 cursor-pointer"
                  onClick={() => {
                    setTopSongsData(item, index);
                   }}
                   style={{display: 'flex', alignItems: 'center', justifyContent: 'left', padding: '0px 10px'}}
                 >
                    <Box className="top-video-count-grid margin-right-top-songs">
                      <span className="count_songs_font" style={{alignSelf: 'center'}}>
                        {Number(index + 1)}
                      </span>
                      <img src={Sort_Up_large} className="top-video-large-icon" />
                    </Box>
                    <Box 
                      className=" " style={{width: '100%'}}
                      //top-video-justify-center
                      >
                      <Box  className="topsongs-div" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                         <Box className="margin-right-top-songs">
                           <img
                             className="top-video-images videos-image-height-width"
                             //vidImage_in_TopSongs top-video-images
                            style={{}}
                            src={
                              item?.attributes?.art_work ||
                              item?.attributes?.image ||
                             defaultProfile
                            }
                          />
                         </Box>
                      <Grid
                       container
                       className="ml10 txtAndBtnsWrapper_in_topSongsTab"
                       >

                      <Grid item xs={12}>
                        <Typography className="song-name-font">
                           {item?.attributes?.title || "Unknown"}
                         </Typography>
                         <Typography className="topsongs_creative_font">
                           {item?.attributes?.artist_name || "Unknown"}
                         </Typography>
                       </Grid>
                       <Grid item xs={12} className="top-video-combined-icon">
                         <Grid item xs={4} className="top-video-align">
                          <img src={PlayIcon} height="24px" width="24px" />
                           <span className="play-songs-times">
                             {item?.attributes?.play_count ||
                            item?.attributes?.play_count === 0
                              ? item?.attributes?.play_count
                              : "22k"}
                          </span>
                        </Grid>
                        <Grid item xs={4} className="top-video-align">
                          <img src={repeatIcon} height="24px" width="24px" />
                          <span className="play-songs-times">
                            {item?.attributes?.repost_count ||
                            item?.attributes?.repost_count === 0
                              ? item?.attributes?.repost_count
                              : "22k"}
                          </span>
                        </Grid>
                        <Grid item xs={4} className="top-video-align ">
                          <img src={messageIcon} height="24px" width="24px" />
                          <span className="play-songs-times ml4">
                             {item?.attributes?.comments_count ||
                            item?.attributes?.comments_count === 0
                              ? item?.attributes?.comments_count
                              : "22k"}
                          </span>
                        </Grid>
                        </Grid>
                         </Grid>
                         </Box>
                    </Box>
                  </Grid>


                // <Grid
                //   container
                //   item
                //   xs={12}
                //   className="parent-videos-list mr5 cursor-pointer"
                //   onClick={() => {
                //     setTopSongsData(item, index);
                //   }}
                // >
                //   <Grid item xs={1} className="top-video-count-grid">
                //     <span className="count_songs_font">
                //       {Number(index + 1)}
                //     </span>
                //     <img src={Sort_Up_large} className="top-video-large-icon" />
                //   </Grid>
                //   <Grid
                //     item
                //     xs={11}
                //     className="display top-video-justify-center"
                //   >
                //     <Grid container item xs={5} md={4} lg={5} className="topsongs-div">
                //       <img
                //         className="vidImage_in_TopSongs top-video-images"
                //         style={{height: '75px', width: '135px'}}
                //         src={
                //           item?.attributes?.art_work ||
                //           item?.attributes?.image ||
                //           defaultProfile
                //         }
                //       />
                //     </Grid>
                //     <Grid
                //       container
                //       item
                //       xs={6} md={7} lg={6}
                //       className="ml10 txtAndBtnsWrapper_in_topSongsTab"
                //     >
                //       <Grid item xs={12}>
                //         <Typography className="song-name-font">
                //           {item?.attributes?.title || "Unknown"}
                //         </Typography>
                //         <Typography className="topsongs_creative_font">
                //           {item?.attributes?.artist_name || "Unknown"}
                //         </Typography>
                //       </Grid>
                //       <Grid item xs={12} className="top-video-combined-icon">
                //         <Grid item xs={4} className="top-video-align">
                //           <img src={PlayIcon} height="24px" width="24px" />
                //           <span className="play-songs-times">
                //             {item?.attributes?.play_count ||
                //             item?.attributes?.play_count === 0
                //               ? item?.attributes?.play_count
                //               : "22k"}
                //           </span>
                //         </Grid>
                //         <Grid item xs={4} className="top-video-align">
                //           <img src={repeatIcon} height="24px" width="24px" />
                //           <span className="play-songs-times">
                //             {item?.attributes?.repost_count ||
                //             item?.attributes?.repost_count === 0
                //               ? item?.attributes?.repost_count
                //               : "22k"}
                //           </span>
                //         </Grid>
                //         <Grid item xs={4} className="top-video-align ">
                //           <img src={messageIcon} height="24px" width="24px" />
                //           <span className="play-songs-times ml4">
                //             {item?.attributes?.comments_count ||
                //             item?.attributes?.comments_count === 0
                //               ? item?.attributes?.comments_count
                //               : "22k"}
                //           </span>
                //         </Grid>
                //       </Grid>
                //     </Grid>
                //   </Grid>
                // </Grid>
              );
            }
          })}
      </Grid>
    </Grid>
  );
};
export default TopSongs;
// Customizable Area End
