// Customizable Area Start
import React, { useContext } from 'react'
import { Grid, Box } from "@material-ui/core";
import './Activity.web.css'
import ActivityNotifications from "./ActivityNotifications.web";
import AudioActivityNew from "./AudioActivityNew.web";
import VideoActivity from "./VideoActivity.web";
import UserActivity from "./UserActivity.web";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import moment from 'moment';
import { FProfileIcon, notification, RProfileIcon, UProfileIcon } from '../../../../dashboard/src/assets';
import { AudioContextApi } from '../../../../dashboard/src/AudioContext/AudioContext.web';


function ActivitySelfView({ get_All_Activity_data, creativeById, get_notification,handleReadNotification }: any) {
  const { handleAudioVideo, setHandleAudioVideo, handlePlayList } = useContext(AudioContextApi);
  const [selectedIndex, SetSelectedIndex] = React.useState(null)
  const [videos, setVideos] = React.useState(false)
  const [url, setUrl] = React.useState('')
  const [selected, setSelected] = React.useState('')
  const [hide, setHide] = React.useState(true)
  const findDuration = (fromDate: number) => {
    let myDate = moment(fromDate * 1000).format("YYYY/MM/DD HH:mm:ss");

    if (myDate === "Invalid date") {
      myDate = moment(fromDate, "MM-DD-YYYY HH:mm:ss").format(
        "YYYY/MM/DD HH:mm:ss"
      );
    }

    const date1 = moment(myDate);

    const date2 = moment(new Date());

    const days = date2.diff(date1, "days");

    if (days > 1) {

      return `${days} days`;
    } else if (days == 1) {
      return `${days} day`;
    }


    const hours = date2.diff(date1, "hours");
    if (hours > 0) {
      return `${hours} hr`;
    }
    const minutes = date2.diff(date1, "minutes");
    if (minutes > 0) {
      return `${minutes} min`;
    }
  
    return "Just Now";
  };
  const topPlayMusic = (item: any, index: any, songsList: any, trackName: any) => {
    if (handleAudioVideo === 1 || handleAudioVideo === 0) {
      SetSelectedIndex(index)
      handlePlayList(item, index, songsList, trackName)
    } else {
      setHandleAudioVideo(1)
    }

  }





  // video-block-end
  const returnActivity = (item: any, index: any) => {
    let iconComponent;

    
    switch (item.type) {
      case "repost"|| "reposted_track":
        iconComponent = RProfileIcon;
        break;
      case "uploads_track":
        iconComponent = UProfileIcon;
        break;
      default:
        iconComponent = FProfileIcon;
    }
    return (
      <Grid item style={{ padding: '10px' }} >
        {
          item.type === "track" && item.attributes.track_upload_type !== "Video" &&
          <AudioActivityNew
            data={item.attributes}
            song={item}
            time={findDuration(new Date(item.attributes.created_at).getTime() / 1000)}
            type={RProfileIcon}
            creativeById={creativeById}
            PlaySong={() => topPlayMusic(item, index, item, item.attributes.track_upload_type)}


          />
        }
        {
          item.type === "repost" && item.attributes.track_upload_type !== "Video" &&
          <AudioActivityNew
            data={item.attributes}
            song={item}
            time={findDuration(new Date(item.attributes.created_at).getTime() / 1000)}
            type={RProfileIcon}
            creativeById={creativeById}
            PlaySong={() => topPlayMusic(item, index, item, item.attributes.track_upload_type)}

          />
        }
        {
          item.type === "uploads_track" && item.attributes.track_upload_type !== "Video" &&
          <AudioActivityNew
            data={item.attributes}
            song={item}
            time={findDuration(new Date(item.attributes.created_at).getTime() / 1000)}
            type={UProfileIcon}
            creativeById={creativeById}
            PlaySong={() => topPlayMusic(item, index, item, item.attributes.track_upload_type)}


          />
        }
        {
          item.attributes.track_upload_type == "Video" &&
          <VideoActivity
            data={item.attributes}
            song={item}
            time={findDuration(new Date(item.attributes.created_at).getTime() / 1000)}
            type={iconComponent}
            creativeById={creativeById}
            handleAudioVideo={handleAudioVideo}
            setHandleAudioVideo={setHandleAudioVideo}
            selected={selected}
            videos={videos}
            hide={hide}
            setUrl={setUrl}
            setHide={setHide}
            setSelected={setSelected}
            url={url}
            setVideos={setVideos}
          />
        }
        {
          item.type === "following"||item.type === "activity_following" &&
          <UserActivity
            data={item.attributes}
            time={findDuration(new Date(item.attributes.created_at).getTime() / 1000)}
            creativeById={creativeById}
            type={FProfileIcon}
          />
        }

      </Grid>
    )
  }

  return (

    <>
      <Grid container xs={12} spacing={1} className="activity-parentDiv hide_for_mobile_view_activity">
        <Grid
          container
          spacing={1}
          item
          xs={12}
          sm={8}
          className="activity-mr main-scroll-activity hide_in_medium-activity"
          style={{
            flexDirection: get_All_Activity_data.length <= 2 ? "column" : "row",
            display: "flex"
          }}
        >
          <Box style={{ marginTop: "-11px" }} className="tab-content  activity-margin-head">
            Activity
          </Box>
          <ResponsiveMasonry
             columnsCountBreakPoints={{ 350: 1, 650:1, 1200: 2 }}
            style={{ width: '100%', height: '80vh'}}
            className= "profile-scroll-activity"
          >
            <Masonry style={{ width: '100%' }} >
              {get_All_Activity_data.length > 0 ? get_All_Activity_data.map((item: any, index: any) => returnActivity(item, index)

              ) :
                <p style={{
                  fontSize: "14px", color: "white", fontWeight: 900,
                  marginBottom: '30px',
                  position: "absolute",
                  left: "0px",
                  right: "0px",
                  marginLeft: "3%",
                  marginTop: "4%"
                }}
                >
                  No New Activity Releases
                </p>

              }
            </Masonry>
          </ResponsiveMasonry>

        </Grid>

        <Grid container item xs={12} sm={4} className="hide_in_medium-activity" style={{ display: "flex", alignContent: "baseline", flexDirection: 'column' }} >
          <Grid style={{ color: 'white', fontSize: '20px', fontWeight: 'bold', marginLeft: '10px', position: "relative" }}>Notifications <img src={notification} style={{
            width: "22px", height: "22px"
          }} /></Grid>
          {Object.keys(get_notification).length > 0 ?
            <ActivityNotifications get_notification={get_notification} handleReadNotification={handleReadNotification} />
            :
            <p style={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "white",
              marginLeft: "10px",
              marginTop: "10%"
            }}>No Notification Present</p>

          }
        </Grid>
        <Grid container item xs={12} sm={Object.keys(get_notification).length > 0 ? 6 : 12} className="activity-mr main-scroll-activity hide_in_mobile_and_desktop_activity" style={{
          height: '500px',
          display: "flex",
        }}>
          <Box style={{ marginTop: "-11px" }} className="tab-content">
            Activity
          </Box>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1 }}
            style={{ width: '100%' }}
          >
            <Masonry style={{ width: '100%' }} >
          {get_All_Activity_data.length > 0 ? get_All_Activity_data.map((item: any, index: any) =>

            returnActivity(item, index)

          )
            :
            <p style={{
              fontSize: "14px", color: "white", fontWeight: 900,
              marginBottom: '30px',
              position: "absolute",
              left: "0px",
              right: "0px",
              marginLeft: "3%",
              marginTop: "4%"
            }}
            >
              No New Activity Releases
            </p>

          }
           </Masonry>
          </ResponsiveMasonry>

        </Grid>
        {Object.keys(get_notification).length > 0 &&

          <Grid container item xs={12} sm={6} className="hide_in_mobile_and_desktop_activity" style={{ display: "flex", alignContent: "baseline", flexDirection: 'column' }} >
            <Grid style={{ color: 'white', fontSize: '20px', fontWeight: 'bold', marginLeft: '10px' }}>Notifications</Grid>
            <ActivityNotifications get_notification={get_notification} handleReadNotification={handleReadNotification}/>
          </Grid>
        }
      </Grid>

      <Grid container xs={12} className="hide_in_tab_and_desktop_activity">
        {Object.keys(get_notification).length > 0 &&

          <Grid item xs={12} style={{ display: "flex", alignContent: "baseline", flexDirection: 'column' }} >
            <Grid style={{ color: 'white', fontSize: '20px', fontWeight: 'bold', marginLeft: '10px' }}>Notifications</Grid>
            <ActivityNotifications get_notification={get_notification} handleReadNotification={handleReadNotification}/>


          </Grid>
        }
        <Grid item xs={12} className="main-scroll-activity" style={{
          display: "flex",
          flexDirection: "column"
        }}>
            <Box  className="tab-content">
            Activity
          </Box>
          {get_All_Activity_data.length > 0 ? get_All_Activity_data.map((item: any, index: any) =>

            returnActivity(item, index)


          ) :<div style={{height:"120px"}}>

            <p style={{
              fontSize: "14px", color: "white", fontWeight: 900,
              marginBottom: '30px',
              position: "absolute",
              left: "0px",
              right: "0px",
              marginLeft: "6%",
              marginTop: "6%"
            }}
            >
              No New Activity Releases
            </p>
          </div>
          

          }


        </Grid>
      </Grid>

    </>
  )
}

export default ActivitySelfView
// Customizable Area End