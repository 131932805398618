// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { HISTORY } from "../../../../../components/src/common";
import { getStorageData, setStorageData } from "../../../../../framework/src/Utilities";
import { openNotification } from "components/src/Notification.web";

export const configJSON = require("../../config.js");
// Customizable Area End

// Customizable Area Start
const tokenStr: any = localStorage.getItem("token");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: any;
    loading: boolean;
    selectedUser: any;
    dealTerms: any;
    selectedTerm: any;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class DealTermController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            loading: false,
            token: tokenStr,
            selectedUser: {
                id: 1,
                name: 'yfrs',
                type: 'Producer',
                equity: 60
            },
            dealTerms: [{
                id: 1,
                duration: 3,
                type: 'Month',
            }, {
                id: 2,
                duration: 6,
                type: 'Month',
            }, {
                id: 3,
                duration: 9,
                type: 'Month',
            }, {
                id: 4,
                duration: 1,
                type: 'Year',
            }],
            selectedTerm: {}
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start


    onNextClick = async () => {
        if (Object.keys(this.state.selectedTerm).length === 0) {
            openNotification("select one term", 'error')
        }
        else {
            await setStorageData('selectedTerm', this.state.selectedTerm)
            HISTORY.push({
                pathname: `/deal/preview-deal`,
                state: {}
            });
        }

    }

    onBackClick = () => {
        HISTORY.goBack();
    }

    isOdd = (n: number) => {
        if (n < 2) {
            return true;
        }
        if (n % 2 !== 0) {
            return true;
        }

        return false;
    }

    selectTerm = (term: any) => {
        this.setState({
            selectedTerm: term
        })
    }
    // Customizable Area End
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const selectedUser = await getStorageData('selectedUser')
        if (selectedUser) {
            this.setState({
                selectedUser: selectedUser,
            })
        } else {
            HISTORY.push('/deal')
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End
}
