// Customizable Area Start
import React, { Component } from "react";
import { defaultProfile, playButton, arrowBack } from "blocks/dashboard/src/assets";
import "blocks/dashboard/assets/css/repost.css";
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    Box,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    DialogTitle,
    Dialog,
    Typography,
    Grid,
    DialogActions,
    DialogContentText,
    DialogContent,
    TextField
} from "@material-ui/core";

import "blocks/user-profile-basic/src/Common/CardItem.web.css";

export default function RepostPopup(props: any) {
    const { onClose, open, profileImage, artistName, songName, repost_count, _handleTextFieldChange } = props;
    let profileImg: any = localStorage.getItem("profile_img");
    const user_name: any = localStorage.getItem("user_name");
    if(profileImg=="null"){
        profileImg=defaultProfile
    }

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            BackdropProps={{
                style: {
                    backdropFilter: "blur(10px)",
                    backgroundColor: "rgba(0, 0, 0, 0.15)",
                },
            }}
            PaperProps={{
                style: {
                    backgroundColor: "#121212",
                    borderRadius: "20px",
                    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
                    border: "solid 1px #1f1f1f",
                    width: "600px",
                },
            }}
        >
            <DialogTitle id="simple-dialog-title" className="dialog-title" >
                <Grid container xs>
                    <Grid item xs={1}  >
                        <img src={arrowBack} style={{
                            width: "10px",
                            height: "15px",
                            cursor: "pointer"
                        }}
                            onClick={onClose}
                        />

                    </Grid>
                    <Grid item xs={11} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <span className="repost-head" style={{ marginRight: "6%", fontWeight: "normal" }}> REPOST</span>
                    </Grid>
                </Grid>
            </DialogTitle>
            <List className="dialog-list">
                <ListItem button className="dialog-list-div ">

                    <ListItemAvatar className="relative">
                        <>
                            <Avatar
                                alt="Jimi Hendrix"
                                src={profileImg}
                                className="dialog-avatar"
                                style={{ width: "61px", height: "61px" }}
                            />

                        </>
                    </ListItemAvatar>
                    <ListItemText
                        className="dialog-list-text"
                        primary={user_name}
                        secondary={
                            <>
                                <span className="dialog-text-span" style={{ fontSize: "10px" }}>Reposted a track</span>
                                <span
                                    style={{
                                        borderRadius: "50px",
                                        width: "4px",
                                        height: "4px",
                                        margin: "11px 6px 8px",
                                        backgroundColor: "#99999f",

                                    }}
                                />
                                <span style={{
                                    color: "#99999f",
                                    fontSize: "10px"
                                }}>&#x2022; Just Now</span>
                            </>
                        }
                        style={{ flex: "inherit" }}
                    />





                </ListItem>
            </List>
            <DialogContent style={{ flex: "inherit" }}>
                {/* <DialogContentText>
            Add a caption (optional)
          </DialogContentText> */}
                {/* <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label=" Add a caption (optional)"
                    type="name"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                        style: { color: 'gray', fontWeight: 'bold' },
                    }}
                    InputProps={{
                        style: { color: "white", }
                    }}
                    onChange={(e) => _handleTextFieldChange(e)}

                /> */}
                <div className='material-textfield'>
                    <input
                        type="text"
                        className="inputCapation"
                        onChange={(e) => _handleTextFieldChange(e)}
                    />
                    <label className="labelcaption">Add a caption (optional)</label>
                </div>
            </DialogContent >
            <Grid container xs className="dialog-list" spacing={2} style={{ flex: "inherit" }}>
                <Grid item xs={3} style={{ position: "relative", height: "110px" }}>
                <img src={profileImage||defaultProfile} className="repost-dialog-img" />
                    <img

                        style={{
                            marginRight: "5px",
                            position: "absolute",
                            top: "30%",
                            left: "36%",
                            height: "36px",
                            cursor: "pointer"

                        }}

                        src={playButton}
                    />
                </Grid>
                <Grid item xs={9} style={{ display: "flex", flexDirection: "column", color: "white", justifyContent: "center" }}>
                    <Typography className="repost-dialog-songName">{songName}</Typography>
                    <Typography className="repost-dialog-artist">{artistName}</Typography>
                    {/* <Typography className="repost-dialog-artist">12:00 / 40:00</Typography> */}
                </Grid>
            </Grid>
            <DialogActions test-id= "repost_submit_count_id" className="repost--btn-parent" onClick={repost_count}>
                <Button className="repost-dialog-btn">Repost</Button>
            </DialogActions>
        </Dialog>
    );
}
// Customizable Area End
