// Customizable Area Start
import React from "react";
import DealAmountController, {
    Props,
} from "./DealAmountController.web";
import "./DealAmount.web.css";
import AsideLeft from "components/src/CustomChart/PopUps/AsideLeft.web";
import HomeHeader from "components/src/CustomChart/PopUps/HomeHeader.web";
import Loader from "../Loader.web";
import { Layout } from "antd";
import { Grid, Hidden, TextField, withStyles } from "@material-ui/core";
import { Content } from "antd/lib/layout/layout";
import RightPanel from '../Common/RightPanel.web';
import { DealAmountRightBg } from "../assets";
import CustomAmountInput from './CustomAmountInput.web'
// Customizable Area End

// Customizable Area Start
const CssTextField = withStyles({
    root: {
        '& label': {
            color: 'white',
            fontWeight: 800,
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& label.MuiInputLabel-shrink': {
            transform: 'translate(48px, -6px) scale(1)',
            background: 'black',
            padding: '0px 16px',
        },
        '& label..MuiInputLabel-outlined': {
            transform: 'translate(48px, 20px) scale(1)'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
            borderWidth: '2px',
            borderRadius: 10,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
                borderWidth: '2px',
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: 'white',
                borderWidth: '2px',
                borderRadius: 10,
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                borderWidth: '2px',
                borderRadius: 10,
            },
        },
    },
})(TextField);
// Customizable Area End
export class DealAmount extends DealAmountController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading, dealAmount } = this.state;
        return (
            <>
                <Layout className="MainLayoutContainer">
                    <Hidden smDown>
                        <AsideLeft />
                    </Hidden>
                    <Content
                        className="MainContent"
                    >
                        <HomeHeader />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="AudioSplitContent">
                                <Grid container>
                                    <div className='MainContentDiv'>
                                        <Grid item xs={12} sm={12} md={6} lg={7}  xl={8} className='ColumnCenter'>
                                            <div className='ColumnCenter AICenter AmountDiv'
                                            >

                                                <Hidden only={['lg', 'xl']}>
                                                    <div className="ColumnCenter AICenter MT32">
                                                        <div className='CommonText InfoTextSm'>Enter Amount You would like to </div>
                                                        <p className='CommonText InfoTextSm'>Offer For Split Purchase</p>
                                                    </div>
                                                </Hidden>
                                                {/* Deal Amount Input START*/}
                                                <CssTextField
                                                    data-test-id="DealAmountInput"
                                                    label="Enter Amount"
                                                    className="AmountTextField"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    value={dealAmount}
                                                    onChange={this.onAmountChange}
                                                    InputProps={{
                                                        inputComponent: CustomAmountInput as any,
                                                    }}
                                                />
                                                {/* Deal Amount Input END */}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                                            <RightPanel
                                                line1={`Enter The Amount You`}
                                                line2={`Would Like To Offer`}
                                                backgroundImage={DealAmountRightBg}
                                                onNextClick={this.onNextClick}
                                                onBackClick={this.onBackClick}
                                            />
                                        </Grid>
                                    </div>
                                </Grid>
                            </div>
                        )}
                    </Content >
                </Layout >
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default DealAmount;
// Customizable Area End