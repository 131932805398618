// Customizable Area Start
import React from "react"
import { IBlock } from  "../../../../../framework/src/IBlock" 
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
export const configJSON = require("../../config.js");

export interface Props {
    id?: any;
    navigation?: any;
  }

  interface S {
    songsList: any;
    apiToken: any;
    commentTabs: any;
    toggleComment: any;
  }

  interface SS {
    id: any;
  }

  export default class ChartVideosController extends BlockComponent<Props, S, SS> {
    getTopVideoList: string = "";
    constructor(props: Props) {
        super(props);
       
        this.receive = this.receive.bind(this);
    
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage)
        ];
    
        this.state = {
          songsList: [],
          apiToken: localStorage.getItem("token"),
          commentTabs: 0,
          toggleComment: false,
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getTopVideos()

      }

      getTopVideos = () => {
        const header = {
          "Content-Type": configJSON.dashboarContentType,
          'token': this.state.apiToken
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getTopVideoList = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.baseUrl + `${configJSON.topVideos}`
        );
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getAPIMethod
        );
        // console.log(requestMessage.id, requestMessage, "method call")
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      }

      handleToggle=()=>{
        this.setState({ toggleComment: !this.state.toggleComment })
      }

      handleCommentTabs=(data:any)=>{
        this.setState({ commentTabs: data })
      }

      handleRestApiCall = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getTopVideoList) {
          if (responseJson !== undefined && !responseJson.errors) {
            this.setState({ songsList: responseJson.data })
          }
        }
      }

      async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)
       
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
        if (apiRequestCallId == null) {
          this.parseApiCatchErrorResponse(errorReponse);
        }
        this.handleRestApiCall(apiRequestCallId, responseJson)
    }

  }

// Customizable Area End