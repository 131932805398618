// Customizable Area Start
import React from 'react';
import { Hidden, Typography } from "@material-ui/core";
import NextBackButtonGroup from './NextBackButtonGroup.web';
import './RightPanel.web.css';
// Customizable Area End


// Customizable Area Start
interface Props {
    backgroundImage: string;
    line1: string;
    line2: string;
    nextButtonName?: string;
    backButtonName?: string;
    onNextClick: () => void;
    onBackClick: () => void;
}
// Customizable Area End

// Customizable Area Start
export const RightPanel = (props: Props) => {
    const { line1, line2, backgroundImage, onNextClick, onBackClick } = props;
    return (
        <>
            <Hidden only={['xs', 'sm', 'md']}>
                <div className='RightPanel'
                    style={{
                        background: `linear-gradient(transparent 60vh, black 715px), url(${backgroundImage}) no-repeat center/cover`,
                    }}
                >
                    <div className="ActionButtonContainer">
                        <div className="ColumnCenter AICenter BtnContainer">
                            <Typography className='CommonText InfoTextLg'>{line1}</Typography>
                            <Typography className='CommonText InfoTextLg'>{line2}</Typography>
                            <NextBackButtonGroup
                                nextButtonName={props.nextButtonName}
                                backButtonName={props.backButtonName}
                                onNextClick={onNextClick}
                                onBackClick={onBackClick}
                            />
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden only={['lg', 'xl']}>
                <div className="ColumnCenter AICenter ActionButtonContainerSm BtnContainer">
                    <NextBackButtonGroup
                        nextButtonName={props.nextButtonName}
                        backButtonName={props.backButtonName}
                        onNextClick={onNextClick}
                        onBackClick={onBackClick}
                    />
                </div>
            </Hidden>
        </>
    );
}
// Customizable Area End


// Customizable Area Start
export default RightPanel;
// Customizable Area End
