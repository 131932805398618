// Customizable Area Start
import React from 'react'
import { Box, Typography } from '@material-ui/core';
import './Default.css'
import { useHistory } from "react-router-dom";
import { defaultProfile } from '../../dashboard/src/assets';


function ViewNewMessengerProfile({ userData ,isExpand}: any) {
    console.log(userData,"userData")
    const history = useHistory();

    const redirectProfile = (userType: any) => {
        if (userType == "creative") {
            history.push(`/creatives/beats?creative_id=${userData.id}`)
        } else if (userType == "listener") {
            history.push(`/listeners/activity?listener_id=${userData.id}`)
        } else {
            history.push(`/originals/beats?originals_id=${userData.id}`)
        }

    }
    const isExpandReturn=()=>{
        let returnElement:any;
        if(isExpand){
            returnElement= 
                    <p className='showPrivateMessageList' >Send Private
                    Message</p>
        } else{
            returnElement=
            <p className='view-profile-newconversation' onClick={() => { redirectProfile(userData?.attributes.user_type) }}>View Profile</p>

        }
        return returnElement
    }
    return (
        <>
            <Box className='particularUserWeb'>

                <img
                    className={`AVInfoContainer Audio`}
                    style={{
                        borderRadius: "50%",
                        width: '140px', height: '140px',
                        border: "solid 2px #212121"

                    }}
                    src={
                        userData?.attributes?.avatar || defaultProfile
                    }
                />
                 <Typography className='AVICMediaName' style={{ fontSize: "16px" }}>{userData?.attributes?.user_name||userData?.attributes?.artist_name || "Unknown"}</Typography>
                <Typography className='AVICMediaCreatorName' style={{ color: "#8f92a1" }}>{userData?.attributes?.user_type||userData?.attributes?.artist_type || "Unknown"}</Typography>
                <div style={{ marginTop: "7%" }}>
                {isExpandReturn()}
                </div>
            </Box>
        </>
    )
}

export default ViewNewMessengerProfile
// Customizable Area End