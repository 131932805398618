// Customizable Area Start
import React from 'react'
import { Grid } from "@material-ui/core";
import { useHistory } from 'react-router-dom';


export const MCreativePlan = ({ creativePlanAlt, price, name, currentPlan, isCurrentPlan, currentPaymentData,activatedPlan }: any) => {
console.log(creativePlanAlt, price, name, currentPlan, isCurrentPlan, currentPaymentData,"mcreativeplan")
    const history = useHistory();




    let label: any;
   

    if (name.includes("CREATIVE") ) {
        label = (
          <>
            <span className="listenerFont title_margin">Creative</span>
            {/* <AddIcon className="redColor" fontSize="medium" /> */}
    
          </>
        );
      } 
      else {
        label = (
          <>
            <span className="listenerFont title_margin">Listener</span>
            {/* <AddIcon className="redColor" fontSize="medium" /> */}
          </>
        );
      }
    
    return (
        <>
            <Grid item xs={12} container className="m_user_plan_div">
                <Grid item xs={12} container className="m_creativePlan" style={{
                    border: creativePlanAlt
                        ? "10px solid #0f6fd1"
                        : "10px solid #141414",

                }}>

                    <Grid className="m_plan_name_div">
                        <Grid className="m_plan_name">{label}</Grid>
                    </Grid>

                    <span className="m_creativePlanPriceFont">${price}</span>
                    <button className={creativePlanAlt ? "m_creative_your_btn" : "m_creative_get_btn"}

                        onClick={() => !creativePlanAlt && history.push({
                            pathname: "/mconfirmplan",
                            state: { planName: name, planPrice: price, currentPaymentData: currentPaymentData, currentPlan: currentPlan, isCurrentPlan: isCurrentPlan,activatedPlan:activatedPlan }
                        })}
                    >{creativePlanAlt ? "Your Plan" : "Get Plan"}</button>
                </Grid>
            </Grid>
        </>
    )
}
// Customizable Area End