// Customizable Area Start
import React from 'react'
import {
    Grid,
    Dialog,
} from "@material-ui/core";
import { Row, Col, Input, Form, Button, Upload } from "antd";
import './edit-playlist-popup.css'
import EditPlaylistPopupController from './EditPlaylistPopupController.web'
import { EditIcon } from './assets.web';
import { nxzloader } from '../../assets';

export class EditPopup extends EditPlaylistPopupController {

  render() {    
    return (
      <>
     <Dialog 
     open={this.props.open}
     onClose={this.props.handleClose}
     PaperProps = {{className: "dialog-Paper-edit"}}
     >
      {this.state.loading ? (
        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      ) : 
      (
         <Row justify="center" >
                <Col >
                  <Grid container  style={{justifyContent: 'center', padding: '10px', flexDirection: 'column', alignItems: 'center'}}>
                  <Grid item style={{fontSize: '28px', fontWeight: 900, color: 'white', margin: '15px'}} data-test-id="send-sms" >Edit Playlist</Grid>
                  <Grid item style={{fontSize: '16px',  color: '#9c9797', width: '100%', display: 'flex', alignItems: 'center',justifyContent: 'center'}} >Update Playlist Name</Grid>
                  <Grid item style={{fontSize: '16px',  color: '#9c9797', width: '100%', display: 'flex', alignItems: 'center',justifyContent: 'center'}} >and cover art</Grid>


                  <Grid item style={{width: '100%'}}>
                  <Form
                    name="basic"
                    ref={this.formRef}
                    className="Sms_form"
                    layout="vertical"
                    onFinish={this.updatePlaylist}
                    autoComplete="off"
                    initialValues={{
                      Playlist_cover: this.state.values.Playlist_cover,
                      Playlist_name: this.state.values.playlist_name          
                    }}
                  >
                   <Form.Item
                      name="Playlist_cover"
                      style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px'}}
                    >
                    <Upload
                      name="Playlist_cover"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={this.beforeUpload}
                      customRequest={(options: any) => {
                        this.handleChange(options)

                      }}
                    >
                      {this.state.values.Playlist_cover && 
                      <div style={{width: '100%',height: '100%', position: 'relative',borderRadius: '10px'}}>
                        <img src={this.state.values.Playlist_cover} alt="avatar" style={{ width: '100%', height: '100%' ,borderRadius: '8px'}} />
                        <div style={{position: 'absolute',top: '40%',left: '-20px' , width: '35px', height: '35px'}}>
                          <img src={EditIcon} style={{width: '100%', height: '100%'}}/>
                        </div>
                      </div> 
                      
                      }
                    </Upload>
                    </Form.Item>
                    <Form.Item
                      label="Update Playlist Name"
                      name="Playlist_name"
                      style={{width: '85%', margin: '20px'}}
                    >
                        <Input  style={{fontSize: '22px',fontWeight: 'bold', fontFamily: 'inter'}} />
                    </Form.Item>
                    <Form.Item style={{display: 'flex', justifyContent: 'center'}}>
                      <Button
                        className="red_custom_btn_sms login_btn mt-1"
                        //type="primary"
                        htmlType="submit"
                        data-test-id="send-button"
                      >
                        Update Playlist
                      </Button>
                    </Form.Item>
                    <Form.Item style={{display: 'flex', justifyContent: 'center'}}>
                      <Button
                        className="grey_custom_btn"
                        //type="primary"
                        htmlType="button"
                        data-test-id="send-button"
                        onClick={this.props.handleClose}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
                </Grid>
              </Grid>
            </Col>
         </Row>
      )
                    }
     </Dialog>
      
                    
      </>
    )
  }
}

export default EditPopup
// Customizable Area End