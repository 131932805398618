// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/common";
import axios from 'axios'
import { openNotification } from '../../../../components/src/Notification.web';

const urlConfig = require("./../../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

export interface Props {
    navigation?: any;
    id?: string;
    setActiveStep: () => void;
}
interface S {
    token: any;
    loading: any;
    scaned: any;
    audioFile: any;
    videoFile: any;
    uploadType: any;
}
interface SS {
    id: any;
}

export default class ScanProcessController extends BlockComponent<Props, S, SS> {
    getrecognizeAudioId: string = "";

    constructor(props :Props){
      super(props);
      this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
 
        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            scaned: false,
            audioFile: "",
            videoFile: "",
            uploadType: window.location.href.split('/')[4]
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async componentDidMount() {
        super.componentDidMount();
    }

    backClick = () =>{
        HISTORY.push('/uploadTrack')
    }

     uploadMp3 = (options: any, uploadType: any) =>{
        console.log(options, 'mp3 file')

        const {  file } = options;

        console.log(uploadType, 'upload type test')
        if(uploadType == 'audio'){
          console.log(file.type, 'selected file');
          if(file.type.split("/")[0] == "audio"){
            this.setState({audioFile: file, scaned: true})
            this.recognizeAudio(file)
          }else{
            openNotification('Please Select audio file', 'Error');
          }
        }else if(uploadType == 'video'){
          if(file.type.split('/')[0] == 'video'){
            console.log('video file')
            this.setState({videoFile: file, scaned: true})
  
          }else{
            openNotification('Please Select video file', 'Error');
          }
        }
      }

    recognizeAudio = async(audioFile: any) =>{
    
        const fmData: any = new FormData();
          fmData.append('audio_file', audioFile);

        const header = {
            "Content-Type": "application/json",
            'token': this.state.token
          };
          const headers = {
            "Content-Type": "application/json",
            'token': this.state.token
          };

          await axios.post(`${ baseUrl + '/account_block/acr/recognize_music'}`,fmData,{ headers }

        )
            .then((response: any) => {

            }).catch((err: any) => {

            });

          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.getrecognizeAudioId = requestMessage.messageId;
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            baseUrl + `${'/account_block/acr/recognize_music'}`
          );
          
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            fmData
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
    }


      async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
         if (apiRequestCallId != null) {
            if (
                apiRequestCallId === this.getrecognizeAudioId
      
              ) {
                if (responseJson !== undefined && !responseJson.errors) {
                 // this.setState({ allPlaylists: responseJson.data })
                }
             }
         }

        }
}
}
// Customizable Area End