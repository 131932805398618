// Customizable Area Start
// @ts-nocheck
import React, { useContext, useState } from "react";
import "./index.css";
import { Grid, Button, Typography } from "@material-ui/core";
import { plays, reshared, commentBlue, defaultProfile, playButton, pauseA } from '../../../../blocks/dashboard/src/assets'
import "../../../../blocks/user-profile-basic/src/Common/TopSongs.web.css";
import axios from "axios";
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import millify from "millify";
import { Col, Form, Row, Dropdown, Menu } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { openNotification } from '../../Notification.web';
import { useHistory } from "react-router-dom";
import RepostPopup from "components/src/CustomChart/PopUps/RepostPopup.web";
import AddToQueuePopup from 'blocks/dashboard/src/CustomComponent/AddToQueue/AddToQueuePopup.web';
import SharePlaylistPopup from 'blocks/dashboard/src/CustomComponent/SharePlaylistPopup/SharePlaylistPopup.web';
import PlayListCreateAndAddplayListModel from '../../../../blocks/Playlist/src/PlayListCard/PlayListCreateAndAddplayListModel.web'
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
//@ts-ignore
import WaveformCaraoussel from "../Songs/WaveformCaraoussel";

//@ts-ignore
const urlConfig = require('../../../../framework/src/config');

const baseUrl = urlConfig.baseURL;
const ChartsCarousel = (props: any) => {
    const history = useHistory();
    const [showModalAddNewPlaylist, setshowModalAddNewPlaylist] = useState<boolean>(false)
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [openPlaylistModal, setOpenPlaylistModal] = useState<any>(false)
    const [openAlertLogin, setopenAlertLogin] = useState(false)
    const [dialogText, setDialogText] = useState<any>("")
    const [open, setOpen] = useState(false);
    const [dialogTextAlertLogin, setDialogTextAlertLogin] = useState<any>(false)
    const [openRepostPopup, setOpenRepostPopup] = useState<boolean>(false)
    const [openPopUp, setOpenPopUp] = useState<boolean>(false)
    const [openPopup, setOpenPopup] = React.useState(false);
    const [textFieldValue, setTextFieldValue] = useState<any>('')
    const token: any = localStorage.getItem("token")
    const { data, name, mainList, songIndex, unique, handlePlayList } = props
    const { endingTime, currentTime, handleAudioVideo, setHandleAudioVideo } = useContext(AudioContextApi);
    const user_id: any = localStorage.getItem("user_id");
    const selectedSong = localStorage.getItem("selectedSong")
    const userType = localStorage.getItem('user_type')
    const subscriptionPlan = localStorage.getItem("subscription_plans")

    const closeModal = () => {
        setshowModalAddNewPlaylist(false)
    }

    const getUserPlaylist = async () => {
        await axios
            .get(`${baseUrl}/bx_block_playlist/play_lists`, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",

                    token: token,
                },
            })
            .then((response: any) => {
                console.log(response.data.data, "carasouel");

            })
            .catch((err: any) => {
                openNotification("error", "Error");

            });
    };

    // add to playlist charts fina

    const handleAddToPlaylist = () => {
        if (token) {

            setOpenPlaylistModal(true),
                getUserPlaylist()
        } else {
            history.push('/Welcome');
            // setopenAlertLogin(true),
            //     setDialogTextAlertLogin(token ? "1" : "2")
        }
    }
    const handleRepostClose = () => {
        setOpenRepostPopup(false)
    }

    const handleClosePlaylistModal = () => {
        form.resetFields();
        form1.resetFields();
        setOpenPlaylistModal(false)
    }
    const handleCloseAlertLogin = () => {
        setopenAlertLogin(false);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const onProfile = async (data: any, songIndex: any, mainList: any, name: any) => {
        const token: any = localStorage.getItem("token")
        const playlist = window.location.href.includes('playlists')

        if (token) {
            if (playlist) {
                // const artistId = localStorage.getItem('artist_id')
                const artistId = data?.attributes?.artist_id
                const user_id: any = localStorage.getItem("user_id")
                await localStorage.setItem("temp_id", user_id)
                await localStorage.removeItem("user_id")
                await localStorage.setItem("user_id", artistId)
                await localStorage.setItem("creatives_screen", "true")
                history.push(`/creatives/beats?creative_id=${artistId}`)
            } else history.push(`/creatives/beats?creative_id=${data?.attributes?.account_id}`)
        } else {
            history.push("/Welcome")
            // setopenAlertLogin(true),
            //     setDialogTextAlertLogin(token ? "1" : "2")
        }
    }
    const saveDownloadHistory = async () => {
        if (userType === "listener" && name != "Songs") {
            setOpen(true);
            setDialogText(userType === "listener" ? "3" : "2")
        } else {
            const datas = {
                account_id: user_id,
                add_track_id: data?.id

            };
            await axios
                .post(`${baseUrl}/download_histories`, datas, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",

                        token: token,
                    },
                })
                .then((res) => {
                    console.log("successfully downloaded")

                })
                .catch((err: any) => {

                    console.log("error in download")

                })
        }
    };
    const onShare = () => {
        const token: any = localStorage.getItem("token")
        if (token) {
            if (userType == "listener" && subscriptionPlan == "NXZ") {
                setopenAlertLogin(true)
                setDialogTextAlertLogin("3")
            } else {
                setOpenPopup(true)
            }
        } else {
            // setopenAlertLogin(true),
            //     setDialogTextAlertLogin("2")
            history.push('/Welcome')
        }

    }
    const ButtonGrp = () => {
        return (
            <div>
                <Menu
                    className="custom-drop-menu"
                >
                    <Menu.Item key="0" className="carasouel-menu-item" >Repost</Menu.Item>
                    <Menu.Item key="1" className="carasouel-menu-item">Share</Menu.Item>
                    <Menu.Item key="3" className="carasouel-menu-item"> Add to playlist</Menu.Item>
                    <Menu.Item key="4" className="carasouel-menu-item">Download</Menu.Item>

                </Menu>
            </div>
        );
    }
    const respostSubmit = () => {
        props.handleChartTrackRepost(textFieldValue)
        handleRepostClose()
    }
    const handleCaptionChange = (e: any) => {
        setTextFieldValue(e.target.value)
    }
    const handleRepost = () => {
        if (token) {
            setOpenRepostPopup(!openRepostPopup)
            setOpenPopUp(true)
        } else {
            setDialogText("2")
            setOpen(true)
        }
    }
    const StartTimeCalc = () => {
        const minute = Math.floor(currentTime / 60);
        const minutes: any = minute < 9 ? "0" + Number(minute) : minute;
        const second = parseInt(currentTime) - minutes * 60;
        const seconds: any = second < 9 ? "0" + Number(second) : second;
        return {
            minutes,
            seconds,
        };
    };
    const endTime = () => {
        const minute = Math.floor(endingTime / 60);
        const minutes: any = minute < 9 ? "0" + Number(minute) : minute;
        const second = parseInt(endingTime) - minutes * 60;
        const seconds: any = second < 9 ? "0" + Number(second) : second;
        return {
            minutes,
            seconds,
        };
    }
    let selectedSongID;

    if (selectedSong) {
        selectedSongID = JSON.parse(selectedSong)
    }
    return (
        <>
            <Row className="width_100 carasouel-parent-div" >
                <Col span={24} className="carasouel-track-div">
                    <div className="width_100 playTrackContainer column_flex_direction" >
                        <div >
                            <div className="song-cover-img width_height_cover carousel-track-child">
                                <div className="hide_in_medium carousel-artwork">
                                    <img src={data?.attributes?.art_work || data?.attributes?.cover_image || data?.attributes?.track_artwork || defaultProfile}
                                        className="carousel-artwork-img"
                                        onClick={() => {
                                            handlePlayList(data, unique, mainList, name, songIndex)
                                        }}/>

                                    <div className="carousel-position">
                                        <img src={data?.attributes?.art_work || data?.attributes?.cover_image || data?.attributes?.track_artwork || defaultProfile}
                                           className="carousel-cover-image"
                                            onClick={() => {
                                                handlePlayList(data, unique, mainList, name, songIndex)
                                            }}/>
                                    </div>
                                    <div className="carousel-absolute">
                                        <img
                                            className="carousel-song-img"
                                            onClick={(e: any) => {
                                                handlePlayList(data, unique, mainList, name, songIndex)
                                                setHandleAudioVideo(handleAudioVideo == 1 ? 2 : 1)
                                            }}
                                            src={selectedSong && selectedSong.split('"')[1] === data?.id && handleAudioVideo === 2 ? pauseA : playButton}/>
                                    </div>
                                </div>

                                <img src={data?.attributes?.art_work || data?.attributes?.cover_image || data?.attributes?.track_artwork || defaultProfile}
                                  
                                    onClick={(e: any) => {
                                        handlePlayList(data, unique, mainList, name, songIndex)

                                    }
                                    }
                                    className="hide_in_small_screen carousel-cover1"
                                />

                                <img
                                    className='hide_in_small_screen play-icon-centered carousel-absolute'
                                    onClick={() => {
                                        handlePlayList(data, unique, mainList, name, songIndex)
                                        setHandleAudioVideo(handleAudioVideo == 1 ? 2 : 1)
                                    }}
                                    src={selectedSong && selectedSong.split('"')[1] === data?.id && handleAudioVideo === 2 ? pauseA : playButton}
                                />
                            </div>
                        </div>
                        <div className="justify_space ml_1 width100">
                            <div className="width_100">
                                <div>
                                    <Grid container className='buttonContainer'>
                                        <Grid item md={3} className="dflex">
                                            <img
                                                onClick={() => { onProfile(data, songIndex, mainList, name) }}
                                                src={data?.attributes?.artist_image || data?.attributes?.avatar || defaultProfile} className="carousel-profile-img" />

                                            <div className="ml_1">
                                                <div className="carousel-width"
                                                    onClick={() => { onProfile(data, songIndex, mainList, name) }}>
                                                    <Typography className=" text_white mr_1 caraousel-artist carousel-artist1" >

                                                        {data?.attributes?.artist_name || 'Unknown'}
                                                    </Typography> <Typography className="caraousel-artist carousel-artist-type">
                                                        {data?.attributes?.creative_type_name ? data?.attributes?.creative_type_name : ""}
                                                    </Typography>
                                                </div>
                                                <Typography className="text_blue ml_1 font_16 font_W_600 caraousel-artist"
                                                    onClick={() => { onProfile(data, songIndex, mainList, name) }}>
                                                    {data?.attributes?.title || data?.attributes?.name || 'Remember me'}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item md={9}>
                                            <Row className="playTrackButton aliginment_center">
                                                <Col className="hiddn_countGrp">
                                                    <Row className="display-charts-icons mr_2" >
                                                        <Col className="display-carousel carousel-charts-pd">
                                                            <img src={plays} className="carousel-numbers-div" />
                                                            <p className="carasouel-numbers">
                                                                {data?.attributes?.play_count ? millify(data?.attributes?.play_count) : "0"}
                                                            </p>
                                                        </Col>
                                                        <Col className="display-carousel carousel-charts-pd">
                                                            <img src={commentBlue} className="carousel-number-blue" />
                                                            <p className="ml_05 carasouel-numbers" >
                                                                {data?.attributes?.comments_count ? millify(data?.attributes?.comments_count) : "0"}
                                                            </p>
                                                        </Col>
                                                        <Col className={data.type == "playlist"? "hide_repost_in_playlist carousel-type1" : "display-carousel carousel-type1"}>
                                                            <img src={reshared} className="carousel-reshared1"/>
                                                            <p className="carasouel-numbers">
                                                                {data?.attributes?.repost_count ? millify(data?.attributes?.repost_count) : "0"}
                                                            </p>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="show_btnGrp_menu display-none">
                                                    <Dropdown overlay={ButtonGrp} trigger={['click']}>
                                                          {/* @ts-ignore */}
                                                        <MoreOutlined  className="carousel-more" translate={undefined} />
                                                    </Dropdown>
                                                </Col>
                                                <Col className="hiddn_btnGrp">
                                                    <Row className="charts-display-carousel  display-column-chart " >
                                                        {!props.hideDownloadInPlaylist && token &&
                                                            <Col className="marginBottom-chart carousel-col-hide">
                                                                <Button className="carousel-col-btn"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={
                                                                        handleRepost
                                                                    }>
                                                                    Repost
                                                                </Button>
                                                            </Col>
                                                        }
                                                        <Col className="carousel-col-dflex">
                                                            <Button className="carousel-col-btn"
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={onShare}>
                                                                Share
                                                            </Button>
                                                        </Col>
                                                        <Col className="marginBottom-chart carousel-col-dflex">
                                                            <Button className="carousel-col-btn"
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={
                                                                    handleAddToPlaylist
                                                                }>
                                                                Add to playlist
                                                            </Button>
                                                        </Col>
                                                        {!props.hideDownloadInPlaylist && token &&
                                                            <a href={userType != "listener" || (userType == "listener" && name == "Songs") ? `${data?.attributes?.track_file}?response-content-disposition=attachment` : undefined} download={data?.attributes?.title} onClick={saveDownloadHistory}>
                                                                <Col className="carousel-col-dflex">
                                                                    <Button className="download-carousel-col-btn"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        endIcon={data?.attributes?.download_count.toString() || "0"}
                                                                    >
                                                                        Download
                                                                    </Button>
                                                                </Col>
                                                            </a>
                                                        }
                                                    </Row>

                                                </Col>
                                            </Row>
                                        </Grid>
                                    </Grid>
                                </div>
                                {openRepostPopup && <RepostPopup open={openPopUp}
                                    onClose={handleRepostClose}
                                    profileImage={data?.attributes?.art_work || data?.attributes?.cover_image || defaultProfile}
                                    artistName={data?.attributes?.artist_name}
                                    songName={data?.attributes?.title}
                                    repost_count={respostSubmit}
                                    _handleTextFieldChange={handleCaptionChange}
                                />}


                            </div>
                            {
                                name !== "Playlists" &&

                                <Row className="hiddn_wave width_100 mt_05">
                                    <div className="width100">
                                        <WaveformCaraoussel
                                            url={data?.attributes?.track_file}
                                            id={data?.id}
                                        />
                                        <p className="time_text">
                                            {selectedSongID === unique ?
                                                <>{StartTimeCalc().minutes}:{StartTimeCalc().seconds}/{endTime().minutes}:{endTime().seconds}</>
                                                :
                                                <>00:00/00:00</>
                                            }
                                            {/* {startMin}:{startendMin}/{songsMinutes}:{songsSeconds} */}
                                        </p>
                                    </div>
                                </Row>
                            }
                        </div>
                    </div>
                </Col>


            </Row >

            {/* < ScanQrDialog
                openPopup={openPopup}
                handleOpenPopup={() => setOpenPopup(false)}
                shareItem={window.location.href.includes('playlists') ? "Playlist" : "Track"}
                TrackId={data && data.id}
                playData={data}
                handleChartTrackRepost={props.handleChartTrackRepost}
            /> */}

            <SharePlaylistPopup
                id={data.type == "playlist" ? "Playlist" : "Track"}
                open={openPopup}
                handleClose={() => setOpenPopup(false)}
                track={data.type == "playlist" ? "" : data}
                playlist={data.type == "playlist" ? data : ""}
                isPlaylistRepost = {data.type == "playlist" ? true : false}
            />

            {
                showModalAddNewPlaylist && (
                    <PlayListCreateAndAddplayListModel closeModal={closeModal} showModalAddNewPlaylist={showModalAddNewPlaylist} addTrackToPlaylist={'addTrackToPlaylist'} />
                )
            }
            {open && <LoginAlert open={open} handleClose={handleClose} dialogText={dialogText} />}
            {openAlertLogin && <LoginAlert open={openAlertLogin} handleClose={handleCloseAlertLogin} dialogText={dialogTextAlertLogin} />}

            {
                openPlaylistModal &&
                <AddToQueuePopup id='charts' open={openPlaylistModal} handleClose={handleClosePlaylistModal} playlistType="audio"
                    // trackData={playData}
                    trackData={data}
                />
            }

        </>
    )
}
export default ChartsCarousel;
// Customizable Area End