// Customizable Area Start
import React from 'react'
import {
    Grid,
    Dialog,
} from "@material-ui/core";
import { Row, Col, Input, Form, Button } from "antd";
import PhoneInput from 'react-phone-input-2'
import './send-sms.css'
import SendSmsDialogController from './SendSmsDialogController.web'

export class SendSmsDialog extends SendSmsDialogController {
 
  render() {    
    return (
     <Dialog 
     open={this.props.open}
     onClose={this.props.handleClose}
     PaperProps = {{className: "dialog-Paper-Sms"}}
     >
         <Row justify="center" >
                <Col span={14} className='margin-left-div'>
                  <Grid container  style={{justifyContent: 'center', padding: '10px', flexDirection: 'column', alignItems: 'center'}}>
                  <Grid item style={{fontSize: '20px', fontWeight: 'bold', color: '#9c9797'}} data-test-id="send-sms" >Send SMS</Grid>
                  <Grid item>
                  <Form
                    name="basic"
                    className="Sms_form"
                    layout="vertical"
                    onFinish={this.onFinish}
                    autoComplete="off"
                    initialValues={{ }}

                  >
                    <Form.Item
                      label="Phone Number"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please Input Your Phone Number!",
                        },
                      ]}
                    >
                      <PhoneInput
                        inputStyle={{ background: '#020202', width: '100%', borderBottom: "1px solid #7a7e8a", borderTop: 'none', borderLeft: 'none', borderRight: 'none', color: 'white' }}
                        buttonStyle={{ background: '#020202', borderBottom: "1px solid #7a7e8a", borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
                        dropdownStyle={{ height: '150px', background: '#18181C', color: 'white' }}
                        searchStyle={{ background: '#020202', width: '100%', borderBottom: "1px solid #7a7e8a", borderTop: 'none', borderLeft: 'none', borderRight: 'none', marginLeft: '-10px', marginTop: "-10px" }}
                        country={'us'}
                        enableSearch
                        disableSearchIcon
                        onChange={(phone: any) => console.log(phone)}
                      />
                    </Form.Item>

                    <Form.Item
                      label="ShareLink"
                      name="share_link"
                    >
                      <Input defaultValue={this.props.smsLink} disabled/>
                    </Form.Item>
                    <Form.Item style={{display: 'flex', justifyContent: 'center'}}>
                      <Button
                        className="red_custom_btn_sms login_btn mt-1"
                        //type="primary"
                        htmlType="submit"
                        data-test-id="send-button"
                      >
                        Send
                      </Button>
                    </Form.Item>
                  </Form>
                </Grid>
              </Grid>
            </Col>
         </Row>
     </Dialog>
    )
  }
}

export default SendSmsDialog
// Customizable Area End