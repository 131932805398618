// Customizable Area Start
import React from 'react';
import { Button } from '@material-ui/core';
// Customizable Area End


// Customizable Area Start
interface Props {
    onNextClick: () => void;
    onBackClick: () => void;
    nextButtonName?: string;
    backButtonName?: string;
}
// Customizable Area End

// Customizable Area Start
export const NextBackButtonGroup = (props: Props) => {
    return (
        <>
            <Button
            data-test-id="nextbuttonid"
                className='SplitButton Next'
                onClick={props.onNextClick}
            >
                {props.nextButtonName || 'Next'}
            </Button>
            <Button
            data-test-id="prevbuttonid"
                className='SplitButton Back'
                onClick={props.onBackClick}
            >
                {props.backButtonName || 'Back'}
            </Button>
        </>
    );
}
// Customizable Area End


// Customizable Area Start
export default NextBackButtonGroup;
// Customizable Area End
