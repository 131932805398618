// Customizable Area Start


import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";




export interface Props {
  navigation: any;
  id: string;
}

interface S {
 musicButton:any
 enableButton:boolean
 
}

interface SS {
  id: any;
}

export default class DashboardWalletcontroller extends BlockComponent<
  Props,
  S,
  SS
> {

  
  subscriptionPlan = localStorage.getItem("subscription_plans")

  getTop100ListsApiCallId: any
  getSongsListsApiCallId: any
  beatsListsApiCallId: any
  gethooksListsApiCallId: any
  getversesListsApiCallId: any
  getplayListsApiCallId: any
  handleFilterApiCallId: any


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
    musicButton:1,
    enableButton:false

    };
  

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }
  setMusicButtonTrue=()=>{
    this.setState({musicButton:1})
    
  }
  setVideoButtonTrue=()=>{
    this.setState({musicButton:2})
   
    
  }
  setUserButtontrue=()=>{
    this.setState({musicButton:3})
   
    
  }




}
// Customizable Area End
