// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import "./Activity.web.css";
import { defaultProfile } from "../../../../dashboard/src/assets";

function ActivityNotifications({ get_notification,handleReadNotification }: any) {
  return (
    <>
      <Grid container
        style={{ paddingLeft: "10px" }}
        className="main-scroll notifications_height"
       
      >
        {get_notification.length > 0 &&
          get_notification.map((notifications: any, index: number) => {
            return (
              <Grid container item xs={12} style={{ display: "flex", padding: "10px 0px" ,alignItems:"center",
              // cursor:notifications.attributes.user_id?"pointer":"not-allowed"
            cursor:"pointer"
            }}
              onClick={()=>{
                handleReadNotification(notifications.id,notifications.attributes.user_type,notifications.attributes.type,notifications.attributes.user_id)
              }
              }
              >
                <Grid item xs={2}>
                <img
                  src={notifications.attributes.avatar || defaultProfile}
                  style={{
                    width: "44px",
                    height: "44px",
                    borderRadius: "50px",
                    marginRight: "3%",
                    objectFit:"cover"
                  }}
                />
                </Grid>
                <Grid item xs={9} style={{ display: "flex", flexDirection: "column" }}>
                  <div className="activity-notification-ellipses">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: notifications.attributes.color_content,
                      }} style={{
                        
                      }}
                    />
                 
                  </div>

                </Grid>
                <Grid item xs={1}>
                {!(notifications?.attributes?.is_read) &&
                <div style={{ backgroundColor: "#3959f9", height: 10, width: 10, borderRadius: 5, alignSelf: "center" }} />
            }
            </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}

export default ActivityNotifications;
// Customizable Area End
