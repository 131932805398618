// Customizable Area Start
import React from 'react'
import { List, Avatar, ListItemAvatar, ListItemText, Typography, ListItem, Grid, Box } from "@material-ui/core";
import ComposeBlockFollow from './ComposeBlockFollow.web';
import ComposerBlockUpperVideoController from '../Common/ComposeBlockUpperVideoController';
import { defaultProfile } from 'blocks/dashboard/src/assets';


export default class ComposeBlockUpperVideo extends ComposerBlockUpperVideoController {
    render() {
        const { creativeById } = this.props;
        if (Object.keys(creativeById).length > 0) {
            return (
                <Grid container xs={12} style={{ display: "flex", flexDirection: "row" }}>
                    <Grid item xs={12} sm={9} className="compose-grid"  >
                        <List >
                            <ListItem alignItems="flex-start" className="left-padding">
                                <ListItemAvatar>
                                    <Avatar alt="Loading..." src={creativeById?.attributes?.avatar || defaultProfile} className="profileImg" />
                                </ListItemAvatar>

                                <ListItemText
                                    className="title"
                                    primary=
                                    {
                                        <span className="list-name" style={{ fontSize: "22px", fontWeight: "bold", fontFamily: "karla", textTransform: "capitalize" }}> {creativeById?.attributes && creativeById?.attributes?.user_name} </span>

                                    }
                                    secondary={
                                        <Grid item xs={12} sm={12} className="subtitle-grid" style={{ fontFamily: "karla" }}>
                                            <Typography component="span" variant="body1" style={{ fontFamily: "karla" }}> {creativeById?.attributes && (creativeById?.attributes?.creative_type[0]?.name)} </Typography>
                                            {
                                                creativeById?.attributes?.city && (
                                                    <Typography component="span" variant="body2" style={{ fontFamily: "karla" }}> {creativeById?.attributes && creativeById?.attributes?.city}{creativeById?.attributes.country && `,${creativeById.attributes.country}`} </Typography>
                                                )
                                            }
                                        </Grid>
                                    }
                                />
                            </ListItem>
                        </List>
                        <Box>

                        </Box>
                    </Grid>
                    <ComposeBlockFollow
                        creativeById={creativeById}
                        new_released_video={this.props.new_released_video}
                        userData={this.props.userData}
                        openPopupFollowers={this.props.openPopupFollowers}
                        openPopupFollowing={this.props.openPopupFollowing}
                        allFollowerData={this.props.allFollowerData}
                        followingData={this.props.followingData}
                        loading={this.props.loading}
                        unfollowState={this.props.unfollowState}
                        loadingFollow={this.props.loadingFollow}
                        openScanQr={this.props.openScanQr}
                        visible={this.props.visible}
                        getFollowerData={this.props.getFollowerData}
                        unfollowUserById={this.props.unfollowUserById}
                        followUserByID={this.props.followUserByID}
                        handleRedirectToMessage={this.props.handleRedirectToMessage}
                        FolloweUser={this.props.FolloweUser}
                        getFollowingData={this.props.getFollowingData}
                        UnFolloweUser={this.props.UnFolloweUser}
                        handlePopupVisible={this.props.handlePopupVisible}
                        setVisible={this.props.setVisible}
                        setLoadingFollow={this.props.setLoadingFollow}
                        setopenScanQr={this.props.setopenScanQr}
                        setopenPopupFollowers={this.props.setopenPopupFollowers}
                        setopenPopupFollowing={this.props.setopenPopupFollowing}
                    />
                </Grid>
            )
        } else {
            return null
        }
    }
}
// Customizable Area End